import { Directive, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core'


import { DateService } from '../../_shared-core/service/date.service'

import { MatDatepickerInput } from '@angular/material/datepicker'
import { LocalDate } from 'app/_shared-core/model/common'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[numberDatePicker]'
})
export class NumberDatePickerDirective implements AfterViewInit, OnChanges {

  constructor(
    private _matDatepickerInput: MatDatepickerInput<Date>,
    private _dateService: DateService
  ) { }

  private _maxDate: LocalDate = { year: 2099, month: 12, day: 31 }
  private _minDate: LocalDate = { year: 2010, month: 1, day: 1 }

  ngAfterViewInit() {
    this._processMin(this._matDatepickerInput.min)
    this._processMax(this._matDatepickerInput.max)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.max) {
      this._processMax(changes.max.currentValue)
    }
    if (changes.min) {
      this._processMin(changes.min.currentValue)
    }
  }

  private _processMin(currentValue: Date) {
    if (currentValue) {
      const currentLocalDate = this._dateService.dateToLocalDate(currentValue)
      if (this._dateService.compareLocalDates(currentLocalDate, '<', this._minDate)) {
        this._matDatepickerInput.min = this._dateService.localDateToDate(this._minDate)
      }
    } else {
      this._matDatepickerInput.min = this._dateService.localDateToDate(this._minDate)
    }
  }

  private _processMax(currentValue: Date) {
    if (currentValue) {
      const currentLocalDate = this._dateService.dateToLocalDate(currentValue)
      if (this._dateService.compareLocalDates(currentLocalDate, '>', this._maxDate)) {
        this._matDatepickerInput.max = this._dateService.localDateToDate(this._maxDate)
      }
    } else {
      this._matDatepickerInput.max = this._dateService.localDateToDate(this._maxDate)
    }
  }

}
