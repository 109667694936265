import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { LaskuSharedService } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuSpostiService } from '../../../_jaettu/service/lasku/lasku-sposti.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { Lasku, LaskuBase, Laskuasetukset, LaskunumeroTyyppi, LaskunSahkpostipohjanVari } from '../../../_jaettu/model/lasku'

import { WindowSizeService } from '../../../_jaettu-angular/service/window.service'
import { TuettuKieli } from '../../../_shared-core/model/common'

import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface LaskuEmailEsikatselutiedot {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  maksumuistutus: boolean
}

@Component({
  selector: 'lasku-email-esikatselu-perinteinen',
  styleUrls: ['./email.perinteinen.component.css'],
  templateUrl: './email.perinteinen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuEmailEsikatseluPerinteinen implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('esikatseluContainer', { static: true }) esikatseluContainer: ElementRef
  @ViewChild('esikatseluSpacer', { static: true }) esikatseluSpacer: ElementRef
  @ViewChild('kokoemail', { static: true }) kokopdflasku: ElementRef

  private edellinenKerroin = 0
  private edellinenSpacerKorkeus = 0
  private _ngUnsubscribe = new Subject<void>()

  transformStyleObservable: BehaviorSubject<string> = new BehaviorSubject('scale(' + 695 / 595 + ')')
  _tiedot: LaskuEmailEsikatselutiedot

  juurilasku: Lasku
  kasiteltava: LaskuBase
  asetukset: Laskuasetukset
  ytunnusMuotoiltu: string
  maksumuistutus = false
  summa: number = 0
  virtuaaliviivakoodi = ''
  kieli: TuettuKieli = 'fi'
  logo: SafeUrl = null
  laskuttajanMaa: string = ''
  muotoiltuYtunnus: string = ''

  otsikko = ''
  erapaiva = ''
  vastaanottajat = ''

  @Input()
  varit: { [key in LaskunSahkpostipohjanVari]: string } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'header_taustavari': 'rgb(45,178,223)',
    'valiviiva': 'rgb(151,151,151)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'header_fontti': 'rgb(255,255,255)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'otsikko_fontti': 'rgb(45, 202, 253)'
  }

  get tiedot(): LaskuEmailEsikatselutiedot {
    return this._tiedot
  }
  @Input()
  set tiedot(t: LaskuEmailEsikatselutiedot) {

    this._tiedot = t
    this.juurilasku = t ? t.juurilasku : null
    this.kasiteltava = t ? t.kasiteltava : null
    this.asetukset = t ? t.asetukset : null
    this.maksumuistutus = t ? t.maksumuistutus : null

    if (this.asetukset) {
      this.laskuttajanMaa = this.shared.annaLokalisoituMaa(this.asetukset.maakoodi, this.kasiteltava)
    } else {
      this.laskuttajanMaa = ''
    }

    if (this.juurilasku && this.kasiteltava) {

      this.kieli = this.kasiteltava.kieli
      this.muotoiltuYtunnus = this.asetukset.ytunnus ? this.shared.muotoileYtunnusLaskunTyypinPerusteella(this.kasiteltava, this.asetukset.ytunnus) : ''

      if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        this.otsikko = this.shared.annaLokalisoituMerkkijono('email-perinteinen.otsikko-muistutuslasku', this.kasiteltava)
      } else {
        this.otsikko = this.shared.annaLokalisoituMerkkijono('email-perinteinen.otsikko', this.kasiteltava)
      }

      // if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
      //   this.erapaiva = this.shared.annaLokalisoituMerkkijono('pdf-perinteinen.heti', this.kasiteltava)
      // } else {
      this.erapaiva = this.shared.formatoiPvm(this.kasiteltava.erapvml, this.kasiteltava.erapvm, this.kasiteltava)
      // }

      if (this.kasiteltava && this.kasiteltava.email && this.kasiteltava.email.vastaanottajat) {
        this.vastaanottajat = this.kasiteltava.email.vastaanottajat.map(a => { return a.email }).join(', ')
      } else {
        this.vastaanottajat = ''
      }

      const summat = this.shared.annaLaskunSummat(this.kasiteltava)
      if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        const maksettavaaYhteensa = summat.yhteensaKaikki.plus(this.kasiteltava.summaHyvityksetLaskunPvm).minus(this.kasiteltava.summaReskontraLaskunPvm)
        this.summa = this.currencyService.muutaBigDecimalRahaksi(maksettavaaYhteensa)
      } else {
        this.summa = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki)
      }

      this.virtuaaliviivakoodi = this.shared.annaMuotoiltuVirtuaaliviivakoodi(this.juurilasku, this.kasiteltava, this.asetukset)

    } else {
      this.kieli = 'fi'
      this.otsikko = ''
      this.erapaiva = ''
      this.vastaanottajat = ''
      this.summa = 0
      this.virtuaaliviivakoodi = ''
      this.muotoiltuYtunnus = this.asetukset?.ytunnus ?? ''
    }

    if (this.asetukset && this.asetukset.base64Logo) {
      this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + this.asetukset.base64Logo)
    } else {
      this.logo = null
    }

    setTimeout(() => {
      this.laskeUudelleen()
      this.changeDetectorRef.markForCheck()
    }, 50)

  }

  constructor(
    public shared: LaskuSharedService,
    private currencyService: CurrencyService,
    private laskuSpostiService: LaskuSpostiService,
    private windowSizeService: WindowSizeService,
    private changeDetectorRef: ChangeDetectorRef,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // this.laskeUudelleen()
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {
      setTimeout(() => {
        this.laskeUudelleen()
        this.changeDetectorRef.markForCheck()
      }, 50)
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.laskeUudelleen()
      this.changeDetectorRef.markForCheck()
    }, 50)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private laskeUudelleen() {

    const leveysEnnenTransformaatiota = this.esikatseluContainer.nativeElement.offsetWidth
    const korkeusEnnenTransformaatiota = this.kokopdflasku.nativeElement.offsetHeight
    const uusiKerroin = Number((leveysEnnenTransformaatiota / 595).toFixed(2))

    if (this.edellinenKerroin !== uusiKerroin) {
      // console.log('UUSI KERROIN', this.edellinenKerroin, uusiKerroin)
      this.edellinenKerroin = uusiKerroin
      this.transformStyleObservable.next('scale(' + uusiKerroin + ')')
    }

    // const leveysTransformaationJalkeen = leveysEnnenTransformaatiota * this.esikatselunLeveysKerroin
    const korkeusTransformaationJalkeen = korkeusEnnenTransformaatiota * this.edellinenKerroin
    const kaytettavaSpacerKorkeus = korkeusTransformaationJalkeen < 1 ? 10 : Number(korkeusTransformaationJalkeen.toFixed(0))

    if (this.edellinenSpacerKorkeus !== kaytettavaSpacerKorkeus) {
      this.edellinenSpacerKorkeus = kaytettavaSpacerKorkeus
      this.esikatseluSpacer.nativeElement.style.height = (kaytettavaSpacerKorkeus + 20) + 'px'
      this.changeDetectorRef.markForCheck()
    }
    // this.esikatseluSpacer.nativeElement.style.width = (leveysTransformaationJalkeen) + 'px'
  }

  korvaaMuuttujat(korvattava: string): string {
    return this.laskuSpostiService.korvaaMuuttujat(korvattava, this.asetukset, this.juurilasku, this.kasiteltava)
  }

}
