/* eslint-disable no-bitwise */
import { Data, EcLevel, Matrix } from './model'

// Initialize matrix with zeros
export function init(version: number): Matrix {
  // eslint-disable-next-line no-bitwise
  const n = (version << 2) + 0b10001
  const matrix: Matrix = []
  const zeros: number[] = Array(n).fill(0)
  for (let i = 0; i < n; i++) {
    matrix[i] = [...zeros]
  }
  return matrix
}

// Put finders into matrix
export function fillFinders(matrix: Matrix) {
  const n = matrix.length
  for (let i = -3; i <= 3; i++) {
    for (let j = -3; j <= 3; j++) {
      const max = Math.max(i, j)
      const min = Math.min(i, j)
      const pixel =
        (max === 2 && min >= -2) || (min === -2 && max <= 2)
          ? 0x80
          : 0x81
      matrix[3 + i][3 + j] = pixel
      matrix[3 + i][n - 4 + j] = pixel
      matrix[n - 4 + i][3 + j] = pixel
    }
  }
  for (let i = 0; i < 8; i++) {
    matrix[7][i] =
      matrix[i][7] =
      matrix[7][n - i - 1] =
      matrix[i][n - 8] =
      matrix[n - 8][i] =
      matrix[n - 1 - i][7] =
      0x80
  }
}

// Put align and timinig
export function fillAlignAndTiming(matrix: Matrix) {
  const n = matrix.length
  if (n > 21) {
    const len = n - 13
    let delta = Math.round(len / Math.ceil(len / 28))
    if (delta % 2) { delta++ }
    const res: number[] = []
    for (let p = len + 6; p > 10; p -= delta) {
      res.unshift(p)
    }
    res.unshift(6)
    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < res.length; j++) {
        const x = res[i]
        const y = res[j]
        if (matrix[x][y]) { continue }
        for (let r = -2; r <= 2; r++) {
          for (let c = -2; c <= 2; c++) {
            const max = Math.max(r, c)
            const min = Math.min(r, c)
            const pixel =
              (max === 1 && min >= -1) || (min === -1 && max <= 1)
                ? 0x80
                : 0x81
            matrix[x + r][y + c] = pixel
          }
        }
      }
    }
  }
  for (let i = 8; i < n - 8; i++) {
    matrix[6][i] = matrix[i][6] = i % 2 ? 0x80 : 0x81
  }
}

// Fill reserved areas with zeroes
export function fillStub(matrix: Matrix) {
  const n = matrix.length
  for (let i = 0; i < 8; i++) {
    if (i !== 6) {
      matrix[8][i] = matrix[i][8] = 0x80
    }
    matrix[8][n - 1 - i] = 0x80
    matrix[n - 1 - i][8] = 0x80
  }
  matrix[8][8] = 0x80
  matrix[n - 8][8] = 0x81

  if (n < 45) { return }

  for (let i = n - 11; i < n - 8; i++) {
    for (let j = 0; j < 6; j++) {
      matrix[i][j] = matrix[j][i] = 0x80
    }
  }
}

// Fill reserved areas
export const fillReserved = (function () {
  const formats: number[] = Array(32)
  const versions: number[] = Array(40)

  const gf15 = 0x0537
  const gf18 = 0x1f25
  const formatsMask = 0x5412

  for (let format = 0; format < 32; format++) {
    let res = format << 10
    for (let i = 5; i > 0; i--) {
      if (res >>> (9 + i)) {
        res ^= gf15 << (i - 1)
      }
    }
    formats[format] = (res | (format << 10)) ^ formatsMask
  }

  for (let version = 7; version <= 40; version++) {
    let res = version << 12
    for (let i = 6; i > 0; i--) {
      if (res >>> (11 + i)) {
        res ^= gf18 << (i - 1)
      }
    }
    versions[version] = res | (version << 12)
  }

  const ecLevels: { [K in EcLevel]: number } = {
    L: 1,
    M: 0,
    Q: 3,
    H: 2,
  }

  return function fillReserveds(
    matrix: Matrix,
    ecLevel: EcLevel,
    mask: number
  ) {
    const n = matrix.length
    const format = formats[(ecLevels[ecLevel] << 3) | mask]

    function f(k: number) {
      return (format >> k) & 1 ? 0x81 : 0x80
    }
    for (let i = 0; i < 8; i++) {
      matrix[8][n - 1 - i] = f(i)
      if (i < 6) { matrix[i][8] = f(i) }
    }
    for (let i = 8; i < 15; i++) {
      matrix[n - 15 + i][8] = f(i)
      if (i > 8) { matrix[8][14 - i] = f(i) }
    }
    matrix[7][8] = f(6)
    matrix[8][8] = f(7)
    matrix[8][7] = f(8)

    const version = versions[(n - 17) / 4]
    if (!version) {
      return
    }

    function v(k: number) {
      return (version >> k) & 1 ? 0x81 : 0x80
    }
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 3; j++) {
        matrix[n - 11 + j][i] = matrix[i][n - 11 + j] = v(i * 3 + j)
      }
    }
  }
})()

// Fill data
export const fillData = (function () {
  const maskFunctions = [
    (i: number, j: number) => (i + j) % 2 === 0,
    (i: number, j: number) => i % 2 === 0,
    (i: number, j: number) => j % 3 === 0,
    (i: number, j: number) => (i + j) % 3 === 0,
    (i: number, j: number) => (Math.floor(i / 2) + Math.floor(j / 3)) % 2 === 0,
    (i: number, j: number) => ((i * j) % 2) + ((i * j) % 3) === 0,
    (i: number, j: number) => (((i * j) % 2) + ((i * j) % 3)) % 2 === 0,
    (i: number, j: number) => (((i * j) % 3) + ((i + j) % 2)) % 2 === 0,
  ]

  return function fillDatas(matrix: Matrix, data: Data, mask: number) {
    const n = matrix.length
    let row: number
    let col: number
    let dir = -1
    row = col = n - 1
    const maskFn = maskFunctions[mask]
    let len = data.blocks[data.blocks.length - 1].length

    for (let i = 0; i < len; i++) {
      for (let b = 0; b < data.blocks.length; b++) {
        if (data.blocks[b].length <= i) {
          continue
        }
        put(data.blocks[b][i])
      }
    }

    len = data.ecLen
    for (let i = 0; i < len; i++) {
      for (let b = 0; b < data.ec.length; b++) {
        put(data.ec[b][i])
      }
    }

    if (col > -1) {
      do {
        matrix[row][col] = maskFn(row, col) ? 1 : 0
      } while (next())
    }

    function put(byte: number) {
      for (let m = 0x80; m; m >>= 1) {
        let pixel = !!(m & byte)
        if (maskFn(row, col)) { pixel = !pixel }
        matrix[row][col] = pixel ? 1 : 0
        next()
      }
    }

    function next() {
      do {
        if (col % 2 ^ Number(col < 6)) {
          if ((dir < 0 && row === 0) || (dir > 0 && row === n - 1)) {
            col--
            dir = -dir
          } else {
            col++
            row += dir
          }
        } else {
          col--
        }
        if (col === 6) {
          col--
        }
        if (col < 0) {
          return false
        }
      } while (matrix[row][col] & 0xf0)
      return true
    }
  }
})()

// Calculate penalty
export function calculatePenalty(matrix: Matrix) {
  const n = matrix.length
  let penalty = 0
  // Rule 1
  for (let i = 0; i < n; i++) {
    let pixel = matrix[i][0] & 1
    let len = 1
    for (let j = 1; j < n; j++) {
      const p = matrix[i][j] & 1
      if (p === pixel) {
        len++
        continue
      }
      if (len >= 5) {
        penalty += len - 2
      }
      pixel = p
      len = 1
    }
    if (len >= 5) {
      penalty += len - 2
    }
  }
  for (let j = 0; j < n; j++) {
    let pixel = matrix[0][j] & 1
    let len = 1
    for (let i = 1; i < n; i++) {
      const p = matrix[i][j] & 1
      if (p === pixel) {
        len++
        continue
      }
      if (len >= 5) {
        penalty += len - 2
      }
      pixel = p
      len = 1
    }
    if (len >= 5) {
      penalty += len - 2
    }
  }

  // Rule 2
  for (let i = 0; i < n - 1; i++) {
    for (let j = 0; j < n - 1; j++) {
      const s =
        (matrix[i][j] +
          matrix[i][j + 1] +
          matrix[i + 1][j] +
          matrix[i + 1][j + 1]) &
        7
      if (s === 0 || s === 4) {
        penalty += 3
      }
    }
  }

  // Rule 3
  function getI(i: number, j: number, k: number) {
    return matrix[i][j + k] & 1
  }
  function getJ(i: number, j: number, k: number) {
    // eslint-disable-next-line no-bitwise
    return matrix[i + k][j] & 1
  }
  for (let i = 0; i < n; i++) {
    for (let j = 0; j < n; j++) {
      if (
        j < n - 6 &&
        getI(i, j, 0) &&
        !getI(i, j, 1) &&
        getI(i, j, 2) &&
        getI(i, j, 3) &&
        getI(i, j, 4) &&
        !getI(i, j, 5) &&
        getI(i, j, 6)
      ) {
        if (j >= 4 && !(getI(i, j, -4) || getI(i, j, -3) || getI(i, j, -2) || getI(i, j, -1))) {
          penalty += 40
        }
        if (j < n - 10 && !(getI(i, j, 7) || getI(i, j, 8) || getI(i, j, 9) || getI(i, j, 10))) {
          penalty += 40
        }
      }

      if (
        i < n - 6 &&
        getJ(i, j, 0) &&
        !getJ(i, j, 1) &&
        getJ(i, j, 2) &&
        getJ(i, j, 3) &&
        getJ(i, j, 4) &&
        !getJ(i, j, 5) &&
        getJ(i, j, 6)
      ) {
        if (i >= 4 && !(getJ(i, j, -4) || getJ(i, j, -3) || getJ(i, j, -2) || getJ(i, j, -1))) {
          penalty += 40
        }
        if (i < n - 10 && !(getJ(i, j, 7) || getJ(i, j, 8) || getJ(i, j, 9) || getJ(i, j, 10))) {
          penalty += 40
        }
      }
    }
  }

  // Rule 4
  let numDark = 0
  for (let i = 0; i < n; i++) {
    for (let j = 0; j < n; j++) {
      // eslint-disable-next-line no-bitwise
      if (matrix[i][j] & 1) { numDark++ }
    }
  }
  penalty += 10 * Math.floor(Math.abs(10 - (20 * numDark) / (n * n)))

  return penalty
}

// All-in-one function
export function getMatrix(data: Data): Matrix {
  const matrix = init(data.version)
  fillFinders(matrix)
  fillAlignAndTiming(matrix)
  fillStub(matrix)

  let penalty = Infinity
  let bestMask = 0
  for (let mask = 0; mask < 8; mask++) {
    fillData(matrix, data, mask)
    fillReserved(matrix, data.ecLevel, mask)
    const p = calculatePenalty(matrix)
    if (p < penalty) {
      penalty = p
      bestMask = mask
    }
  }

  fillData(matrix, data, bestMask)
  fillReserved(matrix, data.ecLevel, bestMask)

  return matrix.map((row) => row.map((cell) => cell & 1))
}
