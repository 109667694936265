// import { Input, OnDestroy, OnChanges, Directive } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'

// import { MatInput } from '@angular/material/input'
// import { coerceBooleanProperty } from '@angular/cdk/coercion'

// import { Subject } from 'rxjs'

export class ValueAccessorBase<T> implements ControlValueAccessor {

  protected innerValue: T

  protected changed = new Array<(value: T) => void>()
  protected initial = new Array<(value: T) => void>()
  protected touched = new Array<() => void>()

  get value(): T {
    return this.innerValue
  }

  set value(value: T) {
    // console.log('SET ', value, this.innerValue,  typeof value, typeof this.innerValue)
    if (this.innerValue !== value) {
      this.innerValue = value
      this.changed.forEach(f => f(value))
    }
  }

  touch() {
    this.touched.forEach(f => f())
  }

  writeValue(value: T) {
    // console.log('WRITE ', value, this.innerValue,  typeof value, typeof this.innerValue)
    if (this.innerValue !== value) {
      this.innerValue = value
      this.initial.forEach(f => f(value))
    }
  }

  registerOnWrite(fn: (value: T) => void) {
    this.initial.push(fn)
  }

  registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn)
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn)
  }

}

// export class MaterialFormComponentBase<T> extends ValueAccessorBase<T> implements OnChanges, OnDestroy {

//   stateChanges = new Subject<void>()

//   ngOnChanges() {
//     this.stateChanges.next()
//   }

//   ngOnDestroy() {
//     this.stateChanges.complete()
//   }

// }

// @Directive()
// export abstract class MaterialFormComponentMaterialBase<T> extends MaterialFormComponentBase<T> {

//   protected abstract get empty(): boolean

//   @Input()
//   get focused(): boolean { return this._focused }
//   set focused(req: boolean) {
//     this._focused = coerceBooleanProperty(req)
//     this.stateChanges.next()
//   }
//   private _focused: boolean = false

//   @Input()
//   get placeholder(): string { return this._placeholder }
//   set placeholder(plh: string) {
//     this._placeholder = plh
//     this.stateChanges.next()
//   }
//   private _placeholder: string

//   @Input()
//   get required(): boolean { return this._required }
//   set required(req: boolean) {
//     this._required = coerceBooleanProperty(req)
//     this.stateChanges.next()
//   }
//   private _required: boolean = false

//   @Input()
//   get disabled(): boolean { return this._disabled }
//   set disabled(dis: boolean) {
//     this._disabled = coerceBooleanProperty(dis)
//     this.stateChanges.next()
//   }
//   private _disabled: boolean = false

//   /** Whether the element is readonly. */
//   @Input()
//   get readonly(): boolean { return this._readonly }
//   set readonly(value: boolean) { this._readonly = coerceBooleanProperty(value) }
//   private _readonly: boolean = false

//   /** Callback for the cases where the focused state of the input changes. */
//   _focusChanged(isFocused: boolean) {
//     if (isFocused !== this.focused && !this.readonly) {
//       this.focused = isFocused
//       this.stateChanges.next()
//     }
//   }

//   get shouldLabelFloat() {
//     return this.focused || !this.empty
//   }

//   constructor() {
//     super()
//   }

// }

// @Directive()
// export abstract class MaterialFormComponentMaterialInputBase<T> extends MaterialFormComponentBase<T> {

//   protected abstract materialInput: MatInput
//   protected abstract get empty(): boolean
//   protected abstract markAsTouched()

//   constructor() {
//     super()
//   }

//   @Input()
//   get focused() { return this.materialInput.focused }
//   set focused(req) {
//     this.materialInput.focused = coerceBooleanProperty(req)
//     this.stateChanges.next()
//   }

//   @Input()
//   get placeholder() { return this.materialInput.placeholder }
//   set placeholder(plh) {
//     this.materialInput.placeholder = plh
//     this.stateChanges.next()
//   }

//   @Input()
//   get required() { return this.materialInput.required }
//   set required(req) {
//     this.materialInput.required = coerceBooleanProperty(req)
//     this.stateChanges.next()
//   }

//   @Input()
//   get disabled() { return this.materialInput.disabled }
//   set disabled(dis) {
//     this.materialInput.disabled = coerceBooleanProperty(dis)
//     this.stateChanges.next()
//   }

//   /** Whether the element is readonly. */
//   @Input()
//   get readonly(): boolean { return this.materialInput.readonly }
//   set readonly(value: boolean) { this.materialInput.readonly = coerceBooleanProperty(value) }

//   focus() {
//     this.materialInput.focus()
//     this._focusChanged(true)
//   }

//   /** Callback for the cases where the focused state of the input changes. */
//   _focusChanged(isFocused: boolean) {
//     if (isFocused !== this.focused && !this.readonly) {
//       if (!isFocused) {
//         this.markAsTouched()
//       }
//       this.focused = isFocused
//       this.stateChanges.next()
//     }
//   }

//   get shouldLabelFloat() {
//     return this.focused || !this.empty
//   }

// }
