import { Component, OnInit, ViewChild, Optional, forwardRef, ErrorHandler, ChangeDetectionStrategy, Input, Host, SkipSelf } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlContainer, AbstractControl, FormControl } from '@angular/forms'

import { MatInput } from '@angular/material/input'

import { LaskunTuoteTypeaheadBase } from '../../service/lasku/typeahead.service'
import { ValueAccessorBase } from '../../../_jaettu-angular/_components/value-accessor-base'

import { AsiakkaanTuote, TypeaheadTuote } from '../../../_jaettu/model/lasku'

import { BehaviorSubject } from 'rxjs'
import { FormValidationService } from '../../../_jaettu-angular/service/form-validation.service'

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TuoteHakuComponent),
  multi: true
}

@Component({
  selector: 'app-tuote-haku',
  template: `
      <mat-form-field>
        <input #inputti autocomplete="kirjanpitoaineistoarkki" [formControl]="ctrl" [placeholder]="'tuote.aloita-kirjoittamalla-tuotteen-nimi' | translate | async" [matAutocomplete]="tuoteamaattinen" (blur)="onBlur()" matInput [name]="name()" data-lpignore="true">
        <mat-error>
          <ng-content></ng-content>
        </mat-error>
      </mat-form-field>

      <mat-autocomplete #tuoteamaattinen [displayWith]="getDisplayFn()">
        <mat-option *ngFor="let tuote of suodatetutTuotteet | async" [value]="tuote">
          {{ tuote.nimi }}
        </mat-option>
      </mat-autocomplete>
    `
  ,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuoteHakuComponent extends ValueAccessorBase<AsiakkaanTuote> implements OnInit {

  @ViewChild('inputti', { read: MatInput, static: true }) materialInput: MatInput

  @Input() paivitaValidaatiot: any
  @Input() formControlName: string

  private control: AbstractControl
  _disabled: boolean = false

  ctrl: FormControl<TypeaheadTuote> = null
  suodatetutTuotteet: BehaviorSubject<TypeaheadTuote[]> = new BehaviorSubject([])

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private laskunTuoteTypeahead: LaskunTuoteTypeaheadBase,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler
  ) {
    super()
  }

  ngOnInit() {

    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName)
      } else {
        console.warn('Missing FormControlName directive from host element of the component')
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive')
    }

    this.ctrl = new FormControl<TypeaheadTuote>(
      {
        value: this.control.value,
        disabled: this.control.disabled
      },
      this.control.validator,
      this.control.asyncValidator
    )

    this.registerOnWrite(value => {
      this.ctrl.setValue({
        avain: value.$key,
        nimi: value.nimi
      }, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false })
      // JOS TÄMÄ ON PÄÄLLÄ, niin CONTROLLILLE TULEVAT ARVOT LAUKAISEVAT MYÖS ULOSPÄIN ARVON VIENNIN!!
      // TÄMÄ AIHEUTTAA ONGELMAN KUN MUOKATAAN LASKULUONNOSTA, JA TYPEAHEAD TUOTTEELLA ON ERI HINTA KUIN LASKURIVILLÄ
      // (LUONNOKSEN TALLENTAMINEN EI TALLENNA HINTAA TUOTTEESEEN)

      // EN TIEDÄ; MIKSI TÄMÄ ON JOSKUS PÄÄLLE LAITETTU???

      // this.ctrl.updateValueAndValidity()
    })

    this.ctrl.valueChanges.subscribe((value: TypeaheadTuote) => {

      if (!value) {
        this.suodatetutTuotteet.next([])
        this.value = null
        return
      }

      if (typeof value === 'string') {
        const kasitelty = this.validationService.processValue(value)
        // console.log('kasitelty', kasitelty)
        this.laskunTuoteTypeahead.haeJaSuodata(kasitelty).then(result => {
          if (result.loytynyt == null) {
            const tuote: AsiakkaanTuote = {
              $key: null,
              nimi: kasitelty,
              hinta: null,
              date: null,
              alv: null
            }
            this.value = tuote
            this.suodatetutTuotteet.next(result.suodatetut)
          } else {
            this.laskunTuoteTypeahead.getTuote(result.loytynyt.avain)
              .then(tuote => {
                this.value = tuote
                this.suodatetutTuotteet.next(result.suodatetut)
              }).catch(err => {
                this.errorHandler.handleError(err)
              })
          }
        }, err => {
          this.errorHandler.handleError(err)
        })
      } else {
        const avain = value.avain as string
        if (avain && avain.replace(/\s/g, '') !== '') {
          // console.log('Hae tuotetta', value)
          this.laskunTuoteTypeahead.getTuote(avain).then(tuote => {
            if (tuote) {
              // console.log('löytynyt tuote', tuote)
              this.value = tuote
              this.suodatetutTuotteet.next([])
            } else {
              // console.log('ei löytynyt tuotetta')
              const t: AsiakkaanTuote = {
                $key: avain,
                nimi: value.nimi,
                hinta: null,
                date: null,
                alv: null
              }
              this.value = t
              this.suodatetutTuotteet.next([])
            }
          }).catch(err => {
            this.errorHandler.handleError(err)
          })
        }
      }
    })
  }

  onBlur() {
    this.touch()
  }

  public focus() {
    this.materialInput.focus()
  }

  markAsTouched() {
    this.control.markAsTouched()
    this.ctrl.markAsTouched()
    this.touch()
  }

  getDisplayFn() {
    return (val) => this.display(val)
  }

  private display(tuote: AsiakkaanTuote): string {
    if (tuote && tuote.nimi) {
      return tuote.nimi
    }
    return ''
  }

  name(): string {
    return '' + Math.random()
  }

}
