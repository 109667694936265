export class LruMap<K, V> implements Map<K, V> {

  private vals: Map<K, V> = new Map<K, V>()

  constructor(private maxEntries: number) { }

  public clear(): void {
    this.vals.clear()
  }

  delete(key: K): boolean {
    return this.vals.delete(key)
  }

  forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this.vals.forEach(callbackfn, thisArg)
  }

  has(key: K): boolean {
    return this.vals.has(key)
  }

  keys(): IterableIterator<K> {
    return this.vals.keys()
  }

  entries(): IterableIterator<[K, V]> {
    return this.vals.entries()
  }

  values(): IterableIterator<V> {
    return this.vals.values()
  }

  get size(): number {
    return this.vals.size
  }

  get(key: K): V {
    const entry = this.vals.get(key)
    if (entry) {
      this.vals.delete(key)
      this.vals.set(key, entry)
    }
    return entry
  }

  set(key: K, value: V) {
    if (this.vals.size >= this.maxEntries) {
      // least-recently used cache eviction strategy
      const keyToDelete = this.vals.keys().next().value
      this.vals.delete(keyToDelete)
    }
    this.vals.set(key, value)
    return this
  }

  [Symbol.iterator]() {
    const i = 0
    return this.vals[Symbol.iterator]()
  }

  get [Symbol.toStringTag]() {
    return 'LruMap'
  }

}
