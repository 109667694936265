import { captureException } from '@sentry/browser'

import { Component, OnInit, ErrorHandler, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core'

import { MatSnackBar } from '@angular/material/snack-bar'

import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { KirjanpitoService, ListausAlvIlmoitus } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { KirjanpitoJaettuService } from '../_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { Asiakas, AsiakkaalleLemonatoristaLahetettyLasku, KirjanpitoKuukausiruutu, VerojenMaksuyhteystiedot } from 'app/_jaettu-lemonator/model/asiakas'
import { AlvIlmoitus, AlvIlmoituksenLahetyspyynto, AlvIlmoituksenTyviTiedostonLatauspyynto, AlvIlmoituksenTyviTiedostonLatausvastaus, AlvTilienArhPaattaminenIlmoituksenPerusteellaPyynto, AlvTilienPaattaminenPyynto, AlvTilienPaattaminenVastaus, AlvIlmoituksenTunnistetiedot } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { LocalDate, LocalMonth } from 'app/_shared-core/model/common'

import { Observable, combineLatest, of, Subject, BehaviorSubject, firstValueFrom } from 'rxjs'
import { switchMap, map, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators'

import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { Lasku } from 'app/_jaettu/model/lasku'
import { ViitenumeroService } from 'app/_shared-core/service/viitenumero.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'
import { LEMONATOR_CF_API, LemonHttpService } from 'app/_angular/service/lemon-http.service'
import { LaskuLataaDialog, LaskuLataaDialogData } from 'app/laskut/dialogit/lasku.lataa.dialog'
import { MatDialog } from '@angular/material/dialog'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { LaskuLataaLahetystiedostaDialog, LaskuLataaLahetystiedostaDialogData } from 'app/laskut/dialogit/lasku.lataa-lahetystiedosta.dialog'
import { AlvLaskunLahetysService } from 'app/_jaettu-lemonator/service/alv-laskun-lahetys.service'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

@Component({
  selector: '[app-kirjanpito-alv-ilmoitus]',
  templateUrl: './alv-ilmoitus.component.html',
  styleUrls: ['./alv-ilmoitus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoAlvIlmoitusComponent implements OnInit, OnDestroy {

  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Output() nykyisellaKuukaudellaOnMuutoksia: EventEmitter<'yes' | 'no' | 'not-saved-yet'> = new EventEmitter()
  @Output() viimeisinAlvIlmoitus: EventEmitter<AlvIlmoitus> = new EventEmitter()

  private _ngUnsubscribe = new Subject<void>()

  tunnistetietoObservable: Observable<string>
  tallennustiedotObservable: Observable<string>
  aikaisemmatVersiotObservable: Observable<ListausAlvIlmoitus[]>

  historyLoadingSubject = new BehaviorSubject<boolean>(true)
  numbersLoadingSubject = new BehaviorSubject<boolean>(true)
  alvIlmoitusSubject = new BehaviorSubject<AlvIlmoitus>(this._kirjanpitoJaettuService.annaUusiAlvilmoitus())

  tallentamattomiaMuutoksiaObservable: Observable<boolean>
  edellinenAlvIlmoitusObservable: Observable<AlvIlmoitus | null>
  edellinenMaksettavaTaiPalautettavaVeroObservable: Observable<number>
  maksettavaTaiPalautettavaVeroObservable: Observable<number>
  muutosVeronMaarassaObservable: Observable<number>
  voidaanLahettaaObservable: Observable<boolean>
  errorMessageObservable: Observable<string>

  alvLaskunViitenumeroObservable: Observable<string>
  alvLaskunSahkoinenLaskutusosoiteKunnossaObservable: Observable<boolean>
  asiakkaanOsoitetiedotPuuttuvatObservable: Observable<boolean>
  alvLaskunPerustiedotKunnossaObservable: Observable<boolean>
  private _erapaivaObservable: Observable<LocalDate>
  private _alvLaskunVoiLahettaaObservable: Observable<boolean>
  // aikaisemminLahetetytLaskutObservable: Observable<AsiakkaanMaksettuLaskuJaEsikatselutiedot[]>
  // laskunEsikatselutiedotObservable: Observable<LaskuPdfEsikatselutiedot>

  naidenErotus: number = 0
  naytaArh: boolean = false
  arhVirhe: string = null
  // onkoAlvTilienPaattoJoTehtyObservable: Observable<boolean>

  alvLaskuMaaraCtrl: FormControl<number> = new FormControl<number>(0)
  alvLaskuSendCtrl: FormControl<boolean> = new FormControl<boolean>(false)
  alvLaskuForm: FormGroup<{ maara: FormControl<number>, send: FormControl<boolean> }> = new FormGroup<{ maara: FormControl<number>, send: FormControl<boolean> }>({
    maara: this.alvLaskuMaaraCtrl,
    send: this.alvLaskuSendCtrl
  })

  arhVero: FormControl<number> = new FormControl<number>(null, Validators.required)
  arhLiikevaihto: FormControl<number> = new FormControl<number>(null, Validators.required)
  arhForm: FormGroup<{ arhVero: FormControl<number>, arhLiikevaihto: FormControl<number> }> = new FormGroup<{ arhVero: FormControl<number>, arhLiikevaihto: FormControl<number> }>({
    'arhVero': this.arhVero,
    'arhLiikevaihto': this.arhLiikevaihto
  })

  namename = 'a7erts1ruo9peq' + Math.random()

  constructor(
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _currencyService: CurrencyService,
    private _asiakasService: AsiakasService,
    private _asiakasUriService: AsiakasUriService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _dateService: DateService,
    private _ladataanService: LadataanService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjanpitoService: KirjanpitoService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _snackbar: MatSnackBar,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _viitenumeroService: ViitenumeroService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _laskuUriService: LaskuUriService,
    private _httpService: LemonHttpService,
    private _timestampService: TimestampService,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() {

    const hyvaksymattomatKirjauksetRaakadataObservable: Observable<KirjanpitoKuukausiruutu[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const collectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
          return this._firebase.firestoreCollection<KirjanpitoKuukausiruutu>(collectionUri)
            .where('u', '>', 0)
            .listen()
        }
        return of<KirjanpitoKuukausiruutu[]>([])
      })
    )

    const hyvaksymattomatKirjauksetAlvJaksonAikanaObservable: Observable<KirjanpitoKuukausiruutu[]> = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.valittuKuukausiObservable, hyvaksymattomatKirjauksetRaakadataObservable]).pipe(
      map(([asiakas, kuukausi, ruudut]) => {
        if (asiakas && kuukausi && ruudut) {
          const alkuJaLoppupaivamaara = this._kirjanpitoJaettuService.annaAlvIlmoituksenAlkamisJaPaattymisPaiva(kuukausi, asiakas)
          const alkuPvm = alkuJaLoppupaivamaara ? this._dateService.localMonthToNumber(alkuJaLoppupaivamaara.start) : 0
          const loppuPvm = alkuJaLoppupaivamaara ? this._dateService.localMonthToNumber(alkuJaLoppupaivamaara.end) : 0
          return ruudut.filter(a => {
            return alkuPvm <= a.k && a.k <= loppuPvm
          })
        }
        return ruudut
      })
    )

    const perustiedotObservable: Observable<{ asiakas: Asiakas, kuukausi: LocalMonth, tunnistetiedot: AlvIlmoituksenTunnistetiedot }> = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.valittuKuukausiObservable]).pipe(
      map(([asiakas, kuukausi]) => {
        if (asiakas && kuukausi) {
          const tunnistetiedot = this._kirjanpitoJaettuService.annaAlvilmoituksenTunnistetiedot(kuukausi, asiakas)
          if (tunnistetiedot) {
            return { asiakas: asiakas, kuukausi: kuukausi, tunnistetiedot: tunnistetiedot }
          }
        }
        return null
      })
      // ,
      // distinctUntilChanged((previous, current) => {
      //   if (!previous && !!current) {
      //     return false
      //   }
      //   if (!!previous && !current) {
      //     return false
      //   }
      //   if (!previous && !current) {
      //     return true
      //   }
      //   if (previous.asiakas?.avain !== current.asiakas?.avain) {
      //     return false
      //   }
      //   if (previous.kuukausi?.year !== current.kuukausi?.year) {
      //     return false
      //   }
      //   if (previous.kuukausi?.month !== current.kuukausi?.month) {
      //     return false
      //   }
      //   if (previous.tunnistetiedot?.vuosi !== current.tunnistetiedot?.vuosi) {
      //     return false
      //   }
      //   if (previous.tunnistetiedot?.jarjestys !== current.tunnistetiedot?.jarjestys) {
      //     return false
      //   }
      //   if (previous.tunnistetiedot?.alvIlmoitusjakso !== current.tunnistetiedot?.alvIlmoitusjakso) {
      //     return false
      //   }
      //   return true
      // })
    )

    // If the asiakas or kuukausi changes, we need to reset the form.
    perustiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(perustiedot => {
      this.arhVero.setValue(null, { emitEvent: false })
      this.arhLiikevaihto.setValue(null, { emitEvent: false })
    })

    combineLatest([perustiedotObservable, this.arhVero.valueChanges, this.arhLiikevaihto.valueChanges]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([perustiedot, vero, liikevaihto]) => {
      const ilmoitus = this.alvIlmoitusSubject.value
      if (ilmoitus && perustiedot) {
        const tilikausi = this._kirjanpitoJaettuService.annaTilikausiKuukaudenEnsimmaisellePaivalle(perustiedot.asiakas.tilikaudet, perustiedot.kuukausi)

        if (vero || liikevaihto) {

          if (!ilmoitus.arhSyotetty) { ilmoitus.arhSyotetty = {} }

          if (vero) {
            ilmoitus.arhSyotetty.vero = vero
          } else {
            delete ilmoitus.arhSyotetty.vero
          }
          if (liikevaihto) {
            ilmoitus.arhSyotetty.suhteutettuLiikevaihto = liikevaihto
          } else {
            delete ilmoitus.arhSyotetty.suhteutettuLiikevaihto
          }
          if (tilikausi && vero && liikevaihto) {
            ilmoitus.arhSyotetty.huojennus = this._kirjanpitoJaettuService.laskeAlarajahuojennus(tilikausi, vero, liikevaihto)
          } else {
            delete ilmoitus.arhSyotetty.huojennus
          }

          this.alvIlmoitusSubject.next(this.alvIlmoitusSubject.value)

        } else {
          delete ilmoitus.arhSyotetty
          this.arhVero.setValue(null, { emitEvent: false })
          this.arhLiikevaihto.setValue(null, { emitEvent: false })
          this.alvIlmoitusSubject.next(this.alvIlmoitusSubject.value)
        }

      } else {
        this.arhVero.setValue(null, { emitEvent: false })
        this.arhLiikevaihto.setValue(null, { emitEvent: false })
      }
      this.arhVirhe = null
    })

    // this.onkoAlvTilienPaattoJoTehtyObservable = perustiedotObservable.pipe(
    //   switchMap(perustiedot => {
    //     if (perustiedot) {
    //       const tunniste = this._kirjanpitoJaettuService.annaAlvilmoituksenTunniste(perustiedot.tunnistetiedot)
    //       const kirjaus1Avain = 'alv_automaattikirjaus1_' + tunniste
    //       const kirjaus1Uri = this._kirjanpitoUriService.annaRaportointikirjauksenUri(perustiedot.asiakas.avain, kirjaus1Avain)
    //       return this._firestore.doc<Raportointikirjaus>(kirjaus1Uri).valueChanges()
    //     }
    //     return of<Raportointikirjaus>(null)
    //   }),
    //   map(kirjaus => !!kirjaus)
    // )

    const aikaisemmatAlvIlmoituksetObservable = perustiedotObservable.pipe(
      tap(() => this.historyLoadingSubject.next(true)),
      switchMap(perustiedot => {
        if (perustiedot) {
          const tunniste = this._kirjanpitoJaettuService.annaAlvilmoituksenTunniste(perustiedot.tunnistetiedot)
          const alvilmoituksetUri = this._kirjanpitoUriService.annaAlvIlmoituksenCollectionUri(perustiedot.asiakas.avain)
          console.log('Haetaan tunnisteella 1: ' + tunniste + ' urista ' + alvilmoituksetUri)
          return this._firebase.firestoreCollection<AlvIlmoitus>(alvilmoituksetUri)
            .where('tunniste', '==', tunniste)
            .listen()
        }
        return of<AlvIlmoitus[]>([])
      }),
      map(edelliset => {
        return edelliset.sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())
      }),
      takeUntil(this._ngUnsubscribe),
      lemonShare()
    )

    this.edellinenAlvIlmoitusObservable = aikaisemmatAlvIlmoituksetObservable.pipe(
      map(edelliset => {
        if (edelliset && edelliset.length > 0) {
          const sorted = [...edelliset].sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())
          return sorted[0]
        }
        return null
      })
    )

    // This check system needs only input from asiakas and kuukausi, but we also want to execute it when
    // edelliset changes
    combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.valittuKuukausiObservable, aikaisemmatAlvIlmoituksetObservable]).pipe(
      switchMap(([asiakas, kuukausi, aikaisemmat]) => {
        if (asiakas && kuukausi) {
          const tunnistetiedot = this._kirjanpitoJaettuService.annaAlvilmoituksenTunnistetiedot(kuukausi, asiakas)
          if (tunnistetiedot) {
            const tunniste = this._kirjanpitoJaettuService.annaAlvilmoituksenTunniste(tunnistetiedot)
            const alvilmoituksetUri = this._kirjanpitoUriService.annaAlvIlmoituksenCollectionUri(asiakas.avain)
            console.log('Haetaan tunnisteella 2: ' + tunniste + ' urista ' + alvilmoituksetUri)
            return this._firebase.firestoreCollection<AlvIlmoitus>(alvilmoituksetUri)
              .where('tunniste', '==', tunniste)
              .getFromServer()
          }
        }
        return of<AlvIlmoitus[]>([])
      }),
      map(edelliset => {
        if (edelliset) {
          return edelliset.sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())
        }
        return null
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(edelliset => {

      if (!edelliset) {
        return
      }

      let index = 0
      for (const ilmoitus of edelliset) {
        const edellinen = index + 1 < edelliset.length ? edelliset[index + 1] : null
        if (edellinen) {
          if (ilmoitus.edellinenMaksettavaTaiPalautettavaVero !== this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(edellinen)) {
            captureException(new Error('Edellisen maksetun veron määrä ei täsmää ilmoituksessa 1 ' + ilmoitus.avain + '! ' + ilmoitus.edellinenMaksettavaTaiPalautettavaVero + ' !== ' + this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(edellinen)))
          }
        } else if (ilmoitus.edellinenMaksettavaTaiPalautettavaVero !== 0) {
          // console.log('Edellisen maksetun veron määrä ei täsmää ilmoituksessa ' + alvIlmoitus.avain + '! ' + alvIlmoitus.edellinenMaksettavaTaiPalautettavaVero + ' !== 0,00')
          captureException(new Error('Edellisen maksetun veron määrä ei täsmää ilmoituksessa 2 ' + ilmoitus.avain + '! ' + ilmoitus.edellinenMaksettavaTaiPalautettavaVero + ' !== 0,00'))
        }
        index++
      }

    })

    this.edellinenMaksettavaTaiPalautettavaVeroObservable = this.edellinenAlvIlmoitusObservable.pipe(
      map(edellinen => {
        if (edellinen) {
          return this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(edellinen)
        }
        return 0
      })
    )

    // Emit latest sent ALV-ilmoitus or newly created one (if none sent yet)
    combineLatest([this.edellinenAlvIlmoitusObservable, this.alvIlmoitusSubject]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([edellinenIlmoitus, nykyinenlmoitus]) => {
      if (edellinenIlmoitus) {
        this.viimeisinAlvIlmoitus.emit(edellinenIlmoitus)
      } else {
        this.viimeisinAlvIlmoitus.emit(nykyinenlmoitus)
      }
    })

    this.maksettavaTaiPalautettavaVeroObservable = this.alvIlmoitusSubject.pipe(
      map(alvIlmoitus => {
        return this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(alvIlmoitus)
      })
    )

    this.muutosVeronMaarassaObservable = combineLatest([this.maksettavaTaiPalautettavaVeroObservable, this.edellinenMaksettavaTaiPalautettavaVeroObservable]).pipe(
      map(([maksettavaTaiPalautettavaVero, edellinenMaksettavaTaiPalautettavaVero]) => {
        return maksettavaTaiPalautettavaVero - edellinenMaksettavaTaiPalautettavaVero
      })
    )

    this.aikaisemmatVersiotObservable = combineLatest([this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, this._asiakasService.nykyinenAsiakasAvainObservable, aikaisemmatAlvIlmoituksetObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([kirjanpitaja, asiakas, ilmoitukset, kirjanpitajienNimitiedotMap]) => {
        if (!kirjanpitaja || !asiakas || !ilmoitukset || !kirjanpitajienNimitiedotMap) {
          return []
        }
        return ilmoitukset.map(ilmoitus => {
          return this._kirjanpitoService.muunnaAlvIlmoitusListausAlvIlmoitukseksi(kirjanpitaja.rooli === KirjanpitajanRooli.SUPER, asiakas, ilmoitus, kirjanpitajienNimitiedotMap)
        })
      }),
      tap(() => this.historyLoadingSubject.next(false))
    )

    this.tallentamattomiaMuutoksiaObservable = combineLatest([this.alvIlmoitusSubject, this.edellinenAlvIlmoitusObservable]).pipe(
      map(([alvIlmoitus, edellinenAlvIlmoitus]) => {
        return this._kirjanpitoJaettuService.onkoAlvIlmoituksissaTaiArhssaEroja(alvIlmoitus, edellinenAlvIlmoitus)
      })
    )

    this.voidaanLahettaaObservable = combineLatest([this.tallentamattomiaMuutoksiaObservable, hyvaksymattomatKirjauksetAlvJaksonAikanaObservable]).pipe(
      map(([tallentamattomiaMuutoksia, hyvaksymattomatKirjauksetAlvJaksonAikana]) => {
        return hyvaksymattomatKirjauksetAlvJaksonAikana.length < 1 && tallentamattomiaMuutoksia
      })
    )

    combineLatest([this.tallentamattomiaMuutoksiaObservable, this.aikaisemmatVersiotObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([onkoMuutoksia, aikaisemmatVersiot]) => {
      if (aikaisemmatVersiot && aikaisemmatVersiot.length > 0) {
        this.nykyisellaKuukaudellaOnMuutoksia.next(onkoMuutoksia ? 'yes' : 'no')
      } else {
        this.nykyisellaKuukaudellaOnMuutoksia.next('not-saved-yet')
      }
    })

    const draftObservable: Observable<AlvIlmoitus> = perustiedotObservable.pipe(
      switchMap(perustiedot => {
        if (perustiedot) {
          const draftUri = this._kirjanpitoUriService.annaAlvIlmoituksenDraftUri(perustiedot.asiakas.avain, perustiedot.tunnistetiedot)
          return this._firebase.firestoreDoc<AlvIlmoitus>(draftUri).listen()
        }
        return of<AlvIlmoitus>(null)
      })
    )

    combineLatest([draftObservable, this.edellinenAlvIlmoitusObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([draft, edellinen]) => {

      if (!draft) {
        this.numbersLoadingSubject.next(false)
        this.alvIlmoitusSubject.next(this._kirjanpitoJaettuService.annaUusiAlvilmoitus())
        return
      }

      this.naidenErotus = this._currencyService.roundHalfUp(draft.automaattikirjaus2939Summa - draft.automaattikirjaus1763Summa, 2)
      this.numbersLoadingSubject.next(false)
      this.alvIlmoitusSubject.next(draft)

      if (edellinen?.arhSyotetty) {
        this.arhVero.setValue(edellinen.arhSyotetty.vero)
        this.arhLiikevaihto.setValue(edellinen.arhSyotetty.suhteutettuLiikevaihto)
      } else {
        this.arhVero.setValue(null)
        this.arhLiikevaihto.setValue(null)
      }

    })

    this.tunnistetietoObservable = perustiedotObservable.pipe(
      map(perustiedot => {
        if (perustiedot) {
          return this._kirjanpitoJaettuService.annaTunnistetiedotString(perustiedot.tunnistetiedot, 'fi')
        }
        return ''
      })
    )

    this.errorMessageObservable = combineLatest([this.tunnistetietoObservable, hyvaksymattomatKirjauksetAlvJaksonAikanaObservable]).pipe(
      map(([tunnistetiedot, hyvaksymattomatRuudut]) => {
        if (hyvaksymattomatRuudut?.length && tunnistetiedot) {
          let kuukaudet = ''
          for (const ruutu of hyvaksymattomatRuudut) {
            const month = this._dateService.numberToLocalMonth(ruutu.k)
            kuukaudet += ' ' + month.month + '/' + month.year + ','
          }
          kuukaudet = kuukaudet.substring(0, kuukaudet.length - 1)
          return 'Et voi lähettää ALV-ilmoitusta ennen kuin olet hyväksynyt kaikki verokauden ' + tunnistetiedot + ' kirjaukset. Hyväksymättömiä kirjauksia löytyi kuukausilta:' + kuukaudet + '.'
        }
        return null
      })
    )

    this._erapaivaObservable = combineLatest([this.valittuKuukausiObservable, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      map(([kuukausi, asiakas]) => {
        if (!kuukausi || !asiakas) {
          return null
        }
        return this._annaAlvLaskunErapaiva(asiakas, kuukausi)
      }),
      distinctUntilChanged((a, b) => {
        return this._dateService.localDateToNumber(a) === this._dateService.localDateToNumber(b)
      })
    )

    const sahkoisetValitystiedotObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const maksutietojenPath = 'customers/' + asiakas.avain + '/apix-received-invoice-config/' + asiakas.avain
          return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>(maksutietojenPath).listen()
        }
        return of<ApixReceivedInvoiceConfig>(null)
      }),
      lemonShare()
    )

    const yhteystietoObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const maksutietojenPath = 'customers/' + asiakas.avain + '/customer-vero-maksuyhteystiedot/' + asiakas.avain
          return this._firebaseLemonaid.firestoreDoc<VerojenMaksuyhteystiedot>(maksutietojenPath).listen()
        }
        return of<VerojenMaksuyhteystiedot>(null)
      }),
      lemonShare()
    )

    this.alvLaskunViitenumeroObservable = yhteystietoObservable.pipe(
      map(yhteystiedot => {
        if (
          yhteystiedot &&
          yhteystiedot.omaAloitteisetViite &&
          yhteystiedot.omaAloitteisetViite.trim() &&
          this._viitenumeroService.onkoViitenumeroValidi(yhteystiedot.omaAloitteisetViite.trim())
        ) {
          return yhteystiedot.omaAloitteisetViite.trim()
        }
        return ''
      })
    )

    this.alvLaskunSahkoinenLaskutusosoiteKunnossaObservable = combineLatest([sahkoisetValitystiedotObservable, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      map(([sahkoisetValitystiedot, asiakas]) => {
        if (
          sahkoisetValitystiedot &&
          sahkoisetValitystiedot.forwardLemontreeLaskut &&
          sahkoisetValitystiedot.paymentReceiveIsActive
        ) {
          // Jos maksut on asiakkaalla käytössä -> menee aina maksuihin.
          return true
        } else if (
          sahkoisetValitystiedot &&
          sahkoisetValitystiedot.forwardLemontreeLaskut &&
          sahkoisetValitystiedot.forwardActive
        ) {
          if (
            !sahkoisetValitystiedot.forwardAddress ||
            !sahkoisetValitystiedot.forwardAddress.sahkoinenOsoite ||
            !sahkoisetValitystiedot.forwardAddress.sahkoinenValittaja
          ) {
            throw new Error('Asiakkaalle asiakkaat/' + asiakas.avain + ' on merkitty välitys aktiiviseksi ja lemontreen laskujen välitys päälle, mutta sähköistä osoitetta ei löydy.')
          }
          return true
        } else if (
          asiakas &&
          asiakas.sahkoinenLaskutusosoite &&
          asiakas.sahkoinenLaskutusosoite.sahkoinenOsoite &&
          asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja
        ) {
          return true
        }
        return false
      })
    )

    this.asiakkaanOsoitetiedotPuuttuvatObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        return !asiakas?.katuosoite ||
          !asiakas?.postitmp ||
          !asiakas?.postinro
      })
    )

    this.alvLaskunPerustiedotKunnossaObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.alvLaskunSahkoinenLaskutusosoiteKunnossaObservable, yhteystietoObservable, this.asiakkaanOsoitetiedotPuuttuvatObservable]).pipe(
      map(([asiakas, sahkoinenLaskutusosoiteKunnossa, yhteystiedot, asiakkaanOsoitetiedotPuuttuvat]) => {
        if (
          !asiakas.estaAlvVerkkolaskujenLahetys &&
          sahkoinenLaskutusosoiteKunnossa &&
          yhteystiedot &&
          yhteystiedot.omaAloitteisetViite &&
          yhteystiedot.omaAloitteisetViite.trim() &&
          this._viitenumeroService.onkoViitenumeroValidi(yhteystiedot.omaAloitteisetViite) &&
          !asiakkaanOsoitetiedotPuuttuvat
        ) {
          return true
        }
        return false
      }),
      lemonShare()
    )

    this._alvLaskunVoiLahettaaObservable = combineLatest([this.alvLaskunPerustiedotKunnossaObservable, this.muutosVeronMaarassaObservable]).pipe(
      map(([perustiedotKunnossa, alvIlmoituksenSumma]) => {
        if (
          perustiedotKunnossa &&
          alvIlmoituksenSumma > 0
        ) {
          return true
        }
        return false
      })
    )

    this.alvLaskunPerustiedotKunnossaObservable.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(isAvailable => {
      if (isAvailable) {
        this.alvLaskuMaaraCtrl.enable()
      } else {
        this.alvLaskuMaaraCtrl.disable()
      }
    })

    this._alvLaskunVoiLahettaaObservable.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(isAvailable => {
      this.alvLaskuSendCtrl.setValue(!!isAvailable)
      if (isAvailable) {
        this.alvLaskuSendCtrl.enable()
        // this.alvLaskuMaaraCtrl.enable()
      } else {
        this.alvLaskuSendCtrl.disable()
        // this.alvLaskuMaaraCtrl.disable()
      }
    })

    this.muutosVeronMaarassaObservable.pipe(
      distinctUntilChanged(),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(muutos => {
      if (muutos > 0) {
        this.alvLaskuMaaraCtrl.setValue(muutos)
      } else {
        this.alvLaskuMaaraCtrl.setValue(0)
      }
    })

  }

  async lataaAlvLasku(ilmoitus: ListausAlvIlmoitus) {

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const lahetystieto = await this._firebaseLemonaid.firestoreDoc<AsiakkaalleLemonatoristaLahetettyLasku>('customers/' + asiakas.avain + '/customer-sent-vat-invoice/' + ilmoitus.avain).get()

    if (!lahetystieto) {
      const data: LaskuLataaLahetystiedostaDialogData = {
        alvIlmoituksenAvain: ilmoitus.avain,
        asiakasAvain: asiakas.avain
      }
      this._dialog.open(LaskuLataaLahetystiedostaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
    } else {

      const laskuUri = lahetystieto ? this._laskuUriService.getLaskuUri(AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId, lahetystieto.laskuAvain) : null
      const lasku = laskuUri ? await this._firebaseLemonaid.firestoreDoc<Lasku>(laskuUri).get() : null

      if (
        lasku &&
        (
          (lasku.print && lasku.print.done) ||
          (lasku.email && lasku.email.done) ||
          (lasku.sahkoinen && lasku.sahkoinen.done)
        )
      ) {
        this._ladataanService.aloitaLataaminen()
        const pdfUri = this._laskuUriService.getPdfUri(AlvLaskunLahetysService.lahettavanAsiakkaanAsiakasId, lahetystieto.laskuAvain)
        const url = '/api/1/laskut/lataaPdf/' + pdfUri
        return this._httpService.getBinary('/laskuLataaPdf?a=' + encodeURIComponent(url) + '&time=' + encodeURIComponent(new Date().getTime()), LEMONATOR_CF_API).then(result => {
          const nimi = 'alv-lasku.pdf'
          this._ladataanService.lopetaLataaminen()
          this._fileSaverService.saveAs(result, nimi)
        }).catch(err => {
          this._ladataanService.lopetaLataaminen()
          this._errorHandler.handleError(err)
        })
      } else {
        const data: LaskuLataaDialogData = {
          juurilasku: lasku,
          kasiteltava: lasku
        }
        this._dialog.open(LaskuLataaDialog, { 'data': data, panelClass: 'ilman-paddingia' })
      }

    }

  }

  toggleArh() {
    if (this.naytaArh) {
      this.naytaArh = false
      this.arhVero.setValue(null)
      this.arhLiikevaihto.setValue(null)
    } else {
      this.naytaArh = true
    }
  }

  async laskeAutomaattikirjauksetUudelleen() {

    this._ladataanService.aloitaLataaminen()

    const [asiakas, kuukausi] = await Promise.all([
      firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable),
      firstValueFrom(this.valittuKuukausiObservable)
    ])
    const data: AlvTilienPaattaminenPyynto = {
      asiakasAvain: asiakas.avain,
      kuukausi: kuukausi
    }
    return this._firebase.functionsCall<AlvTilienPaattaminenPyynto, AlvTilienPaattaminenVastaus>('kirjanpitoAlvIlmoitusAutomaattikirjauksetUusiUudelleen', data)
      .then(result => {
        this._ladataanService.lopetaLataaminen()
        this._snackbar.open('ALV-tilit päätettiin onnistuneesti.', 'OK', { duration: 2500 })
      }).catch(err => {
        this._errorHandler.handleError(err)
        this._ladataanService.lopetaLataaminen()
      })

  }

  async laskeArhAutomaattikirjauksetUudelleen() {

    this._ladataanService.aloitaLataaminen()

    const [asiakas, edellinen] = await Promise.all([
      firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable),
      firstValueFrom(this.edellinenAlvIlmoitusObservable)
    ])

    if (asiakas && edellinen) {
      const data: AlvTilienArhPaattaminenIlmoituksenPerusteellaPyynto = {
        asiakasAvain: asiakas.avain,
        ilmoitus: edellinen
      }
      return this._firebase.functionsCall<AlvTilienArhPaattaminenIlmoituksenPerusteellaPyynto, void>('kirjanpitoAlvIlmoitusAutomaattikirjauksetUusiArhUudelleen', data)
        .then(result => {
          this._ladataanService.lopetaLataaminen()
          this._snackbar.open('ALV-tilit päätettiin onnistuneesti.', 'OK', { duration: 2500 })
        }).catch(err => {
          this._errorHandler.handleError(err)
          this._ladataanService.lopetaLataaminen()
        })
    }

  }

  private saveInflight = false
  async laheta() {

    const alvIlmoitus = this.alvIlmoitusSubject.value
    if (!alvIlmoitus) {
      return
    }

    if (alvIlmoitus.arhSyotetty) {
      if (!alvIlmoitus.arhSyotetty.huojennus) {
        this.arhVirhe = 'Alarajahuojennus on syötetty vain osittain. Ole hyvä ja korjaa laskelma.'
        return
      }
    }

    if (this.saveInflight) {
      return
    }
    this.saveInflight = true

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
      const kuukausi = await firstValueFrom(this.valittuKuukausiObservable)
      const aikaisemmat = await firstValueFrom(this.aikaisemmatVersiotObservable)
      const edellinenVeronMaara = await firstValueFrom(this.edellinenMaksettavaTaiPalautettavaVeroObservable)

      if (alvIlmoitus && asiakas && kuukausi && aikaisemmat && (edellinenVeronMaara || edellinenVeronMaara === 0)) {

        this.valmisteleAlvIlmoitusLahetettavaksi(alvIlmoitus, kuukausi, asiakas, aikaisemmat, edellinenVeronMaara)

        const data: AlvIlmoituksenLahetyspyynto = {
          asiakasAvain: asiakas.avain,
          valittuKuukausi: kuukausi,
          ilmoitus: alvIlmoitus
        }

        const maksettavaMaara = this.alvLaskuMaaraCtrl.value
        if (this.alvLaskuSendCtrl.value && maksettavaMaara > 0.00999) {

          // Check that we're still allowed to send
          const allowed = await firstValueFrom(this.alvLaskunPerustiedotKunnossaObservable)
          if (!allowed) {
            throw new Error('Detected that ALV invoice can\'t be sent while sending it.')
          }

          const viitenumero = await firstValueFrom(this.alvLaskunViitenumeroObservable)
          const erapaiva = await firstValueFrom(this._erapaivaObservable)

          if (!viitenumero || !kuukausi || !erapaiva) {
            throw new Error('Detected missing data in ALV invoice send.')
          }

          data.ilmoitus.alvLasku = {
            erapaiva: erapaiva,
            maksettavaMaara: maksettavaMaara,
            viitenumero: viitenumero,
            lahetetty: this._timestampService.now()
          }

        }

        await this._firebase.functionsCall<AlvIlmoituksenLahetyspyynto, void>('kirjanpitoAlvIlmoitusLahetaUusi', data)

        // Get ready for new save
        const uusi = this._kirjanpitoJaettuService.annaUusiAlvilmoitus()

        uusi.alv255 = alvIlmoitus.alv255
        uusi.alv24 = alvIlmoitus.alv24
        uusi.alv14 = alvIlmoitus.alv14
        uusi.alv10 = alvIlmoitus.alv10
        uusi.taveu = alvIlmoitus.taveu
        uusi.palveu = alvIlmoitus.palveu
        uusi.taveieu = alvIlmoitus.taveieu
        uusi.rak = alvIlmoitus.rak
        uusi.vahennettava = alvIlmoitus.vahennettava
        uusi.nolla = alvIlmoitus.nolla
        uusi.tavmyynniteu = alvIlmoitus.tavmyynniteu
        uusi.palvmyynniteu = alvIlmoitus.palvmyynniteu
        uusi.tavostoeu = alvIlmoitus.tavostoeu
        uusi.palvostoeu = alvIlmoitus.palvostoeu
        uusi.tavulkeu = alvIlmoitus.tavulkeu
        uusi.rakmetmyynti = alvIlmoitus.rakmetmyynti
        uusi.rakmetosto = alvIlmoitus.rakmetosto

        uusi.automaattikirjaus1763Summa = alvIlmoitus.automaattikirjaus1763Summa
        uusi.automaattikirjaus2939Summa = alvIlmoitus.automaattikirjaus2939Summa

        if (alvIlmoitus.arhSyotetty) {
          uusi.arhSyotetty = {
            huojennus: alvIlmoitus.arhSyotetty.huojennus,
            suhteutettuLiikevaihto: alvIlmoitus.arhSyotetty.suhteutettuLiikevaihto,
            vero: alvIlmoitus.arhSyotetty.vero
          }
        }

        this.alvIlmoitusSubject.next(uusi)

        if (alvIlmoitus.arhSyotetty) {
          this.arhVero.setValue(alvIlmoitus.arhSyotetty.vero)
          this.arhLiikevaihto.setValue(alvIlmoitus.arhSyotetty.suhteutettuLiikevaihto)
        } else {
          this.arhVero.setValue(null)
          this.arhLiikevaihto.setValue(null)
        }

        this._ladataanService.lopetaLataaminen()
        this._snackbar.open('ALV-ilmoitus lähetettiin onnistuneesti.', 'OK', { duration: 2500 })

      }

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this.saveInflight = false
    }

  }

  async lahetaVainAlvLasku(edellinenIlmoitus: ListausAlvIlmoitus) {
    if (!edellinenIlmoitus.alvLasku) {

      if (this.saveInflight) {
        return
      }
      this.saveInflight = true

      this._ladataanService.aloitaLataaminen()

      try {
        const viitenumero = await firstValueFrom(this.alvLaskunViitenumeroObservable)
        const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
        let erapaiva = this._annaAlvLaskunErapaiva(asiakas, edellinenIlmoitus.endDate)
        if (!viitenumero || !erapaiva) {
          throw new Error('Data fault at 11.')
        }
        if (this._dateService.compareLocalDates(erapaiva, '<', this._dateService.currentLocalDate())) {
          erapaiva = this._dateService.currentLocalDate()
        }
        const maara = edellinenIlmoitus.maksettavaTaiPalautettavaVero - edellinenIlmoitus.edellinenMaksettavaTaiPalautettavaVero
        edellinenIlmoitus.alvLasku = {
          erapaiva: erapaiva,
          maksettavaMaara: maara,
          viitenumero: viitenumero,
          lahetetty: this._timestampService.now()
        }
        const data: AlvIlmoituksenLahetyspyynto = {
          asiakasAvain: asiakas.avain,
          ilmoitus: edellinenIlmoitus,
          valittuKuukausi: edellinenIlmoitus.endDate
        }
        await this._firebase.functionsCall<AlvIlmoituksenLahetyspyynto, void>('kirjanpitoAlvIlmoitusLahetaVainLasku', data)
      } catch (err) {
        this._errorHandler.handleError(err)
      } finally {
        this._ladataanService.lopetaLataaminen()
        this.saveInflight = false
      }

    }
  }

  private _annaAlvLaskunErapaiva(asiakas: Asiakas, kuukausi: LocalMonth): LocalDate {
    const kuunEnsimmainen: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
    const nykyinenIlmoitusjakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, this._dateService.localDateToDate(kuunEnsimmainen))
    const seuraavaIlmoitusjakso = this._asiakasJaettuService.annaSeuraavaAlvIlmoitusjakso(asiakas, nykyinenIlmoitusjakso)
    const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
    return this._kirjanpitoJaettuService.annaAlvnErapaiva(clientType, kuukausi, nykyinenIlmoitusjakso, seuraavaIlmoitusjakso)
  }

  async lataaTyviTiedosto(ilmoitus: AlvIlmoitus) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    if (asiakas) {

      const data: AlvIlmoituksenTyviTiedostonLatauspyynto = {
        asiakasAvain: asiakas.avain,
        alvIlmoitusAvain: ilmoitus.avain
      }

      this._firebase.functionsCall<AlvIlmoituksenTyviTiedostonLatauspyynto, AlvIlmoituksenTyviTiedostonLatausvastaus>('kirjanpitoAlvIlmoitusLataaTyvi', data)
        .then(vastaus => {
          this._fileSaverService.saveStringAs(vastaus.tiedosto, vastaus.nimi, 'txt')
          this._ladataanService.lopetaLataaminen()
        }).catch(err => {
          this._errorHandler.handleError(err)
          this._ladataanService.lopetaLataaminen()
        })

    }

  }

  private valmisteleAlvIlmoitusLahetettavaksi(alvIlmoitus: AlvIlmoitus, kuukausi: LocalMonth, asiakas: Asiakas, aikaisemmat: AlvIlmoitus[], edellinenVeronMaara: number) {
    const tunnistetiedot = this._kirjanpitoJaettuService.annaAlvilmoituksenTunnistetiedot(kuukausi, asiakas)

    if (!tunnistetiedot) {
      throw new Error('Ei tunnistetietoja.')
    }

    const alkuJaLoppupaivamaara = this._kirjanpitoJaettuService.annaAlvIlmoituksenAlkamisJaPaattymisPaiva(kuukausi, asiakas)

    alvIlmoitus.tunniste = this._kirjanpitoJaettuService.annaAlvilmoituksenTunniste(tunnistetiedot)
    alvIlmoitus.jarjestysnumero = aikaisemmat.length + 1
    alvIlmoitus.tunnistetiedot = tunnistetiedot
    alvIlmoitus.startDate = alkuJaLoppupaivamaara.start
    alvIlmoitus.endDate = alkuJaLoppupaivamaara.end
    alvIlmoitus.edellinenMaksettavaTaiPalautettavaVero = edellinenVeronMaara
  }

  kopioiArhIlmoitukselle(alvIlmoitus: AlvIlmoitus) {
    if (alvIlmoitus.arhLaskettu) {
      this.arhLiikevaihto.setValue(alvIlmoitus.arhLaskettu.suhteutettuLiikevaihto)
      this.arhVero.setValue(alvIlmoitus.arhLaskettu.vero)
    }
  }

}
