<div style="text-align: center; min-width: 300px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      {{ header }}
    </lemon-dialog-header>

    <div>
      {{ text }}
    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>{{ leftAction }}</button>
    <span style="display: inline-block; width: 8vw; max-width: 4em;"></span>
    <button (click)="replyTrue()" class="primarybutton" mat-button>{{ rightAction }}</button>
  </div>
</div>