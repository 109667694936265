import { Timestamp, LocalDate, LocalDateTime } from '../model/common'

export interface TimestampProvider {
  localDateTimeToTimestamp(pvm: LocalDateTime): Timestamp
  now(): Timestamp
  isTimestamp(obj: any): boolean
  newTimestamp(seconds: number, nanoseconds: number): Timestamp
  dateToTimestamp(date: Date): Timestamp
  localDateToTimestamp(pvm: LocalDate): Timestamp
  lisaaPaiviaTimestamp(paiva: Timestamp, lisattavaMaara: number): Timestamp
  fixTimestamps(obj: any): any
}

export abstract class TimestampProviderBase implements TimestampProvider {
  abstract localDateTimeToTimestamp(pvm: LocalDateTime): Timestamp
  abstract now(): Timestamp
  abstract isTimestamp(obj: any): boolean
  abstract newTimestamp(seconds: number, nanoseconds: number): Timestamp
  abstract dateToTimestamp(date: Date): Timestamp
  abstract lisaaPaiviaTimestamp(paiva: Timestamp, lisattavaMaara: number): Timestamp
  abstract localDateToTimestamp(pvm: LocalDate): Timestamp
  abstract fixTimestamps(obj: any): any
}
