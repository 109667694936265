/**
 * Method to return the rendering scale.
 * @param targetWidth the width to which we aim, like 720
 * @param multiplier if we want to render in higher pixel count, like 2x, one can use multiplier here
 * @param pdfWidth the native width from PDF before rendering
 * @returns The scale rounded to four decimal places.
 */
export function getPdfRenderScale(targetWidth: number, multiplier: number, pdfWidth: number): number {
  const tw = targetWidth * multiplier
  return parseFloat((tw / pdfWidth).toFixed(4))
}
