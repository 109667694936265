import { BicService } from './bic.service'
import { isValidIBAN, friendlyFormatIBAN, composeIBAN } from 'ibantools'

export class IbanService {

  constructor(private bicService: BicService) { }

  public isValidIban(iban: string): boolean {
    if (!iban) {
      return false
    }
    const ilmanValilyonteja = iban.replace(/\s+/g, '')
    return isValidIBAN(ilmanValilyonteja)
  }

  public formatoiIban(iban: string): string | null {
    if (iban) {
      return friendlyFormatIBAN(iban)
    }
    return ''
  }

  public async convertBBanToIban(bban: string, countryCode: string): Promise<string | null> {
    return composeIBAN({ bban: bban, countryCode: countryCode })
  }

}
