import { TuettuKieli, Valuutta } from '../../_shared-core/model/common'
import { Big } from 'big.js'
import { CurrencyFormatService } from './currency-format.service'

export class CurrencyService {

  constructor(
    private currencyFormatService: CurrencyFormatService
  ) { }

  formatoiRaha(amount: number, valuutta: string, kieli: TuettuKieli): string {
    if ((amount !== undefined && amount !== null && !isNaN(amount)) && valuutta && kieli) {
      return this.currencyFormatService.format(amount, valuutta, kieli)
    }
    return ''
  }

  formatoiBigRaha(amount: Big, valuutta: string, kieli: TuettuKieli): string {
    if (amount) {
      const decimal = this.muutaBigDecimalRahaksi(amount)
      return this.formatoiRaha(decimal, valuutta, kieli)
    }
    return ''
  }

  muutaBigDecimalRahaksi(numero: Big): number {
    return Number(numero.toFixed(2, Big.roundHalfUp))
  }

  muutaBigDecimalRahamerkkijonoksi(numero: Big): string {
    return numero.toFixed(2, Big.roundHalfUp)
  }

  annaKokonaiset(numero: Big): string {
    return numero.toFixed(2, Big.roundHalfUp).split('.')[0]
  }

  annaDesimaalit(numero: Big): string {
    return numero.toFixed(2, Big.roundHalfUp).split('.')[1]
  }

  muutaBigDecimalRahamerkkijonoksiDesimaalit(numero: Big, desimaalienLukumaara: number): string {
    if (desimaalienLukumaara > 0) {
      return numero.toFixed(desimaalienLukumaara, Big.roundHalfUp)
    }
    return numero.toFixed(0, Big.roundHalfUp)
  }

  muutaMerkkijonoNumeroksi(merkkijono: string, desimaalienLukumaara: number): number | null {
    if (merkkijono) {
      const vainHyvatMerkit = this.poistaNumeroonKuulumattomatMerkit(merkkijono, desimaalienLukumaara)
      if (vainHyvatMerkit) {
        const replaced = vainHyvatMerkit.replace(',', '.')
        const parsedNumber = Number(replaced)
        const numberToSet = isNaN(parsedNumber) ? null : parsedNumber
        return numberToSet
      }
    }
    return null
  }

  public annaValuutat(): Valuutta[] {
    const valuutat: Valuutta[] = []
    // eslint-disable-next-line guard-for-in
    for (const [koodi, symboli] of Object.entries(this.currencyFormatService.symbols)) {
      valuutat.push({ desim: 2, koodi: koodi, symboli: symboli })
    }
    return valuutat
  }

  public formatoiDesimaali(amount: number, desimaalienLukumaara: number, kieli: TuettuKieli): string {
    if (amount !== undefined && amount !== null && !isNaN(amount)) {
      let numero = this.muutaBigDecimalRahamerkkijonoksiDesimaalit(new Big(amount), desimaalienLukumaara)

      if (numero.indexOf('.') > -1) {
        while (numero.endsWith('0')) {
          numero = numero.slice(0, -1)
        }
        if (numero.endsWith('.')) {
          numero = numero.slice(0, -1)
        }
      }

      const pattern = this.currencyFormatService.getLocalePattern(kieli)
      return numero.replace('.', pattern.d)
    }
    return ''
  }

  public formatoiDesimaaliLisaaValilyontiTuhanteen(amount: number, desimaalienLukumaaraMin: number, desimaalienLukumaaraMax: number, kieli: TuettuKieli): string {
    if (amount !== undefined && amount !== null && !isNaN(amount)) {

      const numero = this.muutaBigDecimalRahamerkkijonoksiDesimaalit(new Big(amount), desimaalienLukumaaraMax)
      let decimals = ''
      let integers = ''

      if (numero.indexOf('.') > -1) {
        const splitted = numero.split('.')
        integers = splitted[0]
        decimals = splitted[1]
      } else {
        integers = numero
      }

      while (decimals.endsWith('0')) {
        if (decimals.length === desimaalienLukumaaraMin) {
          break
        }
        decimals = decimals.slice(0, -1)
      }

      const formattedIntegers = integers.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

      if (decimals) {
        const pattern = this.currencyFormatService.getLocalePattern(kieli)
        return formattedIntegers + pattern.d + decimals
      }

      return formattedIntegers

    }
    return ''
  }

  public formatoiDesimaaliSailytaNollat(amount: number, desimaalienLukumaara: number, kieli: TuettuKieli): string {
    if (amount !== undefined && amount !== null && !isNaN(amount)) {
      const numero = this.muutaBigDecimalRahamerkkijonoksiDesimaalit(new Big(amount), desimaalienLukumaara)
      const pattern = this.currencyFormatService.getLocalePattern(kieli)
      return numero.replace('.', pattern.d)
    }
    return ''
  }

  public formatoiBigRahaIlmanValuuttaSymbolia(amount: Big, kieli: TuettuKieli): string {
    const desimaali = this.muutaBigDecimalRahaksi(amount)
    return this.formatoiRahaIlmanValuuttaSymbolia(desimaali, kieli)
  }

  public formatoiBigRahaIlmanValuuttaSymboliaTaiRyhmittelya(amount: Big, kieli: TuettuKieli): string {
    const desimaali = this.muutaBigDecimalRahaksi(amount)
    return this.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(desimaali, kieli)
  }

  public formatoiRahaIlmanValuuttaSymbolia(amount: number, kieli: TuettuKieli): string {
    if (amount !== undefined && amount !== null && !isNaN(amount)) {
      return this.currencyFormatService.formatWithoutCurrencySymbol(amount, kieli)
    }
    return ''
  }

  public formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(amount: number, kieli: TuettuKieli) {
    if (amount !== undefined && amount !== null && !isNaN(amount)) {
      return this.currencyFormatService.formatWithoutCurrencySymbolOrGrouping(amount, kieli)
    }
    return ''
  }

  muutaMerkkijonoNumeroksiKaikkiDesimaalit(merkkijono: string): number | null {
    if (merkkijono) {
      const vainHyvatMerkit = this.poistaNumeroonKuulumattomatMerkitKaikkiDesimaalit(merkkijono)
      if (vainHyvatMerkit) {
        const replaced = vainHyvatMerkit.replace(',', '.')
        const parsedNumber = Number(replaced)
        const numberToSet = isNaN(parsedNumber) ? null : parsedNumber
        return numberToSet
      }
    }
    return null
  }

  private poistaNumeroonKuulumattomatMerkitKaikkiDesimaalit(value: string): string | null {
    let index = 0
    let str = ''
    let commaTaken = false
    for (const char of value) {
      if (char === ',' || char === '.') {
        if (commaTaken) {
          index++
          continue
        } else {
          commaTaken = true
        }
      }
      if (this.onkoSallittuMerkkiNumerossa(char, index)) {
        str += char
      }
      index++
    }
    return str === '' ? null : str
  }

  public poistaNumeroonKuulumattomatMerkit(value: string, desimaalienLukumaara: number): string | null {
    let index = 0
    let str = ''
    let commaTaken = false
    let pilkunJalkeen = 0
    const val = value + ''
    for (const char of val) {
      if (char === ',' || char === '.') {
        if (commaTaken || desimaalienLukumaara < 1) {
          index++
          continue
        } else {
          commaTaken = true
        }
      }
      if (this.onkoSallittuMerkkiNumerossa(char, index)) {
        if (commaTaken) {
          if (pilkunJalkeen < desimaalienLukumaara + 1) {
            str += char
          }
          pilkunJalkeen++
        } else {
          str += char
        }
      }
      index++
    }
    return str === '' ? null : str
  }

  public onkoMerkkijonoNumero(value: string): boolean {
    if (value) {
      let index = 0
      for (const char of value) {
        if (!this.onkoSallittuMerkkiNumerossa(char, index)) {
          return false
        }
        index++
      }
      return true
    }
    return false
  }
  public onkoMerkkijonoNumeroSalliTuhannenValilyontia(value: string): boolean {
    if (value) {
      let index = 0
      for (const char of value) {
        if (this.onkoTuhannenValilyonti(char, index, value)) {
          continue
        }
        if (!this.onkoSallittuMerkkiNumerossa(char, index)) {
          return false
        }
        index++
      }
      return true
    }
    return false
  }
  /**
   * Maximum checkable number is 999 999 999.
  */
  private onkoTuhannenValilyonti(char: string, index: number, value: string) {
    const startsWithPlusOrMinus = value[0] === '-' || value[0] === '+'
    const potentialCommaIndex = value.indexOf(',') > -1 ? value.indexOf(',') : value.length
    const valueAsInt = value.substring(0, potentialCommaIndex)
    const length = startsWithPlusOrMinus ? valueAsInt.length - 1 : valueAsInt.length
    index -= startsWithPlusOrMinus ? 1 : 0

    if (char !== ' ' || length < 5) {
      return false
    }
    if (
      index === 1 ||
      (length === 5 && index === 1) || // 1 000
      (length === 6 && index === 2) || // 10 000
      (length === 7 && index === 3) || // 100 000
      (length === 9 && (index === 1 || index === 5)) || // 1 000 000
      (length === 10 && (index === 2 || index === 6)) || // 10 000 000
      (length === 11 && (index === 3 || index === 7)) // 100 000 000
    ) {
      return true
    }
    return false
  }

  public onkoSallittuMerkkiNumerossa(char: string, index: number): boolean {
    if (
      (index === 0 && (char === '-' || char === '+')) ||
      char === '1' ||
      char === '2' ||
      char === '3' ||
      char === '4' ||
      char === '5' ||
      char === '6' ||
      char === '7' ||
      char === '8' ||
      char === '9' ||
      char === '0' ||
      char === ',' ||
      char === '.'
    ) {
      return true
    } else {
      return false
    }
  }

  public onkoMerkkijonoKokonaisluku(value: string): boolean {
    if (value) {
      for (const char of value) {
        if (!this.onkoSallittuMerkkiKokonaisluvussa(char)) {
          return false
        }
      }
      return true
    }
    return false
  }

  public onkoSallittuMerkkiKokonaisluvussa(char: string): boolean {
    if (
      char === '1' ||
      char === '2' ||
      char === '3' ||
      char === '4' ||
      char === '5' ||
      char === '6' ||
      char === '7' ||
      char === '8' ||
      char === '9' ||
      char === '0'
    ) {
      return true
    } else {
      return false
    }
  }

  public onkoNumero(object: any): boolean {
    const onkoNumero = Object.prototype.toString.call(object) === '[object Number]'
    const tulos = onkoNumero && !isNaN(object)
    // console.log(object, tulos)
    return tulos
  }

  nullSafeRoundHalfUp(value: number, desimaalipaikkoja: number): number {
    if (value === undefined || value === null) {
      return value
    }
    return this.roundHalfUp(value, desimaalipaikkoja)
  }

  /**
   * TODO: This is used to round monetary values - we should round to the number of decimals needed by the currency and not a hard coded 2
   * Isnpired by https://stackoverflow.com/questions/1726630/formatting-a-number-with-exactly-two-decimals-in-javascript
   * @param value pyöristettävä luku
   * @param desimaalipaikkoja desimaalien lukumäärä
   */
  roundHalfUp(value: number, desimaalipaikkoja: number): number {

    if (!desimaalipaikkoja) {
      return Math.round(value)
    }

    value = +value
    desimaalipaikkoja = +desimaalipaikkoja

    if (isNaN(value) || !(typeof desimaalipaikkoja === 'number' && desimaalipaikkoja % 1 === 0)) {
      return NaN
    }

    // Shift
    const asString = value.toString().split('e')
    value = Math.round(+(asString[0] + 'e' + (asString[1] ? (+asString[1] + desimaalipaikkoja) : desimaalipaikkoja)))

    // Shift back
    const asString2 = value.toString().split('e')
    return +(asString2[0] + 'e' + (asString2[1] ? (+asString2[1] - desimaalipaikkoja) : -desimaalipaikkoja))

  }

  annaAlvMaaraAlvillisestaHinnasta(hintaJokaSisaltaaAlvn: number, alvProsentti: number): number {
    const kerroin1 = (100 + alvProsentti) / 100
    const kerroin2 = alvProsentti / 100
    return hintaJokaSisaltaaAlvn / kerroin1 * kerroin2
  }

  annaAlvMaaraAlvittomastaHinnasta(hintaJokaSisaltaaAlvn: number, alvProsentti: number): number {
    const kerroin1 = alvProsentti / 100
    return hintaJokaSisaltaaAlvn * kerroin1
  }

}
