import { DateService } from '../../_shared-core/service/date.service'
import { LocalDateTime, Timestamp } from '../../_shared-core/model/common'
import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'
import { LocalDate } from '../../_shared-core/model/common'
import { Injectable } from '@angular/core'
import { SharedFirebaseLemonaid } from '../base-firebase.service'

@Injectable()
export class TimestampService implements TimestampProviderBase {

  constructor(
    private _dateService: DateService,
    private _firebaseLemonaid: SharedFirebaseLemonaid
  ) { }

  localDateToTimestamp(pvm: LocalDate): Timestamp {
    if (this._dateService.onkoLocalDateValidi(pvm)) {
      const date = this._dateService.localDateToDate(pvm)
      return this.dateToTimestamp(date)
    }
    return null
  }

  newTimestamp(seconds: number, nanoseconds: number): Timestamp {
    return this._firebaseLemonaid.firestoreTimestampNew(seconds, nanoseconds)
  }

  isTimestamp(obj: any): boolean {
    return this._firebaseLemonaid.firestoreIsTimestamp(obj)
  }

  now(): Timestamp {
    return this._firebaseLemonaid.firestoreTimestampFromDate(new Date())
  }

  dateToTimestamp(pvm: Date): Timestamp {
    if (this._dateService.dateToLocalDate(pvm)) {
      return this._firebaseLemonaid.firestoreTimestampFromDate(pvm)
    }
    return null
  }

  localDateTimeToTimestamp(pvm: LocalDateTime): Timestamp {
    const asDate = this._dateService.localDateTimeToDate(pvm)
    if (asDate) {
      return this.dateToTimestamp(asDate)
    }
    return null
  }

  lisaaPaiviaTimestamp(paiva: Timestamp, lisattavaMaara: number): Timestamp {
    if (paiva) {
      const date = this._dateService.lisaaPaivia(paiva.toDate(), lisattavaMaara)
      return this.dateToTimestamp(date)
    }
    return null
  }

  fixTimestamps(obj: any): any {
    if (obj && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        const arr = obj as Array<any>
        for (let i = 0; i < arr.length; i++) {
          arr[i] = this.fixTimestamps(arr[i])
        }
      } else {
        const keys = Object.keys(obj)
        if (keys.length === 2 && keys.indexOf('seconds') > -1 && keys.indexOf('nanoseconds') > -1) {
          const nanos = Number(obj['nanoseconds'])
          const secs = Number(obj['seconds'])
          return this._firebaseLemonaid.firestoreTimestampNew(secs, nanos)
        }
        if (keys.length === 2 && keys.indexOf('_seconds') > -1 && keys.indexOf('_nanoseconds') > -1) {
          const nanos = Number(obj['_nanoseconds'])
          const secs = Number(obj['_seconds'])
          return this._firebaseLemonaid.firestoreTimestampNew(secs, nanos)
        }
        for (const key of keys) {
          obj[key] = this.fixTimestamps(obj[key])
        }
      }
    }
    return obj
  }

}
