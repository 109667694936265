import { Component, Inject, ErrorHandler, ViewChild, HostListener, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators, FormControl } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Kirjanpitotili, OstoAlvt, MyyntiAlvt, AlvMaaritys, LokalisoidutAlvtyypit, LokalisoituKirjanpitotilinAlvTyyppi, KirjanpitotilinAlvTyyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'

export interface KirjanpitotiliMuokkaaDialogData {
  tili: Kirjanpitotili
  lisaa: boolean
}

@Component({
  templateUrl: './kirjanpitotili-muokkaa.dialog.html'
})
export class KirjanpitotiliMuokkaaDialog implements OnInit, OnDestroy {

  @ViewChild('nimiFiInput', { read: MatInput, static: true }) nimiFiInput: MatInput
  @ViewChild('numeroInput', { read: MatInput, static: true }) numeroInput: MatInput

  commonError: string = ''
  form: UntypedFormGroup
  name = '' + Math.random()
  lisaa = false
  alvt: AlvMaaritys[] = []
  lokalisoidutAlvtyypit: LokalisoituKirjanpitotilinAlvTyyppi[] = LokalisoidutAlvtyypit.kaikki
  tilitObservable: Observable<Kirjanpitotili[]>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  vertaaAlvMaarityksia: (a: AlvMaaritys, b: AlvMaaritys) => boolean = (a: AlvMaaritys, b: AlvMaaritys): boolean => {
    return a && b && a.tunniste === b.tunniste
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitotiliMuokkaaDialogData,
    private _dialogRef: MatDialogRef<KirjanpitotiliMuokkaaDialog>,
    private _errorHandler: ErrorHandler,
    private _tilikarttaService: TilikarttaService,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _dialog: MatDialog
  ) {
    this.lisaa = this._data.lisaa
    this.tilitObservable = this._tilikarttaService.paatilikartanTilitObservable
  }

  private asetaAlvt(tyyppi: KirjanpitotilinAlvTyyppi) {
    if (tyyppi === KirjanpitotilinAlvTyyppi.MYYNTI) {
      this.alvt = [].concat(MyyntiAlvt.kaikki)
    } else if (tyyppi === KirjanpitotilinAlvTyyppi.OSTO) {
      this.alvt = [].concat(OstoAlvt.kaikki)
    } else if (tyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI_EI_REKISTEROITYNYT) {
      this.alvt = [].concat(MyyntiAlvt.suomi)
    } else {
      this.alvt = []
    }
  }

  ngOnInit() {

    const kaytettavaTyyppi = this._data.tili.alvTyyppi || KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA
    const onEiAlvKasittelya = kaytettavaTyyppi === KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA || kaytettavaTyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI

    this.asetaAlvt(kaytettavaTyyppi)

    // Create form
    this.form = new UntypedFormGroup({
      'vanhempi': new UntypedFormControl(this._data.tili.vanhempi, []),
      'numero': new UntypedFormControl({ value: this._data.tili.numero, disabled: !this._data.lisaa }, [Validators.required]),
      'nimiFi': new UntypedFormControl(this._data.tili.localisedName?.fi ?? this._data.tili.nimi, [Validators.required]),
      'nimiEn': new UntypedFormControl(this._data.tili.localisedName?.en ?? null, [Validators.required]),
      'alv': new UntypedFormControl({ disabled: onEiAlvKasittelya, value: onEiAlvKasittelya ? null : this._data.tili.oletusAlvKasittely }, onEiAlvKasittelya ? [] : [Validators.required]),
      'alvtyyppi': new UntypedFormControl(kaytettavaTyyppi, [Validators.required]),
      'aktiivinen': new UntypedFormControl(this._data.tili.aktiivinen, []),
      'reskontraActive': new FormControl<boolean>(this._data.tili.reskontraActive)
    })

    this.form.get('alvtyyppi').valueChanges
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(value => {
        const alvField = this.form.get('alv')
        alvField.setValue(null)
        this.asetaAlvt(value)
        if (
          value &&
          value !== KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA &&
          value !== KirjanpitotilinAlvTyyppi.ETAMYYNTI
        ) {
          console.log('enable')
          alvField.enable()
          alvField.setValidators(Validators.required)
          alvField.updateValueAndValidity()
        } else {
          console.log('disable')
          alvField.clearValidators()
          alvField.updateValueAndValidity()
          alvField.disable()
        }
      })

    if (this.lisaa) {
      this.numeroInput.focus()
    } else {
      this.nimiFiInput.focus()
    }

    this.reskontraActive.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async isActive => {
      if (
        this._data.tili.reskontraActive && // Was active before
        !isActive // But was just marked as NOT active
      ) {
        const dialogData: AreYouSureDialogData = {
          text: 'Jos reskontra laitetaan pois päältä, kaikki reskontramerkinnät poistetaan.',
          rightAction: 'Kyllä',
        }
        const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

        if (!isSure) {
          this.reskontraActive.setValue(true)
        }
      }
    })

  }

  get vanhempi(): AbstractControl {
    return this.form.get('vanhempi')
  }

  get numero(): AbstractControl {
    return this.form.get('numero')
  }

  get nimiFi(): AbstractControl {
    return this.form.get('nimiFi')
  }

  get nimiEn(): AbstractControl {
    return this.form.get('nimiEn')
  }

  get alv(): AbstractControl {
    return this.form.get('alv')
  }

  get alvtyyppi(): AbstractControl {
    return this.form.get('alvtyyppi')
  }

  get aktiivinen(): AbstractControl {
    return this.form.get('aktiivinen')
  }

  get reskontraActive(): AbstractControl {
    return this.form.get('reskontraActive')
  }

  @HostListener('document:keydown.esc')
  peruuta() {
    this._dialogRef.close()
  }

  @HostListener('document:keydown.enter')
  async tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      const localisedName: { [kieli in TuettuKieli]: string } = {
        'fi': null,
        'en': null
      }

      if (this.nimiFi.value) {
        localisedName.fi = this.nimiFi.value
      }
      if (this.nimiEn.value) {
        localisedName.en = this.nimiEn.value
      }

      const tili: Kirjanpitotili = {
        vanhempi: this.vanhempi.value ? this.vanhempi.value + '' : null,
        aktiivinen: !!this.aktiivinen.value,
        numero: this.numero.value ? this.numero.value + '' : null,
        nimi: this.nimiFi.value,
        oletusAlvKasittely: this.alv.value,
        alvTyyppi: this.alvtyyppi.value,
        localisedName: localisedName
      }

      if (this.reskontraActive.value) {
        tili.reskontraActive = true
      }
      await this._tilikarttaService.tallennaPaatilikartanTili(tili)
    } catch (error) {
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
