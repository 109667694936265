import { setUser } from '@sentry/browser'

import { Injectable, ErrorHandler } from '@angular/core'

import { ReplaySubject, combineLatest, BehaviorSubject, Observable, firstValueFrom, of as observableOf } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { KirjanpitajanRooli } from '../../_jaettu/lemonator/model/kirjanpitaja'
import { FirebaseLemonator, FirebaseLemonaid } from './firebase-lemonator.service'
import { KirjanpitajanTiimiService } from 'app/_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { DateService } from 'app/_shared-core/service/date.service'

export interface KirjanpitajanTiedot {
  uid: string
  rooli: KirjanpitajanRooli
}

@Injectable()
export class KirjautunutKayttajaService {

  public kirjautuminenOnKaynnissaSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>((window.location.href || '').trim().endsWith('/kirjaudu'))

  private nykyisetKirjanpitajanTiedot: KirjanpitajanTiedot = null
  private kirjanpitajanTiedotSubject = new ReplaySubject<KirjanpitajanTiedot>(1)
  kirjanpitajanTiedotObservable = this.kirjanpitajanTiedotSubject.asObservable()

  kirjanpitajaOnItTiiminJasenObservable: Observable<boolean> = this.kirjanpitajanTiedotObservable.pipe(
    switchMap(kirjanpitaja => {
      if (!kirjanpitaja?.uid) {
        return observableOf(false)
      }
      return this._kirjanpitajanTiimiService.annaKirjanpitajanTiimi(kirjanpitaja.uid, this._dateService.currentNumberDate()).then(tiimi => tiimi?.avain === 'IT')
    })
  )

  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this.kirjanpitajanTiedotObservable.pipe(
    switchMap(kirjanpitaja => {
      if (!kirjanpitaja?.uid) {
        return observableOf(false)
      }
      return this._kirjanpitajanTiimiService.annaKirjanpitajanTiimi(kirjanpitaja.uid, this._dateService.currentNumberDate()).then(tiimi => tiimi?.avain === 'IT')
    })
  )

  kirjanpitajaOnLuottoObservable: Observable<boolean> = this.kirjanpitajanTiedotObservable.pipe(
    map(kirjanpitaja => {
      if (!kirjanpitaja?.uid) {
        return false
      }
      if (
        kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || // Ville
        kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2' || // Lauri
        kirjanpitaja.uid === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
        kirjanpitaja.uid === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' || // Jon
        kirjanpitaja.uid === 'H6KU6K9S39QaqDnpe1apaUV3dsz1' || // Elina P.
        kirjanpitaja.uid === '3d4UG33dpERg5CqyF1TmafusXNa2' // Ville H.
      ) {
        return true
      }
      return false
    })
  )

  kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable: Observable<boolean> = this.kirjanpitajanTiedotObservable.pipe(
    switchMap(kirjanpitaja => {

      if (!kirjanpitaja?.uid) {
        return observableOf<boolean>(false)
      }

      if (
        kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || // Ville
        kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2' || // Lauri
        kirjanpitaja.uid === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
        kirjanpitaja.uid === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' // Jon
      ) {
        return observableOf<boolean>(true)
      }

      return this._kirjanpitajanTiimiService.annaKaikkiEsihenkilot()
        .then(esihenkilot => {
          return esihenkilot?.findIndex(eh => eh.kirjanpitajaAvain === kirjanpitaja.uid) > -1
        }).catch(err => {
          this._errorHandler.handleError(err)
          return false
        })
    })
  )

  constructor(
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _errorHandler: ErrorHandler,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService,
    private _dateService: DateService
  ) {

    // console.log('Service', this.kirjautuminenOnKaynnissaSubject.value)

    combineLatest([this._firebase.authUserObservable, this._firebaseLemonaid.authUserObservable, this.kirjautuminenOnKaynnissaSubject])
      .subscribe({
        next: ([lemonatorUser, lemontreeUser, kirjautuminenOnKaynnissa]) => {

          // console.log('Subscribe', kirjautuminenOnKaynnissa)

          if (kirjautuminenOnKaynnissa) {
            this.asetakirjanpitajanulliksi(false)
            return
          }

          if (lemonatorUser && lemontreeUser) {

            if (
              this.nykyisetKirjanpitajanTiedot === null ||
              this.nykyisetKirjanpitajanTiedot.uid !== lemonatorUser.uid ||
              this.nykyisetKirjanpitajanTiedot.uid !== lemontreeUser.uid
            ) {

              const lemonatorTokenPromise = lemonatorUser.getIdTokenResult()
              const lemontreeTokenPromise = lemontreeUser.getIdTokenResult()

              Promise.all([lemonatorTokenPromise, lemontreeTokenPromise]).then(result => {

                if (
                  result &&
                  result[0] &&
                  result[0].claims &&
                  result[0].claims.kirjanpitaja &&
                  result[1] &&
                  result[1].claims &&
                  result[1].claims.kirjanpitaja
                ) {
                  if (result[0].claims.kirjanpitaja === result[1].claims.kirjanpitaja) {
                    const tiedot: KirjanpitajanTiedot = { uid: lemonatorUser.uid, rooli: result[0].claims.kirjanpitaja as any as KirjanpitajanRooli }
                    this.nykyisetKirjanpitajanTiedot = tiedot
                    this.asetaUserContext(tiedot, lemonatorUser.email)
                    this.kirjanpitajanTiedotSubject.next(tiedot)
                    console.log('Kirjanpitäjän uid asetettu paikoilleen.')
                  }
                  // else {
                  //   console.error('Palautuneet roolit eivät täsmää')
                  //   this.logout().catch(er => {
                  //     this._errorHandler.handleError(er)
                  //   })
                  // }
                }
                // else {
                //   console.error('Claimeissa on jotakin vikana')
                //   this.logout().catch(er => {
                //     this._errorHandler.handleError(er)
                //   })
                // }

              }).catch(err => {
                this.logout().catch(er => {
                  this._errorHandler.handleError(er)
                })
                this._errorHandler.handleError(err)
              })

            }
            // else {
            //   console.error('Sama käyttäjä', this.nykyisetKayttajanTiedot, lemonaidUser, lemontreeUser)
            // }

          } else {
            console.error('Jompikumpi käyttäjistä on null', lemonatorUser, lemontreeUser)
            this.asetakirjanpitajanulliksi(!kirjautuminenOnKaynnissa || (!lemonatorUser && !lemontreeUser))
          }

        },
        error: error => {
          this.logout().catch(er => {
            this._errorHandler.handleError(er)
          })
          this._errorHandler.handleError(error)
        }
      })

  }

  private asetakirjanpitajanulliksi(force: boolean) {
    console.log('asetetaan kirjanpitäjä nulliksi', force, this.nykyisetKirjanpitajanTiedot)
    if (force || this.nykyisetKirjanpitajanTiedot !== null) {
      console.log('asetetaan kirjanpitäjä nulliksi', force)
      this.nykyisetKirjanpitajanTiedot = null
      this.asetaUserContext(null, null)
      this.kirjanpitajanTiedotSubject.next(null)
    }
  }

  private asetaUserContext(kirjanpitajanTiedot: KirjanpitajanTiedot, email: string) {
    if (kirjanpitajanTiedot) {
      setUser({
        id: kirjanpitajanTiedot.uid, // Your internal identifier for the user.
        username: email, // The username. Generally used as a better label than the internal ID.
        email: email, // An alternative to a username (or addition). Sentry is aware of email addresses and can show things like Gravatars, unlock messaging capabilities, and more.
      })
    } else {
      setUser(null)
    }
  }

  public logout(): Promise<void> {
    console.log('logout')
    this.asetakirjanpitajanulliksi(true)
    return this.timeoutPromise(1500).then(() => {
      return Promise.all([this._firebase.authSignOut(), this._firebaseLemonaid.authSignOut()])
    }).then(() => {
      return
    })
  }

  // renewAccessCookie(): Promise<'success' | 'failure'> {
  //   console.log('Renew cookie.', new Date().toISOString())
  //   return this._lemonatorAuth.auth.currentUser.getIdToken().then(lemonatorToken => {
  //     const payload: { idToken: string } = {
  //       idToken: lemonatorToken
  //     }
  //     const envi = environment.environment === EnvironmentType.DEV ? 'Dev' : ''
  //     return this._http.postJsonGetJsonWithCredentials<{ status: 'success', expires: number }, { idToken: string }>('/api/1/autentikointi/alustaKeksi' + envi, payload, LEMONATOR_HTTPS_API)
  //   }).then(result => {
  //     if (result && result.expires) {
  //       console.log('Renew cookie done.', new Date().toISOString())
  //       if (environment.environment === EnvironmentType.DEV) {
  //         /** NOTE!! IF YOU CHANGE THIS, CHANGE ALSO THE CODE IN lemonator-web/functions KIRJAUDU.TS */
  //         const minute = 1000 * 60
  //         const hour = minute * 60
  //         const day = hour * 24

  //         const nowInMillis = new Date().getTime()

  //         // Set session expiration to 6 hours. Note that due to Firebase limitation this must be:
  //         // "The session cookie custom expiration in milliseconds. The minimum allowed is
  //         //  5 minutes and the maxium allowed is 2 weeks."
  //         const expiresInMillis = hour * 6
  //         // const expiresAtDate = new Date(nowInMillis + expiresInMillis)
  //         // const expiresInSeconds = Math.floor(expiresInMillis / 1000)

  //         // Lie a little to front end - make it expire 4 hours before the actual time.
  //         // This will make the front end to start trying to get a new cookie earlier.
  //         const expiresInMillisForFrontEnd = expiresInMillis - Math.floor(hour * 4)
  //         const expiresAtMillisForFrontEnd = Math.floor(nowInMillis + expiresInMillisForFrontEnd)
  //         const expiresAtSecondsForFrontEnd = Math.floor(expiresAtMillisForFrontEnd / 1000)
  //         const expiresAtDateForFrontEnd = new Date(expiresAtMillisForFrontEnd)

  //         this._cookieService.setCookie('lemonse', expiresAtSecondsForFrontEnd + '', expiresAtDateForFrontEnd)
  //       }
  //       return 'success'
  //     }
  //     return 'failure'
  //   })
  // }

  public getKirjanpitajanTiedot(): Promise<KirjanpitajanTiedot> {
    return firstValueFrom(this.kirjanpitajanTiedotObservable)
  }

  private timeoutPromise(millis: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id)
        resolve()
      }, millis)
    })
  }

}
