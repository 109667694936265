import { Injectable } from '@angular/core'
import { ViewportRuler } from '@angular/cdk/scrolling'

import { Observable, BehaviorSubject } from 'rxjs'

export interface WindowSize {
  width: number
  height: number
}

@Injectable()
export class WindowSizeService {

  private sizeSubject = new BehaviorSubject<void>(null)
  sizeObservable: Observable<void> = this.sizeSubject.asObservable()

  constructor(private _viewportRuler: ViewportRuler) {
    this.sizeSubject.next(null)
    _viewportRuler.change().subscribe(event => {
      this.sizeSubject.next(null)
    })
  }

  public publishChange() {
    this.sizeSubject.next(null)
  }

  // public annaTodellinenResoluutio(): WindowSize {
  //   const ratio = window.devicePixelRatio ? window.devicePixelRatio : 1
  //   const currentSize = this._viewportRuler.getViewportRect()
  //   if (currentSize) {
  //     return {
  //       width: currentSize.width * ratio,
  //       height: currentSize.height * ratio,
  //     }
  //   }
  //   return null
  // }

}
