<div class="alv-card">

  <div class="light-blue-text" style="text-align: right; float: right;">
    {{ tunnistetietoObservable | async }}
  </div>

  <div style="display: flex; height: 200px; justify-content: center; align-items: center" *ngIf="numbersLoadingSubject | async">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>

  <ng-container *ngIf="!(numbersLoadingSubject | async) && alvIlmoitusSubject | async; let alvIlmoitus">
    <h2 class="light-blue-text alv-otsikko" style="padding: 1em 0 0 0; margin: 0;">Vero kotimaan myynneistä verokannoittain</h2>
    <div style="padding: 0 1em;">
      <table class="alv-table">
        <tbody>
          <tr>
            <td>25,5% vero</td>
            <td>{{alvIlmoitus?.alv255 | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>24% vero</td>
            <td>{{alvIlmoitus?.alv24 | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>14% vero</td>
            <td>{{alvIlmoitus?.alv14 | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>10% vero</td>
            <td>{{alvIlmoitus?.alv10 | lmoney: 'EUR' | async}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="light-blue-text alv-otsikko">Vero ostoista ja maahantuonneista</h2>
    <div style="padding: 0 1em;">
      <table class="alv-table">
        <tbody>
          <tr>
            <td>Vero tavaraostoista muista EU-maista</td>
            <td>{{alvIlmoitus?.taveu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Vero palveluostoista muista EU-maista</td>
            <td>{{alvIlmoitus?.palveu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Vero tavaroiden maahantuonneista EU:n ulkopuolelta</td>
            <td>{{alvIlmoitus?.taveieu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Vero rakentamispalvelun ja metalliromun ostoista</td>
            <td>{{alvIlmoitus?.rak | lmoney: 'EUR' | async}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="light-blue-text alv-otsikko">Vähennettävä vero</h2>
    <div style="padding: 0 1em;">
      <table class="alv-table">
        <tbody>
          <tr>
            <td>Verokauden vähennettävä vero</td>
            <td>{{alvIlmoitus?.vahennettava | lmoney: 'EUR' | async}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="background: rgb(229,244,250); padding: 1em;">

      <div style="display: flex; justify-content: space-between;">
        <div style="font-size: 18px; line-height: 30px;">Alarajahuojennus</div>
        <button mat-button *ngIf="!alvIlmoitus.arhLaskettu" (click)="toggleArh()">
          {{ naytaArh ? 'Poista alarajahuojennus ilmoitukselta' : '+ Avaa alarajahuojennus' }}
        </button>
      </div>
      <div *ngIf="alvIlmoitus.arhError else esilasketutArhArvot" class="lemon-error">

        <!-- <div style="font-size: 18px; line-height: 30px;">Lemonatorin laskema alarajahuojennus</div> -->

        <div *ngIf="alvIlmoitus.arhError == 'no-alv'">
          Lemonator ei voinut laskea alarajahuojennusta. Asiakkaalla ei ole voimassa olevaa ALV-ilmoituskautta.
        </div>

        <div *ngIf="alvIlmoitus.arhError == 'calculation-not-supported-for-alv-type'">
          Lemonator ei voinut laskea alarajahuojennusta. Asiakkaan voimassa olevalla ALV-ilmoitusjaksotyypillä ei voi hakea alarajahuojennusta.
        </div>

        <div *ngIf="alvIlmoitus.arhError == 'alv-change-during-tilikausi'">
          Lemonator ei osaa laskea alarajahuojennusta automaattisesti, koska ALV-jakso on vaihtunut kesken tilikauden. Laske alarajahuojennus käsin ja syötä se tähän.
        </div>

        <div *ngIf="alvIlmoitus.arhError == 'not-eligible-due-to-too-short-tilikausi'">
          Asiakas ei ole oikeutettu alarajahuojennukseen liian lyhyen tilikauden takia. Vain tilikaudella olevat täydet kuukaudet lasketaan mukaan ja tällaisia kuukausia on oltava enemmän kuin yksi.
        </div>

      </div>

      <ng-template #esilasketutArhArvot>
        <div *ngIf="alvIlmoitus.arhLaskettu; let laskettu" style="margin-bottom: 2em;">
          <table class="alv-table">
            <tbody>
              <tr>
                <td>Laskettu liikevaihto</td>
                <td>{{ laskettu.suhteutettuLiikevaihto | lmoney: 'EUR' | async }}</td>
              </tr>
              <tr>
                <td>Laskettu vero</td>
                <td>{{ laskettu.vero | lmoney: 'EUR' | async }}</td>
              </tr>
              <tr>
                <td>Laskettu alarajahuojennuksen määrä</td>
                <td>{{ laskettu.huojennus | lmoney: 'EUR' | async }}</td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: right;">
            <button class="primarybutton" (click)="kopioiArhIlmoitukselle(alvIlmoitus)" mat-button>Vie lasketut arvot ilmoitukselle</button>
          </div>
          <!-- <pre>{{laskettu | json}}</pre> -->
        </div>
      </ng-template>
      <div *ngIf="naytaArh || alvIlmoitus.arhLaskettu">
        <div *ngIf="!alvIlmoitus.arhLaskettu && !alvIlmoitus.arhError" style="margin: 1em; font-size: 16px; line-height: 26px;" class="lemon-error">
          Alarajahuojennusta ollaan ilmoittamassa kaudella, jota Lemonator ei tue.
          Alarajahuojennus on laskettava itse.
        </div>
        <table class="alv-table">
          <tbody>
            <tr>
              <td>Alarajahuojennukseen oikeuttava liikevaihto</td>
              <td><input type="text" decimalNumberField [showAllDecimals]="true" [allowNegative]="false" class="input currency" [formControl]="arhLiikevaihto" required autocomplete="kirjanpitoaineistoarkki" data-lpignore="true" /></td>
            </tr>
            <tr>
              <td>Alarajahuojennukseen oikeuttava vero</td>
              <td><input type="text" decimalNumberField [showAllDecimals]="true" [allowNegative]="false" class="input currency" [formControl]="arhVero" required autocomplete="kirjanpitoaineistoarkki" data-lpignore="true" /></td>
            </tr>
            <tr>
              <td>Alarajahuojennuksen määrä</td>
              <td>{{ alvIlmoitus.arhSyotetty?.huojennus | lmoney: 'EUR' | async }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="padding: 1em; background-color: gray; margin-top: 1em;">
      <table class="alv-table" style="color: white; font-size: 18px; line-height: 30px;">
        <tbody>
          <tr>
            <td>Maksettava vero / palautukseen oikeuttava vero</td>
            <td>{{ maksettavaTaiPalautettavaVeroObservable | async | lmoney: 'EUR' | async }}</td>
          </tr>
          <tr>
            <td>Aiemman ilmoituksen mukainen vero</td>
            <td>{{ edellinenMaksettavaTaiPalautettavaVeroObservable | async | lmoney: 'EUR' | async }}</td>
          </tr>
          <tr>
            <td>Muutos veron määrässä</td>
            <td>{{ muutosVeronMaarassaObservable | async | lmoney: 'EUR' | async }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="light-blue-text alv-otsikko">Myynnit, ostot ja maahantuonnit</h2>
    <div style="padding: 0 1em;">
      <table class="alv-table">
        <tbody>
          <tr>
            <td>0-verokannan alainen liikevaihto</td>
            <td>{{alvIlmoitus?.nolla | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Tavaroiden myynnit muihin EU-maihin</td>
            <td>{{alvIlmoitus?.tavmyynniteu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Palveluiden myynnit muihin EU-maihin</td>
            <td>{{alvIlmoitus?.palvmyynniteu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Tavaraostot muista EU-maista</td>
            <td>{{alvIlmoitus?.tavostoeu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Palveluostot muista EU-maista</td>
            <td>{{alvIlmoitus?.palvostoeu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Tavaroiden maahantuonnit EU:n ulkopuolelta</td>
            <td>{{alvIlmoitus?.tavulkeu | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)</td>
            <td>{{alvIlmoitus?.rakmetmyynti | lmoney: 'EUR' | async}}</td>
          </tr>
          <tr>
            <td>Rakentamispalvelun ja metalliromun ostot</td>
            <td>{{alvIlmoitus?.rakmetosto | lmoney: 'EUR' | async}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="errorMessageObservable | async; let virhe" class="lemon-error" style="margin: 20px; font-weight: bold; text-align: center;">
      {{ virhe }}
    </div>

    <div *ngIf="arhVirhe" class="lemon-error" style="margin: 20px; font-weight: bold; text-align: center;">
      {{ arhVirhe }}
    </div>

    <div style="padding: 0 1em; margin-bottom: -15px; margin-top: 15px;" *ngIf="!(alvLaskunSahkoinenLaskutusosoiteKunnossaObservable | async)">
      <div style="font-size: 75%;" class="gray-text">ALV-lasku voidaan lähettää ainoastaan sähköisenä. Voit lisätä osoitteen navigoimalla Asetukset -> Laskutus.</div>
    </div>
    <div style="display: flex; padding: 0 1em;">
      <mat-checkbox [formControl]="alvLaskuSendCtrl" style="margin-top: 15px; margin-right: 5px;">
        Lähetä myös sähköinen alv-lasku summalla
      </mat-checkbox>
      <mat-form-field style="width: 100px;">
        <input style="display: inline-block;" decimalNumberField [name]="namename" type="text" [formControl]="alvLaskuMaaraCtrl" autocomplete="nothing" [numberOfDecimals]="2" matInput required />
        <!-- <mat-error>{{fieldErrors.huomautuskulu}}</mat-error> -->
      </mat-form-field>
    </div>
    <div style="padding: 0 1em;" *ngIf="alvLaskunSahkoinenLaskutusosoiteKunnossaObservable | async">
      <div class="error-text" *ngIf="asiakkaanOsoitetiedotPuuttuvatObservable | async">ALV-laskua ei voi lähettää. Asiakkaan osoitetiedot puuttuvat. (Mene Asetukset -> Asiakastiedot ja tarkista, että katuosoite, postinumero ja postitoimipaikka on täytetty)</div>
      <div class="error-text" *ngIf="!(alvLaskunViitenumeroObservable | async)">ALV-laskua ei voi lähettää. Asiakkaalta puuttuu verohallinnon sähköinen osoite. Ota yhteys tekniseen tukeen asiakaslinkin kera.</div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 1.5em;">
      <button mat-button class="primarybutton" [disabled]="!(voidaanLahettaaObservable | async)" (click)="laheta()" style="line-height: 35px;">
        <i *ngIf="alvLaskuSendCtrl.value" style="font-size: 20px; margin-right: 10px;" class="icon ion-ios-flash"></i> Lähetä
      </button>
    </div>

  </ng-container>
  <div style="position: relative; height: 20px;">
    <div style="text-align: right; position: absolute; right: 15px; bottom: -5px; color: darkgray; font-size: 80%; width: 600px;">

      <!-- *ngIf="kirjaus.edellisiaVersioita" -->
      <!-- *ngIf="kirjaus.seuraaviaVersioita" -->
      <!-- <span>< Aiempi versio </span> -->
      {{tallennustiedotObservable | async}}
      <!-- <span> Uudempi versio ></span> -->

      <!-- <span class="tallennetaan"></span> -->

      <!-- <span *ngIf="!(tallentamattomiaMuutoksiaObservable | async)" style="font-size: 30px; line-height: 30px; vertical-align: middle; position: absolute; top: -7px; right: -14px;"><i style="padding-left: 4px;" class="ion-ios-checkmark green-text"></i></span> -->
      <span *ngIf="tallentamattomiaMuutoksiaObservable | async" style="font-size: 16px; position: absolute; top: -17px; right: -14px;"> Ilmoittamattomia veroja kaudelta {{ tunnistetietoObservable | async }} <i class="fa fa-exclamation-triangle yellow-text" style="font-size: 20px; padding-left: 3px;"></i></span>
    </div>
  </div>

</div>

<button mat-button (click)="laskeAutomaattikirjauksetUudelleen()">Päätä ALV-tilit (automaattikirjaus)</button>

<!-- <div>
  <button mat-button (click)="laskeArhAutomaattikirjauksetUudelleen()">Päätä ARH ALV-tilit (automaattikirjaus)</button>
</div> -->

<div>
  <h3 class="light-blue-text alv-otsikko" style="margin: 1em 0;">Lähetetyt ALV-ilmoitukset</h3>

  <div style="display: flex; height: 200px; justify-content: center; align-items: center" *ngIf="historyLoadingSubject | async">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>

  <div [style.display]="(historyLoadingSubject | async) ? 'none' : 'block'">
    <div class="alv-card dark-gray-text" *ngFor="let edellinenIlmoitus of aikaisemmatVersiotObservable | async">

      <div class="" style="text-align: right; float: right;">
        {{ edellinenIlmoitus.tunnistetiedotString }}
      </div>

      <div *ngIf="edellinenIlmoitus.url" style="clear: both;">
        Data: <a [href]="edellinenIlmoitus.url" target="_BLANK">{{edellinenIlmoitus.uri}}</a>
      </div>

      <h2 class="alv-otsikko" style="padding: 1em 0 0 0; margin: 0;">Vero kotimaan myynneistä verokannoittain</h2>
      <div style="padding: 0 1em;">
        <table class="alv-table">
          <tbody>
            <tr>
              <td>25,5% vero</td>
              <td>{{edellinenIlmoitus.alv255 | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>24% vero</td>
              <td>{{edellinenIlmoitus.alv24 | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>14% vero</td>
              <td>{{edellinenIlmoitus.alv14 | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>10% vero</td>
              <td>{{edellinenIlmoitus.alv10 | lmoney: 'EUR' | async}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="alv-otsikko">Vero ostoista ja maahantuonneista</h2>
      <div style="padding: 0 1em;">
        <table class="alv-table">
          <tbody>
            <tr>
              <td>Vero tavaraostoista muista EU-maista</td>
              <td>{{edellinenIlmoitus.taveu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Vero palveluostoista muista EU-maista</td>
              <td>{{edellinenIlmoitus.palveu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Vero tavaroiden maahantuonneista EU:n ulkopuolelta</td>
              <td>{{edellinenIlmoitus.taveieu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Vero rakentamispalvelun ja metalliromun ostoista</td>
              <td>{{edellinenIlmoitus.rak | lmoney: 'EUR' | async}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="alv-otsikko">Vähennettävä vero</h2>
      <div style="padding: 0 1em;">
        <table class="alv-table">
          <tbody>
            <tr>
              <td>Verokauden vähennettävä vero</td>
              <td>{{edellinenIlmoitus.vahennettava | lmoney: 'EUR' | async}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="edellinenIlmoitus.arhSyotetty" style="background: rgb(229,244,250); padding: 1em;">
        <div style="font-size: 18px; line-height: 30px;">Alarajahuojennus</div>
        <table class="alv-table">
          <tbody>
            <tr>
              <td>Alarajahuojennukseen oikeuttava liikevaihto</td>
              <td>{{ edellinenIlmoitus.arhSyotetty.suhteutettuLiikevaihto | lmoney: 'EUR' | async }}</td>
            </tr>
            <tr>
              <td>Alarajahuojennukseen oikeuttava vero</td>
              <td>{{ edellinenIlmoitus.arhSyotetty.vero | lmoney: 'EUR' | async }}</td>
            </tr>
            <tr>
              <td>Alarajahuojennuksen määrä</td>
              <td>{{ edellinenIlmoitus.arhSyotetty.huojennus | lmoney: 'EUR' | async }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="padding: 1em; background-color: gray; margin-top: 1em;">
        <table class="alv-table" style="color: white; font-size: 18px; line-height: 30px;">
          <tbody>
            <tr>
              <td>Maksettava vero / palautukseen oikeuttava vero</td>
              <td>{{ edellinenIlmoitus.maksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
            </tr>
            <tr>
              <td>Aiemman ilmoituksen mukainen vero</td>
              <td>{{ edellinenIlmoitus.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
            </tr>
            <tr>
              <td>Muutos veron määrässä</td>
              <td>{{ edellinenIlmoitus.maksettavaTaiPalautettavaVero - edellinenIlmoitus.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="alv-otsikko">Myynnit, ostot ja maahantuonnit</h2>
      <div style="padding: 0 1em;">
        <table class="alv-table">
          <tbody>
            <tr>
              <td>0-verokannan alainen liikevaihto</td>
              <td>{{edellinenIlmoitus.nolla | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Tavaroiden myynnit muihin EU-maihin</td>
              <td>{{edellinenIlmoitus.tavmyynniteu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Palveluiden myynnit muihin EU-maihin</td>
              <td>{{edellinenIlmoitus.palvmyynniteu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Tavaraostot muista EU-maista</td>
              <td>{{edellinenIlmoitus.tavostoeu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Palveluostot muista EU-maista</td>
              <td>{{edellinenIlmoitus.palvostoeu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Tavaroiden maahantuonnit EU:n ulkopuolelta</td>
              <td>{{edellinenIlmoitus.tavulkeu | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)</td>
              <td>{{edellinenIlmoitus.rakmetmyynti | lmoney: 'EUR' | async}}</td>
            </tr>
            <tr>
              <td>Rakentamispalvelun ja metalliromun ostot</td>
              <td>{{edellinenIlmoitus.rakmetosto | lmoney: 'EUR' | async}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div style="position: relative; height: 20px;">

        <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
          {{edellinenIlmoitus.tallennustiedot}} (Lähetys #{{ edellinenIlmoitus.jarjestysnumero }})
          <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedosto(edellinenIlmoitus)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div> -->

      <div style="margin-top: 5px; height: 20px; color: darkgray; font-size: 80%; display: flex; justify-content: space-between;">
        <div>
          <span *ngIf="edellinenIlmoitus?.alvLasku">Lähetetty sähköinen <a style="color: blue; cursor: pointer;" (click)="lataaAlvLasku(edellinenIlmoitus)">lasku</a> summalla {{ edellinenIlmoitus.alvLasku.maksettavaMaara | lmoney: 'EUR' | async}}</span>
          <span *ngIf="!edellinenIlmoitus?.alvLasku && edellinenIlmoitus.maksettavaTaiPalautettavaVero - edellinenIlmoitus.edellinenMaksettavaTaiPalautettavaVero > 0 && alvLaskunPerustiedotKunnossaObservable | async">
            <button (click)="lahetaVainAlvLasku(edellinenIlmoitus)">Lähetä sähköinen alv-lasku summalla {{ edellinenIlmoitus.maksettavaTaiPalautettavaVero - edellinenIlmoitus.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async}}</button>
          </span>
        </div>
        <div style="text-align: right;">
          {{edellinenIlmoitus.tallennustiedot}} (Lähetys #{{ edellinenIlmoitus.jarjestysnumero }})
          <button class="light-blue-text" style="margin-top: -10px; margin-right: -10px;" mat-icon-button (click)="lataaTyviTiedosto(edellinenIlmoitus)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>

    </div>
  </div>

  <div app-kirjanpito-lahetys-alv *ngIf="alvLaskunPerustiedotKunnossaObservable | async" [valittuKuukausiObservable]="valittuKuukausiObservable"></div>

</div>