import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

import { FirestoreTosite, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'
import { TositeUriService } from '../../_jaettu/service/tosite/tosite-uri.service'

import { LEMONATOR_CF_API, LEMONATOR_HTTPS_IMAGES_API, LemonHttpService } from '../../_angular/service/lemon-http.service'
import { TositeKuvaService } from '../../_angular/service/tosite/tosite-kuva.service'
import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { TositeKatseleComponentData } from '../../_angular/_resolvers/tosite.resolve'

import { Subject, Observable } from 'rxjs'
import { takeUntil, map } from 'rxjs/operators'

import { DateService } from '../../_shared-core/service/date.service'
import { Asiakas, AsiakkaanMaksutapa } from '../../_jaettu-lemonator/model/asiakas'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { LataaTositeLemonaidistaUudelleenRequest, LataaTositeLemonaidistaUudelleenResponse } from 'app/_jaettu-lemonator/model/tyojono'
import { MatDialog } from '@angular/material/dialog'
import { TuontiKaynnistettyUudelleenDialog, TuontiKaynnistettyUudelleenDialogData } from './tuonti-kaynnistetty-uudelleen.dialog'

interface FirestoreTositteenAlkuperainenListausTiedosto extends FirestoreTositteenAlkuperainenTiedosto {
  dataUri: string
}

interface FirestoreKuitinLatausKuva extends FirestoreTositteenKuva {
  ladataan: boolean
  safeUrl: SafeUrl
  dataUri: string
}

@Component({
  templateUrl: './tosite.katsele.component.html',
  styleUrls: ['./tosite.katsele.component.css']
})
export class TositeSelailuKatseleComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>()

  tosite: FirestoreTosite = null
  kuvat: FirestoreKuitinLatausKuva[] = null
  alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = null
  otsakeObservable: Observable<string> = null
  asiakas: Asiakas = null
  paluuUrl: string = null
  kuittiDataFirestoreUrl: string = null
  kirjattavaKuittiDataFirestoreUri: string = null
  kirjattavaKuittiDataFirestoreUrl: string = null
  kuittiDataFirestoreUri: string = null
  kuittiDataRealtimeDbUrl: string = null
  kuittiDataRealtimeDbUri: string = null
  binaryDataUrlFirebase: string = null
  binaryDataUrlGCP: string = null
  naytaDebug: boolean = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private sanitizer: DomSanitizer,
    private httpService: LemonHttpService,
    private tositeUriService: TositeUriService,
    private tositeKuvaService: TositeKuvaService,
    private asiakasService: AsiakasService,
    private dateService: DateService,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _ladataanService: LadataanService,
    private _copyService: KopioijaPalvelu,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _fileSaverService: FileSaverService,
    private _lemonatorFirebase: FirebaseLemonator,
    private _dialog: MatDialog
  ) { }

  kuvanLataaminenValmis(kuitti: FirestoreKuitinLatausKuva) {
    kuitti.ladataan = false
  }

  ngOnInit() {

    this._kirjautunutKayttajaService.kirjanpitajaOnItTiiminJasenObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(onItTiiminJasen => {
      this.naytaDebug = onItTiiminJasen
    })

    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: { data: TositeKatseleComponentData }) => {

      this.tosite = data.data.tosite
      this.asiakas = data.data.asiakas

      this.kuvat = this.annaKuvat(this.tosite)
      this.alkuperaiset = this.annaAlkuperaiset(this.tosite)

      this.kuittiDataRealtimeDbUri = this.tositeUriService.annaKuitinFirebaseUri(this.asiakas.asiakasId + '', this.tosite.localPvm.year, this.tosite.localPvm.month, this.tosite.kuvakansio)
      this.kuittiDataRealtimeDbUrl = this._debugService.createRealtimeDatabaseLinkLemonaid(this.kuittiDataRealtimeDbUri)

      this.kuittiDataFirestoreUri = this.tositeUriService.annaKuitinFirestoreUri(this.asiakas.asiakasId + '', this.tosite.avain)
      this.kuittiDataFirestoreUrl = this._debugService.createFirestoreLinkLemonaid(this.kuittiDataFirestoreUri)

      this.kirjattavaKuittiDataFirestoreUri = this._kirjanpitoUriService.annaKirjattavanFirestoreTositteenUriAvaimella(this.asiakas.avain, this.tosite.avain)
      this.kirjattavaKuittiDataFirestoreUrl = this._debugService.createFirestoreLink(this.kirjattavaKuittiDataFirestoreUri)

      this.binaryDataUrlFirebase = this._debugService.annaFirebaseBinaryLinkLemonaidUsa(this.asiakas.asiakasId + '/' + this.tosite.kuvakansio)
      this.binaryDataUrlGCP = this._debugService.annaGCBinaryLinkLemonaidUsa(this.asiakas.asiakasId + '/' + this.tosite.kuvakansio)

      this.paluuUrl = 'asiakkaat/' + this.asiakas.avain + '/tositteet/selaa'
      this.otsakeObservable = this.asiakasService.nykyisenAsiakkaanJaKayttajienRoolienPerusteellaAktivoitavatMaksutavatObservable.pipe(
        map((maksutavat): string => {
          if (maksutavat) {
            let valittuMaksutapa: AsiakkaanMaksutapa = null
            for (const maksutapa of maksutavat) {
              if (maksutapa.tunniste === this.tosite.maksutapa) {
                valittuMaksutapa = maksutapa
              }
            }
            return (valittuMaksutapa === null ? 'Ei maksutapaa' : valittuMaksutapa.nimi) + ' ' + this.dateService.muotoilePaikallinenPaiva(this.tosite.localPvm, 'fi')
          }
          return ''
        })
      )
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  private annaAlkuperaiset(tosite: FirestoreTosite): FirestoreTositteenAlkuperainenListausTiedosto[] {
    if (tosite.alkuperaiset) {
      const alkuperaiset: FirestoreTositteenAlkuperainenListausTiedosto[] = []
      for (const alkuperaisenAvain of Object.keys(tosite.alkuperaiset)) {
        const alkuperainen: FirestoreTositteenAlkuperainenListausTiedosto = this._copyService.cloneObjectDeep(this.tosite.alkuperaiset[alkuperaisenAvain])
        alkuperainen.dataUri = this.tositeUriService.annaCloudStorageAlkuperainenUriAsiakasIdlle(this.asiakas.asiakasId + '', alkuperainen)
        alkuperaiset.push(alkuperainen)
      }
      return alkuperaiset.sort((a, b) => {
        return (a.jarjestys ?? 0) - (b.jarjestys ?? 0)
      })
    }
    return null
  }

  private annaKuvat(tosite: FirestoreTosite): FirestoreKuitinLatausKuva[] {
    const kuvat: FirestoreKuitinLatausKuva[] = []
    for (const kuvanAvain of Object.keys(tosite.kuvat)) {
      const kuva: FirestoreKuitinLatausKuva = this._copyService.cloneObjectDeep(tosite.kuvat[kuvanAvain])

      kuva.safeUrl = '/assets/noimage.png'
      kuva.ladataan = true
      kuvat.push(kuva)

      const kavain = kuva.avain + '_quality'
      const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(this.asiakas.asiakasId + '', tosite.kuvakansio, kavain, 'webp')
      kuva.safeUrl = LEMONATOR_HTTPS_IMAGES_API + '/api/1/kuvat/' + kuvanUrl
    }
    return kuvat.sort((a, b): number => {
      return a.jarjestys < b.jarjestys ? -1 : a.jarjestys > b.jarjestys ? 1 : 0
    })
  }

  lataaAlkuperainen(alkuperainen: FirestoreTositteenAlkuperainenTiedosto) {

    this._ladataanService.aloitaLataaminen()

    const uri = this.tositeUriService.annaCloudStorageAlkuperainenUriAsiakasIdlle(this.asiakas.asiakasId + '', alkuperainen)
    return this.httpService.getBinary('/kuittiLataaAlkuperainen?a=' + encodeURIComponent('/api/1/kuitit/kuvat/alkuperainen/' + uri), LEMONATOR_CF_API).then(result => {
      this._ladataanService.lopetaLataaminen()
      this._fileSaverService.saveAs(result, alkuperainen.nimi)
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this.errorHandler.handleError(err)
    })

  }

  async reloadTosite() {
    this._ladataanService.aloitaLataaminen()

    try {
      const reqData: LataaTositeLemonaidistaUudelleenRequest = {
        asiakasId: '' + this.asiakas.asiakasId,
        tositeAvain: this.tosite.avain
      }
      const resp = await this._lemonatorFirebase.functionsCall<LataaTositeLemonaidistaUudelleenRequest, LataaTositeLemonaidistaUudelleenResponse>('lataaTositeLemonaidistaUudelleen', reqData, { timeout: 540 * 1000 })

      if (!resp || resp.e) {
        throw new Error('Failed! ' + (resp?.e ?? 'No response'))
      }

      this._dialog.open<TuontiKaynnistettyUudelleenDialog, TuontiKaynnistettyUudelleenDialogData>(TuontiKaynnistettyUudelleenDialog, {
        data: { uri: resp.uri }
      })

    } catch (err) {
      this.errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

}
