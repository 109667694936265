import { Directive, Input, OnInit, OnDestroy, ErrorHandler } from '@angular/core'

import { MatDatepicker } from '@angular/material/datepicker'
import { DateAdapter } from '@angular/material/core'
import { Platform } from '@angular/cdk/platform'

import { LemonKuukausiDateAdapter } from '../_material/LemonKuukausiDateAdapter'
import { DateService } from '../../_shared-core/service/date.service'
import { LemonTranslationService } from '../service/lemon-translation.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[monthPicker]',
  providers: [{ provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] }]
})
export class MonthPickerDirective implements OnInit, OnDestroy {

  @Input() matDatepicker: MatDatepicker<Date>

  private _ngUnsubscribe = new Subject<void>()

  constructor() { }

  ngOnInit() {
    this.matDatepicker.startView = 'year'
    this.matDatepicker.monthSelected.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(month => {
      this.matDatepicker.select(month)
      this.matDatepicker.close()
    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
