export class TilinpaatosUriService {

  getTilinpaatosUsersDocUri(asiakasAvain: string) {
    return 'customers/' + asiakasAvain + '/tilinpaatos-users/' + asiakasAvain
  }
  getTilinpaatosMetadataDocUri(asiakasAvain: string, tilikausiAvain: string) {
    return 'customers/' + asiakasAvain + '/tilinpaatos-export-metadata/' + tilikausiAvain
  }
  getTilinpaatosSignaturesDocUri(asiakasAvain: string, tilinpaatosVersionAvain: string) {
    return 'customers/' + asiakasAvain + '/tilinpaatos-signatures/' + tilinpaatosVersionAvain
  }
  getTilinpaatosSignaturesHistoryDocUri(asiakasAvain: string, tilinpaatosVersionAvain: string, historyAvain: string) {
    return 'customers/' + asiakasAvain + '/tilinpaatos-signatures/' + tilinpaatosVersionAvain + '/tilinpaatos-signatures-history/' + historyAvain
  }

  getConvertKycToTilinpaatosUsersWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-user-conversion/' + tyojonoAvain
  }

  getConvertPoytakirjaToPdfWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/yhtiokokous-create-pdf/' + tyojonoAvain
  }

  getYhtiokokouksenPoytakirjaDocUri(asiakasAvain: string, tilikausiAvain: string) {
    return 'customers/' + asiakasAvain + '/yhtiokokouksen-poytakirja/' + tilikausiAvain
  }
  getYhtiokokouksenPoytakirjaHistoryDocUri(asiakasAvain: string, tilikausiAvain: string, historyAvain: string) {
    return 'customers/' + asiakasAvain + '/yhtiokokouksen-poytakirja/' + tilikausiAvain + '/yhtiokokouksen-poytakirja-history/' + historyAvain
  }

  // Storage
  annaYhtiokokousPoytakirjaPdfStorageUri(asiakasAvain: string, tilikausiAvain: string, pdfAvain: string) {
    return 'tilinpaatos-yhtiokokouksen-poytakirja/' + asiakasAvain + '/' + tilikausiAvain + '/' + pdfAvain + '.pdf'
  }

}

