import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { TuettuKieli, Valuutta } from '../../../_shared-core/model/common'
import { LaskunTyyppi } from '../../../_jaettu/model/lasku'
import { KonfiguraatioPalvelu } from '../../service/lasku/configuration.service'

import { BehaviorSubject, Observable } from 'rxjs'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { LaskuForm } from './lasku-rivit.component'

@Component({
  selector: 'app-lasku-ylaosan-dropparit',
  templateUrl: './lasku-ylaosan-dropparit.component.html',
  styleUrls: ['./lasku-ylaosan-dropparit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuYlaosanDropparitComponent {

  @Input() parent: FormGroup<LaskuForm>

  kielet: BehaviorSubject<TuettuKieli[]> = new BehaviorSubject(['fi', 'en'])
  valuutatObservable: Observable<Valuutta[]>
  tyypitObservable: Observable<LaskunTyyppi[]>
  kieliObservable: Observable<TuettuKieli>

  constructor(
    private _konfiguraatioPalvelu: KonfiguraatioPalvelu,
    private _translationService: LemonTranslationService
  ) {
    this.valuutatObservable = this._konfiguraatioPalvelu.valuutatObservable
    this.tyypitObservable = this._konfiguraatioPalvelu.tyypitObservable
    this.kieliObservable = this._translationService.currentLanguageObservable
  }

  vertaaTyyppia = (o1: LaskunTyyppi, o2: LaskunTyyppi): boolean => {
    if (o1 && o2) {
      // console.log(o1, o2)
      return o1.avain === o2.avain
    }
    return false
  }

}
