/* eslint-disable no-bitwise */
export function calculateEC(msg: Uint8Array, ecLen: number): Uint8Array {

  const copy: number[] = Array.prototype.slice.call(msg)

  // Generator Polynomial
  const poly = generatorPolynomial(ecLen)

  for (let i = 0; i < ecLen; i++) { copy.push(0) }
  while (copy.length > ecLen) {
    if (!copy[0]) {
      copy.shift()
      continue
    }
    const logK = log(copy[0])
    for (let i = 0; i <= ecLen; i++) {
      copy[i] = copy[i] ^ exp(poly[i] + logK)
    }
    copy.shift()
  }
  return new Uint8Array(copy)
}

// Galois Field Math
const gf256Base = 285

const expTable: number[] = [1]
const logTable: number[] = []

for (let i = 1; i < 256; i++) {
  let n = expTable[i - 1] << 1
  if (n > 255) { n ^= gf256Base }
  expTable[i] = n
}

for (let i = 0; i < 255; i++) {
  logTable[expTable[i]] = i
}

function exp(k: number) {
  while (k < 0) { k += 255 }
  while (k > 255) { k -= 255 }
  return expTable[k]
}

function log(k: number) {
  if (k < 1 || k > 255) {
    throw Error(`Bad log(${k})`)
  }
  return logTable[k]
}

// Generator Polynomials
const polynomials = [
  [0], // a^0 x^0
  [0, 0], // a^0 x^1 + a^0 x^0
  [0, 25, 1], // a^0 x^2 + a^25 x^1 + a^1 x^0
  // and so on...
]

function generatorPolynomial(num: number): number[] {
  if (polynomials[num]) {
    return polynomials[num]
  }
  const prev = generatorPolynomial(num - 1)
  const res: number[] = []

  res[0] = prev[0]
  for (let i = 1; i <= num; i++) {
    res[i] = log(exp(prev[i]) ^ exp(prev[i - 1] + num - 1))
  }
  polynomials[num] = res
  return res
}
