<div style="text-align: center; min-width: 300px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      {{ header }}
    </lemon-dialog-header>

    <div [innerHTML]="text" class="info-dialog-text"></div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button mat-dialog-close class="primarybutton" mat-button>OK</button>
  </div>
</div>