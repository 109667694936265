import { Component, OnInit, ChangeDetectionStrategy, NgZone, ErrorHandler, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core'

import { Observable, BehaviorSubject, combineLatest, switchMap, of, firstValueFrom, Subject } from 'rxjs'
import { map, startWith, takeUntil } from 'rxjs/operators'

import { TilinpaatosRekisterointiWorkQueueData, TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import {
  CustomTilinpaatosUploadHistory,
  DownloadPreviouslyGeneratedTilinpaatosPdfRequest,
  DownloadPreviouslyGeneratedTilinpaatosPdfResponse,
  DownloadUploadedPoytakirjaPdfRequest,
  DownloadUploadedPoytakirjaPdfResponse,
  ExportPresignedPoytakirjaToLemonaidRequest,
  ExportPresignedPoytakirjaToLemonaidResponse,
  ExportPresignedTilinpaatosToLemonaidRequest,
  ExportPresignedTilinpaatosToLemonaidResponse,
  RaporttiPdfResponse,
  RaporttiRequest,
  TilinpaatoksenTilintarkastuksenLiitetiedostonLatauspyynto,
  TilinpaatoksenTilintarkastuksenLiitetiedostonLatausvastaus,
  TilinpaatoksenTilintarkastustiedonLiite,
  TilinpaatoksenTilintarkastustiedot,
  TilinpaatosLiitetiedot,
  TilintarkastuskertomusUploadedTyojonoData,
  VeroilmoitusDraft,
  VeroilmoitusOsingonjakoNotificationType
} from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'

import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { InfoDialog, InfoDialogData } from '../../_jaettu-angular/_components/info.dialog'
import { MatDialog } from '@angular/material/dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LocalDateTime, NumberDate, NumberTimestamp } from 'app/_shared-core/model/common'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

interface HumanReadableCustomTilinpaatosUploadHistory extends CustomTilinpaatosUploadHistory {
  kirjanpitaja: string
  ladattu: string
}

interface MainForm {
  tilinpaatosPerinteisesti: FormControl<boolean>
  poytakirjaPerinteisesti: FormControl<boolean>
  meetingDate: FormControl<Date>
  meetingHours: FormControl<number>
  meetingMinutes: FormControl<number>
  dividendsDistributed: FormControl<boolean>
  dividendsPayableDate: FormControl<Date>
  voittoPaatos: FormControl<string>
}

@Component({
  selector: '[app-kirjanpito-tilinpaatos-rekisterointi]',
  templateUrl: './rekisterointi.component.html',
  styleUrls: ['./rekisterointi.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoTilinpaatosRekisterointiComponent implements OnInit, OnDestroy {

  @ViewChild('fileInputTilinpaatos', { static: true }) fileInputTilinpaatos: ElementRef<HTMLInputElement>
  @ViewChild('fileInputPoytakirja', { static: true }) fileInputPoytakirja: ElementRef<HTMLInputElement>
  @ViewChild('fileInputKertomus', { static: true }) fileInputKertomus: ElementRef<HTMLInputElement>

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() paivitaArvotHiljaisestiObservable: Observable<number>
  @Input() tilinpaatosStatusObservable: Observable<TilinpaatosStatus>

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)

  tilinpaatosAllekirjoitettamattaObservable: Observable<boolean>
  liitetiedotLuomattaObservable: Observable<boolean>
  poytakirjaAllekirjoitettamattaObservable: Observable<boolean>
  tilinpaatosAllekirjoitettuJarjestelmanUlkopuolellaObservable: Observable<boolean>
  veroilmoitusNotificationObservable: Observable<VeroilmoitusOsingonjakoNotificationType>
  yhtionkokouksenPoytakirjaAllekirjoitettamattaObservable: Observable<boolean>
  rekisteroityObservable: Observable<string>
  errorTextSubject: BehaviorSubject<string> = new BehaviorSubject(null)
  sendDisabledObservable: Observable<boolean>
  previoslySentThirdPartyTilinpaatokset: Observable<HumanReadableCustomTilinpaatosUploadHistory[]>
  tilintarkastustiedotObservable: Observable<TilinpaatoksenTilintarkastustiedot>
  tilintarkastusKertomusLataamatta: Observable<boolean>
  tilintarkastetaanObservable: Observable<boolean>
  tilikaudenLoppumisestaOnYli8KkObservable: Observable<boolean>
  tpUploadError: string = null
  pkUploadError: string = null

  tilintarkastustiedotUriObservable: Observable<string>
  tilintarkastustiedotEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

  form: FormGroup<MainForm>

  private _generalMeetingDateAndTime: LocalDateTime = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: 1,
    hour: 0,
    minutes: 0,
    seconds: 1
  }
  private _poytakirjaPdfAsBase64: string
  private _poytakirjaUploadedSubject: Subject<boolean> = new Subject()
  poytakirjaUploadedObservable = this._poytakirjaUploadedSubject.asObservable()
  showHallituksenPoytakirjaDownloadBtnObservable: Observable<boolean>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _ngZone: NgZone,
    private _dialog: MatDialog,
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _debugService: DebugService,
    private _snackbar: MatSnackBar,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _formValidationService: FormValidationService,
    private _lemonatorFirebase: FirebaseLemonator,
    private _fileSaverService: FileSaverService
  ) { }

  ngOnInit() {

    this.form = new FormGroup<MainForm>({
      tilinpaatosPerinteisesti: new FormControl<boolean>({ value: false, disabled: true }),
      poytakirjaPerinteisesti: new FormControl<boolean>(false),
      meetingDate: new FormControl<Date>(null, [Validators.required]),
      meetingHours: new FormControl<number>(null, [Validators.required]),
      meetingMinutes: new FormControl<number>(null, [Validators.required]),
      dividendsDistributed: new FormControl<boolean>(null),
      dividendsPayableDate: new FormControl<Date>(null),
      voittoPaatos: new FormControl<string>(null, [Validators.required])
    })

    this.dividendsDistributed.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (val) {
        this.dividendsPayableDate.setValidators([Validators.required])
      } else {
        this.dividendsPayableDate.clearValidators()
      }
      this.dividendsPayableDate.updateValueAndValidity()
    })

    this.meetingDate.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(val => {
      if (val) {
        const asLocalDate = this._dateService.dateToLocalDate(val)
        this._generalMeetingDateAndTime.year = asLocalDate.year
        this._generalMeetingDateAndTime.month = asLocalDate.month
        this._generalMeetingDateAndTime.day = asLocalDate.day
      }
    })

    this.meetingHours.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      if (this._generalMeetingDateAndTime) {
        this._generalMeetingDateAndTime.hour = val
      }
    })

    this.meetingMinutes.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      if (this._generalMeetingDateAndTime) {
        this._generalMeetingDateAndTime.minutes = val
      }
    })

    this.tilikaudenLoppumisestaOnYli8KkObservable = this.selectedTilikausiObservable.pipe(
      map(tilikausi => {
        if (!tilikausi) { return false }
        return this._dateService.compareLocalDates(this._dateService.lisaaKuukausiaPaikallinen(tilikausi.loppuu, 8), '<', this._dateService.currentLocalDate())
      })
    )

    const liitetiedotObservable: Observable<TilinpaatosLiitetiedot> = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
          // console.log('load current from ' + uri)
          return this._lemonatorFirebase.firestoreDoc<TilinpaatosLiitetiedot>(uri).listen()
        }
        return of<TilinpaatosLiitetiedot>(null)
      }),
      lemonShare()
    )

    liitetiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe({
      next: liitetiedot => {
        const value = liitetiedot?.allekirjoitetaanLemonaidissa
        if (value !== null && value !== undefined) {
          this.form.get('tilinpaatosPerinteisesti').setValue(!value)
        } else {
          this.form.get('tilinpaatosPerinteisesti').setValue(false)
        }
      }
    })

    this.tilintarkastetaanObservable = liitetiedotObservable.pipe(
      map(liitetiedot => !!liitetiedot?.tilintarkastetaan)
    )

    this.liitetiedotLuomattaObservable = liitetiedotObservable.pipe(
      map(liitetiedot => !liitetiedot?.kokoLomakeTallennettu)
    )

    this.tilintarkastustiedotUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          return this._kirjanpitoUriService.annaTilinpaatosTilintarkastustiedotUri(asiakas.avain, tilikausi)
        }
        return ''
      })
    )
    this.tilintarkastustiedotEncodedUriObservable = this.tilintarkastustiedotUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.tilintarkastustiedotObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosTilintarkastustiedotUri(asiakas.avain, tilikausi)
          return this._lemonatorFirebase.firestoreDoc<TilinpaatoksenTilintarkastustiedot>(uri).listen().pipe(
            map(fromDatabase => {
              if (fromDatabase) {
                return fromDatabase
              }
              const empty: TilinpaatoksenTilintarkastustiedot = {
                avain: null,
                kertomus: null,
                luoja: null,
                luotu: null,
                paivitetty: null,
                paivittaja: null,
                poistettu: false,
                teksti: null
              }
              return empty
            })
          )
        }
        return of<TilinpaatoksenTilintarkastustiedot>(null)
      }),
      lemonShare()
    )

    this.tilintarkastusKertomusLataamatta = this.tilintarkastustiedotObservable.pipe(
      map(tilintarkastustiedot => {
        return !tilintarkastustiedot?.kertomus
      })
    )

    this.veroilmoitusNotificationObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const veroilmoitusDraftUri = this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(asiakas.avain, tilikausi)
          return this._lemonatorFirebase.firestoreDoc<VeroilmoitusDraft>(veroilmoitusDraftUri).listen()
        }
        return of<VeroilmoitusDraft>(null)
      }),
      map(draft => draft?.osingonjakoNotification ?? null)
    )

    this.tilinpaatosAllekirjoitettamattaObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => {
        return !status?.tilinpaatosAllSigned
      })
    )

    this.poytakirjaAllekirjoitettamattaObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => {
        return !status?.varsinainenYhtiokokousAllSigned
      })
    )

    this.tilinpaatosAllekirjoitettamattaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(allekirjoittamatta => {
      if (!allekirjoittamatta) {
        this.form.get('tilinpaatosPerinteisesti').setValue(null)
      }
    })

    this.poytakirjaAllekirjoitettamattaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(allekirjoittamatta => {
      if (!allekirjoittamatta) {
        this.form.get('poytakirjaPerinteisesti').setValue(null)
      }
    })

    this.tilinpaatosAllekirjoitettuJarjestelmanUlkopuolellaObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => {
        return !status?.tilinpaatosAllSigned
      })
    )
    this.yhtionkokouksenPoytakirjaAllekirjoitettamattaObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => !status?.varsinainenYhtiokokousAllSigned)
    )
    this.sendDisabledObservable = combineLatest([this.tilinpaatosStatusObservable, this.tilikaudenLoppumisestaOnYli8KkObservable]).pipe(
      map(([status, yli8Kk]) => {
        return yli8Kk || !status?.tilinpaatosAllSigned || !status?.varsinainenYhtiokokousAllSigned
      }),
      startWith(true)
    )
    this.rekisteroityObservable = combineLatest([this.tilinpaatosStatusObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([status, nimitiedotMap]) => {
        // console.log('status', status)
        if (status?.rekisteroity) {
          const rekAsDate = this._dateService.timestampToDate(status.rekisteroity)
          const nimitiedot = nimitiedotMap.get(status.rekisteroija)
          const nimi = nimitiedot ? nimitiedot.etunimi + ' ' + nimitiedot.sukunimi : ''
          return 'Tilinpäätös lähetetty rekisteröitäväksi ' + this._dateService.muotoilePaivaJaAikaDate(rekAsDate, 'fi') + ' ' + nimi
        }
        return null
      })
    )

    this.showHallituksenPoytakirjaDownloadBtnObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => {
        return status?.boardMinutesCreated
      })
    )

    const rawThirdPartySentTilinpaatokset = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = 'asiakkaat/' + asiakas.avain + '/kirjanpito-tilinpaatos-custom-pdf-history'
          return this._lemonatorFirebase.firestoreCollection<CustomTilinpaatosUploadHistory>(uri).where('tilikausiAvain', '==', tilikausi.avain).listen()
        }
        return of<CustomTilinpaatosUploadHistory[]>(null as [])
      }),
      map(res => res?.sort((a, b) => this._dateService.compareTimestamps(a.luotu, '<', b.luotu) ? 1 : 0)) || null
    )

    this.previoslySentThirdPartyTilinpaatokset = combineLatest([this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, rawThirdPartySentTilinpaatokset]).pipe(
      map(([kirjanpitajatMap, tps]) => {
        if (kirjanpitajatMap && tps) {
          return tps.map(tp => {
            const kp = kirjanpitajatMap.get(tp.luoja)
            const entry = tp as HumanReadableCustomTilinpaatosUploadHistory
            entry.kirjanpitaja = ((kp?.etunimi ?? '') + ' ' + (kp?.sukunimi ?? '')).trim() || tp.luoja
            entry.ladattu = this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(tp.luotu), 'fi')
            return entry
          })
        }
        return null
      })
    )
  }

  get meetingDate() {
    return this.form.get('meetingDate')
  }

  get meetingHours() {
    return this.form.get('meetingHours')
  }

  get meetingMinutes() {
    return this.form.get('meetingMinutes')
  }

  get dividendsDistributed() {
    return this.form.get('dividendsDistributed')
  }

  get dividendsPayableDate() {
    return this.form.get('dividendsPayableDate')
  }

  get voittoPaatos() {
    return this.form.get('voittoPaatos')
  }

  async downloadTilinpaatosPdfWithVersionKey(versioAvain?: string) {

    this._ladataanService.aloitaLataaminen()

    try {

      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

      const pyynto: DownloadPreviouslyGeneratedTilinpaatosPdfRequest = {
        asiakasAvain: asiakas.avain,
        tilikausiAvain: tilikausi.avain,
        versioAvain: versioAvain
      }

      await this._lemonatorFirebase.functionsCall<DownloadPreviouslyGeneratedTilinpaatosPdfRequest, DownloadPreviouslyGeneratedTilinpaatosPdfResponse>('tilinpaatosLataaAiemminGeneroituPdf', pyynto).then(data => {
        if (data.e) {
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          if (data.base64Pdf) {
            const fileName = data.nimi || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64Pdf, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
          }
        }
      })
    } catch (err) {
      this._snackbar.open('PDF:n lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async downloadYhtiokokousPdf() {
    this._ladataanService.aloitaLataaminen()

    try {
      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

      const reqData: DownloadUploadedPoytakirjaPdfRequest = {
        asiakasAvain: asiakas.avain,
        tilikausiAvain: tilikausi.avain
      }

      await this._lemonatorFirebase.functionsCall<DownloadUploadedPoytakirjaPdfRequest, DownloadUploadedPoytakirjaPdfResponse>('yhtiokokouksenPoytakirjaDownloadPdf', reqData).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('PDF:n lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
            }
          }
        } else {
          if (data.base64Pdf) {
            const fileName = data.nimi || 'Yhtiökokouksen pöytäkirja.pdf'
            this._fileSaverService.saveBase64As(data.base64Pdf, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
          }
        }
      })
    } catch (err) {
      this._snackbar.open('PDF:n lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  downloadTilinpaatosilmoitusPdf() {
    this._downloadPdf(RaporttiType.PRH_TILINPAATOSILMOITUS)
  }

  downloadHallituksenPoytakirjaPdf() {
    this._downloadPdf(RaporttiType.HALLITUKSEN_POYTAKIRJA)
  }

  private async _downloadPdf(reportType: RaporttiType) {
    this._ladataanService.aloitaLataaminen()

    try {

      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

      const pyynto: RaporttiRequest = {
        a: asiakas.avain,
        k: 'fi',
        w: reportType,
        s: this._dateService.localDateToNumber(tilikausi.alkaa),
        e: this._dateService.localDateToNumber(tilikausi.loppuu),
        ta: tilikausi.avain
      }

      await this._lemonatorFirebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', pyynto).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          this._ladataanService.lopetaLataaminen()
          if (data.base64File) {
            const fileName = data.fileName || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.')
          }
        }
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }

  }

  lisaaTilinpaatos() {
    this.fileInputTilinpaatos.nativeElement.click()
  }

  lisaaPoytakirja() {
    this.fileInputPoytakirja.nativeElement.click()
  }

  lisaaTilintarkastuskertomus() {
    this.fileInputKertomus.nativeElement.click()
  }

  async tpFileChanged(event: Event) {

    this.tpUploadError = null
    this._ladataanService.aloitaLataaminen()

    try {
      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])
      const target: HTMLInputElement = event.target as HTMLInputElement

      if (!asiakas || !target?.files?.length || target?.files?.length !== 1) {
        // TODO: FIXME: ERROR MESSAGES
        this._ladataanService.lopetaLataaminen()
        return
      }

      const file = target.files.item(0)

      const onkoPdf = file.name.toLowerCase().endsWith('pdf') || file.type.toLowerCase() === 'application/pdf'
      if (!onkoPdf) {
        // TODO: FIXME: ERROR MESSAGES
        this._ladataanService.lopetaLataaminen()
        return
      }

      const dataUrl = await this._tiedostojenLataamisService.getAsDataUrl(file)

      if (this.stringByteLength(dataUrl) > 1024 * 1024 * 9.5) {
        this.tpUploadError = 'Tilinpäätösasiakirja voi olla maksimissaan 7 megatavua suuri. Ole hyvä ja pakkaa asiakirjaa enemmän.'
        return
      }

      await this._savePresignedTilinpaatos(asiakas.avain, tilikausi, dataUrl.split('base64,')[1])

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  stringByteLength(str: string): number {
    // returns the byte length of an utf8 string
    let s = str.length
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i)
      if (code > 0x7f && code <= 0x7ff) { s++ } else if (code > 0x7ff && code <= 0xffff) { s += 2 }
      if (code >= 0xDC00 && code <= 0xDFFF) { i-- } // trail surrogate
    }
    return s
  }

  async pkFileChanged(event: Event) {

    this.pkUploadError = null
    this._ladataanService.aloitaLataaminen()

    try {
      const target: HTMLInputElement = event.target as HTMLInputElement

      if (!target?.files?.length || target?.files?.length !== 1) {
        // TODO: FIXME: ERROR MESSAGES
        this._ladataanService.lopetaLataaminen()
        return
      }

      const file = target.files.item(0)

      const onkoPdf = file.name.toLowerCase().endsWith('pdf') || file.type.toLowerCase() === 'application/pdf'
      if (!onkoPdf) {
        // TODO: FIXME: ERROR MESSAGES
        this._ladataanService.lopetaLataaminen()
        return
      }

      const dataUrl = await this._tiedostojenLataamisService.getAsDataUrl(file)

      if (this.stringByteLength(dataUrl) > 1024 * 1024 * 9.5) {
        this.pkUploadError = 'Pöytäkirja voi olla maksimissaan 7 megatavua suuri. Ole hyvä ja pakkaa asiakirjaa enemmän.'
        return
      }

      this._poytakirjaPdfAsBase64 = dataUrl.split('base64,')[1]
      this._poytakirjaUploadedSubject.next(true)

    } catch (err) {
      this._snackbar.open('Lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
      this._errorHandler.handleError(err)

      this._poytakirjaPdfAsBase64 = null
      this._poytakirjaUploadedSubject.next(false)

    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async kertomusFileChanged(event: Event) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
    const tallennettava = await firstValueFrom(this.tilintarkastustiedotObservable)
    const kirjanpitaja = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    const target: HTMLInputElement = event.target as HTMLInputElement

    if (!asiakas || !target?.files?.length) {
      this._ladataanService.lopetaLataaminen()
      return
    }

    if (!asiakas || !target?.files?.length || target?.files?.length !== 1) {
      // TODO: FIXME: ERROR MESSAGES
      this._ladataanService.lopetaLataaminen()
      return
    }

    const file = target.files.item(0)
    const onkoPdf = file.name.toLowerCase().endsWith('pdf') || file.type.toLowerCase() === 'application/pdf'
    if (!onkoPdf) {
      // TODO: FIXME: ERROR MESSAGES
      this._ladataanService.lopetaLataaminen()
      return
    }

    const liite: TilinpaatoksenTilintarkastustiedonLiite = {
      avain: this._lemonatorFirebase.firestoreCreateId(),
      nimi: file.name,
      tyyppi: 'pdf',
      poistettu: false
    }
    tallennettava.kertomus = liite

    const storageUri = this._kirjanpitoUriService.annaTilinpaatosTilintarkastusBinaariliiteStorageUri(asiakas.avain, tilikausi.avain, liite.avain, liite.tyyppi)
    const bucket = environment.environment === EnvironmentType.PRODUCTION || environment.environment === EnvironmentType.BETA ? 'tilinpaatos-tilintarkastus-eu' : 'tilinpaatos-tilintarkastus-eu-test'
    const runningUpload = this._tiedostojenLataamisService.upload(this._lemonatorFirebase, storageUri, file, bucket)
    await firstValueFrom(runningUpload.doneObservable)

    if (!tallennettava.luotu) { tallennettava.luotu = this._timestampService.now() }
    if (!tallennettava.luoja) { tallennettava.luoja = kirjanpitaja.uid }

    tallennettava.avain = tilikausi.avain
    tallennettava.paivitetty = this._timestampService.now()
    tallennettava.paivittaja = kirjanpitaja.uid

    const tyojonoData: TilintarkastuskertomusUploadedTyojonoData = {
      luotu: this._timestampService.now(),
      asiakasAvain: asiakas.avain,
      tilikausiAvain: tilikausi.avain
    }

    const uri = this._kirjanpitoUriService.annaTilinpaatosTilintarkastustiedotUri(asiakas.avain, tilikausi)
    const historiaUri = this._kirjanpitoUriService.annaTilinpaatosTilintarkastustiedotHistoriaUri(asiakas.avain, tilikausi, this._lemonatorFirebase.firestoreCreateId())
    const tyojonoUri = this._kirjanpitoUriService.annaTilinpaatosTilintarkastusLadattuTyojonoUri(asiakas.avain, this._lemonatorFirebase.firestoreCreateId())

    const batch = this._lemonatorFirebase.firestoreBatch()

    batch.set(uri, tallennettava)
    batch.set(historiaUri, tallennettava)
    batch.set(tyojonoUri, tyojonoData)

    await batch.commit()

    this._ladataanService.lopetaLataaminen()

  }

  private _saveInFlight = false
  async tallenna() {

    if (this._saveInFlight) {
      return
    }

    this.errorTextSubject.next(null)

    this._saveInFlight = true
    this._ladataanService.aloitaLataaminen()

    try {

      const yhtiokokousAllekirjoittamatta = await firstValueFrom(this.yhtionkokouksenPoytakirjaAllekirjoitettamattaObservable)
      const tilinpaatosAllekirjoittamatta = await firstValueFrom(this.tilinpaatosAllekirjoitettamattaObservable)
      const tilintarkastetaan = await firstValueFrom(this.tilintarkastetaanObservable)
      const kertomusLataamatta = await firstValueFrom(this.tilintarkastusKertomusLataamatta)

      if (yhtiokokousAllekirjoittamatta || tilinpaatosAllekirjoittamatta || (kertomusLataamatta && tilintarkastetaan)) {
        this.errorTextSubject.next('Kaikki asiakirjat on allekirjoitettava ja ladattava ennen tilinpäätöksen rekisteröintiä.')
        return
      }

      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const kirjanpitajaTiedotPromise = this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, kirjanpitajaTiedot, tilikausi] = await Promise.all([asiakasPromise, kirjanpitajaTiedotPromise, tilikausiPromise])

      if (!asiakas || !tilikausi) {
        return
      }

      const statusUri = 'asiakkaat/' + asiakas.avain + '/tilinpaatos-status/' + tilikausi.avain
      const rekisterointiWorkQueueUri = 'tyojono/' + asiakas.avain + '/tilinpaatos-rekisterointi/' + tilikausi.avain

      const statusUpdate: Partial<TilinpaatosStatus> = {
        rekisteroija: kirjanpitajaTiedot.uid,
        rekisteroity: this._timestampService.now()
      }

      const workQueueData: TilinpaatosRekisterointiWorkQueueData = {
        asiakasAvain: asiakas.avain,
        tilikausiAvain: tilikausi.avain,
        startedAt: this._timestampService.now()
      }

      const batch = this._lemonatorFirebase.firestoreBatch()

      batch.set(statusUri, statusUpdate, { merge: true })
      batch.set(rekisterointiWorkQueueUri, workQueueData)

      await batch.commit()

      this._snackbar.open('Rekisteröinti suoritettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'bottom' })

    } catch (error) {
      this._errorHandler.handleError(error)
      this._snackbar.open('Rekisteröinnin aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000, verticalPosition: 'bottom' })

    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
    }

  }

  avaaInfoTeksti(teksti: string) {
    const data: InfoDialogData = {
      text: teksti,
      header: ''
    }
    this._dialog.open<InfoDialog, InfoDialogData>(InfoDialog, { data: data })
  }

  async downloadFile(liite: TilinpaatoksenTilintarkastustiedonLiite) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)

    const request: TilinpaatoksenTilintarkastuksenLiitetiedostonLatauspyynto = {
      asiakasAvain: asiakas.avain,
      liite: liite,
      tilikausiAvain: tilikausi.avain
    }
    const response = await this._lemonatorFirebase.functionsCall<TilinpaatoksenTilintarkastuksenLiitetiedostonLatauspyynto, TilinpaatoksenTilintarkastuksenLiitetiedostonLatausvastaus>('kirjanpitoTilinpaatosTilintarkastusLiitaTiedosto', request)
    this._fileSaverService.saveBase64AsGuessedType(response.base64File, liite.nimi)
  }

  private async _savePresignedTilinpaatos(asiakasAvain: string, tilikausi: Tilikausi, base64File: string) {
    const tpRequest: ExportPresignedTilinpaatosToLemonaidRequest = {
      asiakasAvain: asiakasAvain,
      tilikausiAvain: tilikausi.avain,
      base64File: base64File
    }

    const response = await this._lemonatorFirebase.functionsCall<ExportPresignedTilinpaatosToLemonaidRequest, ExportPresignedTilinpaatosToLemonaidResponse>('tilinpaatosLahetysLemonaidiinPresigned', tpRequest)

    if (response.e) {
      this._errorHandler.handleError(new Error('Error while saving presigned TP PDF: ' + response.e))
    } else {
      this._snackbar.open('Allekirjoitettu tilinpäätös tallennettiin.', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })
    }
  }

  async savePoytakirjaData() {
    if (!this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (!this._poytakirjaPdfAsBase64) {
      return
    }

    try {

      this._ladataanService.aloitaLataaminen()

      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

      const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

      const dividendsPayableDateAsNumber = this._dateService.dateToNumber(this.dividendsPayableDate.value)

      await this._savePresignedPoytakirja(
        asiakas.avain,
        this._dateService.localDateTimeToNumberTimestamp(this._generalMeetingDateAndTime),
        !!this.dividendsDistributed.value,
        dividendsPayableDateAsNumber,
        this.voittoPaatos.value,
        tilikausi,
        this._poytakirjaPdfAsBase64
      )

      this._snackbar.open('Allekirjoitettu pöytäkirja ladattu onnistuneesti', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })

    } catch (err) {
      this._snackbar.open('Lataaminen epäonnistui!', 'OK', { duration: 10000, horizontalPosition: 'center', verticalPosition: 'bottom' })
      this._errorHandler.handleError(new Error('Pöytäkirja save failed!' + err?.message))

    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  private async _savePresignedPoytakirja(
    asiakasAvain: string,
    dateOfConfirmation: NumberTimestamp,
    dividendsDistributed: boolean,
    dividendsPayableDate: NumberDate,
    voittoPaatosText: string,
    tilikausi: Tilikausi,
    base64File: string
  ) {

    const pkRequest: ExportPresignedPoytakirjaToLemonaidRequest = {
      asiakasAvain: asiakasAvain,
      tilikausiAvain: tilikausi.avain,
      base64File: base64File,
      dateOfConfirmation: dateOfConfirmation,
      dividendsDistributed: dividendsDistributed,
      dividendsPayableDate: dividendsPayableDate,
      voittoPaatosText: voittoPaatosText.trim()
    }

    const response = await this._lemonatorFirebase.functionsCall<ExportPresignedPoytakirjaToLemonaidRequest, ExportPresignedPoytakirjaToLemonaidResponse>('yhtiokokouksenPoytakirjaLahetysLemonaidiinPresigned', pkRequest)
    if (response.e) {
      this._errorHandler.handleError(new Error('Error while saving presigned pöytäkirja PDF: ' + response.e))
    } else {
      this._snackbar.open('Allekirjoitettu pöytäkirja tallennettiin.', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })
    }

  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
