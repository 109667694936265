import { Component, OnInit, Inject, ErrorHandler, OnDestroy } from '@angular/core'
import { Validators, FormControl, FormGroup, ValidationErrors } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjauksenKulunJaksotusRequest, KirjauksenTallennusvastaus, Kirjaus, Kirjausrivi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { combineLatest, map, Subject, take, takeUntil } from 'rxjs'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

export interface KirjausJaksotaKuluDialogData {
  kirjaus: Kirjaus
  asiakas: Asiakas
  kirjauksenKulurivi: Kirjausrivi
}

interface MainForm {
  ekaKuukausi: FormControl<Date>
  vikaKuukausi: FormControl<Date>
}

@Component({
  templateUrl: './kirjaus-jaksota-kulu.dialog.html',
  styleUrl: './kirjaus-jaksota-kulu.dialog.css'
})
export class KirjausJaksotaKuluDialog implements OnInit, OnDestroy {

  form: FormGroup<MainForm>
  namename: string = 'assoiuhgfdetryiknvdwaqwsdfghjlpobxf' + Math.random()

  kuukausienLukumaara: number
  kulutYhteensa: number
  kuluPerKuukausi: number

  virhe: string = ''

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausJaksotaKuluDialogData,
    private _dialogRef: MatDialogRef<KirjausJaksotaKuluDialog>,
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {

    this.kulutYhteensa = this.data.kirjauksenKulurivi.d || (-this.data.kirjauksenKulurivi.k)

    this.form = new FormGroup<MainForm>({
      ekaKuukausi: new FormControl<Date>({ value: null, disabled: false }, [Validators.required, this._alkaaEnnenLoppuaValidator]),
      vikaKuukausi: new FormControl<Date>({ value: null, disabled: false }, [Validators.required, this._alkaaEnnenLoppuaValidator])
    })
  }

  get ekaKuukausi() {
    return this.form?.get('ekaKuukausi')
  }

  get vikaKuukausi() {
    return this.form?.get('vikaKuukausi')
  }

  ngOnInit() {

    combineLatest([this.ekaKuukausi.valueChanges, this.vikaKuukausi.valueChanges]).pipe(
      takeUntil(this._ngUnsubscribe),
      map(([eka, vika]) => {
        if (!eka || !vika) {
          return null
        }
        return this._dateService.kuukausiaValissa(vika, eka)
      }),
    ).subscribe(kuukausiaValissa => {
      if (kuukausiaValissa !== null && kuukausiaValissa !== undefined) {
        this.kuukausienLukumaara = kuukausiaValissa + 1
        this.kuluPerKuukausi = this.kuukausienLukumaara ? this.kulutYhteensa / this.kuukausienLukumaara : null
      }
    })

    this._asiakasService.nykyisenAsiakkaanTilikaudetObservable.pipe(
      take(1),
      map(tilikaudet => this._asiakasJaettuService.annaTilikausiPaivalle({ tilikaudet: tilikaudet }, this._dateService.currentLocalDate()))
    ).subscribe(tilikausi => {
      if (tilikausi) {
        this.vikaKuukausi.setValue(this._dateService.localDateToDate(tilikausi.loppuu))
      }
    })

    const kirjauksenPaivamaara = this.data.kirjaus.p
    const asDate = this._dateService.numberToDate(kirjauksenPaivamaara)

    this.ekaKuukausi.setValue(asDate)
  }

  jaaKulu() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const pyynto: KirjauksenKulunJaksotusRequest = {
      asiakasAvain: this.data.asiakas.avain,
      kirjaus: this.data.kirjaus,
      alku: this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(this.ekaKuukausi.value)),
      loppu: this._dateService.numberDateToNumberMonth(this._dateService.dateToNumber(this.vikaKuukausi.value)),
      kulurivi: this.data.kirjauksenKulurivi
    }

    this._ladataanService.aloitaLataaminen()

    this._firebase.functionsCall<KirjauksenKulunJaksotusRequest, KirjauksenTallennusvastaus>('kirjanpitoKirjauksetJaksotaKuluKuukausille', pyynto, { timeout: 540 * 1000 }).then(() => {
      this._dialogRef.close()
    }).catch(err => this._errorHandler.handleError(err))
      .finally(() => this._ladataanService.lopetaLataaminen())
  }

  private _alkaaEnnenLoppuaValidator = (control: FormControl<Date>): ValidationErrors | null => {
    const eka = this.ekaKuukausi?.value
    const vika = this.vikaKuukausi?.value
    if (vika && eka && eka > vika) {
      return { 'loppuEnnenAlkua': true }
    }
    return null
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
