import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core'

export interface LoadingConfig {
  fullScreenBackdrop: boolean
  backdropBackgroundColour: string
  backdropBorderRadius: string
  primaryColour: string
  secondaryColour: string
  tertiaryColour: string
}

@Component({
  selector: '[lemon-loading]',
  template: `
    <div *ngIf="show">
      <div class="backdrop" [ngClass]="{'full-screen' : config?.fullScreenBackdrop == true}" [ngStyle]="{'border-radius': config?.backdropBorderRadius, 'background-color': config?.backdropBackgroundColour}">
        <div class="spinner-three-bounce" [ngClass]="{'full-screen' : config?.fullScreenBackdrop == true}">
          <div class="bounce1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
          <div class="bounce2" [ngStyle]="{'background-color': config?.secondaryColour}"></div>
          <div class="bounce3" [ngStyle]="{'background-color': config?.tertiaryColour}"></div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
          .backdrop {
            z-index: 1999;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
          }
    
          /* Three Bounce styles */
          .spinner-three-bounce {
              width: 70px;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 20px;
              margin: auto;
              z-index: 2000;
          }
    
          .spinner-three-bounce > div {
              width: 18px;
              height: 18px;
              background-color: #ffffff;
    
              border-radius: 100%;
              display: inline-block;
              -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
              animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          }
    
          .spinner-three-bounce .bounce1 {
              -webkit-animation-delay: -0.32s;
              animation-delay: -0.32s;
          }
    
          .spinner-three-bounce .bounce2 {
              -webkit-animation-delay: -0.16s;
              animation-delay: -0.16s;
          }
    
          @-webkit-keyframes sk-bouncedelay {
              0%, 80%, 100% { -webkit-transform: scale(0) }
              40% { -webkit-transform: scale(1.0) }
          }
    
          @keyframes sk-bouncedelay {
              0%, 80%, 100% {
                  -webkit-transform: scale(0);
                  transform: scale(0);
              } 40% {
                  -webkit-transform: scale(1.0);
                  transform: scale(1.0);
              }
          }
    
          .full-screen {
              position: fixed;
              position: -ms-page;
          }
        `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit {

  @Input() show: boolean
  @Input() config: LoadingConfig = {
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(255,255,255,0)',
    backdropBorderRadius: '4px',
    primaryColour: 'rgba(0, 0, 0, 0.15)',
    secondaryColour: 'rgba(0, 0, 0, 0.15)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(0, 0, 0, .15)'
  }

  constructor() { }

  ngOnInit() {}

}
