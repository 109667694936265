import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { LemonTranslationService } from '../service/lemon-translation.service'

export interface AreYouSureDialogData {
  rightAction: string
  text: string
  header?: string
  leftAction?: string
}

@Component({
  templateUrl: './are-you-sure.dialog.html'
})
export class AreYouSureDialog implements OnInit {

  public text = ''
  public header = 'Oletko varma?'
  public rightAction = this.translationService.lokalisoi('yleiset.jatka')
  public leftAction = this.translationService.lokalisoi('yleiset.peruuta')

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AreYouSureDialogData,
    private dialogRef: MatDialogRef<AreYouSureDialog>,
    private translationService: LemonTranslationService,
  ) { }

  ngOnInit() {
    this.rightAction = this.data.rightAction

    this.text = this.data.text
    this.header = this.data.header ? this.data.header : this.header
    this.leftAction = this.data.leftAction ? this.data.leftAction : this.leftAction
  }

  replyTrue() {
    this.dialogRef.close(true)
  }

}
