import { MatDialog } from '@angular/material/dialog'

import { VirheDialogData, VirheDialog } from '../_components/virhe.dialog'

import { ReplaySubject } from 'rxjs'
import { delay } from 'rxjs/operators'
import { Injectable, NgZone } from '@angular/core'

import { runInZone } from '../angularfire.extensions'

@Injectable()
export class LadataanService {

  private _ladataanSubject = new ReplaySubject<boolean>(1)
  ladataanObservable = this._ladataanSubject.asObservable().pipe(
    delay(10),
    runInZone(this._ngZone)
  )

  constructor(
    private _dialog: MatDialog,
    private _ngZone: NgZone
  ) {
    this._ladataanSubject.next(false)
  }

  aloitaLataaminen() {
    this._ladataanSubject.next(true)
  }

  lopetaLataaminen() {
    this._ladataanSubject.next(false)
  }

  naytaVirheDialog(error: Error | null): void {
    const data: VirheDialogData = {
      error: error
    }
    this._dialog.open(VirheDialog, { data: data })
  }

}
