import { alpha2ToAlpha3, alpha3ToAlpha2, getName } from 'i18n-iso-countries'
import { CountryLocalizationService } from './translation.service'

// eslint-disable-next-line no-shadow
export enum MaaErikoiskoodit {
  KANARIANSAARET_KOODI_ALPHA_3 = 'IC1',
  KANARIANSAARET_KOODI_ALPHA_2 = 'IC',
  POHJOIS_IRLANTI_KOODI_ALPHA_3 = 'XI1',
  POHJOIS_IRLANTI_KOODI_ALPHA_2 = 'XI'
}
export abstract class MaaBaseService extends CountryLocalizationService {

  public euMaakoodit: string[] = [
    'AUT', // Austria
    'BEL', // Belgium
    'BGR', // Bulgaria
    'HRV', // Croatia
    'CYP', // Cyprus
    'CZE', // Czechia (Czech Republic)
    'DNK', // Denmark
    'EST', // Estonia
    'FIN', // Finland
    'FRA', // France
    'DEU', // Germany
    'GRC', // Greece
    'HUN', // Hungary
    'IRL', // Ireland
    'ITA', // Italy
    'LVA', // Latvia
    'LTU', // Lithuania
    'LUX', // Luxembourg
    'MLT', // Malta
    'NLD', // Netherlands
    'POL', // Poland
    'PRT', // Portugal
    'ROU', // Romania
    'SVK', // Slovakia
    'SVN', // Slovenia
    'ESP', // Spain
    'SWE' // Sweden
  ]

  public euroMaakoodit: string[] = [
    'AUT', // Austria
    'BEL', // Belgium
    'CYP', // Cyprus
    'EST', // Estonia
    'FIN', // Finland
    'FRA', // France
    'DEU', // Germany
    'GRC', // Greece
    'IRL', // Ireland
    'ITA', // Italy
    'LVA', // Latvia
    'LTU', // Lithuania
    'LUX', // Luxembourg
    'MLT', // Malta
    'NLD', // Netherlands
    'PRT', // Portugal
    'SVK', // Slovakia
    'SVN', // Slovenia
    'ESP'  // Spain
  ]

  public sepaMaakoodit: string[] = this.euMaakoodit.concat([
    'ISL', // Iceland
    'LIE', // Liechtenstein
    'MCO', // Monaco
    'NOR', // Norway
    'SMR', // San Marino
    'CHE', // Switzerland
    'GBR'  // United Kingdom
  ])



  getAlpha2Code(alpha3: string): string {
    if (MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_3 === alpha3) {
      return MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_2
    } else if (MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3 === alpha3) {
      return MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_2
    }
    return alpha3ToAlpha2(alpha3)
  }

  getAlpha3Code(alpha2: string): string {
    if (MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_2 === alpha2) {
      return MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_3
    } else if (MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_2 === alpha2) {
      return MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3
    }
    return alpha2ToAlpha3(alpha2)
  }

  getName(value: string, lang: string): string {
    if (MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_3 === value) {
      return lang === 'en' ? 'Canary Islands, Spain' : 'Kanariansaaret, Espanja'
    } else if (MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3 === value) {
      return lang === 'en' ? 'Northern Ireland, United Kindom' : 'Pohjois-Irlanti, Yhdistynyt Kuningaskunta'
    }
    return getName(value, lang)
  }

  getAlpha2CodeTranslateTaxAreaToCountry(alpha3: string): string {
    if (MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_3 === alpha3) {
      return 'ES'
    } else if (MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3 === alpha3) {
      return 'GB'
    }
    return this.getAlpha2Code(alpha3)
  }

  getNameTranslateTaxAreaToCountryName(alpha3: string, lang: string): string {
    return getName(this.getAlpha2CodeTranslateTaxAreaToCountry(alpha3), lang)
  }

  // Alankomaat, Belgia, Bulgaria, Viro, Espanja, Irlanti, Iso-Britannia, Italia, Itävalta, Kreikka, Kroatia, Kypros, Latvia,
  // Liettua, Luxemburg, Malta, Portugali, Puola, Ranska, Romania, Ruotsi, Saksa, Slovakia, Slovenia, Suomi, Tanska, Tšekki ja
  // Unkari; ETA-maat: Islanti, Liechtenstein, Norja sekä lisäksi Sveitsi

}
