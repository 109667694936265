import { NumberData } from './model'

export function encode(inData: string | number) {
  const data = new TextEncoder().encode(inData + '')
  if (data.byteLength > 2953) {
    throw new Error('Too much data')
  }
  return encode8bit(data)
}

/* eslint-disable no-bitwise */
function pushBits(arr: number[], n: number, value: number) {
  for (let bit = 1 << (n - 1); bit; bit >>>= 1) {
    arr.push(bit & value ? 1 : 0)
  }
}

// 8bit encode
function encode8bit(data: Uint8Array): NumberData {
  const len = data.byteLength
  const bits: number[] = []

  for (let i = 0; i < len; i++) {
    pushBits(bits, 8, data[i])
  }

  const res: NumberData = {}

  const d = [0, 1, 0, 0]
  pushBits(d, 16, len)
  res.data10 = res.data27 = d.concat(bits)

  if (len < 256) {
    const d2 = [0, 1, 0, 0]
    pushBits(d2, 8, len)
    res.data1 = d2.concat(bits)
  }

  return res
}
