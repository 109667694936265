<div class="dropparit-container" [class.lemon-lang-fi]="(kieliObservable | async) === 'fi'" [class.lemon-lang-en]="(kieliObservable | async) === 'en'">
  <div class="dropparit placeholder-isoilla" [formGroup]="parent">
    <div class="ekadroppari">
      <mat-form-field class="no-underline valkoinen pyoristettu-potko-select">
        <mat-label>{{ 'lasku.tyyppi' | translate | async }}</mat-label>
        <mat-select [compareWith]="vertaaTyyppia" formControlName="tyyppi">
          <mat-option *ngFor="let tyyppi of tyypitObservable | async" [value]="tyyppi">
            {{ ('laskuntyyppi.' + tyyppi.avain) | translate | async }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tokadroppari">
      <mat-form-field class="no-underline valkoinen pyoristettu-potko-select">
        <mat-label>{{ 'lasku.valuutta' | translate | async }}</mat-label>
        <mat-select formControlName="valuutta">
          <mat-option *ngFor="let valuutta of valuutatObservable | async" [value]="valuutta.koodi">
            {{valuutta.koodi}} ({{ ('valuutta.' + valuutta.koodi) | translate | async }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="kolmasdroppari">
      <mat-form-field class="no-underline valkoinen pyoristettu-potko-select">
        <mat-label>{{ 'lasku.kieli' | translate | async }}</mat-label>
        <mat-select formControlName="kieli">
          <mat-option *ngFor="let kieli of kielet | async" [value]="kieli">
            {{ ('kieli.' + kieli) | translate | async }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>