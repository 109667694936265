import { Directive, Input, Output, EventEmitter } from '@angular/core'

@Directive({
  selector: '[appCountTo]'
})
export class CountToDirective {

  @Output() countToChange = new EventEmitter()
  @Output() countToEnd = new EventEmitter()
  private _timer: any
  private _duration: number
  private _countTo: number
  private _countFrom: number
  private _step: number

  @Input()
  set duration(duration: number) {
    this._duration = duration
    this.run()
  }

  @Input()
  set countTo(countTo: number) {
    this._countTo = countTo
    this.run()
  }

  @Input()
  set countFrom(countFrom: number) {
    this._countFrom = countFrom
    this.run()
  }

  @Input()
  set step(step: number) {
    this._step = step
    this.run()
  }

  run() {
    const _this = this
    clearInterval(_this._timer)

    if (isNaN(_this._duration)) {
      return false
    }

    if (isNaN(_this._step)) {
      return false
    }

    if (isNaN(_this._countFrom)) {
      return false
    }

    if (isNaN(_this._countTo)) {
      return false
    }

    if (_this._step <= 0) {
      console.log('Step must be greater than 0.')
      return false
    }

    if (_this._duration <= 0) {
      console.log('Duration must be greater than 0.')
      return false
    }

    if (_this._step > _this._duration * 1000) {
      console.log('Step must be equal or smaller than duration.')
      return false
    }

    let intermediate = _this._countFrom
    const increment = Math.abs(_this._countTo - _this._countFrom) / ((_this._duration * 1000) / _this._step)

    _this.countToChange.emit(intermediate)

    _this._timer = setInterval(() => {
      if (_this._countTo < _this._countFrom) {
        if (intermediate <= _this._countTo) {
          clearInterval(_this._timer)
          _this.countToChange.emit(_this._countTo)
          _this.countToEnd.emit()
        } else {
          _this.countToChange.emit(intermediate)
          intermediate -= increment
        }
      } else {
        if (intermediate >= _this._countTo) {
          clearInterval(_this._timer)
          _this.countToChange.emit(_this._countTo)
          _this.countToEnd.emit()
        } else {
          _this.countToChange.emit(intermediate)
          intermediate += increment
        }
      }
    }, _this._step)

  }
}
