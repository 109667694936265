import { Injectable } from '@angular/core'

import { LaskuttavaAsiakasProvider } from './laskuttava-asiakas.service'
import { LaskunTyypit, LaskunTyyppi } from '../../../_jaettu/model/lasku'
import { Valuutta } from '../../../_shared-core/model/common'
import { CurrencyService } from '../../../_shared-core/service/currency.service'

import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class KonfiguraatioPalvelu {

  private valuutatSubject = new ReplaySubject<Valuutta[]>(1)
  valuutatObservable = this.valuutatSubject.asObservable()

  // private tyypitSubject = new ReplaySubject<LaskunTyyppi[]>(1)
  tyypitObservable: Observable<LaskunTyyppi[]> // = this.tyypitSubject.asObservable()

  constructor(
    private _currencyService: CurrencyService,
    private _laskuttavaAsiakasProvider: LaskuttavaAsiakasProvider
  ) {

    this.tyypitObservable = this._laskuttavaAsiakasProvider.onkoOssRekisterissa().pipe(
      map(onkoRekisterissa => {
        if (onkoRekisterissa) {
          return [
            LaskunTyypit.TAVALLINEN,
            LaskunTyypit.TAVALLINEN_ULKOM,
            LaskunTyypit.RAKENNUSALA,
            LaskunTyypit.EU_PALVELU,
            LaskunTyypit.EU_TAVARA,
            LaskunTyypit.EU_KULUTTAJA_PALVELU,
            LaskunTyypit.EU_KULUTTAJA_TAVARA,
            LaskunTyypit.MUU_MAAILMA
          ]
        }
        return [
          LaskunTyypit.TAVALLINEN,
          LaskunTyypit.TAVALLINEN_ULKOM,
          LaskunTyypit.RAKENNUSALA,
          LaskunTyypit.EU_PALVELU,
          LaskunTyypit.EU_TAVARA,
          LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT,
          LaskunTyypit.MUU_MAAILMA
        ]
      })
    )

    // Alusta valuutat
    this.valuutatSubject.next(this._currencyService.annaValuutat())
  }

}
