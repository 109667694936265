import { Component, ChangeDetectionStrategy, ErrorHandler, OnInit, ChangeDetectorRef } from '@angular/core'
import { Timestamp } from '../../_shared-core/model/common'
import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'
import { DateService } from '../../_shared-core/service/date.service'
import { SharedFirebaseLemonaid } from '../base-firebase.service'

@Component({
  selector: '[app-browser-clock-check]',
  templateUrl: './browser-clock-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserClockCheckComponent implements OnInit {

  private _allowedSkew: number = 30 // Minutes
  show: boolean = false
  serverTime: Timestamp
  browserTime: Timestamp

  constructor(
    private _errorHandler: ErrorHandler,
    private _firebaseLemonaid: SharedFirebaseLemonaid,
    private _timestampService: TimestampProviderBase,
    private _changeDetectionRef: ChangeDetectorRef,
    private _dateService: DateService

  ) { }

  ngOnInit() {
    this.isTimeWrong().then(isWrong => {
      this.show = isWrong
      this._changeDetectionRef.markForCheck()
    })
  }

  async isTimeWrong(): Promise<boolean> {
    this.serverTime = await this.getServerTime()
    this.browserTime = this._timestampService.now()

    const diff = this.browserTime.toMillis() - this.serverTime.toMillis()
    const allowedSkewInMillis = this._allowedSkew * 60 * 1000
    const isWrong = diff > allowedSkewInMillis // We only care if the date is in the future. Time in the past does not affect tokens.
    if (isWrong) {
      this._errorHandler.handleError(new Error('Browser time is wrong: ' + this._dateService.muotoilePaivaJaAikaDate(this.browserTime.toDate(), 'fi') + '! Server time: ' + this._dateService.muotoilePaivaJaAikaDate(this.serverTime.toDate(), 'fi')))
    }
    return isWrong
  }

  private getServerTime(): Promise<Timestamp> {
    return this._firebaseLemonaid.databaseGetValue<number>('.info/serverTimeOffset').then(offset => {
      const estimatedServerTimeMs = new Date().getTime() + offset
      return this._timestampService.newTimestamp(Math.round(estimatedServerTimeMs / 1000), 0)
    }).catch(err => {
      console.error('Failure in server time query! ' + err?.toString())
      this._errorHandler.handleError(new Error('Failure in server time query! ' + err?.toString()))
      return this._timestampService.now()
    })
  }

}
