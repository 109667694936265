import { ErrorResponse } from '../../_shared-core/model/common'

export interface TarkistaAlvNumeroPyynto {
  maakoodi: string
  numero: string
}

export interface TarkistaAlvNumeroVastaus {
  tulos: AlvNumeronTarkistustulos
  virhe: 'INVALID_INPUT' | 'GLOBAL_MAX_CONCURRENT_REQ' | 'MS_MAX_CONCURRENT_REQ' | 'SERVICE_UNAVAILABLE' | 'MS_UNAVAILABLE' | 'TIMEOUT' | string
  yrityksenNimi: string
  yrityksenOsoite: string
}

// eslint-disable-next-line no-shadow
export enum AlvNumeronTarkistustulos {
  MAAKOODI_VIRHEELLINEN,
  NUMERO_VIRHEELLINEN,
  EU_PALVELIMEN_VIRHE,
  ONNISTUI
}

export interface AloitaSalasananPalautusProsessiPyynto {
  email: string
}

export interface AloitaSalasananPalautusProsessiVastaus extends ErrorResponse {

}

export interface PalautaSalasanaPyynto {
  oobCode: string
  newPassword: string
}

export interface PalautaSalasanaVastaus extends ErrorResponse {

}

export interface AloitaSalasananPalautusProsessiVastaus extends ErrorResponse {

}

export interface VaihdaSalasanaPyynto {
  vanhaSalasana: string
  uusiSalasana: string
}

// eslint-disable-next-line no-shadow
export enum VaihdaSalasanaVirhe {
  VANHA_SALASANA_PUUTTUU = 1,
  UUSI_SALASANA_PUUTTUU = 2,
  UUSI_SALASANA_LIIAN_LYHYT = 3,
  UUSI_SALASANA_LIIAN_VAHAN_YKSILOLLISIA_MERKKEJÄ = 4,
  UUSI_SALASANA_EI_SISALLA_NUMEROA = 5,
  UUSI_SALASANA_EI_SISALLA_PIENTA_KIRJAINTA = 6,
  UUSI_SALASANA_EI_SISALLA_SUURTA_KIRJAINTA = 7
}

export interface VaihdaSalasanaVastaus {
  virhekoodi?: string
  virheet: VaihdaSalasanaVirhe[]
}
