import { LocalDate } from '../../_shared-core/model/common'
import { DateService } from '../../_shared-core/service/date.service'

const DESIMAALIEROTIN_HAUSSA = '_d_'

export interface MahdollinenJarjestys { sorttaus: JarjestysTiedot, arvo: string | number | LocalDate }

export interface JarjestysTiedot {
  tunniste: string
  tyyppi: 'string' | 'number' | 'date'
}

export class FirestoreIndeksoija {

  constructor(
    private dateService: DateService
  ) { }

  public poistaValimerkit(input: string): string {
    // Remove all punctuation: https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex/25575009#25575009
    return (input || '').replace(/[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g, ' ') // Remove punctuation
  }

  public poistaValilyonnit(input: string): string {
    return input.replace(/\s+/g, '').toLowerCase()
  }

  public poistaValimerkitJaValilyonnit(input: string): string {
    return this.poistaValimerkit(input).replace(/\s+/g, '').toLowerCase() // Poista kaikki whitespace
  }

  public poistaValimerkitJaNormalisoiValilyonnit(input: string): string {
    return this.poistaValimerkit(input).replace(/\s+/g, ' ').toLowerCase() // Normalisoi kaikki whitespace
  }

  public poistaPilkunJalkeisetMerkityksettomatNollat(input: string): string {
    if (input.indexOf(',') > -1 || input.indexOf('.') > -1) {
      while (input.substr(input.length - 1) === '0') {
        input = input.slice(0, -1)
      }
    }
    return input
  }

  public annaSorttausvaihtoehdonArvo(sorttausvaihtoehto: MahdollinenJarjestys) {
    if (sorttausvaihtoehto.sorttaus.tyyppi === 'string') {
      const str = (sorttausvaihtoehto.arvo + '' || '').toLowerCase().replace(/\s+/g, '')
      const chars = [...str]
      return chars.slice(0, 30).join('')
      // NB: The stuff below will NOT work correctly with funky UTF-characters!
      // return (sorttausvaihtoehto.arvo + '' || '').toLowerCase().replace(/\s+/g, '').substring(0, 30)
    } else if (sorttausvaihtoehto.sorttaus.tyyppi === 'date' && sorttausvaihtoehto.arvo) {
      const localPvm = sorttausvaihtoehto.arvo as LocalDate
      const pvmAsDate = this.dateService.localDateToDate(localPvm)
      const epoch = new Date(2017, 9, 23, 1, 1, 1, 1)
      return this.dateService.paiviaValissa(pvmAsDate, epoch)
    }
    return sorttausvaihtoehto.arvo
  }

  public annaAikaLocal(localDate: LocalDate, kohde: string): string {
    return (localDate.year + '').substring(2) + kohde + (localDate.month - 1)
  }

  // public annaAika(date: Date, kohde: string): string {
  //   return date.getUTCFullYear().toString().substring(2) + kohde + date.getUTCMonth()
  // }

  public luoFirestorenVapaatekstihaku(haku: any, str: string, extraData: string[], mahdollisetSorttaukset: MahdollinenJarjestys[]) {
    this.luoFirestorenVapaatekstihakuIndeksointilevelilla(haku, str, extraData, mahdollisetSorttaukset, 2)
  }

  /**
   * Luo vapaasanahaun ja lisää sen hakuindeksiin
   * @param haku haku map
   * @param str indeksoitava merkkijono
   * @param extraData extra asiat, joiden mukaan voidaan lajitella
   * @param mahdollisetSorttaukset asiat, joiden mukaan voidaan sortata
   * @param indeksoitavanMerkkijononMinimipituus mikä on merkkijonon minimipituus, jotta se indeksoidaan
   */
  public luoFirestorenVapaatekstihakuIndeksointilevelilla(haku: any, str: string, extraData: string[], mahdollisetSorttaukset: MahdollinenJarjestys[], indeksoitavanMerkkijononMinimipituus: number) {
    // Index without anything, so we provide the listings when user has selected nothing
    for (const sorttausvaihtoehto of mahdollisetSorttaukset) {
      const arvo = this.annaSorttausvaihtoehdonArvo(sorttausvaihtoehto)
      for (const extra of extraData) {
        haku[sorttausvaihtoehto.sorttaus.tunniste + extra] = arvo
      }
      haku[sorttausvaihtoehto.sorttaus.tunniste] = arvo
    }

    // Index all words word by word
    let nykyinenSana = ''
    const stringToIndex = this.poistaValimerkitJaNormalisoiValilyonnit(str)
    for (const sana of stringToIndex.split(' ')) {
      for (const kirjain of sana) {
        nykyinenSana += kirjain
        if (nykyinenSana.length > indeksoitavanMerkkijononMinimipituus) {
          // haku[nykyinenSana] = true
          this.lisaaArvot(haku, nykyinenSana, extraData, mahdollisetSorttaukset)
        }
      }
      nykyinenSana = ''
    }

    // Index whole, punctuation stripped string
    nykyinenSana = ''
    const noPunctuationString = this.poistaValimerkitJaValilyonnit(str)
    for (const kirjain of noPunctuationString.substring(0, 30)) {
      nykyinenSana += kirjain
      if (nykyinenSana.length > indeksoitavanMerkkijononMinimipituus) {
        // haku[nykyinenSana] = true
        this.lisaaArvot(haku, nykyinenSana, extraData, mahdollisetSorttaukset)
      }
    }

  }

  public korvaaDesimaaliErotinHakuerottimella(val: string): string {
    return val.replace(',', DESIMAALIEROTIN_HAUSSA).replace('.', DESIMAALIEROTIN_HAUSSA)
  }

  public luoFirestorenVapaatekstihakuEksaktillaOsumalla(haku: any, str: string, extraData: string[], mahdollisetSorttaukset: MahdollinenJarjestys[]) {
    const stringToIndex = (str || '').toLowerCase().replace(/\s+/g, '')// Remove all space
    for (const sorttausvaihtoehto of mahdollisetSorttaukset) {
      const arvo = this.annaSorttausvaihtoehdonArvo(sorttausvaihtoehto)
      for (const extra of extraData) {
        haku[sorttausvaihtoehto.sorttaus.tunniste + extra + stringToIndex] = arvo
      }
      haku[sorttausvaihtoehto.sorttaus.tunniste + stringToIndex] = arvo
    }
  }

  private lisaaArvot(haku: any, nykyinenHaku: string, extraData: string[], mahdollisetSorttaukset: MahdollinenJarjestys[]) {
    for (const sorttausvaihtoehto of mahdollisetSorttaukset) {
      const arvo = this.annaSorttausvaihtoehdonArvo(sorttausvaihtoehto)
      for (const extra of extraData) {
        haku[sorttausvaihtoehto.sorttaus.tunniste + extra + nykyinenHaku] = arvo
      }
      haku[sorttausvaihtoehto.sorttaus.tunniste + nykyinenHaku] = arvo
    }
  }

}
