import { captureException } from '@sentry/browser'

import { Injectable } from '@angular/core'

import { TuettuKieli } from '../../_shared-core/model/common'
import { TranslationService } from '../../_jaettu/service/translation.service'

import { BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class LemonTranslationService {

  private currentLanguageSubject: BehaviorSubject<TuettuKieli> = new BehaviorSubject<TuettuKieli>('fi')
  currentLanguageObservable: Observable<TuettuKieli> = this.currentLanguageSubject.asObservable()

  constructor(
    private translationService: TranslationService
  ) { }

  public lokalisoi(avain: string, parametrit?: any): string {
    return this.translationService.lokalisoi(avain, this.currentLanguageSubject.value, parametrit)
  }

  public lokalisoiKielella(avain: string, kieli: TuettuKieli, parametrit?: any): string {
    return this.translationService.lokalisoi(avain, kieli, parametrit)
  }

  public get nykyinenKieli(): TuettuKieli {
    return this.currentLanguageSubject.value
  }

  public vaihdaKieli(kieli: TuettuKieli) {
    if (kieli && this.currentLanguageSubject.value !== kieli) {
      this.currentLanguageSubject.next(kieli)
    }
  }
  getSupportedLocale(suspect: string): TuettuKieli {
    if (suspect === 'fi') {
      return 'fi'
    }
    if (suspect === 'en') {
      return 'en'
    }
    // if (suspect === 'sv') {
    //   return 'sv'
    // }
    captureException(new Error(`No support for locale ${suspect}`))
    return 'fi'
  }

}
