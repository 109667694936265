import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'

@Injectable()
export class VasenValikkoService {

  private aukiSubject = new BehaviorSubject<boolean>(true)
  aukiObservable = this.aukiSubject.asObservable()

  private piilotaValikonAvausJaSulkemisnappiSubject = new BehaviorSubject<boolean>(false)
  piilotaValikonAvausJaSulkemisnappiObservable = this.piilotaValikonAvausJaSulkemisnappiSubject.asObservable()

  private globaaliAukiSubject = new BehaviorSubject<boolean>(true)
  globaaliAukiObservable = this.globaaliAukiSubject.asObservable()

  public paivitaAuki(auki: boolean) {
    if (this.aukiSubject.value !== auki) {
      this.aukiSubject.next(auki)
    }
  }

  public piilotaAvausJaSulkemisnappi(piilotettu: boolean) {
    if (this.piilotaValikonAvausJaSulkemisnappiSubject.value !== piilotettu) {
      this.piilotaValikonAvausJaSulkemisnappiSubject.next(piilotettu)
    }
  }

  public paivitaGlobaaliAuki(auki: boolean) {
    if (this.globaaliAukiSubject.value !== auki) {
      this.globaaliAukiSubject.next(auki)
    }
  }

  public annaAlkuperainenTila(vasemmanValikonVaihtuminen: number): 'vasen' | 'oikea' {

    // TODO TÄMÄ PITÄÄ TUNNISTAA MYÖS!!!
    // laskutus/laskut/:id/:kasiteltavaAvain

    // Router ei ole vielä initialisoitu tässä vaiheessa.
    if (
      window.location.href.indexOf('/kirjaudu') > -1 ||
      window.location.href.indexOf('/unohdin') > -1 ||
      window.location.href.indexOf('/vaihda') > -1 ||
      window.location.href.indexOf('/palauta') > -1 ||
      window.location.href.indexOf('/eiasetuksia') > -1 ||
      window.location.href.indexOf('/eioikeuksia') > -1
    ) {
      return 'vasen'
    }
    return this.annaNaytonLeveys() > vasemmanValikonVaihtuminen && this.aukiSubject.value ? 'oikea' : 'vasen'
  }

  private annaNaytonLeveys(): number {
    // const x = window.innerWidth  || document.documentElement.clientWidth  || document.getElementsByTagName('body')[0].clientWidth,
    //       y = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight
    // console.log(x + ' × ' + y)
    return window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
  }

}
