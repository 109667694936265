import { DateService } from '../../_shared-core/service/date.service'
import { LocalDate } from '../../_shared-core/model/common'
import { Asiakas, AsiakkaanKirjanpidonPeruste, KirjanpidonPeruste, LemonaidAsiakkaanSopimuskausi } from '../model/kayttaja'
import { Laskuasetukset } from '../model/lasku'

type AsiakasType = Pick<Asiakas, 'kirjanpidonPerusteet'> & Partial<Pick<Asiakas, 'asiakasAvain'> & Pick<{ avain: string }, 'avain'>>
export class AsiakasJaettuLemonaidService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _TAUKO_GRACE_PERIOD = 7 // days

  constructor(
    protected dateService: DateService
  ) { }

  public annaKirjanpidonPeruste(asiakas: AsiakasType, date: LocalDate): KirjanpidonPeruste {
    if (asiakas.kirjanpidonPerusteet === undefined || asiakas.kirjanpidonPerusteet === null) {
      return KirjanpidonPeruste.MAKSU
    }
    for (const peruste of asiakas.kirjanpidonPerusteet) {
      if (
        (!peruste.alkaa || this.dateService.compareLocalDates(peruste.alkaa, '<=', date)) &&
        (!peruste.loppuu || this.dateService.compareLocalDates(date, '<=', peruste.loppuu))
      ) {
        return peruste.peruste
      }
    }
    throw new Error('Kirjanpidon perustetta ei löytynyt asiakkaalle ' + (asiakas.avain ?? asiakas.asiakasAvain))
  }

  public annaKirjanpidonPerusteObject(asiakas: AsiakasType, date: LocalDate): AsiakkaanKirjanpidonPeruste {
    if (asiakas.kirjanpidonPerusteet === undefined || asiakas.kirjanpidonPerusteet === null) {
      return null
    }
    for (const peruste of asiakas.kirjanpidonPerusteet) {
      if (
        (!peruste.alkaa || this.dateService.compareLocalDates(peruste.alkaa, '<=', date)) &&
        (!peruste.loppuu || this.dateService.compareLocalDates(date, '<=', peruste.loppuu))
      ) {
        return peruste
      }
    }
    throw new Error('Kirjanpidon perustetta ei löytynyt asiakkaalle ' + (asiakas.avain ?? asiakas.asiakasAvain))
  }

  public onkoKirjanpidonPerusteOllutVoimassaAikavalilla(asiakas: AsiakasType, alkaa: LocalDate, loppuu: LocalDate, peruste: KirjanpidonPeruste): boolean {
    if (asiakas.kirjanpidonPerusteet === undefined || asiakas.kirjanpidonPerusteet === null) {
      return peruste === KirjanpidonPeruste.MAKSU
    }
    for (const p of asiakas.kirjanpidonPerusteet) {
      if (
        peruste === p.peruste &&
        // See https://stackoverflow.com/a/325964/7740038 for an explanation
        (!p.loppuu || this.dateService.compareLocalDates(alkaa, '<=', p.loppuu)) &&
        (!p.alkaa || this.dateService.compareLocalDates(loppuu, '>=', p.alkaa))
      ) {
        return true
      }
    }
    return false
  }

  annaKausiPaivamaaralle(kaudet: LemonaidAsiakkaanSopimuskausi[], pvm: LocalDate): LemonaidAsiakkaanSopimuskausi {
    if (!kaudet) { return null }
    for (const kausi of kaudet) {
      if (kausi.loppuu) {
        if (
          this.dateService.compareLocalDates(kausi.alkaa, '<=', pvm) &&
          this.dateService.compareLocalDates(kausi.loppuu, '>=', pvm)
        ) {
          return kausi
        }
      } else if (this.dateService.compareLocalDates(kausi.alkaa, '<=', pvm)) {
        return kausi
      }
    }
    return null
  }

  public onkoSopimuskausiTauolla(sopimuskausi: LemonaidAsiakkaanSopimuskausi, localDate: LocalDate): boolean {
    if (sopimuskausi && sopimuskausi.tauot) {
      for (const tauko of sopimuskausi.tauot) {

        if (
          tauko.tyyppi === 'tauko' &&
          this.dateService.onkoLocalDateKahdenValissa(localDate, tauko.alkaa, (tauko.loppuu ?? localDate))
        ) {
          return true
        }
      }
    }
    return false
  }

  public onkoSopimuskausiPoytalaatikossa(sopimuskausi: LemonaidAsiakkaanSopimuskausi, localDate: LocalDate): boolean {
    if (sopimuskausi && sopimuskausi.tauot) {
      for (const tauko of sopimuskausi.tauot) {

        if (
          tauko.tyyppi === 'poytalaatikko' &&
          this.dateService.onkoLocalDateKahdenValissa(localDate, tauko.alkaa, (tauko.loppuu ?? localDate))
        ) {
          return true
        }
      }
    }
    return false
  }

  public annaVoimassaOlevaReskontranTyyppi(asiakas: AsiakasType, laskuasetukset: Laskuasetukset): 'laskuperusteinen' | 'maksuperusteinen' | null {
    const currentDate = this.dateService.currentLocalDate()
    const peruste = this.annaKirjanpidonPerusteObject(asiakas, currentDate)

    if (peruste?.peruste === KirjanpidonPeruste.LASKU &&
      peruste.laskuperusteinenReskontraAlkaen &&
      this.dateService.compareLocalDates(peruste.laskuperusteinenReskontraAlkaen, '>=', currentDate)
    ) {
      return 'laskuperusteinen'
    }

    if (laskuasetukset?.maksupReskontraActive) {
      return 'maksuperusteinen'
    }

    return null
  }

}
