export class BankConsentUriService {
  getBankConsentCollectionName() {
    return 'bank-consent'
  }
  getBankConsentUri(asiakasAvain: string): string {
    return 'customers/' + asiakasAvain + '/bank-consent/' + asiakasAvain
  }
  getBankConsentLogsCollection(asiakasAvain: string): string {
    return 'customers/' + asiakasAvain + '/bank-consent-logs/'
  }
  getBankConsentLogUri(asiakasAvain: string, logAvain: string): string {
    return this.getBankConsentLogsCollection(asiakasAvain) + logAvain
  }
  getSmsTiedotteenSendReminderTyojonoUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/pankkiyhteys-sms/' + tyojonoAvain
  }
}
