import { VatNumberValidationCountrySettings } from '../../model/vat-number-validation'

export const slovakiaRepublic: VatNumberValidationCountrySettings = {
  name: 'Slovakia Republic',
  code: 'SK',
  calcFn: (vat: string): boolean => {
    const expect = 0
    const checkDigit = Number(vat) % 11
    return checkDigit === expect
  },
  rules: {
    multipliers: {},
    regex: [/^(SK)([1-9]\d[2346-9]\d{7})$/]
  }
}
