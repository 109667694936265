<button mat-icon-button type="button" (click)="downloadPdf()">
  <div class="pdf-btn">
    <div>PDF</div>
    <mat-icon style="font-size: 14px; height: 15px; line-height: 15px;">save_alt</mat-icon>
  </div>
</button>

<ng-container *ngIf="liiteJaLukitustiedotObservable | async; let data else loading">

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Käyttäjän tallentamat tiedot: <a [href]="liitetiedotEncodedUriObservable | async" target="_BLANK">{{liitetiedotUriObservable | async}}</a></div>
  </div>

  <div *ngIf="data.lukittu" style="padding: 3em; font-weight: bold;">
    Tilinpäätös on rekisteröity. Tietoja ei voi enää muokata.
  </div>

  <form #liitetiedotForm="ngForm">

    <div style="margin-left: 10px;">

      <ng-container *ngIf="{ onkoMikro: (onMikroYritysObservable | async)}  as yritys">

        <h1 style="margin-top: 40px;">Liitetiedot</h1>

        <h2 class="otsikko">
          {{'PMA1_1_5.title' | translatetilinpaatos: 2021 | async }}
          <!-- Tietoa tilinpäätöksen laatimissäännöksestä (PMA 1:1.5) -->
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>

        <div style="margin: 15px 0;">{{ (yritys.onkoMikro ? 'PMA1_1_5.mikroyritys': 'PMA1_1_5.pienyritys') | translatetilinpaatos: 2021 | async }}</div>

        <h2 class="otsikko">
          {{'PMA3_1.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="margin: 15px 0; font-size: 120%; font-weight: bold;">{{'PMA3_1.noudatetut-arvostusperiaatteet' | translatetilinpaatos: 2021 | async }}</div>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_1" [order]="1" [info]="'Vaihto-omaisuuden käyttöjärjestys on lähtökohtaisesti FIFO (first in first out) -periaatteen mukainen. Jos siitä poiketaan, pitää antaa liitetieto.'"
            [example]="'Esim. Vaihto-omaisuuden käyttöjärjestys on LIFO-periaatteen mukainen.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_2" [order]="2" [info]="'<b>KPL 5 luku 5 a §</b><br/><br/>
        Aineettomaan omaisuuteen kuuluvien vastikkeellisesti hankittujen toimilupien, patenttien, lisenssien ja tavaramerkkien sekä muiden vastaavien oikeuksien hankintameno on aktivoitava, jos oikeuden arvioidaan tuottavan tuloa useampana tilikautena.<br/><br/>
        Jos kirjanpitovelvollinen on itse luonut 1 momentissa tarkoitetun aineettoman oikeuden, sen hankintameno saadaan aktivoida varovaisuutta noudattaen edellyttäen, että oikeuden arvioidaan tuottavan tuloa useampana tilikautena.<br/><br/>
        Edellä 1 ja 2 momentissa tarkoitettu aktivoitu hankintameno on poistettava suunnitelman mukaan vaikutusaikanaan.'" [example]="'Esim. Aineettomiin oikeuksiin aktivoitu franchising sopimusmaksu poistetaan vaikutusaikanaan tasapoistoin 5 vuodessa.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_3" [order]="3" [info]="'<b>KPL 5 luku</b><br/><br/>
        <b>8 §<br/>
        Tutkimus- ja kehittämismenojen jaksottaminen</b>
        Tutkimusmenot kirjataan tilikauden kuluksi.<br/><br/>
        Kehittämismenot saadaan aktivoida, jos niiden odotetaan tuottavan tuloa useampana tilikautena. Aktivoidut kehittämismenot on poistettava suunnitelman mukaan vaikutusaikanaan. Jollei kirjanpitovelvollinen voi luotettavalla tavalla arvioida vaikutusaikaa, on kehittämismenot poistettava enintään kymmenessä vuodessa. Kehittämismenoja ei saada aktivoida muina pitkävaikutteisina menoina.<br/><br/>
        Jos kehittämismenoja on aktivoitu, ei aktivoinnista vielä kuluksi kirjaamatonta määrää saada jakaa tilikauden tuloksesta, voittovaroista tai muista jakokelpoisista rahastoista kirjanpitovelvollisessa, joka on:<br/>
        <ol>
          <li>osakeyhtiö tai muussa laissa osakeyhtiölain noudattamiseen velvoitettu yhteisö;</li>
          <li>sellainen avoin yhtiö tai kommandiittiyhtiö, jonka kaikki vastuunalaiset yhtiömiehet ovat osakeyhtiöitä; tai</li>
          <li>osuuskunta tai muussa laissa osuuskuntalain noudattamiseen velvoitettu yhteisö.</li>
        </ol><br/><br/>
        <b>9 §<br/>
        Liikearvon hankintamenon jaksottaminen</b><br/><br/>
        Liikearvon hankintameno saadaan aktivoida. Aktivoitu liikearvo on poistettava suunnitelman mukaan vaikutusaikanaan. Jollei kirjanpitovelvollinen voi luotettavalla tavalla arvioida vaikutusaikaa, on liikearvo poistettava enintään kymmenessä vuodessa.<br/><br/>
        <b>11 §<br/>
        Muiden pitkävaikutteisten menojen jaksottaminen</b><br/><br/>
        Jos muita kuin 7-10 §:ssä tarkoitettuja pitkävaikutteisia menoja on aktivoitu, ne on poistettava suunnitelman mukaan vaikutusaikanaan. Jollei kirjanpitovelvollinen voi luotettavalla tavalla arvioida vaikutusaikaa, on meno poistettava enintään kymmenessä vuodessa.'" [example]="'Esim. Kehittämismenoja on aktivoitu KPL 5:8 §:n mukaisesti liittyen yhtiön uuden mobiilisovelluksen kehittämiseen. Kehittämismenot on aktivoitu vain niiden hankkeiden osalta, joissa kehittämismenojen taloudellisen vaikutusajan on arvioitu olevan vähintään kolme vuotta. Kehittämismenot poistetaan arvioituna taloudellisena vaikutusaikana tasapoistoin. Uuden mobiilisovelluksen osalta näin on määritelty poistoajaksi 5 vuotta.
<br/><br />
Liikearvona on aktivoitu KPL 5:9 §:n mukaisesti liiketoimintakaupasta vuonna 2019 syntynyt 50 000 euron liikearvo. Liikearvo perustuu hankitun liiketoiminnan tuottoarvoon. Liikearvo poistetaan arvioituna taloudellisena vaikutusaikanaan tasapoistoin 5 vuodessa.
<br /><br />
Muina pitkävaikutteisina menoina on aktivoitu vuonna 2018 tehdyistä tuotantotiloina käytettävän vuokrahuoneiston muutostöistä aiheutuneet perusparannusmenot, jotka poistetaan arvioituna taloudellisena vaikutusaikanaan tasapoistoin 5 vuodessa.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_4" [order]="4" [info]="'Kirjanpitolautakunnan yleisohje Tulon kirjaaminen tuotoksi valmistusasteen perusteella:

<a href=&quot;https://kirjanpitolautakunta.fi/-/tulon-kirjaaminen-tuotoksi-valmistusasteen-perusteella&quot; target=&quot;_blank&quot;>https://kirjanpitolautakunta.fi/-/tulon-kirjaaminen-tuotoksi-valmistusasteen-perusteella</a>'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_5" [order]="5" [info]="'Rahoitusvälineitä ja sijotuskiinteistöjä voidaan merkitä kirjanpitolain mukaisessa tilinpäätöksessä käypään arvoonsa noudattaen IFRS-standardien mukaista menettelyä.'"
            [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_A" [field]="kentat.PMA3_1_A_6" [order]="6" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
        </div>

        <div style="margin: 15px 0; font-size: 120%; font-weight: bold;">Pysyviin vastaaviin kuuluvien hyödykkeiden arvostamisessa ja jaksottamisessa käytetyt menetelmät ja periaatteet:</div>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_1" [order]="1" [info]="'Kun hyödykkeen hankintaan ja valmistukseen liittyviä kiinteitä menoja ryhdytään lukemaan muuttuvien menojen lisäksi hankintamenoon, muutos hankintamenon määrittelyssä pienentää tilikauden kuluja tilinpäätöksessä aktivoitaviin hankintamenoihin sisältyvien kiinteiden menojen osalta. Siirtymisellä hankintamenon uuteen määrittelyyn voi olla olennainen vaikutus tuloslaskelman ja taseen toiminnan tuloksesta ja taloudellisesta asemasta antamaan kuvaan.
<br/><br />
Hyödykkeen hankintamenoon saadaan välittömien menojen lisäksi lukea kohtuullinen osuus hankinnan ja valmistuksen välillisistä menoista siltä osin kuin nämä kohdistuvat tuotantojaksoon. Nämä välilliset menot on voitava selvittää kustannuslaskelman tai -laskelmien avulla.
<br /><br />
Hyödykkeen tuotantoon kohdistettavissa olevan lainan korkomeno tuotantojaksolta saadaan lukea hankintamenoon.
<br /><br />
Korkomenot voidaan lukea hankintamenoon, vaikka välillisiä menoja ei olisikaan aktivoitu. Hankintamenoon luettavien korkomenojen tulee ensisijaisesti koostua hankkeen rahoittamiseen otetusta lainasta.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_2" [order]="2" [info]="'Verotuksessa hyväksyttävät enimmäispoistot 2021<br/><br/>
        <b>Irtain kuluva käyttöomaisuus</b>: Koneista, kalustosta ja muusta niihin verrattavasta irtaimesta käyttöomaisuudesta voi tehdä enintään <b>25 %:n poiston</b> menojäännöksestä.<br/><br/>
        <b>Rakennukset</b>: Myymälä-, tehdas-, varasto-, työpaja-, talous- ja muista näihin verrattavista rakennuksista voi tehdä enintään <b>7 %:n poiston</b>.<br/><br/>
        <b>Rakennukset</b>: toimisto-, asuin- ja muista näihin verrattavista rakennuksista <b>enimmäispoisto on 4 %</b>.<br/><br/>
        <b>Rakennukset ja rakennelmat</b>: Rakennuksista ja rakennelmista kuten säilöistä tai muista kevyistä rakennelmista tehtävä poisto on <b>20 %</b>.'" [example]="'Esim. Koneisiin ja kalustoon kirjatut tuotantolaitteet poistetaan 10% menojäännöspoistoin.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_3" [order]="3" [info]="'Arvonkorotus tehdään nimensä mukaisesti korottamaan pysyviin vastaaviin kirjatun maa-alueen tai arvopaperin kirjanpitoarvoa lähemmäksi sen käypää arvoa. Arvonkorotuksen vastakirjaus on omassa pääomassa arvonkorotusrahasto. Arvonkorotusta ei pidä kuitenkaan sotkea kirjanpitosäännöstön sisältämiin säännöksiin käypään arvoon kirjaamisesta. Yritysten tilinpäätöksissä voi olla näkyvissä myös arvonkorotuksia, jotka on tehty vanhan lainsäädännön mukaan rakennuksiin. Tällä hetkellä arvonkorotus on mahdollista vain maa-alueisiin ja tiettyihin arvopapereihin, rakennusten osalta uusia arvonkorotuksia ei enää voi tehdä. Koska taseessa oleva arvonkorotus voi olla peruja vuosien takaa, on mahdollista, että se voi jäädä vuosien saatossa vaihtuvien järjestelmien ja toimijoiden takia piiloon.<br /><br />
        <a href=&quot;https://tilisanomat.fi/kolumnit/lyhyesta-virsi-kaunis-kolumnit/arvonkorotukset&quot; target=&quot;_blank&quot;>https://tilisanomat.fi/kolumnit/lyhyesta-virsi-kaunis-kolumnit/arvonkorotukset</a>'"
            [example]="'Esim. Pysyvien vastaavien maa-alueisiin on kirjattu arvonkorotus yhtiön omistaman kiinteistön maapohjan osalta. Muihin omaisuuseriin ei ole tehty arvonkorotuksia eikä arvonkorotusmahdollisuuksia ole selvitetty.
<br/><br />
Arvonkorotus on tehty perustuen kahdelta asiantuntijalta saatuihin kirjallisiin arvioihin, joiden keskiarviosta on varovaisuuden periaatteella huomioitu 80%. Arvioiden perusteella maa-alueen todennäköinen luovutushinta tilinpäätöspäivänä on pysyvästi alkuperäistä hankintamenoa olennaisesti suurempi. Arviot on päivitetty tilinpäätösajankohdalle. Maa- ja vesialueisiin on kirjattu näin johdettu maapohjan käypä arvo vähennettynä todennäköisillä luovutuskuluilla ja laskennallisella luovutuksesta aiheutuvalla tuloverolla. Arvonkorotusta tarkistetaan tilikausittain arvioidun käyvän arvon muuttuessa.'"
            [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_4" [order]="4" [info]="'Liitetieto on annettava jos saatuja avustuksia ei olla kirjattu hankintamenoksi vaan ne on käsitelty siirtovelkana.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot"
            (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_5" [order]="5"
            [info]="'Lähtökohtaisesti vähäarvoiset hyödykkeet (á alle 1200€, yhteensä enintään 3600€) ja alle kolmen vuoden kalusto kirjataan kokonaan tilikauden kuluksi. Mikäli näitä menoja aktivoidaan taseeseen, tulee siitä mainita liitetiedoissa.'"
            [example]="'Esim. Yhtiön kannettavat tietokoneet, joiden todennäköinen käyttöikä on alle 3 vuotta, on aktivoitu taseeseen. Tilinpäätöshetkellä näin aktivoidun kaluston yhteisarvo oli 4 700 euroa.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_1_B" [field]="kentat.PMA3_1_B_6" [order]="6" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
          <div style="margin: 15px 0;">{{muiltaOsinTekstiObservable | async}}</div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_1}"></div>

        <h2 class="otsikko">
          {{'PMA3_2.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_2" [field]="kentat.PMA3_2_1"
            [info]="'Poikkeuksellisiin eriin kirjataan yleensä sellaisia eriä, jotka eivät liity varsinaiseen liiketoimintaan. Liitetieto tulee antaa, koska tällainen erä vääristää yhtiön liikevoittoa ja ilman lisätietoa tilinpäätöksen lukija ei saa oikeaa ja riittävää kuvaa yrityksen tuloksesta ja taloudellisesta asemasta.'"
            [example]="'Esim. Yhtiö on saanut tilikauden aikana Valtionkonttorin kustannustukea yhteensä 20 000 euroa, joka on kirjattu liiketoiminnan muihin tuottoihin.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_2}"></div>

        <h2 class="otsikko">
          {{'PMA3_3.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_3" [field]="kentat.PMA3_3_1"
            [info]="'Tilikauden jälkeiset olennaiset tapahtumat kohdassa tulisi ilmoittaa kaikki sellaiset olennaiset asiat, jotka ovat tiedossa tilinpäätöstä laadittaessa, eikä vain niitä asioita, jotka ovat tapahtuneet tilikauden viimeiseen päivään mennessä.'"
            [example]="'Esim. Yhtiö on tilikauden päättymisen jälkeen lakkauttanut yhden toimipisteensä kannattamattomuuden vuoksi. Toimipisteen osuus päättyneen tilikauden liikevaihdosta oli noin 5%.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_3}"></div>

        <h2 class="otsikko">
          {{'PMA3_4.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_4" [field]="kentat.PMA3_4_1"
            [info]="'Jos pysyviin vastaaviin kuuluva maa- tai vesialue tai arvopaperi (<span style=&quot;color: red;&quot;>ei rahoitusarvopaperi tai johdannaissopimus, muun päätelmä KPL 5 §2a</span>) on tilinpäätöspäivänä pysyvästi alkuperäistä hankitamenoa olennaisesti suurempi, saadaan taseeseen varovaisuutta noudattaen merkitä arvonkorotus. Korotus on suuruudeltaan poistamatta olevan hankitamenon ja todennäköisen luovutushinnan suuruinen erotus.'"
            [example]="'Esim.
<br /><br />
1) Arvonkorotusrahaston muutokset tilikauden aikana

<table style=&quot;margin-left: 25px;&quot;>
  <tr>
    <th>Kohde</th>
    <th>Alkusaldo</th>
    <th>Lisäys</th>
    <th>Vähennys</th>
    <th>Loppusaldo</th>
    <th>Verovaikutus/<br/>laskennallinen verovelka</th>
  </tr>
  <tr>
    <td>Sijoitukset/<br/>muut osakkeet ja osuudet</td>
    <td style=&quot;text-align:right;&quot;>0,00</td>
    <td style=&quot;text-align:right;&quot;>40 000,00</td>
    <td style=&quot;text-align:right;&quot;>0,00</td>
    <td style=&quot;text-align:right;&quot;>40 000,00</td>
    <td style=&quot;text-align:right;&quot;>8 000,00</td>
  </tr>
</table>
<br />
2) Selvitys arvonkorotusrahaston muutoksiin liittyvistä verovaikutuksista
<div style=&quot;margin-left: 25px;&quot;>
Taseeseen sisältyvä 40 000,00 euron suuruinen arvonkorotus ei vaikuta tilikauden verotettavaa tuloon. Arvonkorotuksessa on otettu huomioon todennäköisesti luovutushinnasta varovaisuuden periaatteella johdetun käyvän arvon ja alkuperäisen hankitamenon erotus väehnnettynä todennäköisillä luovutuskuluilla. Laskennallinen
voimassa olevan yhteisöverokannan mukainen verovelka on suuruudeltaan 8 000,00 euroa.
</div>
<br />
3) Arvonkorotuksen kohteiden yhteenlaskettu kirjanpitoarvo ennen arvonkorotusta
<div style=&quot;margin-left: 25px;&quot;>
Pysyvien vastaavien sijoitusten osakkeiden ja osuuksien sisältämien asuinhuoneiston hallintaan oikeuttavien osakkeiden kirjanpitoarvo on tilinpäätöksessä 243 000,00 euroa. Kirjanpitoarvo ennen arvonkorotusta oli 203 000,00 euroa.
</div>
'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_4}"></div>

        <h2 class="otsikko">
          {{'PMA3_6.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_6" [field]="kentat.PMA3_6_1" [info]="'Liitetietona on esitettävä yhteenlaskettu määrä sellaisista pitkäaikaiseen vieraaseen pääomaan kuuluvista veloista, jotka erääntyvät myöhemmin kuin viiden vuoden kuluttua.'"
            [example]="'Esim. Pitkäaikaiseen vieraaseen pääomaan sisältyy 15 000,00 euroa velkoja, jotka erääntyvät myöhemmin kuin viiden vuoden kuluttua.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_6}"></div>


        <h2 class="otsikko">
          {{'PMA3_7.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_7" [field]="kentat.PMA3_7_1" [order]="1" [unsaved]="data.tallentamattomat"
            [info]="'Yrityksen antamat vakuudet joko oman lainansa vakuutena tai esimerkiksi emoyhtiön/tytäryhtiön lainan vakuudeksi annettu pantti. Vakuuksia voi olla esim. käteispantti, kiinteistökiinnitys, ajoneuvokiinnitys tai yrityskiinnitys. Jos velka on pienempi kuin annetun vakuuden määrä, vakuus esitetään velan määrän mukaisena (eli valitaan pienempi).'" [example]="'Esim. Yhtiö on antanut vakuuksia seuraavasti:
<table style=&quot;margin-left:25px;margin-top:10px;&quot;>
  <tr>
    <td>yrityskiinnitys</td>
    <td>20 000,00€</td>
    <td>emoyhtiön puolesta</td>
  </tr>
  <tr>
    <td>käteispantti</td>
    <td>10 000,00€</td>
    <td>oman yhtiön puolesta</td>
  </tr>
  <tr>
    <td>Vuokravakuus</td>
    <td>5 000,00€</td>
    <td>oman yhtiön puolesta</td>
  </tr>
</table>
'" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_7" [field]="kentat.PMA3_7_2" [order]="2" [info]="'Taseen ulkopuolisia taloudellisisia sitoumuksia (vastuita) voi olla esim.
<ul>
  <li>määräaikaisen vuokrasopimuksen jäljellä oleva vuokravastuu</li>
  <li>leasingvuokran jäljellä oleva vuokrasumma sekä jäännösarvo</li>
  <li>vireillä olevan oikeudenkäynnin mahdollisesti tuottama korvausvastuu</li>
</ul>
'" [example]="'Esim. Taseen ulkopuolisten vastuiden yhteismäärä on 75 000 euroa.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_7" [field]="kentat.PMA3_7_3" [order]="3"
            [info]="'Mikäli kohdassa 2) on annettu vastuiden yhteismäärä, pitää antaa lisätiedot jos järjestelyistä aiheutuvat riskit ja hyödyt ovat olennaisia ja <b>jos</b> niitä koskeva tieto on välttämätön pienyrityksen taloudellisen aseman arvioimista varten.'" [example]="'Esim. Yllä esitettyyn taseen ulkopuolisten vastuiden yhteismäärään sisältyy:
<ul>
  <li>määräaikaisen vuokrasopimuksen jäljellä oleva vuokravastuu</li>
  <li>leasingvuokran avoin vuokravastuu sekä jäännösarvovastuu</li>
</ul>
'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_7" [field]="kentat.PMA3_7_4" [order]="4" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_7}"></div>

        <h2 class="otsikko">
          {{'PMA3_8.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_8" [field]="kentat.PMA3_8_1" [info]="'Liitetietona on esitettävä emoyrityksen nimi ja kotipaikka siitä konsernitilinpäätöksen kohteena olevasta konsernista, johon pienyritys välittömimmin kuuluu.'" [example]="'Esim: Emoyrityksen tiedot:
<table>
  <tr>
    <td>Nimi:</td>
    <td>Emoyritys Oy</td>
  </tr>
  <tr>
    <td>Kotipaikka:</td>
    <td>Helsinki</td>
  </tr>
</table>
'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_8}"></div>

        <h2 class="otsikko">
          {{'PMA3_9.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_9" [field]="kentat.PMA3_9_1" [info]="'Toimitusjohtajasta, hallituksen jäsenistä ja varajäsenistä sekä vastaaviin toimielimiin kuuluvista henkilöistä on ilmoitettava toimielinkohtaisena liitetietona seuraavat:
<ol>
  <li>Toimitusjohtajille ja hallintoelimiin kuuluville annetut lainat</li>
  <li>Intressitahojen puolesta annetut vakuudet ja vastuusitoumukset</li>
  <li>Eläkesitoumukset näistä tehtävistä</li>
</ol>
'" [example]="'Esim. Toimitusjohtajille ja hallintoelimiin kuuluville annetut lainat

<table style=&quot;margin-left:25px;margin-top:10px;&quot;>
  <tr>
    <th>Saaja</th>
    <th>Muutos</th>
    <th>Saldo</th>
    <th>Vakuus</th>
    <th>Laina-aika</th>
    <th>Korko</th>
  </tr>
  <tr>
    <td>toimitusjohtaja</td>
    <td style=&quot;text-align:right;&quot;>+5 000,00</td>
    <td style=&quot;text-align:right;&quot;>5 000,00</td>
    <td>osakepantti</td>
    <td>5 vuotta</td>
    <td>VM:n peruskorko +1%</td>
  </tr>
  <tr>
    <td>hallituksen varajäsen</td>
    <td style=&quot;text-align:right;&quot;>+1 000,00</td>
    <td style=&quot;text-align:right;&quot;>1 000,00</td>
    <td>esinepantti</td>
    <td>1 vuosi</td>
    <td>VM:n peruskorko</td>
  </tr>
</table>
<div style=&quot;margin-top:10px;&quot;>Intressitahojen puolesta annetut vakuudet ja vastuusitoumukset</div>
<table style=&quot;margin-left:25px;margin-top:10px;&quot;>
  <tr>
    <th>Edunsaaja</th>
    <th>Määrä</th>
    <th>Vakuuden / vastuusitoumuksen tyyppi</th>
  </tr>
  <tr>
    <td>toimitusjohtaja</td>
    <td style=&quot;text-align:right;&quot;>60 000,00</td>
    <td>yrityskiinnitys vakuutena rahoituslaitoslainalle</td>
  </tr>
</table>
'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_9}"></div>

        <h2 class="otsikko">
          {{'PMA3_10.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_10" [field]="kentat.PMA3_10_1" [info]="'Liitetietona on esitettävä tiedot pienyrityksen ja tämän intressitahoihin kuuluvien välisistä liiketoimista, jos ne ovat olennaisia eikä niitä ole toteutettu tavanomaisin kaupallisin ehdoin.

Intressitahoilla tarkoitetaan kirjanpitovelvollisen kanssa määräysvalta- tai osakkuusyrityssuhteessa olevaa oikeushenkilöä. Intressitahoiksi luetaan myös toimitusjohtaja, hallituksen varsinaiset ja varajäsenet sekä vastaaviin hallintoelimiin kuuluvat henkilöt.

Intressitahoon liittyvästä toimesta esitettäviin tietoihin tulee sisältyä:
<ol>
  <li>kuvaus liiketoimesta;</li>
  <li>liiketoimen arvo;</li>
  <li>intressisuhteen luonne; sekä</li>
  <li>muut yrityksen taloudellisen aseman arvioimisen kannalta välttämättömät tiedot liiketoimesta.</li>
</ol>'" [example]="'Esim: Tytäryritys Oy:lle on tilikaudella myyty Emoyritys Oy:n tavanomaiseen liiketoimintaan kuuluvia tuotteita ja palveluja. Tuotteiden ja palvelujen myynnissä on käytetty 365 päivän maksuaikaa, joka poikkeaa Emoyritys Oy:n tavanomaisesti käyttämästä 30 päivän maksuajasta. Tilikauden aikana tällaisia myyntisaamisia on kirjattu yhteensä 100 000,00 euroa, joista 20 000,00 euroa on avoimena taseessa. Tytäryritys Oy on Emoyritys Oy:n kokonaan omistama tytäryhtiö.

Huom! Yllä olevassa ei oteta kantaa siihen, ovatko kuvatut liiketoimet säännösten ja määräysten mukaisia tai muutoin hyväksyttäviä.'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_10}"></div>

        <h2 class="otsikko">
          {{'PMA3_11.title' | translatetilinpaatos: 2021 | async }}
          <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Henkilöstön lukumäärä lasketaan usein kokotyökuukausien mukaan.
Tärkeää on, että henkilöstön määrä lasketaan vuosittain saman kaavan mukaisesti, eikä laskentatapa vaihdu tilikausittain. Henkilöstön määrä vaikuttaa mm. tilintarkastusrajoihin, joten niiden suhteen kannattaa olla tarkkana ja epäselvissä tapauksissa pyytää asiakkaalta vahvistus.')">
            <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
          </button>
        </h2>
        <div style="padding: 0 15px;">
          <mat-checkbox [disabled]="data.lukittu" class="cb keskitetty-label" name="o5" [ngModel]="data.liitetiedot.valitut[kentat.PMA3_11_1]" (ngModelChange)="valintaMuuttui($event, kentat.PMA3_11_1)">
            {{'PMA3_11.keskimaarainen-lukumaara-start' | translatetilinpaatos: 2021 | async }}
            <mat-form-field style="width: 60px; height: 48px;">
              <input [disabled]="data.lukittu" #henkilostonMaaraInput class="skip-space-quick-toggle" decimalNumberField type="text" [numberOfDecimals]="0" [ngModel]="data.liitetiedot.numerot[kentat.PMA3_11_1]" (ngModelChange)="numeroMuuttui($event, kentat.PMA3_11_1)" (blur)="tallennaNumero(kentat.PMA3_11_1)" (appOnDisabled)="henkilostonMaaraInputDisabledChanged($event)"
                [disabled]="!data.liitetiedot.valitut[kentat.PMA3_11_1]" [name]="'a6' + namename" required matInput data-lpignore="true" />
            </mat-form-field>
            {{'PMA3_11.keskimaarainen-lukumaara-end' | translatetilinpaatos: 2021 | async }}
          </mat-checkbox><br />
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_11}"></div>

        <h2 class="otsikko">
          {{'PMA3_12.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="padding: 0 15px;">
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.PMA3_12" [field]="kentat.PMA3_12_1" [info]="'Liitetietona on esitettävä:
<ol>
  <li>tilikauden aikana tehtyjen omien osakkeiden tai osakkeita vastaavien oman pääoman ehtoisten osuuksien (omat osuudet) hankintojen perusteet;</li>
  <li>tilikauden aikana hankittujen tai luovutettujen omien osuuksien lukumäärä ja nimellisarvo tai, jos nimellisarvoa ei ole, kirjanpidollinen vasta-arvo sekä suhteellinen osuus, jota omat osuudet edustavat merkitystä omasta pääomasta;</li>
  <li>vastikkeellisissa hankinnoissa tai luovutuksissa omista osuuksista suoritettu vastike; sekä</li>
  <li>yrityksen hankkimien ja sen hallussa olevien kaikkien omien osuuksien lukumäärä ja nimellisarvo tai, jos nimellisarvoa ei ole, kirjanpidollinen vasta-arvo sekä suhteellinen osuus, jota omat osuudet edustavat merkitystä pääomasta.</li>
</ol>'" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()">
          </div>
        </div>

        <div *ngTemplateOutlet="lisarivitTemplate; context: {data: data, osio: osiot.PMA3_12}"></div>

        <h2 class="otsikko">
          {{ 'MUUT.title' | translatetilinpaatos: 2021 | async }}
          <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Vapaamuotoiset lisätiedot.')">
            <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
          </button>
        </h2>
        <div class="lisatiedot-status">
          <span *ngIf="lisatiedotSaveSuccessObservable | async">
            <span class="save-success-text" [@quickInSlowOut]="'in'" style=" color: gray;">
              Arvot tallennettiin
            </span>
            <mat-icon class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle'}">done</mat-icon>
          </span>
        </div>
        <div class="lisatiedot">
          <ng-container *ngFor="let lisatieto of data.liitetiedot.lisatietokentat; index as index;">
            <div *ngIf="!lisatieto.poistettu" class="lisatieto">
              <div class="lisatieto-otsikko">
                <textarea name="lisatieto{{index}}-otsikko" placeholder="Otsikko" cdkAutosizeMaxRows="1" [disabled]="data.lukittu" matInput style="width: 100%; border: 1px solid gray; padding: 5px; font-weight: bold;" (blur)="tallennaLisatiedot()" [ngModel]="lisatieto.otsikko" (ngModelChange)="lisatietoMuuttui($event, lisatieto, 'otsikko')" cdkTextareaAutosize></textarea>
                <button mat-icon-button style="vertical-align: top;" (click)="poistaLisatieto(lisatieto)">
                  <i class="icon ion-ios-trash icon-btn" style="font-size: 24px; color: gray;" aria-hidden="true"></i>
                </button>
              </div>
              <textarea name="lisatieto{{index}}-teksti" placeholder="Teksti" [disabled]="data.lukittu" matInput style="width: 100%; border: 1px dashed gray; padding: 5px;" (blur)="tallennaLisatiedot()" [ngModel]="lisatieto.teksti" (ngModelChange)="lisatietoMuuttui($event, lisatieto, 'teksti')" cdkTextareaAutosize></textarea>
            </div>
          </ng-container>
          <button mat-button (click)="lisaaLisatieto()" class="add-btn">
            <mat-icon>add</mat-icon> Lisää lisätieto
          </button>
        </div>

        <h2 class="otsikko">
          {{'PMA4_4_2.title' | translatetilinpaatos: 2021 | async }}
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
        </h2>
        <div style="margin: 15px 0; font-size: 120%; font-weight: bold;">{{'oman-paaoman-muutokset' | translatetilinpaatos: 2021 | async }}</div>

        <div style="padding: 0 15px;">

          <div style="background-color: #F2F2F2; padding: 15px;">
            <div *ngIf="data.liitetiedot.vertailutiedot !== 'aikaisemmasta'">
              <label [class.mat-form-field-invalid]="vertailutietojaeiloytynyt?.touched && vertailutietojaeiloytynyt?.errors?.required" style="margin-bottom: 5px; display: block;" id="ei-edellisia-vertailuarvoja-radio-group-label">Edellisen tilikauden vertailutietoja ei löytynyt. Valitse:</label>
              <mat-radio-group [disabled]="data.lukittu" #vertailutietojaeiloytynyt="ngModel" aria-labelledby="ei-edellisia-vertailuarvoja-radio-group-label" name="o7" required [ngModel]="data.liitetiedot.vertailutiedot" (ngModelChange)="eiVertailuarvojaArvoMuuttui($event)">
                <mat-radio-button [value]="'ensimmainen-tilikausi'">{{'PMA4_4_2.yhtion-ensimmainen' | translatetilinpaatos: 2021 | async }}</mat-radio-button><br />
                <mat-radio-button [value]="'syotetaan-kasin'">{{'PMA4_4_2.on aiempia' | translatetilinpaatos: 2021 | async }}</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="vertailutietojaeiloytynyt?.touched && vertailutietojaeiloytynyt?.errors?.required">Tieto vertailutiedoista puuttuu.</mat-error>
            </div>
            <ng-container *ngIf="data.liitetiedot.vertailutiedot">
              <div *ngIf="data.liitetiedot.vertailutiedot === 'syotetaan-kasin'" style="padding-left: 3.5em; padding-top: 2em; padding-bottom: 2em;">
                <table class="laskelmat-table edit-table">
                  <thead>
                    <tr>
                      <th style="width: unset; text-align: left;">Syötä edellisen kauden vertailutiedot käsin</th>
                      <!-- <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.header}}</th> -->
                      <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{'osakepaaoma-tilikauden-alussa' | translatetilinpaatos: 2021 | async }}</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenAlussa | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenAlussa" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenAlussa = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()"
                            [name]="'a8' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Osakeanti</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakeanti | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakeanti" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.osakeanti = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()" [name]="'a9' + namename" matInput
                            data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <!-- <tr>
                                <td>Osakepääoma tilikauden lopussa</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenLopussa | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenLopussa | number:'1.2-2'}}</td>
                              </tr>
                              <tr>
                                <td>Sidottu oma pääoma</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.sidottuOmaPaaoma | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.sidottuOmaPaaoma | number:'1.2-2'}}</td>
                              </tr> -->
                    <tr>
                      <td>Sijoitetun vapaan oman pääoman rahasto tilikauden alussa</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenAlussa | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenAlussa" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenAlussa = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()" [name]="'a10' + namename"
                            matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Sijoitetun vapaan oman pääoman rahaston lisäykset tilikaudella</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopLisayksetTilikaudenAikana | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopLisayksetTilikaudenAikana" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.svopLisayksetTilikaudenAikana = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()"
                            [name]="'a11' + namename" matInput data-lpignore="true" />
                        </mat-form-field>

                      </td>
                    </tr>
                    <tr>
                      <td>Sijoitetun vapaan oman pääoman rahaston vähennykset tilikaudella</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopLisayksetTilikaudenAikana | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input matInput [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopVahennyksetTilikaudenAikana" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.svopVahennyksetTilikaudenAikana = $event; vertailuarvoMuuttui()"
                            (blur)="tallennaVertailuarvot()" [name]="'a99' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <!-- <tr>
                                <td>Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenLopussa | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenLopussa | number:'1.2-2'}}</td>
                              </tr> -->
                    <tr>
                      <td>Edellisten tilikausien voitto (tappio) tilikauden alussa</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa"
                            (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()" [name]="'a12' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Osingonjako tilikaudella</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osingonjakoTilikaudella | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osingonjakoTilikaudella" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.osingonjakoTilikaudella = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()"
                            [name]="'a13' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>Tilikaudella hankitut omat osakkeet</td>
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tiikaudellaOstetutOmatOsakkeet" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.tiikaudellaOstetutOmatOsakkeet = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()" [name]="'a14' + namename" matInput
                            data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Tilikaudella luovutetut omat osakkeet</td>
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudellaMyydytOmatOsakkeet" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.tilikaudellaMyydytOmatOsakkeet = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()" [name]="'a15' + namename" matInput
                            data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr> -->
                    <!-- <tr>
                                <td>Edellisten tilikausien voitto (tappio) tilikauden lopussa</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa | number:'1.2-2'}}</td>
                              </tr> -->
                    <tr>
                      <td>Tilikauden voitto (tappio)</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudenVoittoTaiTappio" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.tilikaudenVoittoTaiTappio = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()"
                            [name]="'a16' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <!-- <tr>
                                <td>Vapaa oma pääoma</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.vapaaOmaPaaoma | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.vapaaOmaPaaoma | number:'1.2-2'}}</td>
                              </tr>
                              <tr>
                                <td>Oma pääoma yhteensä</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaYhteensa | number:'1.2-2'}}</td>
                                <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.omaPaaomaYhteensa | number:'1.2-2'}}</td>
                              </tr> -->
                    <tr>
                      <td>Oma pääoma + pääomalainat</td>
                      <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaPlusPaaomalainat | number:'1.2-2'}}</td> -->
                      <td>
                        <mat-form-field>
                          <input [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.omaPaaomaPlusPaaomalainat" (ngModelChange)="data.tallentamattomat.omanPaaomanMuutoksetVertailulaskelma.omaPaaomaPlusPaaomalainat = $event; vertailuarvoMuuttui()" (blur)="tallennaVertailuarvot()"
                            [name]="'a17' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="margin: 0px 15px; font-weight: bold; margin-top: 20px;">{{ 'tilikauden-aikana-tapahtuneet.title' | translatetilinpaatos: 2021 | async }}</div>
              <div style="padding: 15px 30px 20px 30px;">
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.OSAKEANTI" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.SVOP_LISAYKSET" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.SVOP_VAHENNYKSET" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.OSINKOJEN_MAARA" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()" example="Jaettujen osinkojen bruttomäärä"></div>
                <div app-kirjanpito-liitetiedot-multi-input-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [checkboxField]="kentat.YHTION_OSAKKEIDEN_LUNASTUKSET_TAI_LUOVUTUKSET" [fields]="[
                    {field: kentat.YHTIO_ON_OSTANUT_OSAKKEITAAN, type: 'number', example:'Lunastetuista osakkeista maksettu määrä' }, 
                    {field: kentat.YHTIO_ON_MYYNYT_OSAKKEITAAN, type: 'number', example: 'Luovutetuista osakkeista maksettu määrä'}
                  ]" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()" example="Oikaisujen yhteismäärä">
                </div>
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.EDELLISEN_TILIKAUDEN_OIKAISUT" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()" example="Oikaisujen yhteismäärä"></div>

                <!-- <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.YHTIO_ON_MYYNYT_OSAKKEITAAN" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div>
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.YHTIO_ON_OSTANUT_OSAKKEITAAN" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()"></div> -->
                <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" type="number" [parent]="osiot.TILIKAUDEN_AIKANA_TAPAHTUNEET" [field]="kentat.YHTIOLLA_PAAOMALAINAA" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" (checkboxChanged)="checkboxChanged()" example="Pääomalaina yhteensä"></div>
              </div>
            </ng-container>
          </div>

        </div>

        <ng-container *ngIf="data.liitetiedot.vertailutiedot">

          <div style="padding: 0 15px;">
            <table class="laskelmat-table" [class.one-column]="data.liitetiedot.vertailutiedot === 'ensimmainen-tilikausi'">
              <thead>
                <tr>
                  <th style="width: unset;"></th>
                  <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.header}}</th>
                  <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.header}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenAlussaNakyvissa">
                  <td>{{'osakepaaoma-tilikauden-alussa' | translatetilinpaatos: 2021 | async }}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakeantiNakyvissa">
                  <td>Osakeanti</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakeanti || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakeanti || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenLopussaNakyvissa">
                  <td>Osakepääoma tilikauden lopussa</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.osakepaaomaTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osakepaaomaTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.sidottuOmaPaaomaNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.sidottuOmaPaaomaNakyvissa">
                  <td>Sidottu oma pääoma</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.sidottuOmaPaaoma || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.sidottuOmaPaaoma || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenAlussaNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopLisayksetTilikaudenAikanaNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopVahennyksetTilikaudenAikanaNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenAlussaNakyvissa">
                  <td>Sijoitetun vapaan oman pääoman rahasto tilikauden alussa</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopLisayksetTilikaudenAikanaNakyvissa">
                  <td>Lisäykset tilikaudella</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopLisayksetTilikaudenAikana || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopLisayksetTilikaudenAikana || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopVahennyksetTilikaudenAikanaNakyvissa">
                  <td>Vähennykset tilikaudella</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopVahennyksetTilikaudenAikana || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopVahennyksetTilikaudenAikana || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenLopussaNakyvissa">
                  <td>Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr
                  *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.osingonjakoTilikaudellaNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVirheidenOikaisutNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaOstetutOmatOsakkeetNakyvissa || data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaMyydytOmatOsakkeetNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa">
                  <td>Edellisten tilikausien voitto (tappio) tilikauden alussa</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenAlussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.osingonjakoTilikaudellaNakyvissa">
                  <td>Osingonjako tilikaudella</td>
                  <td>{{0 - (data.liitetiedot.omanPaaomanMuutoksetLaskelma.osingonjakoTilikaudella || 0) | number:'1.2-2'}}</td>
                  <td>{{0 - (data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.osingonjakoTilikaudella || 0) | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVirheidenOikaisutNakyvissa">
                  <td>Edellisten tilikausien virheiden oikaisut</td>
                  <td>{{(data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVirheidenOikaisut || 0) | number:'1.2-2'}}</td>
                  <td>{{(data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVirheidenOikaisut || 0) | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaOstetutOmatOsakkeetNakyvissa">
                  <td>Omien osakkeiden lunastukset</td>
                  <td>{{0 - (data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaOstetutOmatOsakkeet || 0) | number:'1.2-2'}}</td>
                  <td> {{ 0 - (data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudellaOstetutOmatOsakkeet || 0 )| number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaMyydytOmatOsakkeetNakyvissa">
                  <td>Omien osakkeiden luovutukset</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudellaMyydytOmatOsakkeet || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudellaMyydytOmatOsakkeet || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa">
                  <td>Edellisten tilikausien voitto (tappio) tilikauden lopussa</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappioNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappioNakyvissa">
                  <td>Tilikauden voitto (tappio)</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudenVoittoTaiTappio || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.vapaaOmaPaaomaNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.vapaaOmaPaaomaNakyvissa">
                  <td>Vapaa oma pääoma</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.vapaaOmaPaaoma || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.vapaaOmaPaaoma || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaYhteensaNakyvissa">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaYhteensaNakyvissa">
                  <td>Oma pääoma yhteensä</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaYhteensa || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.omaPaaomaYhteensa || 0 | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="data.liitetiedot.valitut[kentat.YHTIOLLA_PAAOMALAINAA]">
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="data.liitetiedot.valitut[kentat.YHTIOLLA_PAAOMALAINAA]">
                  <td>Oma pääoma + pääomalainat</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaPlusPaaomalainat || 0 | number:'1.2-2'}}</td>
                  <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.omaPaaomaPlusPaaomalainat || 0 | number:'1.2-2'}}</td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.omaPaaomaEiTasmaa; else tasmaa" class="error-text" style="padding: 15px; font-size: 120%; text-align: end;">
              Oma pääoma ei täsmää taseen kanssa!
            </div>
            <ng-template #tasmaa>
              <div style="color: #75D377; padding: 15px; font-size: 120%; line-height: 120%; text-align: end;">
                <i class="fa fa-check" aria-hidden="true"></i> Oma pääoma täsmää
              </div>
            </ng-template>

            <ng-container *ngIf="data.liitetiedot.valitut[kentat.YHTIOLLA_PAAOMALAINAA]">
              <div [class.mat-form-field-invalid]="paaomalainojenpaasialliset?.touched && paaomalainojenpaasialliset?.errors?.required" style="margin: 15px 0; font-size: 120%; font-weight: bold;">Pääomalainojen pääasialliset lainaehdot ja lainoille kertynyt kuluksi kirjaamaton korko *</div>
              <div style="padding: 0 15px;">
                <textarea #paaomalainojenpaasialliset="ngModel" required matInput style="width: 100%; border: 1px dashed gray; padding: 3px;" name="o18" [ngModel]="data.liitetiedot.tekstit[kentat.YHTIOLLA_PAAOMALAINAA]" (ngModelChange)="tekstiMuuttui($event, kentat.YHTIOLLA_PAAOMALAINAA)" (blur)="tallennaTeksti(kentat.YHTIOLLA_PAAOMALAINAA)" cdkTextareaAutosize></textarea>
                <mat-error *ngIf="paaomalainojenpaasialliset?.touched && paaomalainojenpaasialliset?.errors?.required">Tarkemmat tiedot yhtiön pääomalainasta puuttuu.</mat-error>
                <mat-hint style="padding: 8px;">
                  Esim.<br />
                  <br />
                  1 Laina on tarkoitettu pääomarakenteen vahvistamiseksi.<br />
                  <br />
                  2 Pääoma ja korko saadaan maksaa yhtiön selvitystilassa ja konkurssissa vain kaikkia muita velkoja huonommalla etuoikeudella<br />
                  <br />
                  3 Pääoma saadaan muutoin palauttaa ja korkoa maksaa vain siltä osin, kuin yhtiön vapaan oman pääoman ja kaikkien pääomalainojen määrä maksuhetkellä ylittää yhtiön viimeksi päättyneeltä tilikaudelta vahvistettavan tai sitä uudempaan tilinpäätökseen sisältyvän taseen mukaisen tappion määrän.<br />
                  <br />
                  4 Lainalle ei ole annettu vakuutta<br />
                  <br />
                  5 Lainan vuotuinen korko on<br />
                  <br />
                  6 Jos pääomalainalle tulevaa korkoa ei voida maksaa korko siirtyy maksettavaksi ensimmäisen sellaisen tilinpäätöksen perusteella, jonka perusteella se voidaan maksaa<br />
                  <br />
                  Pääomalainan kuluksi kirjaamaton korko on tilinpäätöspäivänä 450,00€<br />
                  <br />
                </mat-hint>
              </div>
            </ng-container>

            <div style="margin: 15px 0; font-size: 120%; font-weight: bold;">Jakokelpoinen oma pääoma</div>

            <div style="padding-left: 3.5em; padding-top: .5em; padding-bottom: 2em;">
              <table class="laskelmat-table" style="max-width: 600px;" [class.one-column]="data.liitetiedot.vertailutiedot === 'ensimmainen-tilikausi'">
                <thead>
                  <tr>
                    <th style="width: unset;"></th>
                    <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.header}}</th>
                    <!-- <th style="width: 100px;">{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.header}}</th> -->
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenLopussa">
                    <td>Sijoitetun vapaan oman pääoman rahasto</td>
                    <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.svopTilikaudenLopussa | number:'1.2-2'}}</td>
                    <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.svopTilikaudenLopussa | number:'1.2-2'}}</td> -->
                    <td></td>
                  </tr>
                  <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa">
                    <td>Edellisten tilikausien voitto (tappio)</td>
                    <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa | number:'1.2-2'}}</td>
                    <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.edellistenTilikausienVoittoTaiTappioTilikaudenLopussa | number:'1.2-2'}}</td> -->
                    <td></td>
                  </tr>
                  <tr *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio">
                    <td>Tilikauden voitto (tappio)</td>
                    <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio | number:'1.2-2'}}</td>
                    <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.tilikaudenVoittoTaiTappio | number:'1.2-2'}}</td> -->
                    <td></td>
                  </tr>
                  <ng-container *ngFor="let rivi of data.liitetiedot.jakokelpoinenOmaPaaomaRivit">
                    <tr *ngIf="!rivi.poistettu">
                      <td>
                        <mat-form-field style="max-width: 400px;">
                          <input [disabled]="data.lukittu" type="text" class="skip-space-quick-toggle" [ngModel]="rivi.kuvaus" (blur)="tallennaJakokelpoinenOmaPaaoma()" (ngModelChange)="jakokelpoinenOmaPaaomaRivinTekstiMuuttui($event, rivi)" [name]="'rk' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field>
                          <input matInput [disabled]="data.lukittu" decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="rivi.maara" (blur)="tallennaJakokelpoinenOmaPaaoma()" (ngModelChange)="jakokelpoinenOmaPaaomaRivinSummaMuuttui($event, rivi)" [name]="'rs' + namename" matInput data-lpignore="true" />
                        </mat-form-field>
                      </td>
                      <td><button mat-icon-button class="remove-btn" (click)="deleteRivi(rivi)"><i class="icon ion-ios-trash icon-btn"></i></button></td>
                    </tr>
                  </ng-container>
                  <tr>
                    <td>Yhteensä</td>
                    <td>{{data.liitetiedot.omanPaaomanMuutoksetLaskelma.jakokelpoinenOmaPaaomaYhteensa | number:'1.2-2'}}</td>
                    <!-- <td>{{data.liitetiedot.omanPaaomanMuutoksetVertailulaskelma.jakokelpoinenOmaPaaomaYhteensa | number:'1.2-2'}}</td> -->
                    <td>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button mat-button *ngIf="!data.lukittu" (click)="lisaaJakokelpoinenOmaPaaomaRivi()" type="button">
              + Lisää rivi
            </button>

          </div>

        </ng-container>

        <div style="margin-bottom: 20px;">
          <label [class.mat-form-field-invalid]="hallituksenehdotusosingonjaosta?.touched && hallituksenehdotusosingonjaosta?.errors?.required" style="margin: 15px 0; font-size: 120%; font-weight: bold; display: block;" id="osingonjako-radio-group-label">Hallituksen ehdotus jakokelpoisen vapaan oman pääoma käytöstä</label>
          <div style="background-color: #F2F2F2; padding: 10px 10px 0px 10px;">
            <div *ngIf="jakokelpoinenOmaPaaomaEiRiitaObservable | async" class="error-text" style="text-align: center; padding: 20px; font-weight: bold;">Jakokelpoinen oma pääoma ei riitä!</div>
            <mat-radio-group [disabled]="data.lukittu" #hallituksenehdotusosingonjaosta="ngModel" required aria-labelledby="osingonjako-radio-group-label" name="o21" [ngModel]="data.liitetiedot.osingonjako" (ngModelChange)="osingonjaonTyyppiMuuttui($event)">
              <mat-radio-button [value]="'ei-jaeta'">Osinkoa ei jaeta</mat-radio-button><br />
              <mat-radio-button [value]="'esilaskettu-prosentti'">Osinkoa jaetaan 8% omasta pääomasta ({{data.liitetiedot.osingonjakoEsilaskettuOsinkoPerOsake | number:'1.2-10'}} euroa per osake eli yhteensä {{data.liitetiedot.osingonjakoEsilaskettuMaara| number:'1.2-2'}} euroa.)</mat-radio-button><br />
              <div *ngIf="data.liitetiedot.osingonjako === 'esilaskettu-prosentti'" class="gray-text" style="padding-bottom: 10px; padding-top: 5px; padding-left: 35px; font-size: 120%;">
                Huom! Summa on laskettu suoraan liitetietojen omasta pääomasta, eikä siinä olla otettu huomioon esim. osakasvelkoja tai kustannustukia tai muita seikkoja, jotka vaikuttavat jaettavien osinkojen määrään.
              </div>
              <mat-radio-button [value]="'annettu-maara'">Osinkoa jaetaan
                <mat-form-field style="margin-left: 5px; width: 100px;">
                  <input [disabled]="data.lukittu" #osingonMaaraInput decimalNumberField class="skip-space-quick-toggle" type="text" [ngModel]="data.liitetiedot.osingonjakoAnnettuMaara" (blur)="automaattitallennaOsingonMaara()" (ngModelChange)="osingonMaaraMuuttui($event)" (appOnDisabled)="osingonMaaraInputDisabledChanged($event)" [disabled]="data.liitetiedot.osingonjako !== 'annettu-maara'"
                    [name]="'o22_'+namename" required matInput data-lpignore="true" />
                </mat-form-field> euroa
              </mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="hallituksenehdotusosingonjaosta?.touched && hallituksenehdotusosingonjaosta?.errors?.required">Tieto osingojaosta puuttuu.</mat-error>
            <mat-error *ngIf="data.liitetiedot.osingonjakoEsilaskettuOsakkeidenLukumaara &lt; 1">Tuntemistiedoissa ei ole osakkaita tai osakkeiden lukumäärä on nolla. Tarkista tuntemistiedot.</mat-error>
          </div>



          <ng-container *ngIf="data.liitetiedot.osingonjako === 'esilaskettu-prosentti' || data.liitetiedot.osingonjako === 'annettu-maara'">
            <!-- {{ osinkoinputModel?.errors | json }} -->
            <div style="background-color: #F2F2F2; padding: 10px 10px 0px 10px; margin-top: 20px;">
              <!-- aria-labelledby="osingonjako-radio-group-label" -->
              <mat-radio-group [disabled]="data.lukittu" #nostettavissa="ngModel" required name="o76" [ngModel]="data.liitetiedot.osingotNostettavissa" (ngModelChange)="osingotNostettavissaTyyppiMuuttui($event)">
                <mat-radio-button [value]="'yhtiokokouksen-jalkeen'">Osinko on nostettavissa heti yhtiökokouksen jälkeen</mat-radio-button><br />
                <mat-radio-button [value]="'pvm'">Osinko on nostettavissa
                  <mat-form-field style="margin-left: 5px; width: 120px;">
                    <mat-label>Päivämäärä</mat-label>
                    <input [disabled]="data.lukittu || data.liitetiedot.osingotNostettavissa !== 'pvm'" #osinkoNostettavissaInput #osinkoinputModel="ngModel" class="skip-space-quick-toggle" type="text" [matDatepicker]="osinkoNostettavissaPicker" [ngModel]="data.liitetiedot.osingotNostettavissaPvm | numberDateToDate"
                      (ngModelChange)="osingonNostettavissaPvmMuuttui($event, osinkoNostettavissaInput.value)" (blur)="automaattitallennaOsingonjaonNostettavissaPaivamaara()" (appOnDisabled)="osingotNostettavissaInputDisabledChanged($event)" [name]="'osingot77_' + namename" required matInput data-lpignore="true" />
                    <mat-datepicker-toggle matSuffix [for]="osinkoNostettavissaPicker"></mat-datepicker-toggle>
                    <mat-error *ngIf="osinkoinputModel?.touched && osinkoinputModel?.errors?.required">Päivämäärä puuttuu.</mat-error>
                    <mat-error *ngIf="osinkoinputModel?.touched && osinkoinputModel?.errors?.matDatepickerParse">Päivämäärä on virheellinen.</mat-error>
                  </mat-form-field>
                  <mat-datepicker #osinkoNostettavissaPicker (closed)="automaattitallennaOsingonjaonNostettavissaPaivamaara()"></mat-datepicker>
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="nostettavissa?.touched && nostettavissa?.errors?.required">Tieto milloin osinko on nostettavissa puuttuu.</mat-error>
            </div>

            <mat-error *ngIf="osingotSaatetaanMaksaaSamanaVuonnaObservable | async">Huomaathan, että osinkoa ollaan maksamassa samana vuonna kuin tilikausi päättyy ja tämä voi vaikuttaa osinkojen verotukseen.</mat-error>
          </ng-container>

        </div>

        <div style="padding-bottom: 15px;" *ngIf="data.liitetiedot.osingonjako === 'ei-jaeta'">
          <ng-container *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio >= 0">
            <!-- Todettiin, että vahvistettu tulos osoittaa {{data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio | number:'1.2-2'}} euron voittoa. Päätettiin, että osinkoa ei jaeta. Päätettiin kirjata voitto edellisten tilikausien voitto / tappio -tilille. -->
            Hallitus ehdottaa, että osinkoa ei jaeta ja tilikauden voitto kirjataan edellisten tilikausien voitto/tappio -tilille.
          </ng-container>
          <ng-container *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio < 0">
            <!-- Todettiin, että vahvistettu tulos osoittaa {{Math.abs(data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio) | number:'1.2-2'}} euron tappiota. Päätettiin kirjata tappio edellisten tilikausien voitto / tappio -tilille. -->
            Hallitus ehdottaa, että osinkoa ei jaeta ja tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.
          </ng-container>
        </div>

        <div style="padding-bottom: 15px;" *ngIf="data.liitetiedot.osingonjako === 'annettu-maara' || data.liitetiedot.osingonjako === 'esilaskettu-prosentti'">
          <ng-container *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio >= 0">
            Hallitus ehdottaa, että osinkoa jaetaan {{data.liitetiedot.osinkoPerOsake | number:'1.2-10'}} euroa per osake eli yhteensä {{data.liitetiedot.osinkojaYhteensa | number:'1.2-2'}} euroa.
            Osinko on nostettavissa {{ data.liitetiedot.osingotNostettavissa === 'pvm' ? (data.liitetiedot.osingotNostettavissaPvm | lnumberpvm | async) : 'heti yhtiökokouksen jälkeen' }}. Loppuosa tilikauden voitosta kirjataan edellisten tilikausien voitto/tappio -tilille.
            <br /><br />
            Hallituksen näkemyksen mukaan ehdotettu osinko ei vaaranna yhtiön maksukykyä.
          </ng-container>
          <ng-container *ngIf="data.liitetiedot.omanPaaomanMuutoksetLaskelma.tilikaudenVoittoTaiTappio < 0">
            Hallitus ehdottaa, että osinkoa jaetaan {{data.liitetiedot.osinkoPerOsake | number:'1.2-10'}} euroa per osake eli yhteensä {{data.liitetiedot.osinkojaYhteensa | number:'1.2-2'}} euroa.
            Osinko on nostettavissa {{ data.liitetiedot.osingotNostettavissa === 'pvm' ? (data.liitetiedot.osingotNostettavissaPvm | lnumberpvm | async) : 'heti yhtiökokouksen jälkeen' }}. Tilikauden tappio kirjataan edellisten tilikausien voitto/tappio -tilille.
            <br /><br />
            Hallituksen näkemyksen mukaan ehdotettu osinko ei vaaranna yhtiön maksukykyä.
          </ng-container>
        </div>


        <h2 class="otsikko">
          Luettelo kirjanpidoista ja aineistoista (KPL 2:7a)
          <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
            <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
          </button> -->
        </h2>
        <div style="margin: 15px 0;">Luettelo kirjanpidoista ja aineistoista on esitetty erillisessä PDF-dokumentissa. Dokumenttia säilytetään tilinpäätöksen yhteydessä Tilitoimisto Lemon Tree Oy:n tietojärjestelmässä tai kirjanpitovelvollisen hallinnoimilla sähköisillä tallennusvälineillä.</div>

        <!-- <h2 class="otsikko">Luettelo kirjanpidoista ja aineistoista</h2>

      <ul>
        <li>Tilinpäätös</li>
        <li>Tase-erittelyt</li>
        <li>Tilikohtainen tuloslaskelma</li>
        <li>Tilikohtainen tase</li>
        <li>Tililuettelo</li>
        <li>Pää- ja päiväkirjat</li>
      </ul>

      <h2 class="otsikko">Tositteiden lajit ja säilytystavat</h2>

      <ng-container *ngIf="maksutapojenTunnisteetJoissaOnKirjauksiaObservable | async as tunnisteet">
        Tositelajit
        <ul>
          <li *ngFor="let tunniste of tunnisteet">{{tunniste}}</li>
        </ul>
      </ng-container>

      <div>Tilikauden tositteet ja muu kirjanpitoaineisto säilytetään sähköisillä tallennusvälineillä</div> -->

        <button mat-button *ngIf="!data.lukittu" (click)="fileInput.click()" type="button">
          + Lisää liitetietotosite
        </button>
        <div class="error-box" *ngIf="latausVirheObservable | async; let latausVirhe">{{ latausVirhe }}</div>

        <table>
          <ng-container *ngFor="let liite of liitteetObservable | async; let i = index">
            <tr>
              <td><button mat-icon-button class="remove-btn" (click)="deleteAttachment(liite)"><i class="icon ion-ios-trash icon-btn"></i></button></td>
              <td>Liitetietotosite {{i+1}}:</td>
              <td>{{liite.nimi}}</td>
            </tr>
          </ng-container>
        </table>

        <ng-container *ngIf="!data.liitetiedot.allekirjoitetaanLemonaidissa">
          <h2 class="otsikko">
            {{'ALLEKIRJOITUKSET.title' | translatetilinpaatos: 2021 | async }}
            <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
          </h2>
          <!-- [info]="'Vaihto-omaisuuden käyttöjärjestys on lähtökohtaisesti FIFO (first in first out) -periaatteen mukainen. Jos siitä poiketaan, pitää antaa liitetieto.'" [example]="'Suoritetusta tilintarkastuksesta on tänään annettu kertomus.<br /><br />
          Helsingissä _______.2022<br />
          <br /><br />
          Matin tilintarkastus Oy<br />
          Tilintarkastusyhteisö<br />
          <br /><br />
          Matti Meikäläinen<br />
          KHT'" -->
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.ALLEKIRJOITUKSET" [field]="kentat.ALLEKIRJOITUSTEN_TEKSTI" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" [checkboxDisabled]="true" (checkboxChanged)="checkboxChanged()" [example]="'Esim.
          <br /><br /><br /><br />
          _____________________________<br />
          Matti Meikäläinen<br />
          Toimitusjohtaja
          <br /><br /><br /><br />
          _____________________________<br />
          Meikäläisen Matti<br />
          Hallituksen puheenjohtaja
          <br /><br /><br /><br />
          _____________________________<br />
          Mäkeläisen Matti Vaan<br />
          Hallituksen jäsen'"></div>
        </ng-container>

        <ng-container *ngIf="data.liitetiedot.tilintarkastetaan">
          <h2 class="otsikko">
            {{'TTL3.title' | translatetilinpaatos: 2021 | async }}
            <!-- <button mat-icon-button style="vertical-align: top;" (click)="avaaInfoTeksti('Tässä on teksti')">
          <i class="icon ion-ios-information-circle-outline" style="font-size: 28px; color: gray;" aria-hidden="true"></i>
        </button> -->
          </h2>
          <!-- [info]="'Vaihto-omaisuuden käyttöjärjestys on lähtökohtaisesti FIFO (first in first out) -periaatteen mukainen. Jos siitä poiketaan, pitää antaa liitetieto.'" -->
          <div app-kirjanpito-liitetiedot-checkbox [formDisabled]="data.lukittu" (tallennaArvo)="tallennaMuuttunutArvo($event)" [parent]="osiot.TTL3" [field]="kentat.TTL3_4" [unsaved]="data.tallentamattomat" [liitetiedot]="data.liitetiedot" [checkboxDisabled]="true" (checkboxChanged)="checkboxChanged()" [example]="'Esim. Suoritetusta tilintarkastuksesta on tänään annettu kertomus.<br /><br />
          Helsingissä _______.2022<br />
          <br /><br /><br /><br /><br />
          Matin tilintarkastus Oy<br />
          Tilintarkastusyhteisö<br />
          <br /><br /><br /><br /><br />
          Matti Meikäläinen<br />
          KHT'"></div>
        </ng-container>

      </ng-container>

    </div>

    <div style="text-align: right; padding: 30px 0;">
      <button mat-button *ngIf="!data.lukittu" class="primarybutton" (click)="tallenna(data.liitetiedot, liitetiedotForm)">
        Tallenna
      </button>
    </div>

  </form>

</ng-container>

<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>

<input #fileInput type="file" style="visibility: hidden;" accept="*" multiple (change)="lataa($event)" />


<ng-template #lisarivitTemplate let-data="data" let-osio="osio">

  <div class="lisatiedot-status">
    <span *ngIf="osionLisarivitSaveSuccessMap.has(osio)">
      <span class="save-success-text" [@quickInSlowOut]="'in'" style=" color: gray;">
        Arvot tallennettiin
      </span>
      <mat-icon class="save-success-icon" [@quickInSlowOut]="'in'" [ngStyle]="{'color':'#4bd76b','vertical-align':'middle'}">done</mat-icon>
    </span>
  </div>

  <ng-container *ngFor="let lisatieto of data.liitetiedot.osionLisarivit?.[osio]; index as index;">
    <div *ngIf="!lisatieto.poistettu" class="lisatieto">
      <div class="lisatieto-otsikko">
        <textarea class="lisatiedot-otsikko" name="lisatieto{{index}}-otsikko" placeholder="Otsikko" cdkAutosizeMaxRows="1" [disabled]="data.lukittu" matInput (blur)="tallennaOsionLisarivi(osio)" [ngModel]="lisatieto.otsikko" (ngModelChange)="osionLisariviMuuttui(osio, $event, lisatieto, 'otsikko')" cdkTextareaAutosize></textarea>
        <button mat-icon-button style="vertical-align: top;" (click)="poistaOsionLisarivi(osio, lisatieto)">
          <i class="icon ion-ios-trash icon-btn" style="font-size: 24px; color: gray;" aria-hidden="true"></i>
        </button>
      </div>
      <textarea class="lisatiedot-teksti" name="lisatieto{{index}}-teksti" placeholder="Teksti" [disabled]="data.lukittu" matInput (blur)="tallennaOsionLisarivi(osio)" [ngModel]="lisatieto.teksti" (ngModelChange)="osionLisariviMuuttui(osio, $event, lisatieto, 'teksti')" cdkTextareaAutosize></textarea>
    </div>
  </ng-container>

  <div>
    <button mat-icon-button (click)="lisaaOsionLisarivi(osio)" [disabled]="data.lukittu">
      <i class="icon ion-ios-add-circle-outline"></i>
    </button>
  </div>

</ng-template>