import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface InfoDialogData {
  text: string
  header: string
}

@Component({
  templateUrl: './info.dialog.html'
})
export class InfoDialog implements OnInit {

  public text = ''
  public header = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InfoDialogData,
    private dialogRef: MatDialogRef<InfoDialog>
  ) { }

  ngOnInit() {
    this.text = this.data.text
    this.header = this.data.header
  }

}
