<div class="lemon-row" [formGroup]="parent">
  <div class="tuotteet_nimi lemon-cell">
    <div style="background: rgb(39,159,199);">
      <app-tuote-haku formControlName="tuote" [paivitaValidaatiot]="paivitaValidaatiot"></app-tuote-haku>
    </div>
  </div>
  <div class="tuotteet_maara lemon-cell">
    <mat-form-field>
      <input decimalNumberField matInput formControlName="maara" [name]="namename" [numberOfDecimals]="4">
    </mat-form-field>
  </div>
  <div class="tuotteet_ahinta lemon-cell">
    <mat-form-field>
      <input decimalNumberField matInput formControlName="ahinta" [name]="namename" [numberOfDecimals]="2">
    </mat-form-field>
  </div>
  <div class="tuotteet_alv lemon-cell">
    <mat-form-field>
      <mat-select formControlName="alv" [compareWith]="compareAlvt" style="width: 100%;">
        <mat-option *ngFor="let alv of alvt" [value]="alv">{{ alv.prosentti | ldecimal : 2 | async }} %</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="tuotteet_ale lemon-cell">
    <mat-form-field>
      <input decimalNumberField matInput formControlName="ale" [name]="namename" [numberOfDecimals]="4">
    </mat-form-field>
  </div>
  <div class="tuotteet_yhteensa valkoinen lemon-cell">
    <mat-form-field>
      <input decimalNumberField matInput formControlName="yhteensa" [name]="namename" [numberOfDecimals]="4">
    </mat-form-field>
  </div>
  <div class="tuotteet_poista valkoinen lemon-cell" style="text-align: center;">
    <button mat-button (click)="poista()" type="button" style="padding: 0;">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>