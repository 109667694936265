
<div mat-dialog-content>
  <lemon-dialog-header>
    Lemonaidissa tapahtui virhe
  </lemon-dialog-header>

  <p>Valitettavasti Lemonaidissa tapahtui virhe. Olemme nauhoittaneet virheen ja tutkimme sen myöhemmin.</p>
  <p>Pahoittelemme virheestä johtuvaa mahdollista lisävaivaa.</p>

  <p>OK:n painaminen aiheuttaa selaimen uudelleen lataamisen.</p>

</div>
<div mat-dialog-actions>
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.ok' | translate | async}}</button>
</div>
