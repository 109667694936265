import { Component, ViewChild, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { LaskunAlv } from '../../../_jaettu/model/lasku'

import { TuoteHakuComponent } from '../tuotteet/tuote-haku.component'

import { Subject } from 'rxjs'
import { auditTime, takeUntil } from 'rxjs/operators'
import { LaskunRiviForm } from './lasku-rivit.component'

export interface FieldError {
  field: string
  error: string
}

@Component({
  selector: 'app-lasku-rivi',
  templateUrl: './lasku-rivi.component.html',
  styleUrls: ['./lasku-rivi.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuRiviComponent implements OnInit, OnDestroy {

  @ViewChild(TuoteHakuComponent, { static: true }) hakuKomponentti: TuoteHakuComponent
  // @ViewChildren(MatInput) numericKomponentit: QueryList<MatInput>
  // @ViewChildren(MatInput) currencyKomponentit: QueryList<MatInput>

  @Output() riviPoistettuTapahtuma: EventEmitter<FormGroup<LaskunRiviForm>> = new EventEmitter()
  @Output() validaatioMuuttuiTapahtuma: EventEmitter<FieldError[]> = new EventEmitter()

  @Input() alvt: LaskunAlv[] = null
  @Input() parent: FormGroup<LaskunRiviForm>
  @Input() paivitaValidaatiot: any


  private ngUnsubscribe = new Subject<void>()

  namename = 'doine3862ipoiuytfvfew3' + Math.random()

  constructor() {

  }

  ngOnInit() {
    this.parent.valueChanges.pipe(
      auditTime(100),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.paivitaVirheet()
    })
  }

  focus() {
    this.hakuKomponentti.focus()
  }

  poista() {
    this.riviPoistettuTapahtuma.next(this.parent)
  }

  markAsTouched() {
    // if (this.currencyKomponentit) {
    //   for (const komp of this.currencyKomponentit.toArray()) {
    //     komp.markAsTouched()
    //   }
    // }
    // if (this.numericKomponentit) {
    //   for (const komp of this.numericKomponentit.toArray()) {
    //     komp.value
    //   }
    // }
    if (this.hakuKomponentti) {
      this.hakuKomponentti.markAsTouched()
    }
  }

  compareAlvt(o1: LaskunAlv, o2: LaskunAlv): boolean {
    return o1?.tunniste === o2?.tunniste
  }

  paivitaVirheet() {
    this.validaatioMuuttuiTapahtuma.next(this.annaVirheet())
  }

  private annaVirheet(): FieldError[] {
    const errors: FieldError[] = []
    this.lisaaVirheet(errors, 'tuote')
    this.lisaaVirheet(errors, 'maara')
    this.lisaaVirheet(errors, 'ahinta')
    this.lisaaVirheet(errors, 'alv')
    this.lisaaVirheet(errors, 'ale')
    this.lisaaVirheet(errors, 'yhteensa')
    return errors
  }

  private lisaaVirheet(errors: FieldError[], identifier: string) {
    const field = this.parent.get(identifier)
    if (field && field.errors && (field.touched || field.dirty)) {
      for (const error of Object.keys(field.errors)) {
        if (field.errors[error]) {
          errors.push({
            field: identifier,
            error: error
          })
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
