import { captureException } from '@sentry/browser'

import { Injectable, ErrorHandler } from '@angular/core'

import { NativeDateAdapter } from '@angular/material/core'
import { Platform } from '@angular/cdk/platform'

import { fi, enGB, Locale } from 'date-fns/locale'

import { DateService } from '../../_shared-core/service/date.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

/**
 * Maybe switch to @angular/material-date-fns-adapter ?
 * https://github.com/angular/components/blob/main/src/material-date-fns-adapter/adapter/date-fns-adapter.ts
 */
@Injectable()
export class LemonDateAdapter extends NativeDateAdapter {

  private _getSupportedDateFnsLocale(kieli: TuettuKieli): Locale {
    if (kieli === 'fi') {
      return fi
    }
    if (kieli === 'en') {
      return enGB
    }
    captureException(new Error(`No support for DateFns locale ${kieli}`))
    return fi
  }

  constructor(
    protected errorHandler: ErrorHandler,
    protected dateService: DateService,
    protected platform: Platform,
    protected translationService: LemonTranslationService
  ) {
    const supportedLocale = translationService.getSupportedLocale(translationService.nykyinenKieli)
    super(supportedLocale)
  }

  setLocale(locale: any): void {
    // if (typeof locale === 'string' || locale instanceof String) {
    const supportedLocale = this.translationService.getSupportedLocale(locale as string)
    super.setLocale(supportedLocale)
    // } else {
    //   super.setLocale(locale)
    // }
  }

  getFirstDayOfWeek(): number {
    const dateFnsLocale = this._getSupportedDateFnsLocale(this.locale)
    return dateFnsLocale.options?.weekStartsOn ?? 1;
  }

  parse(value: any, parseFormat: string | string[]): Date | null {
    if (typeof value === 'string') {
      try {
        return this.dateService.parsiPaivamaara(value, this.translationService.nykyinenKieli)
      } catch (error) {
        return new Date('thisisinvaliddateithink')
      }
    }
    if (this.isDateInstance(value)) {
      return super.parse(value, parseFormat)
    }
    return new Date('thisisinvaliddateithink')
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const kielenNimet = this.dateService.KUUKAUSIEN_NIMET[this.translationService.nykyinenKieli]
    if (kielenNimet) {
      return kielenNimet[style]
    }
    this.errorHandler.handleError('Ei löytynyt kuukausien nimiä kielelle ' + this.translationService.nykyinenKieli + ', tyylillä ' + style)
    return []
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const paivienNimet = this.dateService.PAIVIEN_NIMET[this.translationService.nykyinenKieli]
    if (paivienNimet) {
      return paivienNimet[style]
    }
    this.errorHandler.handleError('Ei löytynyt päivien nimiä kielelle ' + this.translationService.nykyinenKieli + ', tyylillä ' + style)
    return []
  }

  format(date: Date, displayFormat: Object): string {
    return this.dateService.muotoilePaivaDate(date, this.translationService.nykyinenKieli)
  }

}
