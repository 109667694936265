import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'

export class KopioijaPalvelu {

  constructor(
    private timestampProviderBase: TimestampProviderBase
  ) { }

  nullTaiArvo(arvo: any): any {
    if (arvo === undefined) {
      return null
    }
    return arvo
  }

  kopioiJaAsetaNullPuuttuville<T>(kopioitava: T): T {
    return this.cloneDeep(kopioitava)
  }

  cloneDeep(val: any) {
    switch (this.typeOf(val)) {
      case 'object':
        return this.cloneObjectDeep(val)
      case 'array':
        return this.cloneArrayDeep(val)
      case 'date':
        return new Date(val)
      case 'timestamp':
        return val // No need to copy as this is immutable
      case 'timestampfix':
        // Must create new instance, as if we're here, we actually have a broken timestamp in database, that is
        // one that has been saved as map.
        return this.timestampProviderBase.newTimestamp(val.seconds, val.nanoseconds)
      case 'undefined':
        return null
    }
    if (Object(val) === val) {
      throw new Error('Don\'t know how to convert ' + val + JSON.stringify(val))
    }
    return val
  }

  cloneObjectDeep(obj: any) {
    const res: any = {}
    for (const key of Object.keys(obj)) {
      res[key] = this.cloneDeep(obj[key])
    }
    return res
  }

  cloneArrayDeep(arr: any[]) {
    const res: any[] = []
    for (let i = 0; i < arr.length; i++) {
      res[i] = this.cloneDeep(arr[i])
    }
    return res
  }

  private typeOf(obj: any): 'unknown' | 'timestamp' | 'undefined' | 'null' | 'timestampfix' | 'regexp' | 'date' | 'array' | 'object' {

    if (obj === undefined) { return 'undefined' }
    if (obj === null) { return 'null' }

    // Can't rely on constuctor name as it can be obscured by javascript
    // if (obj.constructor.name === 'Timestamp') {
    // return 'timestamp'
    // }
    if (this.timestampProviderBase.isTimestamp(obj)) {
      return 'timestamp'
    }

    // If the object looks like timestamp, but is just plain javascript object,
    // signal that we need to fix this timestamp
    if (obj.seconds && obj.nanoseconds && Object.keys(obj).length === 2) {
      return 'timestampfix'
    }

    const type = typeof obj
    switch (type) {
      case 'object':
        switch (Object.prototype.toString.call(obj)) {
          case '[object RegExp]': return 'regexp'
          case '[object Date]': return 'date'
          case '[object Array]': return 'array'
          default: return type
        }
      default: return 'unknown'
    }

  }

}
