import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DebugService } from 'app/_angular/service/debug.service'

export interface TuontiKaynnistettyUudelleenDialogData {
  uri: string
}

@Component({
  templateUrl: './tuonti-kaynnistetty-uudelleen.dialog.html'
})
export class TuontiKaynnistettyUudelleenDialog {

  public uri = ''
  public uriEncoded = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TuontiKaynnistettyUudelleenDialogData,
    private dialogRef: MatDialogRef<TuontiKaynnistettyUudelleenDialog>,
    private _debugService: DebugService
  ) {
    this.uri = this.data.uri
    this.uriEncoded = this._debugService.createFirestoreLinkLemonaid(this.uri)
  }

}
