import { FirestoreIndeksoija, MahdollinenJarjestys, JarjestysTiedot } from '../firestore.indeksoija'

import { FirestoreTosite } from '../../model/tosite'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { DateService } from '../../../_shared-core/service/date.service'

export class KuitinSorttaukset {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static PVM: JarjestysTiedot = { tunniste: 'a', tyyppi: 'date' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static SELITE: JarjestysTiedot = { tunniste: 'b', tyyppi: 'string' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static SUMMA: JarjestysTiedot = { tunniste: 'c', tyyppi: 'number' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ALV_VAHENNYSOIKEUS: JarjestysTiedot = { tunniste: 'd', tyyppi: 'number' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAKSUTAPA: JarjestysTiedot = { tunniste: 'e', tyyppi: 'number' }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MUOKATTU_KANNYKASSA: JarjestysTiedot = { tunniste: 'f', tyyppi: 'date' }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static KAIKKI: { [tunniste: string]: JarjestysTiedot } = {
    pvm: KuitinSorttaukset.PVM,
    selite: KuitinSorttaukset.SELITE,
    summa: KuitinSorttaukset.SUMMA,
    alv: KuitinSorttaukset.ALV_VAHENNYSOIKEUS,
    maksutapa: KuitinSorttaukset.MAKSUTAPA,
    muokattu: KuitinSorttaukset.MUOKATTU_KANNYKASSA
  }

}

export class TositeIndeksoija {

  constructor(
    private firestoreIndeksoija: FirestoreIndeksoija,
    private currencyService: CurrencyService,
    private dateService: DateService
  ) {

  }

  annaSelite(tosite: FirestoreTosite): string {
    if (tosite.type === 'einvoice' || tosite.type === 'payment') {
      if (tosite.seller?.trim()) {
        return tosite.seller.trim()
      } else if (tosite.el?.trim()) {
        return tosite.el.trim()
      }
    }
    if (tosite.selite?.trim()) {
      return tosite.selite.trim()
    }
    return ''
  }

  public luoHakutiedotKuitille(tosite: FirestoreTosite): any {
    const mahdollisetSorttaukset: MahdollinenJarjestys[] = []

    const wholeSelite = (this.annaSelite(tosite) ?? '') + ''
    const chars = [...wholeSelite]
    const selite = chars.slice(0, 250).join()?.trim() ?? ''

    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.SELITE,
      arvo: selite
    })
    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.SUMMA,
      arvo: tosite.summa
    })
    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.PVM,
      arvo: tosite.localPvm
    })
    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.MAKSUTAPA,
      arvo: tosite.maksutapa
    })
    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.ALV_VAHENNYSOIKEUS,
      arvo: tosite.alvVahennysoikeus
    })
    mahdollisetSorttaukset.push({
      sorttaus: KuitinSorttaukset.MUOKATTU_KANNYKASSA,
      arvo: this.dateService.timestampToLocalDate(tosite.tallennettuKannykassa)
    })

    const pvm: string = this.firestoreIndeksoija.annaAikaLocal(tosite.localPvm, 'p')
    const maksutapa: string = 'm' + tosite.maksutapa
    const poistettu: string = tosite.poistettu ? 'r0' : 'r1'

    const extra = [

      // Rajattu ajan mukaan
      pvm,

      // Rajattu maksutavan mukaan
      maksutapa,

      // Rajattu sen mukaan onko poistettu vai ei
      poistettu,

      // Yhdistelmät
      maksutapa + pvm,
      poistettu + pvm,
      poistettu + maksutapa,
      poistettu + maksutapa + pvm

    ]

    const indeksoitavaSummaMerkkijonona = this.currencyService.formatoiDesimaaliSailytaNollat(Math.abs(tosite.sum), 2, 'en').trim()
    const indeksoitavaSumma = this.firestoreIndeksoija.korvaaDesimaaliErotinHakuerottimella(indeksoitavaSummaMerkkijonona)

    const haku = {}
    this.firestoreIndeksoija.luoFirestorenVapaatekstihaku(haku, selite, extra, mahdollisetSorttaukset)
    this.firestoreIndeksoija.luoFirestorenVapaatekstihakuIndeksointilevelilla(haku, 's' + indeksoitavaSumma, extra, mahdollisetSorttaukset, 1)
    return haku

  }

}
