import { Injectable } from '@angular/core'

type FileTypes = TxtFileTypes | 'xlsx' | 'pdf'
type TxtFileTypes = 'txt' | 'csv'

@Injectable()
export class FileSaverService {

  saveStringAs(content: string, filename: string, type: TxtFileTypes) {
    const mimeType = this._getMimeType(type)
    this.saveAs(new Blob([content], { type: mimeType }), filename)
  }

  saveBase64As(content: string, filename: string, type: FileTypes): void {
    const mimeType = this._getMimeType(type)
    this.saveAs(this._base64StringToBlob(content, mimeType), filename)
  }

  saveBase64AsGuessedType(content: string, filename: string) {
    const output = this.base64StringToUint8Array(content)
    const mimeType = this._guessTypeFromFilename(filename)
    const blob = new Blob([output], { type: mimeType })
    this.saveAs(blob, filename)
  }

  private _guessTypeFromFilename(filename: string) {
    const lwr = filename.toLowerCase().trim()
    if (lwr.endsWith('.pdf')) {
      return this._getMimeType('pdf')
    } else if (lwr.endsWith('.txt')) {
      return this._getMimeType('txt')
    } else if (lwr.endsWith('.csv')) {
      return this._getMimeType('csv')
    } else if (lwr.endsWith('.xlsx')) {
      return this._getMimeType('xlsx')
    }
    return 'application/octet-stream'
  }

  private _getMimeType(type: FileTypes): string {
    if (type === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    } else if (type === 'pdf') {
      return 'application/pdf'
    } else if (type === 'txt') {
      return 'text/plain'
    } else if (type === 'csv') {
      return 'text/csv'
    }
    throw new Error('Unknown type ' + type)
  }

  saveAs(blob: Blob, fileName: string): void {

    const blobUrl = window.URL.createObjectURL(blob)

    // Create a hidden anchor link
    const element: HTMLAnchorElement = document.createElement('a')
    element.style.display = 'none'
    element.href = blobUrl
    element.download = fileName

    // Append to DOM and simulate click (this will trigger the download)
    document.body.appendChild(element)
    element.click()

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(element)
      window.URL.revokeObjectURL(blobUrl)
    }, 500)

  }

  base64StringToUint8Array(base64: string): Uint8Array {
    return this._binaryStringToArrayBuffer(atob(base64))
  }

  private _base64StringToBlob(base64: string, type: string): Blob {
    const parts = [this.base64StringToUint8Array(base64)]
    return new Blob(parts, { type: type })
  }

  private _binaryStringToArrayBuffer(binary: string): Uint8Array {
    const length = binary.length
    const buf = new ArrayBuffer(length)
    const arr = new Uint8Array(buf)
    let i = -1
    while (++i < length) {
      arr[i] = binary.charCodeAt(i)
    }
    return arr
  }

}
