import { qr } from './qr-base'
import { QrImageOptions, Matrix } from './model'

export async function getQrCodeAsSvg(text: string, options: QrImageOptions) {
  const matrix = qr(text, options.ecLevel)
  return createSVG(matrix, options)
}

function createSVG(
  matrix: Matrix,
  options: QrImageOptions
): string {
  // const actualSize = options.size || 9
  const actualSize = 9
  const x = matrix.length + 2 * options.margin
  const xy = x * actualSize
  const imageWidthStr = options.imageWidth ? ` width='${options.imageWidth}'` : ''
  const imageHeightStr = options.imageWidth ? `height='${options.imageWidth}' ` : ''
  // const xmlTag = '<?xml version=\'1.0\' encoding=\'utf-8\'?>'
  const svgOpeningTag = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink'${imageWidthStr} ${imageHeightStr}viewBox='0 0 ${xy} ${xy}'>`
  const svgBody = getSVGBody(matrix, options, xy, actualSize)
  const svgEndTag = '</svg>'
  return svgOpeningTag + svgBody + svgEndTag
}

function getSVGBody(
  matrix: Matrix,
  options: QrImageOptions,
  xySize: number,
  actualSize: number
): string {
  const path = getSVGPath(matrix, actualSize, options.margin * actualSize, 0)
  let svgBody = `<rect width='${xySize}' height='${xySize}' fill='${options.bgColor}'></rect>`
  svgBody += "<path shape-rendering='geometricPrecision' d='" + path + "' fill='" + options.color + "'/>"
  return svgBody
}

function checkSkip(path: number[], startIndex: number): number {
  let skip = 0
  while (path[startIndex + skip + 1]) {
    skip++
  }
  return skip
}

function getSVGPath(matrix: Matrix, size: number, margin: number = 0, borderRadius: number = 0): string {
  const rectangles: string[] = []
  for (let x = 0; x < matrix.length; x++) {
    const column = matrix[x]
    for (let y = 0; y < column.length; y++) {
      if (column[y]) {

        const leftX = x * size + margin
        const rightX = (x + 1) * size + margin

        const topY = y * size + margin
        y += checkSkip(column, y)
        const bottomY = (y + 1) * size + margin
        // const rectangle: string[] = []
        // rectangle.push(`M ${leftX} ${topY}`)
        // rectangle.push(`L ${leftX} ${bottomY}`)
        // // rectangle.push(`M ${leftX} ${topY + borderRadius}`)
        // // rectangle.push(`L ${leftX} ${bottomY - borderRadius}`)
        // // if (borderRadius > 0) {
        // //   rectangle.push(`A ${borderRadius} ${borderRadius} 0 0 0 ${leftX + borderRadius} ${bottomY} `)
        // // }
        // rectangle.push(`L ${rightX} ${bottomY}`)
        // // rectangle.push(`L ${rightX - borderRadius} ${bottomY}`)
        // // if (borderRadius > 0) {
        // //   rectangle.push(`A ${borderRadius} ${borderRadius} 0 0 0 ${rightX} ${bottomY - borderRadius}`)
        // // }
        // rectangle.push(`L ${rightX} ${topY}`)
        // // rectangle.push(`L ${rightX} ${topY + borderRadius}`)
        // // if (borderRadius > 0) {
        // //   rectangle.push(`A ${borderRadius} ${borderRadius} 0 0 0 ${rightX - borderRadius} ${topY}`)
        // // }
        // rectangle.push(`L ${leftX} ${topY}`)
        // // rectangle.push(`L ${leftX + borderRadius} ${topY}`)
        // // if (borderRadius > 0) {
        // //   rectangle.push(`A ${borderRadius} ${borderRadius} 0 0 0 ${leftX} ${topY + borderRadius}`)
        // // }
        // rectangle.push('z')
        // rectangles.push(rectangle.join(' '))
        rectangles.push(`M ${leftX} ${topY} L ${leftX} ${bottomY} L ${rightX} ${bottomY} L ${rightX} ${topY} L ${leftX} ${topY} z`)
      }
    }
  }
  return rectangles.join(' ')
}

// export const defaultQrImageOptions: QrImageOptions = {
//   ecLevel: 'M',
//   bgColor: 'white',
//   color: 'black',
//   // bgColor: 0xffffffff,
//   // color: 0x000000ff,
//   margin: 1,
//   size: 0,
//   borderRadius: 0,
//   blockSize: 1
// }
