import { MonoTypeOperatorFunction, ReplaySubject, share } from 'rxjs'

export function lemonShare<T>(): MonoTypeOperatorFunction<T> {
  return share<T>({
    connector: () => new ReplaySubject(1),
    resetOnError: true,
    resetOnComplete: false,
    resetOnRefCountZero: true
  })
}
