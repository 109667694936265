import { Component, OnInit, OnDestroy, AfterViewInit, ErrorHandler, ChangeDetectionStrategy, OnChanges } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'


import { Lasku, LaskuBase, LaskunTyyppi, LaskunTyypit, Laskuasetukset, LaskunTuote, UUDEN_LASKUN_AVAIN, TypeaheadAsiakas, LaskuReskontra, LaskunumeroTyyppi, LaskunLahetystyyppi } from '../_jaettu/model/lasku'
import { TuettuKieli } from '../_shared-core/model/common'
import { LaskuSharedService, ReskontraService } from '../_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { LaskuKopioija } from '../_jaettu/service/lasku/lasku.kopioija'

import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { VasenValikkoService } from '../_jaettu-angular/service/vasen-valikko.service'
import { LaskuPdfEsikatselutiedot } from '../_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'
import { KonfiguraatioPalvelu } from '../_jaettu-angular/service/lasku/configuration.service'

import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'
import { LaskuComponentData } from '../_angular/_resolvers/lasku.resolve'
import { LaskuService } from '../_angular/service/lasku/lasku.service'
import { KirjanpitajaService } from '../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Asiakas } from '../_jaettu-lemonator/model/asiakas'

import { LaskuSpostiData, LaskuSpostiDialog } from './dialogit/lasku.sposti.dialog'

import { Subject, ReplaySubject, combineLatest, Observable, BehaviorSubject, firstValueFrom, of } from 'rxjs'
import { takeUntil, take, auditTime, map, switchMap } from 'rxjs/operators'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { LadattavaLiitetiedosto, LaskuForm as JaettuLaskuForm, LaskunRiviForm } from 'app/_jaettu-angular/laskut/muokkaa/lasku-rivit.component'
import { LaskuKorkoService } from 'app/_jaettu/service/lasku/lasku-korko.service'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'

export interface LaskuForm extends JaettuLaskuForm {
  // Hallinnolliset
  lahetystyyppiPalkkapalvelu: FormControl<boolean>
}

@Component({
  templateUrl: './lasku.component.html',
  styleUrls: ['./lasku.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  private ngUnsubscribe = new Subject<void>()
  tilaaEsikatseluPaivitysSubject: ReplaySubject<number> = new ReplaySubject(1)

  laskunTuotteetObservable: ReplaySubject<LaskunTuote[]> = new ReplaySubject(1)
  laskunLiitetiedostotObservable: ReplaySubject<LadattavaLiitetiedosto[]> = new ReplaySubject(1)
  laskunTyyppiObservable: BehaviorSubject<LaskunTyyppi> = new BehaviorSubject(LaskunTyypit.TAVALLINEN)
  laskunVastaanottajanMaaObservable: BehaviorSubject<string> = new BehaviorSubject('FIN')
  laskuObservable: ReplaySubject<{ juurilasku: Lasku, kasiteltava: LaskuBase }> = new ReplaySubject(1)
  esikatselutiedot: ReplaySubject<LaskuPdfEsikatselutiedot> = new ReplaySubject(1)
  lahetaEmailinaObservable: Observable<boolean>
  lahetaSahkoisenaObservable: Observable<boolean>
  private _apixForwardConfigObservable: Observable<ApixReceivedInvoiceConfig>
  paivitaValidaatiot = {}

  laskuForm: FormGroup<LaskuForm>

  juurilasku: Lasku = null
  kasiteltava: LaskuBase = null
  reskontra: LaskuReskontra[] = null
  asetukset: Laskuasetukset = null
  aiemminMaksettu = 0

  alvitonSumma = 0
  alvSumma = 0

  asetuksissaOnOletustietojaVirhe = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private laskuService: LaskuService,
    private laskuKopioija: LaskuKopioija,
    private validationService: FormValidationService,
    private errorHandler: ErrorHandler,
    private laskuSharedService: LaskuSharedService,
    private vasenValikkoService: VasenValikkoService,
    private dialog: MatDialog,
    private currencyService: CurrencyService,
    private ladataanService: LadataanService,
    // private asetustenOletuksetService: AsetustenOletuksetService,
    // private snackbar: MatSnackBar,
    // private laskutResolve: LaskutComponentDataResolve,
    // private translationService: LemonTranslationService,
    // private location: Location,
    // private laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private reskontraService: ReskontraService,
    private asiakasService: AsiakasService,
    private konfiguraatioPalvelu: KonfiguraatioPalvelu,
    private kirjanpitajaService: KirjanpitajaService,
    private _lemonaidFirebase: FirebaseLemonaid,
    private _korkoService: LaskuKorkoService,
    private _laskuUriService: LaskuUriService
  ) {

    this.juurilasku = this.laskuKopioija.annaUusiLasku()
    this.kasiteltava = this.juurilasku

    const laskunTyyppi: LaskunTyyppi = {
      avain: this.kasiteltava.tyyppi
    }
    const tyyppiDisabled = this.kasiteltava.avain !== this.juurilasku.avain
    this.laskuForm = new FormGroup<LaskuForm>({

      // Yläosan dropparit
      tyyppi: new FormControl<LaskunTyyppi>({ value: laskunTyyppi, disabled: tyyppiDisabled }),
      kieli: new FormControl<TuettuKieli>({ value: this.kasiteltava.kieli, disabled: false }),
      valuutta: new FormControl<string>(this.kasiteltava.valuutta),

      // Hallinnolliset
      lahetystyyppiPalkkapalvelu: new FormControl<boolean>({ value: false, disabled: false }),

      // Asiakkaan tiedot
      // asiakastyyppi:          new FormControl<?>(,           [ Validators.required ] ),
      nimi: new FormControl<TypeaheadAsiakas>(null, []),
      ytunnus: new FormControl<string>(''),
      katuosoite: new FormControl<string>('', [Validators.required]),
      maa: new FormControl<string>('', [Validators.required]),
      postinro: new FormControl<string>('', [Validators.required]),
      postitoimipaikka: new FormControl<string>('', [Validators.required]),

      // Laskun muut tiedot
      laskunpvm: new FormControl<Date>({ value: null, disabled: true }),
      erapaiva: new FormControl<Date>(null, [Validators.required]),
      maksuaikaa: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      toimituspvm: new FormControl<Date>(null, []),
      lisatiedot: new FormControl<string>('', []),
      viitteenne: new FormControl<string>('', []),
      viivastyskorko: new FormControl<number>(null, [Validators.required, Validators.min(0.01)]),

      // Tuotteet
      tuotteet: new FormArray<FormGroup<LaskunRiviForm>>([], [this.vahintaanYksiRiviValidator]),
      liitetiedostot: new FormArray<FormControl<LadattavaLiitetiedosto>>([], [])

    })

    this.registerForChanges()

  }

  get laskuFormAsJaettu(): FormGroup<JaettuLaskuForm> {
    return this.laskuForm as unknown as FormGroup<JaettuLaskuForm>
  }

  vahintaanYksiRiviValidator = (c: FormArray<any>) => {
    if (c.length < 1) {
      return { min: 'true' }
    }
    return null
  }

  ngOnChanges() {
    // console.log('lasku changes')
  }

  ngOnInit() {

    this.laskuForm.get('tuotteet').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      auditTime(50)
    ).subscribe((aika) => {
      this.laskeSummat()
    })

    this.tilaaEsikatseluPaivitysSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      auditTime(250)
    ).subscribe((aika) => {
      // console.log('tilattu esikatselu paivitys: ', aika)
      if (this.asetukset && this.juurilasku && this.kasiteltava) {
        this.esikatselutiedot.next({
          asetukset: this.asetukset,
          juurilasku: this.juurilasku,
          kasiteltava: this.kasiteltava
        })
      }
    })

    this._apixForwardConfigObservable = this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<ApixReceivedInvoiceConfig>(null)
        }
        const uri = 'customers/' + asiakas.avain + '/apix-received-invoice-config/' + asiakas.avain
        return this._lemonaidFirebase.firestoreDoc<ApixReceivedInvoiceConfig>(uri).listen()
      })
    )

    this.lahetaSahkoisenaObservable = combineLatest([this._apixForwardConfigObservable, this.asiakasService.nykyinenAsiakasObservable]).pipe(
      map(([sahkoisetValitystiedot, asiakas]) => {
        if (
          sahkoisetValitystiedot &&
          sahkoisetValitystiedot.forwardLemontreeLaskut &&
          sahkoisetValitystiedot.paymentReceiveIsActive
        ) {
          // Jos maksut on asiakkaalla käytössä -> menee aina maksuihin.
          return true
        } else if (
          sahkoisetValitystiedot &&
          sahkoisetValitystiedot.forwardLemontreeLaskut &&
          sahkoisetValitystiedot.forwardActive
        ) {
          if (
            !sahkoisetValitystiedot.forwardAddress ||
            !sahkoisetValitystiedot.forwardAddress.sahkoinenOsoite ||
            !sahkoisetValitystiedot.forwardAddress.sahkoinenValittaja
          ) {
            throw new Error('Asiakkaalle asiakkaat/' + asiakas.avain + ' on merkitty välitys aktiiviseksi ja lemontreen laskujen välitys päälle, mutta sähköistä osoitetta ei löydy.')
          }
          return true
        } else if (
          asiakas?.sahkoinenLaskutusosoite &&
          asiakas?.sahkoinenLaskutusosoite.sahkoinenOsoite &&
          asiakas?.sahkoinenLaskutusosoite.sahkoinenValittaja
        ) {
          return true
        }
        return false
      })
    )

    this.lahetaEmailinaObservable = this.lahetaSahkoisenaObservable.pipe(
      map(lahetaSahkoisena => !lahetaSahkoisena)
    )

    combineLatest([this.route.data, this.asiakasService.nykyinenAsiakasObservable]).pipe(
      switchMap(([data, asiakas]: [{ data: LaskuComponentData }, Asiakas]) => {
        if (asiakas?.asiakasId && data?.data?.juurilasku?.avain) {
          const laskuReskontraUri = this._laskuUriService.getLaskuReskontraCollectionUri(asiakas.asiakasId, data.data.juurilasku.avain)
          return this._lemonaidFirebase.firestoreCollection<LaskuReskontra>(laskuReskontraUri).listen().pipe(
            map(reskontra => {
              return [data, asiakas, reskontra]
            })
          )
        }
        return of([null, null, null])
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: ([data, asiakas, reskontra]: [{ data: LaskuComponentData }, Asiakas, LaskuReskontra[]]) => {
        if (data && asiakas && reskontra) {
          this.alustaNakymaKokonaan(asiakas, data.data.asetukset, data.data.juurilasku, data.data.kasiteltava, reskontra)
        }
      },
      error: error => {
        this.errorHandler.handleError(error)
      }
    })

    setTimeout(() => {
      this.vasenValikkoService.paivitaAuki(false)
    }, 100)

    window.scroll({ top: 0, left: 0 })

  }

  private alustaNakymaKokonaan(asiakas: Asiakas, asetukset: Laskuasetukset, juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]) {

    if (!asiakas || !reskontra) {
      return
    }

    this.asetukset = asetukset
    this.juurilasku = juurilasku
    this.kasiteltava = kasiteltava
    this.kasiteltava.asetukset = this.asetukset
    this.reskontra = reskontra

    this.laskuSharedService.paivitaRyppaanJaKasiteltavanSummat(juurilasku, kasiteltava, reskontra)

    if (!this.kasiteltava.tuotteet) {
      this.kasiteltava.tuotteet = []
    }

    if (!this.kasiteltava.liitteet) {
      this.kasiteltava.liitteet = []
    }

    if (this.juurilasku.viivastyskorkoprosentti === undefined || this.juurilasku.viivastyskorkoprosentti === null) {
      this.juurilasku.viivastyskorkoprosentti = this._korkoService.annaOletusViivastyskorkoProsenttiNumber()
    }

    if (this.juurilasku.avain === UUDEN_LASKUN_AVAIN) {

      const kieli = asiakas.laskunKieli || 'fi'
      this.juurilasku.asiakas.avain = asiakas.avain

      // Osoitetiedot
      this.juurilasku.asiakas.nimi = asiakas.nimi
      this.juurilasku.asiakas.ytunnus = asiakas.ytunnus
      this.juurilasku.asiakas.katuosoite = asiakas.katuosoite
      this.juurilasku.asiakas.postinro = asiakas.postinro
      this.juurilasku.asiakas.postitmp = asiakas.postitmp
      this.juurilasku.asiakas.maa = asiakas.maa

      // Email tiedot
      this.juurilasku.asiakas.laskunKieli = kieli
      this.juurilasku.asiakas.laskunTyyppi = asiakas.laskunTyyppi || LaskunTyypit.TAVALLINEN.avain
      this.juurilasku.asiakas.laskunValuutta = asiakas.laskunValuutta || 'EUR'
      this.juurilasku.asiakas.laskunVastaanottajat = asiakas.laskunVastaanottajat || []

      this.juurilasku.kieli = kieli
      this.kasiteltava.kieli = kieli

      // ERILLISLASKUTUS MENEE AINA ERI VIITENUMEROLLA
      // this.juurilasku.viitenumero = asiakas.viitenumero

    }

    this.asetaTyyppiLaskusta(this.kasiteltava)
    this.laskunTuotteetObservable.next(this.kasiteltava.tuotteet)
    this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })

    // NOTE! THE EXACT ARRAY INSTANCE FROM KASITELTAVA MUST BE PLACED TO THIS
    // ARRAY! IT IS UGLY UGLY, BUT WORKS!
    // NO MAP, NO FILTER, NO NOTHING!
    // The reason for is that the sub component directly modifies the array, which is
    // inside the lasku instance. If we map of filter the array, a new array is created, and
    // the changes are not visible inside the lasku anymore.
    //
    // Loop below marks all already uploaded liitteet as done.
    // The data in lasku is cleaned when saving by the lasku.kopioija, which doesn't identify these
    // properties. (and hence leaves those out of the created copy)
    for (const liite of this.kasiteltava.liitteet) {
      const asLadattava = liite as LadattavaLiitetiedosto
      asLadattava.uploadDone = true
      asLadattava.uploadDonePercentage = 100
    }
    this.laskunLiitetiedostotObservable.next(this.kasiteltava.liitteet)

    if (this.kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
      const summat = this.laskuSharedService.annaLaskunSummatJaTilaLaskulle(this.juurilasku, reskontra)
      const aiemminMaksettuTaiHyvitetty = summat.reskontrasumma.plus(summat.hyvitykset.abs())
      this.aiemminMaksettu = 0 - this.currencyService.muutaBigDecimalRahaksi(aiemminMaksettuTaiHyvitetty)
    } else {
      this.aiemminMaksettu = 0
    }

    const reskontrasumma = this.reskontraService.annaReskontranSumma(reskontra)
    this.aiemminMaksettu = 0 - this.currencyService.muutaBigDecimalRahaksi(reskontrasumma)

    this.alustaLomakeLaskunTiedoilla()
  }

  ngAfterViewInit() {

  }

  asetaTyyppiLaskusta(kasiteltava: LaskuBase) {
    // // Set the existing type
    if (kasiteltava.tyyppi != null) {
      this.konfiguraatioPalvelu.tyypitObservable.pipe(
        take(1)
      ).subscribe(tyypit => {
        for (const tyyppi of tyypit) {
          if (kasiteltava.tyyppi === tyyppi.avain) {
            this.laskuForm.get('tyyppi').setValue(tyyppi)
            break
          }
        }
      })
    }
  }

  alustaLomakeLaskunTiedoilla() {

    if (!this.kasiteltava.tuotteet) {
      this.kasiteltava.tuotteet = []
    }

    // Hae laskunumero
    if (this.kasiteltava.lukossa) {
      // const muotoiltu = this.laskuSharedService.annaMuotoiltuLaskunumero(this.kasiteltava)
      // this.laskuForm.get('laskunro').setValue(muotoiltu)
    } else {
      this.laskuService.haeLaskunumeroArvio(this.kasiteltava).then(numero => {
        // console.log('TÄÄLLÄ!!', numero)
        this.kasiteltava.nro = numero
        this.tilaaEsikatseluPaivitysSubject.next(1)
        // const muotoiltu = this.laskuSharedService.annaMuotoiltuLaskunumero(this.kasiteltava)
        // this.laskuForm.get('laskunro').setValue(muotoiltu)
      })
    }

    // this.laskuForm.get('tyyppi').setValue(this.kasiteltava.tyyppi)
    this.laskuForm.get('kieli').setValue(this.kasiteltava.kieli)
    this.laskuForm.get('valuutta').setValue(this.kasiteltava.valuutta)

    this.laskuForm.get('lahetystyyppiPalkkapalvelu').setValue(this.juurilasku.lahetystyyppi === LaskunLahetystyyppi.PALKKAPALVELU)

    this.asetaTyyppiLaskusta(this.kasiteltava)

    this.laskeSummat()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  private laskeSummat() {
    const summat = this.laskuSharedService.annaLaskunSummat(this.kasiteltava)
    this.alvitonSumma = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaIlmanAlv)
    this.alvSumma = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaAlv)
    this.tilaaEsikatseluPaivitysSubject.next(1)
  }

  registerForChanges(): void {

    this.laskuForm.valueChanges.subscribe(() => {
      this.tilaaEsikatseluPaivitysSubject.next(1)
    })

    this.laskuForm.get('maa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(maa => {
      if (this.laskunVastaanottajanMaaObservable.value !== maa) {
        // console.log('maa muuttui', maa, '!==', this.laskunVastaanottajanMaaObservable.value)
        this.laskunVastaanottajanMaaObservable.next(maa)
      }
    })

    this.laskuForm.get('tyyppi').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: LaskunTyyppi) => {
      if (value) {
        if (this.kasiteltava.tyyppi !== value.avain) {
          // console.log('tyyppi muuttui', value?.avain, '!==', this.laskunTyyppiObservable.value?.avain)
          this.kasiteltava.tyyppi = value.avain
          this.laskunTyyppiObservable.next(value)
          this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })
        }
      }
    })

    this.laskuForm.get('kieli').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (value: TuettuKieli) => {
      if (value) {
        this.kasiteltava.kieli = value
      }
    })

    this.laskuForm.get('valuutta').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: string) => {
      if (value) {
        this.kasiteltava.valuutta = value
      }
    })

    this.laskuForm.get('lahetystyyppiPalkkapalvelu').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: boolean) => {
      this.juurilasku.lahetystyyppi = value ? LaskunLahetystyyppi.PALKKAPALVELU : null
    })

  }

  paivitaValittuAsiakas() {

    if (!this.kasiteltava.asiakas || !this.kasiteltava.asiakas.avain) {
      return
    }

    const asiakas = this.kasiteltava.asiakas

    // Aseta laskun tyyppi
    if (asiakas.laskunTyyppi) {
      this.kasiteltava.tyyppi = asiakas.laskunTyyppi
      this.asetaTyyppiLaskusta(this.kasiteltava)
    }

    // Aseta laskun valuutta
    if (asiakas.laskunValuutta) {
      this.kasiteltava.valuutta = asiakas.laskunValuutta
      this.laskuForm.get('valuutta').setValue(this.kasiteltava.valuutta)
    }

    // Aseta laskun kieli
    if (asiakas.laskunKieli) {
      this.kasiteltava.kieli = asiakas.laskunKieli
      this.laskuForm.get('kieli').setValue(this.kasiteltava.kieli)
    }

    // Päivitä kaikki.
    this.laskuObservable.next({ juurilasku: this.juurilasku, kasiteltava: this.kasiteltava })

  }

  private tarkistaOnkoTiedotOikein(): boolean {
    const virheitaLomakkeella = this.validationService.onkoLomakkeessaVirheita(this.laskuForm)
    if (!virheitaLomakkeella) {
      return true
    }
    this.validationService.merkitseKokoLomakeKosketuksi(this.laskuForm)
    this.paivitaValidaatiot = {}
    return false
  }

  // async save() {
  //   this.ladataanService.aloitaLataaminen()
  //   this.laskuService.tallennaLuonnos(this.juurilasku, this.kasiteltava).then(result => {
  //     const lokalisoitu = this.translationService.lokalisoi('lasku.tallennettu-onnistuneesti')
  //     this.snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
  //     this.location.replaceState('/laskutus/laskut/' + this.juurilasku.avain + '/' + this.kasiteltava.avain)
  //     this.alustaNakymaKokonaan(this.asiakas, this.asetukset, this.juurilasku, this.kasiteltava)
  //     this.ladataanService.lopetaLataaminen()
  //   }).catch(err => {
  //     this.ladataanService.lopetaLataaminen()
  //     this.errorHandler.handleError(err)
  //   })
  // }

  async saveAndSendElectronic() {

    if (!this.tarkistaOnkoTiedotOikein()) {
      return
    }

    this.ladataanService.aloitaLataaminen()
    const [asiakas, kirjanpitaja, sahkoisetValitystiedot] = await firstValueFrom(combineLatest([this.asiakasService.nykyinenAsiakasObservable, this.kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable, this._apixForwardConfigObservable]))
    if (
      sahkoisetValitystiedot &&
      sahkoisetValitystiedot.forwardLemontreeLaskut &&
      sahkoisetValitystiedot.paymentReceiveIsActive
    ) {
      // Jos maksut on asiakkaalla käytössä -> menee aina maksuihin.
      // Tallentaa laskun siis lemontree -yritykselle JA lisää sen
      // tulleisiin sähköisiin laskuihin ilman APIX:in läpi lähettämistä.
      await this.laskuService.merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(asiakas, sahkoisetValitystiedot.receiveAddress, kirjanpitaja, this.juurilasku, this.kasiteltava).then(() => {
        this.ladataanService.lopetaLataaminen()
        this.peruuta()
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    } else if (
      sahkoisetValitystiedot &&
      sahkoisetValitystiedot.forwardLemontreeLaskut &&
      sahkoisetValitystiedot.forwardActive
    ) {
      if (
        !sahkoisetValitystiedot.forwardAddress ||
        !sahkoisetValitystiedot.forwardAddress.sahkoinenOsoite ||
        !sahkoisetValitystiedot.forwardAddress.sahkoinenValittaja
      ) {
        throw new Error('Asiakkaalle asiakkaat/' + asiakas.avain + ' on merkitty välitys aktiiviseksi ja lemontreen laskujen välitys päälle, mutta sähköistä osoitetta ei löydy.')
      }
      if (sahkoisetValitystiedot.forwardRequiresApproving) {
        await this.laskuService.merkitseLaskuLahetetyksiTulostamallaToisenHyvaksyntajonoon(asiakas, sahkoisetValitystiedot.forwardAddress, kirjanpitaja, this.juurilasku, this.kasiteltava).then(() => {
          this.ladataanService.lopetaLataaminen()
          this.peruuta()
        }).catch(err => {
          this.ladataanService.lopetaLataaminen()
          this.errorHandler.handleError(err)
        })
      } else {
        await this.laskuService.merkitseLaskuLahetetyksiSahkoisesti(asiakas, sahkoisetValitystiedot.forwardAddress, kirjanpitaja, this.juurilasku, this.kasiteltava).then(() => {
          this.ladataanService.lopetaLataaminen()
          this.peruuta()
        }).catch(err => {
          this.ladataanService.lopetaLataaminen()
          this.errorHandler.handleError(err)
        })
      }
    } else if (
      asiakas.sahkoinenLaskutusosoite &&
      asiakas.sahkoinenLaskutusosoite.sahkoinenOsoite &&
      asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja
    ) {
      await this.laskuService.merkitseLaskuLahetetyksiSahkoisesti(asiakas, asiakas.sahkoinenLaskutusosoite, kirjanpitaja, this.juurilasku, this.kasiteltava).then(() => {
        this.ladataanService.lopetaLataaminen()
        this.peruuta()
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    } else {
      throw new Error('Trying to send electronic invoice without electronic invoice address.')
    }

  }

  async saveAndSendEmail() {

    if (!this.tarkistaOnkoTiedotOikein()) {
      return
    }

    // TODO: MODAA KUN https://github.com/angular/material2/issues/8493 on korjattu
    // PULL JOKA KORJAA: https://github.com/angular/material2/pull/9236
    const kaytettavaLeveys = window.innerWidth
    const kaytettavaKorkeus = window.innerHeight
    const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
    const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

    combineLatest([this.asiakasService.nykyinenAsiakasObservable, this.kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable]).pipe(
      take(1),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([asiakas, kirjanpitaja]) => {

      const data: LaskuSpostiData = {
        asiakas: asiakas,
        kirjanpitaja: kirjanpitaja,
        juurilasku: this.juurilasku,
        asetukset: this.asetukset,
        kasiteltava: this.kasiteltava
      }
      const settings: MatDialogConfig = {
        'data': data,
        panelClass: 'ilman-paddingia',
        maxWidth: '1600px',
        maxHeight: korkeus,
        width: leveys
      }

      const dialogRef = this.dialog.open(LaskuSpostiDialog, settings)
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.peruuta()
        }
      })

    })


  }

  saveAndPrint() {
    // if (this.tarkistaOnkoTiedotOikein()) {
    //   if (this.asetustenOletuksetService.tarkistaOnkoAsetuksetOletuksia(this.asetukset)) {
    //     this.asetuksissaOnOletustietojaVirhe = true
    //     return
    //   }
    //   this.ladataanService.aloitaLataaminen()
    //   const edellinenLaskunTila: LaskunTila = this.juurilasku.tila
    //   console.log(this.kasiteltava.erapvm)
    //   this.laskuService.merkitseLaskuLahetetyksiTulostamalla(this.juurilasku, this.kasiteltava).then(result => {
    //     this.ladataanService.lopetaLataaminen()
    //     const data: LaskuKatseleComponentExistingData = {
    //       juurilasku: this.juurilasku,
    //       kasiteltava: this.kasiteltava
    //     }
    //     this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(data)
    //     this.router.navigate(['/laskutus/laskut', this.juurilasku.avain, this.kasiteltava.avain, 'tulosta']).then(done => {
    //       this.vasenValikkoService.paivitaAuki(true)
    //     })

    //   }).catch(err => {
    //     this.ladataanService.lopetaLataaminen()
    //     this.errorHandler.handleError(err)
    //   })
    // }
  }

  peruuta() {

    this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      take(1)
    ).subscribe(asiakas => {
      if (asiakas) {
        this.router.navigate(['asiakkaat', asiakas.avain, 'laskutus']).then(done => {
          this.vasenValikkoService.paivitaAuki(true)
        })
      } else {
        this.router.navigate(['asiakkaat']).then(done => {
          this.vasenValikkoService.paivitaAuki(true)
        })
      }
    })

    // if (this.juurilasku && this.juurilasku.avain !== UUDEN_LASKUN_AVAIN) {
    //   const juurilaskunAvain = this.juurilasku.avain
    //   let kasiteltavanAvain = this.juurilasku.avain

    //   if (this.juurilasku.korvaus) {
    //     for (const korvaava of this.juurilasku.korvaus) {
    //       if (korvaava.avain !== UUDEN_LASKUN_AVAIN) {
    //         kasiteltavanAvain = korvaava.avain
    //       }
    //     }
    //   }

    //   this.router.navigate(['/laskutus/laskut', juurilaskunAvain, kasiteltavanAvain, 'katsele']).then(done => {
    //     this.vasenValikkoService.paivitaAuki(true)
    //   })
    // } else {
    //   this.router.navigate(['/laskutus/laskut']).then(done => {
    //     this.vasenValikkoService.paivitaAuki(true)
    //   })
    // }

  }

}
