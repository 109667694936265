<div *ngIf="!tiedot" style="text-align: left; position: relative; height: 300px;">
  <div lemon-loading [show]="true"></div>
</div>
<div [class.hidden]="!tiedot">

  <!-- <pre>{{kasiteltava?.email | json}}</pre> -->

  <!-- <table style="border-spacing: 0; width: 100%; padding-bottom: 20px;">
    <tr>
      <td style="border-bottom: lightgray solid 1px; padding-top: 15px; color: gray; padding-right: 7px; vertical-align: top; white-space: nowrap;">{{'email-perinteinen.vastaanottajat' | translate | async}}:</td>
      <td style="border-bottom: lightgray solid 1px; padding-top: 15px; vertical-align: top; width: 99%;">{{vastaanottajat}}</td>
    </tr>
    <tr>
      <td style="border-bottom: lightgray solid 1px; padding-top: 20px; color: gray; padding-right: 7px; vertical-align: top; white-space: nowrap;">{{'email-perinteinen.aihe' | translate | async}}:</td>
      <td style="border-bottom: lightgray solid 1px; padding-top: 20px; vertical-align: top; width: 99%;">{{korvaaMuuttujat(kasiteltava?.email?.aihe)}}</td>
    </tr>
  </table> -->

  <div #esikatseluContainer style="text-align: left; position: relative; text-align: left; max-width: 100%; overflow: visible;">
    <div #esikatseluSpacer style="width: 100%;"></div>
    <div style="width: 595px; transform-origin: 0 0; position: absolute; top: 0; left: 0;" class="mat-elevation-z4" [style.transform]="transformStyleObservable | async">

      <div #kokoemail>

        <table width="100%" cellspacing="0" cellpadding="0" style="padding: 0; border-spacing: 0; border-collapse: separate; width: 100%;" [style.background-color]="varit.header_taustavari">
          <tr>
            <!-- ERI KORKEUS KUIN AIDOSSA ESIKATSELUA VARTEN! AITO 95px -->
            <td colspan="3" height="40" style="height: 40px;" [style.background-color]="varit.header_taustavari">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: center; vertical-align: middle; font-size: 25px; font-weight: 600; line-height: 35px;" [style.background-color]="varit.header_taustavari" [style.color]="varit.header_fontti" align="center" valign="middle">
              <div style="padding-left: 12px; padding-right: 12px;">{{korvaaMuuttujat(kasiteltava?.email?.otsikko)}}</div>
            </td>
          </tr>
          <tr>
            <!-- ERI KORKEUS KUIN AIDOSSA ESIKATSELUA VARTEN! AITO 95px -->
            <td colspan="3" height="40" style="height: 40px;" [style.background-color]="varit.header_taustavari">&nbsp;</td>
          </tr>
          <tr [style.background-color]="varit.header_taustavari">
            <td height="30" width="8%" style="height: 30px; width:8%;" [style.background-color]="varit.header_taustavari">&nbsp;</td>
            <td height="30" width="84%" style="height: 30px; background-color: rgb(255,255,255); width:84%; border-bottom: 1px solid rgb(255,255,255); border-top-left-radius: 6px; border-top-right-radius: 6px; border-right: 1px solid rgb(229,229,229); border-top: 1px solid rgb(229,229,229); border-left: 1px solid rgb(229,229,229);">&nbsp;</td>
            <td height="30" width="8%" style="height: 30px; width:8%;" [style.background-color]="varit.header_taustavari">&nbsp;</td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="padding: 0; border-spacing: 0; border-collapse: separate; width: 100%; background-color: rgb(245,245,245);">
          <tr>
            <td height="10" style="height: 10px; background-color: rgb(245,245,245);">&nbsp;</td>
            <td height="10" style="height: 10px; background-color: rgb(255,255,255); border-right: 1px solid rgb(229,229,229); border-left: 1px solid rgb(229,229,229);">&nbsp;</td>
            <td height="10" style="height: 10px; background-color: rgb(245,245,245);">&nbsp;</td>
          </tr>
          <tr>
            <td style="background-color: rgb(245,245,245);">&nbsp;</td>
            <td style="background-color: rgb(255,255,255); border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; border-right: 1px solid rgb(229,229,229); border-bottom: 2px solid rgb(229,229,229); border-left: 1px solid rgb(229,229,229);">
              <table width="100%" cellspacing="0" cellpadding="0" style="padding: 0; border-spacing: 0; border-collapse: separate; width: 100%">
                <tr *ngIf="maksumuistutus">
                  <td colspan="3" style="font-weight: 300; padding-left: 12px; padding-right: 12px; font-size: 13px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 20px;" align="center" valign="middle" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(kasiteltava?.email?.teksti)))">

                  </td>
                </tr>
                <tr *ngIf="maksumuistutus">
                  <td colspan="3" height="32" style="height: 32px;">&nbsp;</td>
                </tr>
                <tr>
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 600; font-size: 16px; text-align: center; vertical-align: middle; line-height: 24px;" [style.color]="varit.otsikko_fontti" align="center" valign="middle">{{otsikko}}</td>
                </tr>
                <tr>
                  <td colspan="3" height="16" style="height: 16px;">&nbsp;</td>
                </tr>
                <tr>
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 300; font-size: 14px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 24px;" align="center" valign="middle">{{'email-perinteinen.summa' | translatel: kieli}}: {{summa |
                    lmoneyl: kasiteltava?.valuutta : kieli}}</td>
                </tr>
                <tr>
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 300; font-size: 14px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 24px;" align="center" valign="middle">{{'email-perinteinen.erapaiva' | translatel: kieli}}: {{
                    erapaiva }}</td>
                </tr>
                <tr>
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 300; font-size: 14px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 24px;" align="center" valign="middle">{{'email-perinteinen.viitenumero' | translatel: kieli}}:
                    {{shared.annaMuotoiltuViitenumero(juurilasku)}}</td>
                </tr>
                <tr>
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 300; font-size: 14px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 24px;" align="center" valign="middle">{{'email-perinteinen.tilinumero' | translatel: kieli}}:
                    {{asetukset?.iban}}</td>
                </tr>
                <tr *ngIf="virtuaaliviivakoodi">
                  <td colspan="3" height="24" style="height: 24px; padding-left: 12px; padding-right: 12px; font-weight: 300; font-size: 14px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 24px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; word-break: break-all;" align="center" valign="middle">
                    <br />{{'email-perinteinen.virtuaaliviivakoodi' | translatel: kieli}}:<br />{{virtuaaliviivakoodi}}</td>
                </tr>
                <tr *ngIf="kasiteltava?.email?.lisahuomautus">
                  <td colspan="3" height="60" style="height: 60px;">&nbsp;</td>
                </tr>
                <tr *ngIf="kasiteltava?.email?.lisahuomautus">
                  <td colspan="3" style="font-weight: 300; padding-left: 12px; padding-right: 12px; font-size: 13px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 20px;" align="center" valign="middle" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(kasiteltava?.email?.lisahuomautus)))">
                  </td>
                </tr>
                <tr>
                  <td colspan="3" height="32" style="height: 32px;">&nbsp;</td>
                </tr>
                <tr *ngIf="!maksumuistutus">
                  <td colspan="3" style="font-weight: 300; padding-left: 12px; padding-right: 12px; font-size: 13px; text-align: center; vertical-align: middle; color: rgb(0, 0, 0); line-height: 20px;" align="center" valign="middle" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(kasiteltava?.email?.teksti)))">
                  </td>
                </tr>
                <tr *ngIf="!maksumuistutus">
                  <td colspan="3" height="60" style="height: 60px;">&nbsp;</td>
                </tr>

                <ng-container *ngIf="kasiteltava?.email?.footer === 'footer1'">
                  <tr>
                    <td height="2" width="16%" style="height: 2px; width: 16%;">&nbsp;</td>
                    <td height="2" width="68%" style="height: 2px; width: 68%;">
                      <div style="height: 2px; width: 100%;" [style.background-color]="varit.valiviiva"></div>
                    </td>
                    <td height="2" width="16%" style="height: 2px; width: 16%;">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" height="20" style="height: 20px;">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <table width="100%" cellspacing="0" cellpadding="0" style="padding: 0; border-spacing: 0; border-collapse: separate; width: 100%">
                        <tr *ngIf="kasiteltava?.email?.logoInFooter && logo; else other">
                          <td width="50%" valign="top" style="width: 50%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                            <b>{{asetukset?.nimi}}</b><br />
                            <i *ngIf="asetukset?.slogan" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(asetukset?.slogan)))"><br /></i>
                            {{asetukset?.katuosoite}}, {{asetukset?.postinro}}, {{asetukset?.postitmp}}
                          </td>
                          <td width="25%" valign="top" style="width: 25%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                            <b>{{'email-perinteinen.yhteystiedot' | translatel: kieli}}</b><br />
                            {{asetukset?.replyto}}<br />
                            {{asetukset?.puhelin}}
                          </td>
                          <td width="25%" valign="top" style="width: 25%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                            <img style="max-width: 100%;" [src]="logo" />
                          </td>
                        </tr>
                        <ng-template #other>
                          <tr>
                            <td width="75%" valign="top" style="width: 75%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                              <b>{{asetukset?.nimi}}</b><br />
                              <i *ngIf="asetukset?.slogan" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(asetukset?.slogan)))"><br /></i>
                              {{asetukset?.katuosoite}}, {{asetukset?.postinro}}, {{asetukset?.postitmp}}
                            </td>
                            <td width="25%" valign="top" style="width: 25%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                              <b>{{'email-perinteinen.yhteystiedot' | translatel: kieli}}</b><br />
                              {{asetukset?.replyto}}<br />
                              {{asetukset?.puhelin}}
                            </td>
                          </tr>
                        </ng-template>
                      </table>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </ng-container>

                <ng-container *ngIf="kasiteltava?.email?.footer === 'footer2'">
                  <tr>
                    <td height="2" width="16%" style="height: 2px; width: 16%;">&nbsp;</td>
                    <td height="2" width="68%" style="height: 2px; width: 68%;">
                      <div style="height: 2px; width: 100%;" [style.background-color]="varit.valiviiva"></div>
                    </td>
                    <td height="2" width="16%" style="height: 2px; width: 16%;">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" height="20" style="height: 20px;">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <table width="100%" cellspacing="0" cellpadding="0" style="padding: 0; border-spacing: 0; border-collapse: separate; width: 100%">
                        <tr *ngIf="asetukset?.slogan; else other">
                          <td width="50%" valign="top" style="width: 50%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(korvaaMuuttujat(shared.valmisteleTeksti(asetukset?.slogan)))">
                          </td>
                          <td width="50%" valign="top" style="width: 50%; font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px;">
                            {{asetukset?.nimi}}<br />
                            {{muotoiltuYtunnus}}<br />
                            {{asetukset?.katuosoite}}<br />
                            {{asetukset?.postinro}} {{asetukset?.postitmp}}<br />
                            {{laskuttajanMaa}}<br />
                            {{asetukset?.puhelin}}<br />
                            {{asetukset?.replyto}}
                          </td>
                        </tr>
                        <ng-template #other>
                          <tr>
                            <td valign="top" align="center" style="font-size: 11px; font-weight: 300; line-height: 15px; vertical-align: top; padding: 3px; text-align: center;">
                              {{asetukset?.nimi}}<br />
                              {{asetukset?.ytunnus}}<br />
                              {{asetukset?.katuosoite}}<br />
                              {{asetukset?.postinro}} {{asetukset?.postitmp}}<br />
                              {{laskuttajanMaa}}<br />
                              {{asetukset?.puhelin}}<br />
                              {{asetukset?.replyto}}
                            </td>
                          </tr>
                        </ng-template>
                      </table>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </ng-container>

                <tr>
                  <td colspan="3" height="40" style="height: 40px;">&nbsp;</td>
                </tr>
              </table>
            </td>
            <td style="background-color: rgb(245,245,245);">&nbsp;</td>
          </tr>
          <tr>
            <td height="125" width="8%" style="height: 125px; background-color: rgb(245,245,245); width:8%;">&nbsp;</td>
            <td height="125" width="84%" style="height: 125px; background-color: rgb(245,245,245); width:84%; text-align: center; vertical-align: middle; font-size: 11px; font-weight: 200; color: rgb(0,0,0)" align="center" valign="middle">
              {{'email-perinteinen.lahetetty' | translatel: kieli}} - <a target="_BLANK" href="https://lemontree.fi/lemonaid" style="color: rgb(45, 202, 253); text-decoration: none;">{{'email-perinteinen.lue-lisaa' | translatel: kieli}}</a>
            </td>
            <td height="125" width="8%" style="height: 125px; background-color: rgb(245,245,245); width:8%;">&nbsp;</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</div>