<div [formGroup]="parent">

  <!-- <div style="position: relative;"> -->

  <!-- style="width: calc(96% - 120px); float: left;" -->
  <mat-form-field>
    <mat-label>{{ 'lasku.nimi' | translate | async }}</mat-label>
    <input #asiakassearch type="text" [name]="namename" autocomplete="kirjanpitoaineistoarkki" [matAutocomplete]="asiakasAuto" formControlName="nimi" (keydown.backspace)="tarkistaPoisto()" matInput required data-lpignore="true" />

    <!-- <mat-icon *ngIf="(asiakastyyppiObservable | async) === 'h'" class="light-blue-text" style="margin-right: 8px;" matPrefix>person</mat-icon> -->
    <!-- <mat-icon *ngIf="(asiakastyyppiObservable | async) === 'y'" class="light-blue-text" style="margin-right: 8px;" matPrefix>business</mat-icon> -->

    <!-- [@kuvake]="(asiakastyyppiObservable | async) === 'h' ? (asiakastyypinKuvakkeenNakyvyysObservable | async) : 'hidden'" -->
    <!-- [@kuvake]="(asiakastyyppiObservable | async) === 'y' ? (asiakastyypinKuvakkeenNakyvyysObservable | async) : 'hidden'" -->

    <!-- <i (click)="naytaAsiakastyyppiValitsin($event)" *ngIf="(asiakastyyppiObservable | async) === 'h'" style="margin-right: 6px; font-size: 125%; cursor: pointer;" title="Vaihda asiakastyyppiä" class="fa fa-user-circle mat-icon light-blue-text" matPrefix></i> -->
    <!-- <i (click)="naytaAsiakastyyppiValitsin($event)" *ngIf="(asiakastyyppiObservable | async) === 'y'" style="margin-right: 6px; font-size: 125%; cursor: pointer;" title="Vaihda asiakastyyppiä" class="fa fa-industry mat-icon light-blue-text" matPrefix></i> -->

    <i *ngIf="valittuAsiakas" style="cursor: pointer; font-size: 30px;" title="Poista valinta" matSuffix class="icon ion-ios-close" (click)="poistaValittu()"></i>
    <mat-hint>
      <mat-icon *ngIf="naytaCheck" style="font-size: calc(100% + 4px); position: absolute; top: 4px; left: -4px;" class="green-text">done</mat-icon>
      <span *ngIf="naytaCheck" style="display: inline-block; width: 14px;"></span>
      <span *ngIf="asiakashaunVihjetekstiObservable | async; let asiakashaunVihjeteksti;" [class.red-warning]="asiakashaunVihjeteksti === ('lasku.muokkaa-asiakasta' | translate | async)">{{ asiakashaunVihjeteksti }}</span>
    </mat-hint>
    <mat-error *ngIf="nimi?.errors?.required && (nimi?.dirty || nimi?.touched)">
      {{ 'lasku.validation.nimi.required' | translate | async }}
    </mat-error>
  </mat-form-field>
  <mat-autocomplete #asiakasAuto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let asiakas of suodatetutAsiakkaat | async" [value]="asiakas">
      <!-- <span class="light-blue-text">{{ asiakas.nro }}</span> --> {{ asiakas.nimi }}<ng-container *ngIf="asiakas.ytunnus != null"> ({{asiakas.ytunnus}})</ng-container>
    </mat-option>
  </mat-autocomplete>

  <!-- [@visibili_ tyChanged]="asiakastyypinNakyvyysObservable | async" -->
  <!-- <div style="transform: scale(.7); transform-origin: right top; position: absolute; top: 0; right: 0; display: flex; justify-content: right; width: 100%;"> -->
  <!-- <div *ngIf="!(asiakastyypinNakyvyysObservable | async)" style="font-size: 12px; position: absolute; top: -40px; width: 100%; text-align: right;">
        Voit muuttaa asiakastyyppiä myöhemmin napauttamalla kuvaketta nimen edessä.
      </div> -->
  <!-- <mat-radio-group formControlName="asiakastyyppi" style="margin-left: auto;" required>
        <mat-radio-button color="primary" value="h">{{'asiakas.asiakastyyppi.henkilo' | translate | async}}</mat-radio-button>
        <mat-radio-button color="primary" value="y">{{'asiakas.asiakastyyppi.yritys' | translate | async}}</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="asiakastyyppi?.errors?.required && (asiakastyyppi?.dirty || asiakastyyppi?.touched)" style="font-size: 12px; position: absolute; top: -20px; width: 100%; text-align: right;">
        {{ 'lasku.validation.asiakastyyppi.required' | translate | async }}
      </mat-error>
    </div> -->

  <!-- <mat-form-field style="width: 120px; float: right;" class="valitsin">
      <lemon-henkilo-yritys-valitsin formControlName="asiakastyyppi" required></lemon-henkilo-yritys-valitsin>
      <mat-error *ngIf="asiakastyyppi?.errors?.required && (asiakastyyppi?.dirty || asiakastyyppi?.touched)">
        {{ 'lasku.validation.asiakastyyppi.required' | translate | async }}
      </mat-error>
    </mat-form-field> -->
  <!-- </div> -->

  <!-- class="ei_alaviivaa" -->
  <!-- <mat-form-field [floatLabel]="'always'" class="radio-buttonit">
    <mat-label>{{ 'asiakas.asiakastyyppi.asiakastyyppi' | translate | async }}</mat-label>
<input type="text" formControlName="asiakastyyppi"  style="display: none;" matInput required />
    <div style="transform: scale(.8); height: 28px; transform-origin: left 50%;">
      <mat-radio-group formControlName="asiakastyyppi" required>
        <mat-radio-button color="primary" value="h">{{'asiakas.asiakastyyppi.henkilo' | translate | async}}</mat-radio-button>
        <mat-radio-button color="primary" value="y">{{'asiakas.asiakastyyppi.yritys' | translate | async}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-error *ngIf="asiakastyyppi?.errors?.required && (asiakastyyppi?.dirty || asiakastyyppi?.touched)">
      {{ 'lasku.validation.asiakastyyppi.required' | translate | async }}
    </mat-error>
  </mat-form-field> -->

  <mat-form-field *ngIf="onkoYtunnusPakollinen">
    <mat-label>{{ ytunnusPlaceholderObservable | async }}</mat-label>
    <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="namename" formControlName="ytunnus" matInput data-lpignore="true" />
    <mat-hint>{{vatTunnuksenValidointitulos | async}}</mat-hint>
    <mat-error *ngIf="ytunnus?.errors?.required && (ytunnus?.dirty || ytunnus?.touched)">
      {{ 'lasku.validation.ytunnus.required' | translate | async }}
    </mat-error>
    <mat-error *ngIf="ytunnus?.errors?.invalid_ytunnus && (ytunnus?.dirty || ytunnus?.touched)">
      {{ 'lasku.validation.ytunnus.invalid-ytunnus' | translate | async }}
    </mat-error>
    <mat-error *ngIf="ytunnus?.errors?.invalid_vat_number && (ytunnus?.dirty || ytunnus?.touched)">
      {{ 'lasku.validation.ytunnus.invalid-vat-number' | translate | async }}
    </mat-error>
    <mat-error *ngIf="ytunnus?.errors?.invalid_vat_number_or_ytunnus && (ytunnus?.dirty || ytunnus?.touched)">
      {{ 'lasku.validation.ytunnus.invalid-vat-number-or-ytunnus' | translate | async }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'lasku.katuosoite' | translate | async }}</mat-label>
    <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="namename" formControlName="katuosoite" matInput required data-lpignore="true" />
    <mat-error *ngIf="katuosoite?.errors?.required && (katuosoite?.dirty || katuosoite?.touched)">
      {{ 'lasku.validation.katuosoite.required' | translate | async }}
    </mat-error>
  </mat-form-field>

  <div>
    <mat-form-field style="width: 48%; float: left;">
      <mat-label>{{ 'lasku.postinro' | translate | async }}</mat-label>
      <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="namename" formControlName="postinro" matInput required data-lpignore="true" />
      <mat-error *ngIf="postinro?.errors?.required && (postinro?.dirty || postinro?.touched)">
        {{ 'lasku.validation.postinro.required' | translate | async }}
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 48%; float: right;">
      <mat-label>{{ 'lasku.postitoimipaikka' | translate | async }}</mat-label>
      <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="namename" formControlName="postitoimipaikka" matInput required data-lpignore="true" />
      <mat-error *ngIf="postitoimipaikka?.errors?.required && (postitoimipaikka?.dirty || postitoimipaikka?.touched)">
        {{ 'lasku.validation.postitoimipaikka.required' | translate | async }}
      </mat-error>
    </mat-form-field>
  </div>
  <div style="clear: both; display: block;"></div>

  <mat-form-field>
    <mat-label>{{ 'lasku.maa' | translate | async }}</mat-label>
    <mat-select formControlName="maa" style="width: 100%;" required>
      <mat-option *ngFor="let maa of suodatetutMaat | async" [value]="maa.koodi">
        {{ maa.nimi }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="maa?.errors?.required && (maa?.dirty || maa?.touched)">
      {{ 'lasku.validation.maa.required' | translate | async }}
    </mat-error>
  </mat-form-field>
</div>