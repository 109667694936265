/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, ElementRef, HostListener, Input, OnInit, OnDestroy, Provider, forwardRef, Renderer2 } from '@angular/core'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TuettuKieli } from '../../_shared-core/model/common'
import { LemonTranslationService } from '../service/lemon-translation.service'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const MODIFIER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LemonNumberOnlyDirective),
  multi: true
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[decimalNumberField]',
  host: {
    // FIXME: see https://angular.io/guide/styleguide#style-06-03
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '(change)': 'onChangeValueWithTarget($event.target)',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '(input)': 'onChangeValueWithTarget($event.target)',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '(blur)': 'onTouched()'
  },
  providers: [MODIFIER_CONTROL_VALUE_ACCESSOR]
})
export class LemonNumberOnlyDirective implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() numberOfDecimals: number = 2
  @Input() allowNegative: boolean = true
  @Input() showAllDecimals: boolean = false


  private _kieli: TuettuKieli = 'fi'
  private _ngUnsubscribe = new Subject<void>()

  private _writeToForm: (value: number) => void
  onTouched: () => void = () => { }

  onChangeValueWithTarget(target: HTMLInputElement) {
    const value = target.value
    if (!this._currencyService.onkoMerkkijonoNumeroSalliTuhannenValilyontia(value)) {
      target.value = this._currencyService.poistaNumeroonKuulumattomatMerkit(value, 999)
    }
    this.onChangeValue(value)
  }

  onChangeValue(value: string) {
    if (this._writeToForm) {
      const valueAsNumber = this._currencyService.muutaMerkkijonoNumeroksiKaikkiDesimaalit(value)
      // console.log('in onChangeValue', value, valueAsNumber)
      this._writeToForm(valueAsNumber)
    }
  }

  registerOnChange(fn: (value: number) => void) { this._writeToForm = fn }
  registerOnTouched(fn: () => void): void { this.onTouched = fn }

  writeValue(value: number) {
    // console.log('WRITE', value)
    if (this.showAllDecimals) {
      const formattedValue = this._currencyService.formatoiDesimaaliSailytaNollat(value, this.numberOfDecimals, this._kieli)
      this._renderer.setProperty(this._inputElement, 'value', formattedValue)
    } else {
      const formattedValue = this._currencyService.formatoiDesimaali(value, this.numberOfDecimals, this._kieli)
      this._renderer.setProperty(this._inputElement, 'value', formattedValue)
    }
    // console.log('WRITE', formattedValue)
  }

  ngOnInit() {
    this._lemonTranslationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(lang => {
      this._kieli = lang
      // this._inputElement.value = this._currencyService.formatoiDesimaali(this.value, this.numberOfDecimals, this._kieli), { emitEvent: false, onlySelf: true, emitViewToModelChange: false, emitModelToViewChange: true })
    })
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private decimalSeparatorDot = '.'
  private decimalSeparatorComma = ','
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Del', // Old browsers send this
    'Tab',
    'Escape',
    'Esc', // Old browsers send this
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Left', // Old browsers send this
    'Right', // Old browsers send this
    'Clear',
    'Copy',
    'Paste'
  ]

  private _inputElement: HTMLInputElement

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _currencyService: CurrencyService,
    private _lemonTranslationService: LemonTranslationService
  ) {
    this._inputElement = _el.nativeElement
  }

  private isDecimalKey(key: string): boolean {
    return key === this.decimalSeparatorComma || key === this.decimalSeparatorDot || key === 'Decimal' || key === 'Separator'
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {

    const key = this.getKeyForKeyCode(e) || 'unknowien'

    const isMetaOrCtrl = e.ctrlKey || e.metaKey

    if (
      this.navigationKeys.indexOf(key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((key === 'a' || key === 'A') && isMetaOrCtrl) || // Allow: Ctrl|Meta+A
      ((key === 'c' || key === 'C') && isMetaOrCtrl) || // Allow: Ctrl|Meta+C
      ((key === 'v' || key === 'V') && isMetaOrCtrl) || // Allow: Ctrl|Meta+V
      ((key === 'x' || key === 'X') && isMetaOrCtrl) || // Allow: Ctrl|Meta+X
      this.isAllowedPlusOrMinus(key) || // Allow: + (plus) and - (minus) characters as the first character (and only once)
      (this.isDecimal() && (this.isDecimalKey(key) && this.getDecimalSeparatorCount(this._inputElement.value) < 1)) // Allow: only one decimal point
    ) {
      // let it happen, don't do anything
      return
    }

    // console.log('A', key, e.metaKey, e.ctrlKey)

    // Ensure that it is a number or else stop the keypress
    if (key === ' ' || isNaN(Number(key))) {
      // console.log('stop 1')
      e.preventDefault()
    }

    // console.log('B', this.numberOfDecimals, this._inputElement.value)
    // console.log('C', this.getCharactersAfterLastDecimalPoint(this._inputElement.value))
    // console.log('D', this.getSelection(this._inputElement))
    // console.log('E', this.isCaretBeforeDecimal(this._inputElement))

    // If the max amount of decimals have been set don't continue
    if (
      this.numberOfDecimals &&
      this.getCharactersAfterLastDecimalPoint(this._inputElement.value) >= this.numberOfDecimals &&
      !this.getSelection(this._inputElement) &&
      !this.isCaretBeforeDecimal(this._inputElement)
    ) {
      // console.log('stop 2')
      e.preventDefault()
    }

  }

  // Allow: + (plus) and - (minus) characters as the first character (and only once)
  private isAllowedPlusOrMinus(key: string): boolean {
    if (
      (this.allowNegative && (key === '-' || key === 'Subtract') && this._inputElement.value.indexOf('-') < 0) ||
      ((key === '+' || key === 'Add') && this._inputElement.value.indexOf('+')) < 0
    ) {
      if (
        (!this._inputElement.selectionStart && !this._inputElement.selectionEnd) ||
        (this._inputElement.selectionStart === 0 || this._inputElement.selectionEnd === 0)
      ) {
        return true
      }
    }
    return false
  }

  private isDecimal(): boolean {
    return this.numberOfDecimals > 0
  }

  private getCharactersAfterLastDecimalPoint(input: string): number {
    const value = input || ''
    let count = 0
    let commaFound = false
    for (const char of value) {
      if (char === this.decimalSeparatorDot || char === this.decimalSeparatorComma) {
        count = 0
        commaFound = true
      } else if (commaFound) {
        count++
      }
    }
    return count
  }

  private getDecimalSeparatorCount(input: string): number {
    const value = input || ''
    let count = 0
    for (const char of value) {
      if (char === this.decimalSeparatorDot || char === this.decimalSeparatorComma) {
        count++
      }
    }
    return count
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData('text/plain')
    this.pasteData(pastedInput)
    event.preventDefault()
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData('text')
    this._inputElement.focus()
    this.pasteData(textData)
    event.preventDefault()
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanatizeInput(pastedContent)
    const pasted = document.execCommand('insertText', false, sanitizedContent)
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this._inputElement
      this._inputElement.setRangeText(sanitizedContent, start, end, 'end')
    }
  }

  private sanatizeInput(input: string): string {

    let result = this._currencyService.poistaNumeroonKuulumattomatMerkit(input, this.numberOfDecimals)

    const maxLength = this._inputElement.maxLength
    if (maxLength > 0) { // the input element has maxLength limit
      const allowedLength = maxLength - this._inputElement.value.length
      result = allowedLength > 0 ? result.substring(0, allowedLength) : ''
    }
    return result || ''

  }

  private isCaretBeforeDecimal(textbox: HTMLInputElement) {
    const decimalSeparatorIndex = textbox.value.indexOf(this.decimalSeparatorComma) > -1 ? textbox.value.indexOf(this.decimalSeparatorComma) : textbox.value.indexOf(this.decimalSeparatorDot)
    if (decimalSeparatorIndex < 0) {
      return true
    }
    if (textbox.selectionEnd <= decimalSeparatorIndex) {
      return true
    }
    return false
  }

  private getSelection(textbox: HTMLInputElement) {
    // all browsers (including IE9 and up), except IE before version 9
    if (window.getSelection && document.activeElement === textbox) {
      const startIndex = textbox.selectionStart
      const endIndex = textbox.selectionEnd
      if (endIndex - startIndex > 0) {
        return textbox.value.substring(textbox.selectionStart, textbox.selectionEnd)
      }
    }
    return null
  }

  // Borrowed from https://github.com/cvan/keyboardevent-key-polyfill
  protected getKeyForKeyCode(event: KeyboardEvent): string {

    const key = event.key
    if (key !== undefined && key !== null) {
      return key
    }

    const keycode = event.which || event.keyCode
    if (keycode === undefined && keycode == null) {
      return null
    }

    const keys = {
      3: 'Cancel',
      6: 'Help',
      8: 'Backspace',
      9: 'Tab',
      12: 'Clear',
      13: 'Enter',
      16: 'Shift',
      17: 'Control',
      18: 'Alt',
      19: 'Pause',
      20: 'CapsLock',
      27: 'Escape',
      28: 'Convert',
      29: 'NonConvert',
      30: 'Accept',
      31: 'ModeChange',
      32: ' ',
      33: 'PageUp',
      34: 'PageDown',
      35: 'End',
      36: 'Home',
      37: 'ArrowLeft',
      38: 'ArrowUp',
      39: 'ArrowRight',
      40: 'ArrowDown',
      41: 'Select',
      42: 'Print',
      43: 'Execute',
      44: 'PrintScreen',
      45: 'Insert',
      46: 'Delete',
      48: '0',
      49: '1',
      50: '2',
      51: '3',
      52: '4',
      53: '5',
      54: '6',
      55: '7',
      56: '8',
      57: '9',
      91: 'OS',
      93: 'ContextMenu',
      96: '0',
      97: '1',
      98: '2',
      99: '3',
      100: '4',
      101: '5',
      102: '6',
      103: '7',
      104: '8',
      105: '9',
      106: '*',
      107: '+',
      109: '-',
      110: '.',
      111: '/',
      144: 'NumLock',
      145: 'ScrollLock',
      181: 'VolumeMute',
      182: 'VolumeDown',
      183: 'VolumeUp',
      186: ';',
      187: '=',
      188: ',',
      189: '-',
      190: '.',
      191: '/',
      192: '`',
      219: '[',
      220: '\\',
      221: ']',
      222: "'",
      224: 'Meta',
      225: 'AltGraph',
      246: 'Attn',
      247: 'CrSel',
      248: 'ExSel',
      249: 'EraseEof',
      250: 'Play',
      251: 'ZoomOut'
    }

    return keys[keycode]

  }

}
