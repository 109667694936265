import { Component, OnInit, ChangeDetectionStrategy, NgZone, Input, OnDestroy, Output, EventEmitter } from '@angular/core'

import { Observable, BehaviorSubject, firstValueFrom, Subject, takeUntil, combineLatest, take } from 'rxjs'


import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Tilikausi, TilinpaatoksenTuloslaskelmanTyyppi } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { TilinpaatosLiitetiedot } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilinpaatosLiitetiedotJaettuService } from 'app/_jaettu-lemonator/service/tilinpaatos-liitetiedot-jaettu.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'

interface MainForm {
  taseenLoppusummaYli: FormControl<boolean>
  liikevaihtoYli: FormControl<boolean>
  henkilostoYli: FormControl<boolean>
  tilinpaatoksenTuloslaskelma: FormControl<TilinpaatoksenTuloslaskelmanTyyppi>
  tilintarkastetaan: FormControl<boolean>
  allekirjoitetaanLemonaidissa: FormControl<boolean>
}

@Component({
  selector: '[app-kirjanpito-tilinpaatos-aloitus]',
  templateUrl: './tilinpaatos-aloitus.component.html',
  styleUrls: ['./tilinpaatos-aloitus.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoTilinpaatosAloitusComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() tilinpaatosStatusObservable: Observable<TilinpaatosStatus>
  @Input() liitetiedotObservable: Observable<TilinpaatosLiitetiedot>

  @Output() startDone: EventEmitter<boolean> = new EventEmitter()

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  latausVirhe: string

  readonly TilinpaatoksenTuloslaskelmanTyyppi = TilinpaatoksenTuloslaskelmanTyyppi

  onkoMikro: boolean

  form: FormGroup<MainForm>

  private _cachedLiitetiedot: TilinpaatosLiitetiedot = this._tilinpaatosLiitetiedotJaettuService.annaTyhjaLiitetiedotObject(null, null)


  constructor(
    private _ngZone: NgZone,
    private _firebaseLemonator: FirebaseLemonator,
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _tilinpaatosLiitetiedotJaettuService: TilinpaatosLiitetiedotJaettuService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _copyService: KopioijaPalvelu
  ) { }

  ngOnInit() {

    this.form = new FormGroup<MainForm>({
      taseenLoppusummaYli: new FormControl<boolean>(null),
      liikevaihtoYli: new FormControl<boolean>(null),
      henkilostoYli: new FormControl<boolean>(null),
      tilinpaatoksenTuloslaskelma: new FormControl<TilinpaatoksenTuloslaskelmanTyyppi>(null, Validators.required),
      tilintarkastetaan: new FormControl<boolean>(null, Validators.required),
      allekirjoitetaanLemonaidissa: new FormControl<boolean>(null, Validators.required)
    })

    this.taseenLoppusummaYli.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._cachedLiitetiedot.valitut['ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI'] = val ? true : this._firebaseLemonator.firestoreDeleteMarker())
    this.liikevaihtoYli.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._cachedLiitetiedot.valitut['ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI'] = val ? true : this._firebaseLemonator.firestoreDeleteMarker())
    this.henkilostoYli.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._cachedLiitetiedot.valitut['ONKO_MIKROYRITYS_HENKILOSTO_YLI'] = val ? true : this._firebaseLemonator.firestoreDeleteMarker())
    this.tilintarkastetaan.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._cachedLiitetiedot.tilintarkastetaan = val)
    this.allekirjoitetaanLemonaidissa.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => this._cachedLiitetiedot.allekirjoitetaanLemonaidissa = val)

    combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.selectedTilikausiObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([asiakas, selected]) => {
      if (this.tilinpaatoksenTuloslaskelma?.value) {
        return
      }
      if (selected) {
        const tilikausiInFirestore = asiakas.tilikaudet.find(tk => tk.avain === selected.avain) // selectedTilikausiObservable is based on a manually updated Subject, i.e. does not contain up-to-date data so we're using asiakas.tilikaudet instead here
        if (tilikausiInFirestore.tilinpaatoksenTuloslaskelma) {
          /** Use saved value */
          this.tilinpaatoksenTuloslaskelma.setValue(tilikausiInFirestore.tilinpaatoksenTuloslaskelma)
          return
        }
      }

      if (asiakas?.tilinpaatosRekisteroidaanBruttotuloslaskelmalla) {
        /** Use default settings, if available */
        this.tilinpaatoksenTuloslaskelma.setValue(TilinpaatoksenTuloslaskelmanTyyppi.BRUTTO)
        return
      }
      /** Fall back to VIRALLINEN **/
      this.tilinpaatoksenTuloslaskelma.setValue(TilinpaatoksenTuloslaskelmanTyyppi.VIRALLINEN)
    })

    combineLatest([
      this.taseenLoppusummaYli.valueChanges,
      this.liikevaihtoYli.valueChanges,
      this.henkilostoYli.valueChanges
    ]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(vals => {
      this.onkoMikro = vals.filter(val => val)?.length < 2
    })

    combineLatest([this.liitetiedotObservable, this.selectedTilikausiObservable]).pipe(
      take(1)
    ).subscribe(([liitetiedot, tilikausi]) => {
      this.taseenLoppusummaYli.setValue(liitetiedot?.valitut['ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI'])
      this.liikevaihtoYli.setValue(liitetiedot?.valitut['ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI'])
      this.henkilostoYli.setValue(liitetiedot?.valitut['ONKO_MIKROYRITYS_HENKILOSTO_YLI'])

      this.tilintarkastetaan.setValue(liitetiedot?.tilintarkastetaan)
      this.allekirjoitetaanLemonaidissa.setValue(liitetiedot?.allekirjoitetaanLemonaidissa)

      this._cachedLiitetiedot = liitetiedot ? liitetiedot : this._tilinpaatosLiitetiedotJaettuService.annaTyhjaLiitetiedotObject(tilikausi, null)
    })



  }

  get taseenLoppusummaYli(): AbstractControl {
    return this.form.get('taseenLoppusummaYli')
  }

  get liikevaihtoYli(): AbstractControl {
    return this.form.get('liikevaihtoYli')
  }

  get henkilostoYli(): AbstractControl {
    return this.form.get('henkilostoYli')
  }

  get tilinpaatoksenTuloslaskelma(): AbstractControl {
    return this.form.get('tilinpaatoksenTuloslaskelma')
  }

  get tilintarkastetaan(): AbstractControl {
    return this.form.get('tilintarkastetaan')
  }

  get allekirjoitetaanLemonaidissa(): AbstractControl {
    return this.form.get('allekirjoitetaanLemonaidissa')
  }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  async aloitaTilinpaatos() {
    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)
    const kirjanpitajaPromise = this._kirjautunutKayttajaService.getKirjanpitajanTiedot()

    const [asiakas, tilikausi, kirjanpitaja] = await Promise.all([asiakasPromise, tilikausiPromise, kirjanpitajaPromise])

    for (const tk of asiakas.tilikaudet) {
      if (tk.avain === tilikausi?.avain) {
        tk.tilinpaatosStarted = 1
        tk.tilinpaatoksenTuloslaskelma = this.tilinpaatoksenTuloslaskelma?.value
        break
      }
    }

    const tallennettava = this._cachedLiitetiedot
    this._tilinpaatosLiitetiedotJaettuService.valmisteleTallennettava(tallennettava, kirjanpitaja.uid, tilikausi)

    /** Delete fields entirely if necessary to enable saving the liitetiedot obejct */
    if (tallennettava.osingotNostettavissa === undefined) { delete tallennettava.osingotNostettavissa }
    if (tallennettava.osingotNostettavissaPvm === undefined) { delete tallennettava.osingotNostettavissaPvm }

    const batch = this._firebaseLemonator.firestoreBatch()
    const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
    batch.set(uri, tallennettava, { merge: true })

    const historiakopio: TilinpaatosLiitetiedot = this._copyService.cloneObjectDeep(tallennettava)
    historiakopio.avain = this._firebaseLemonator.firestoreCreateId()
    const historiaUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotHistoriaUri(asiakas.avain, tilikausi, historiakopio.avain)
    batch.set(historiaUri, historiakopio)

    await batch.commit()
    await this._asiakasService.paivitaAsiakas(asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false)
    return this.startDone.emit()

  }


}
