import { TranslationProvider } from './translation-provider.service'

export abstract class CountryLocalizationService {
  abstract getName(value: string, lang: string): string
  abstract getNameTranslateTaxAreaToCountryName(value: string, lang: string): string
}

export class TranslationService {

  private provider = new TranslationProvider()

  lokalisoi(avain: string, kieli: string, parametrit?: any): string {
    const raakaString = this.raakaString(avain, kieli)
    if (raakaString) {
      if (parametrit) {
        return this.korvaaParametrit(raakaString, parametrit)
      }
      return raakaString
    }
    return 'Ei käännöstä avaimelle ' + avain + ' kielellä ' + kieli
  }

  korvaaParametrit(raakaString: string, parametrit?: any): string {
    if (parametrit) {
      for (const key of Object.keys(parametrit)) {
        const keyWithBraces = '{{' + key + '}}'
        const value = parametrit[key]
        // console.log('replace ', '{{' + key + '}}', 'with', value)
        raakaString = raakaString.replace(keyWithBraces, value)
        while (raakaString.indexOf(keyWithBraces) > -1) {
          raakaString = raakaString.replace(keyWithBraces, value)
        }
      }
    }
    return raakaString
  }

  private raakaString(avain: string, kieli: string): string | null {
    const kaannokset = this.provider.getAllTranslations()
    if (kaannokset) {
      const kaannoksetKielelle = this.provider.getAllTranslations()[kieli]
      if (kaannoksetKielelle) {
        const avaintenOsat = avain.split('.')
        if (avaintenOsat.length > 1) {
          let nykyinen = kaannoksetKielelle[avaintenOsat[0]]
          avaintenOsat.shift()
          for (const avaimenOsa of avaintenOsat) {
            if (nykyinen) {
              nykyinen = nykyinen[avaimenOsa]
            }
          }
          if (nykyinen) {
            return nykyinen
          }
        } else {
          return kaannoksetKielelle[avaintenOsat[0]]
        }
      }
    }
    return null
  }

}
