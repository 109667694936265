import { IbanService } from '../service/iban.service'
import { CurrencyService } from '../service/currency.service'
import { LocalDate } from '../model/common'

import { StringService } from '../service/string.service'
import { DateService } from '../service/date.service'

import { Big } from 'big.js'
import { BicService } from './bic.service'

export enum LaskunQrKoodinCharacterSet {
  'UTF8' = '1',
  'ISO88591' = '2',
  'ISO88592' = '3',
  'ISO88594' = '4',
  'ISO88595' = '5',
  'ISO88597' = '6',
  'ISO885910' = '7',
  'ISO885915' = '8'
}

export interface LaskunQrKoodinOsat {
  serviceTag: 'BCD' | string
  version: '001' | string
  charset: LaskunQrKoodinCharacterSet
  identificationCode: 'SCT' | string
  bic: string
  iban: string
  maksunSaajaNimi: string
  valuutta: string
  summa: number
  purposeCode: string
  viitenumero: string
  erapaiva: LocalDate
  remittanceInfo: string
  lisatiedot: string
}

export interface VirtuaaliviivakoodinOsat {
  iban: string
  summa: Big
  viitenumero: string
  /** Päivämäärä muotoiltuna pp.kk.vvvv */
  erapvm: LocalDate
}

export class ViitenumeroRfService {

  private readonly REFERENCE_FORMAT = /^RF[0-9]{2}[0-9A-Z]+$/

  private charTable: Map<string, number> = new Map([
    ['A', 10], ['B', 11], ['C', 12], ['D', 13], ['E', 14], ['F', 15], ['G', 16],
    ['H', 17], ['I', 18], ['J', 19], ['K', 20], ['L', 21], ['M', 22], ['N', 23],
    ['O', 24], ['P', 25], ['Q', 26], ['R', 27], ['S', 28], ['T', 29], ['U', 30],
    ['V', 31], ['W', 32], ['X', 33], ['Y', 34], ['Z', 35]
  ])

  constructor(
    private _stringService: StringService
  ) { }

  onkoViitenumeroValidi(viitenumero: string): boolean {
    const normalisoituViitenumero = this.normalizeReference(viitenumero)
    // console.log(normalisoituViitenumero)
    return normalisoituViitenumero.length <= 25 &&
      !!normalisoituViitenumero.match(this.REFERENCE_FORMAT) &&
      this.isValidChecksum(normalisoituViitenumero)
  }

  private isValidChecksum(reference: string) {
    const rfOsioVimppana = reference.substr(4) + reference.substr(0, 4)
    const rajaytettyna = rfOsioVimppana.split('')
    const preResult = rajaytettyna.map(kirjain => {
      return this.substituteCharWithNumber(kirjain)
    }).join('')
    // console.log(preResult, this.modulo97(preResult))
    return this.modulo97(preResult) === 1
  }



  luoViitenumero(numeroIlmanTarkistetta: string): string | null {

    if (!numeroIlmanTarkistetta) {
      // console.error('Ei numeroa.')
      return null
    }

    const ilmanWhitespace = this._stringService.removeAllWhiteSpaces(numeroIlmanTarkistetta)

    return 'RF' + this.calculateRFChecksum(ilmanWhitespace) + ilmanWhitespace

  }

  private calculateRFChecksum(reference: string): string {
    const preResult = (reference + 'RF00').split('').map(kirjain => {
      // console.log(kirjain, this.substituteCharWithNumber(kirjain))
      return this.substituteCharWithNumber(kirjain)
    }).join('')
    // console.log(preResult, this.modulo97(preResult))
    const checksum = 98 - this.modulo97(preResult)
    return checksum < 10 ? '0' + checksum : '' + checksum
  }


  private normalizeReference(reference: string): string {
    return reference ? (reference + '').replace(/ /g, '').toUpperCase() : ''
  }

  private substituteCharWithNumber(char: string): number {
    const asNumber = Number(char)
    if (Number.isNaN(asNumber)) {
      const value = this.charTable.get(char)
      if (value) {
        return value
      }
      throw new Error('Unknown character in replacement table ' + char)
    }
    return asNumber
  }

  private modulo97(divident: string): number {
    const chunks = divident.match(/.{1,7}/g)
    return Number(chunks.reduce((prev, curr) => {
      return (Number(prev + '' + curr) % 97) + ''
    }, ''))
  }

}

export class ViitenumeroService {

  private readonly REF_NUMBER_MULTIPLIERS = [7, 3, 1]
  private readonly FINNISH_VIRTUAL_BAR_CODE_REGEX = /^[45]\d{53}$/
  private readonly FINNISH_REF_NUMBER_REGEX = /^(\d{4,20}|RF\d{6,22})$/i
  private rfService: ViitenumeroRfService

  constructor(
    private _ibanService: IbanService,
    private _bicService: BicService,
    private _stringService: StringService,
    private _dateService: DateService,
    private _currencyService: CurrencyService
  ) {
    this.rfService = new ViitenumeroRfService(this._stringService)
  }

  luoViitenumero(numeroIlmanTarkistetta: string): string | null {

    if (!numeroIlmanTarkistetta) {
      // console.error('Ei numeroa.')
      return null
    }

    const refNumber = this._stringService.removeAllWhiteSpaces(numeroIlmanTarkistetta)
    const reversedRefNumber = this._stringService.reverseString(refNumber)

    let checksum = 0
    let checksumNumber = 0

    for (let i = 0; i < reversedRefNumber.length; i++) {
      checksum += this.REF_NUMBER_MULTIPLIERS[i % this.REF_NUMBER_MULTIPLIERS.length] * parseInt(reversedRefNumber.charAt(i), 10)
    }

    checksumNumber = 10 - checksum % 10

    if (checksumNumber === 10) {
      checksumNumber = 0
    }

    return refNumber + checksumNumber

    // return FinnishBankUtils.generateFinnishRefNumber(numeroIlmanTarkistetta)
  }

  /**
   * Format Finnish reference number. Adds whitespace every 5 or 4 characters
   *
   * @param refNumber - {String} Reference number to format: RF341234561
   * @param separator - {String} Whitespace or other string to be used
   */
  muotoileViitenumero(viitenumero: string): string | null {

    if (!this.onkoViitenumeroValidi(viitenumero)) {
      return null
    }

    let refNumber = this._stringService.removeAllWhiteSpaces(viitenumero.toUpperCase())
    if (/^RF/.test(refNumber)) {
      refNumber = refNumber.substr(0, 4) + this._stringService.removeLeadingZeros(refNumber.substr(4))
      return refNumber.replace(/.{4}/g, '$& ').trim()
    }

    refNumber = this._stringService.removeLeadingZeros(refNumber)
    return this._stringService.reverseString(this._stringService.reverseString(refNumber).replace(/.{5}/g, '$& ').trim())
  }

  onkoViitenumeroValidi(viitenumero: string): boolean {

    //  Sanity and format check, which allows to make safe assumptions on the format.
    if (!viitenumero || typeof viitenumero !== 'string') {
      return false
    }

    // console.log('check1')
    let refNumber = this._stringService.removeAllWhiteSpaces(viitenumero.toUpperCase())

    // Check Creditor Reference numbers separately.
    if (/^RF/.test(refNumber)) {
      return this.rfService.onkoViitenumeroValidi(refNumber)
    }

    if (!this.FINNISH_REF_NUMBER_REGEX.test(refNumber)) {
      return false
    }

    // console.log('check2')
    refNumber = this._stringService.removeLeadingZeros(refNumber)

    // console.log('check3')
    const
      reversedRefNumber = this._stringService.reverseString(refNumber),
      providedChecksumNumber = parseInt(reversedRefNumber.charAt(0), 10)

    refNumber = reversedRefNumber.substr(1)

    // console.log('check4')
    let
      checksum = 0,
      checksumNumber

    for (let i = 0; i < refNumber.length; i++) {
      checksum += this.REF_NUMBER_MULTIPLIERS[i % this.REF_NUMBER_MULTIPLIERS.length] * parseInt(refNumber.charAt(i), 10)
    }

    checksumNumber = 10 - checksum % 10

    if (checksumNumber === 10) {
      checksumNumber = 0
    }

    // console.log('check5', checksumNumber, providedChecksumNumber)
    return checksumNumber === providedChecksumNumber
  }

  /**
   * Parse Finnish virtual bar code (aka virtuaaliviivakoodi, pankkiviidakoodi).
   * Supports versions 4 and 5
   * Based on: http://www.finanssiala.fi/maksujenvalitys/dokumentit/Pankkiviivakoodi-opas.pdf
   */
  parsiSuomalainenVirtuaaliviivakoodi(koodi: string): VirtuaaliviivakoodinOsat | null {

    if (!koodi) {
      // console.error('Ei virtuaaliviivakooodia.')
      return null
    }

    if (!this.FINNISH_VIRTUAL_BAR_CODE_REGEX.test(koodi)) {
      // console.error('Virtuaaliviivakooodi on virheellinen.')
      return null
    }

    const [version, formatoimatonIban, euros, cents, reserve, reference, year, month, day] = this.viipaloiSuomalainenVirtuaaliviivakoodi(koodi)

    if (version !== '4' && version !== '5') {
      // console.error('Väärä versio.')
      return null
    }

    const iban = this._ibanService.formatoiIban('FI' + formatoimatonIban)
    const sum = new Big(euros + '.' + cents)

    let viitenumero = reference
    if (version === '5') {
      viitenumero = 'RF' + reference.substr(0, 2) + this._stringService.removeLeadingZeros(reference.substr(2))
    }
    viitenumero = this.muotoileViitenumero(viitenumero)

    const erapvm: LocalDate = Number(day) > 0 && Number(month) > 0 ? {
      day: Number(day),
      month: Number(month),
      year: Number('20' + year)
    } : null

    return {
      erapvm: erapvm,
      iban: iban,
      summa: sum,
      viitenumero: viitenumero
    }

  }

  private viipaloiSuomalainenVirtuaaliviivakoodi(koodi: string): string[] | null {
    const versio = koodi.substr(0, 1)
    let slices
    if (versio === '4') {
      slices = [1, 16, 6, 2, 3, 20, 2, 2, 2]
    } else if (versio === '5') {
      slices = [1, 16, 6, 2, 0, 23, 2, 2, 2]
    } else {
      return [versio]
    }
    let index = 0
    return slices.map(length => {
      const slice = koodi.substr(index, length)
      index += length
      return slice
    })
  }

  /**
   * Formats Finnish virtual bar code
   * Supports versions 4 and 5
   * Based on: http://www.finanssiala.fi/maksujenvalitys/dokumentit/Pankkiviivakoodi-opas.pdf
   */
  luoSuomalainenVirtuaaliviivakoodi(params: VirtuaaliviivakoodinOsat): string | null {
    if (!params) {
      // console.error('Ei parametreja.')
      return null
    }
    if (!this._ibanService.isValidIban(params.iban)) {
      // console.error('Iban ei ole validi.')
      return null
    }
    if (!params.summa) {
      // console.error('Summa puuttuu.')
      return null
    }
    if (params.summa.lt(new Big('0'))) {
      // console.info('Summa on vähemmän kuin nolla.')
      return null
    }
    if (params.summa.gt(new Big('999999.99'))) {
      // console.error('Summa on enemmän kuin 999999,99.')
      return null
    }
    if (!this.onkoViitenumeroValidi(params.viitenumero)) {
      // console.error('Viitenumero on virheellinen.')
      return null
    }
    if (params.erapvm && !this._dateService.onkoLocalDateValidi(params.erapvm)) {
      // console.error('Päivämäärä on virheellinen.')
      return null
    }
    if (!params.iban.trim().toUpperCase().startsWith('FI')) {
      // console.error('Tukee vain suomalaisia IBAN tilinumeroja.')
      return null
    }

    let viitenumero = this._stringService.removeAllWhiteSpaces(params.viitenumero)
    const versio = /^RF/.test(viitenumero) ? '5' : '4'

    if (versio === '5') {
      viitenumero = viitenumero.replace(/^RF/, '')
      viitenumero = viitenumero.substr(0, 2) + this._stringService.leftpad(viitenumero.substr(2), 21, '0')
    }

    const iban = this._stringService.removeAllWhiteSpaces(params.iban)
    const eurot = this._currencyService.annaKokonaiset(params.summa)
    const desimaalit = this._currencyService.annaDesimaalit(params.summa)

    // Eräpäivä ei ole pakollinen, jolloin se on nollia
    const day = params.erapvm ? params.erapvm.day : 0
    const month = params.erapvm ? params.erapvm.month : 0
    const year = params.erapvm ? params.erapvm.year : 0

    return versio
      + iban.replace(/^FI/, '')
      + this._stringService.leftpad(String(eurot), 6, '0')
      + this._stringService.leftpad(String(desimaalit), 2, '0')
      + this._stringService.leftpad(viitenumero, 23, '0')
      + this._stringService.leftpad(String(year).substr(-2), 2, '0')
      + this._stringService.leftpad(String(month), 2, '0')
      + this._stringService.leftpad(String(day), 2, '0')
  }

  luoSuomalainenQrKoodi(params: LaskunQrKoodinOsat): string {

    if (!params) {
      console.error('Ei parametreja.')
      return null
    }
    if (!this._bicService.validoiBic(params.bic)) {
      console.error('Bic ei ole validi.')
      return null
    }
    if (!this._ibanService.isValidIban(params.iban)) {
      console.error('Iban ei ole validi.')
      return null
    }
    if (!params.summa && !isNaN(params.summa)) {
      console.error('Summa puuttuu.')
      return null
    }
    if (params.summa < 0.01) {
      console.error('Summa on vähemmän kuin 0,01.')
      return null
    }
    if (params.summa > 999999999.99) {
      console.error('Summa on enemmän kuin 999999999,99.')
      return null
    }
    if (!this.onkoViitenumeroValidi(params.viitenumero)) {
      console.error('Viitenumero on virheellinen.')
      return null
    }
    if (!params.erapaiva || !this._dateService.onkoLocalDateValidi(params.erapaiva)) {
      console.error('Päivämäärä on virheellinen.')
      return null
    }

    const viitenumero = this._stringService.removeAllWhiteSpaces(params.viitenumero)

    const iban = this._stringService.removeAllWhiteSpaces(params.iban)

    // Eräpäivä ei ole pakollinen, jolloin se on nollia
    const day = this._stringService.leftpad(params.erapaiva.day + '', 2, '0')
    const month = this._stringService.leftpad(params.erapaiva.month + '', 2, '0')
    const year = this._stringService.leftpad(params.erapaiva.year + '', 4, '0')

    return params.serviceTag + '\n'
      + params.version + '\n'
      + params.charset + '\n'
      + params.identificationCode + '\n'
      + params.bic + '\n'
      + params.maksunSaajaNimi.substring(0, 70) + '\n'
      + iban + '\n'
      + params.valuutta + this._currencyService.roundHalfUp(params.summa, 2) + '\n'
      + '\n'
      + viitenumero + '\n'
      + (params.remittanceInfo ?? '').substring(0, 140) + '\n'
      + 'ReqdExctnDt/' + year + '-' + month + '-' + day
  }

  /**
   * Parse the string qr code to more structured
   * https://www.finanssiala.fi/wp-content/uploads/2021/03/QR_koodin_kaytto_tilisiirtolomakkeella.pdf
   **/
  parsiLaskunQrKoodi(koodi: string): LaskunQrKoodinOsat | null {
    if (!koodi) {
      // console.error('Ei qr koodia.')
      return null
    }
    const splitted = koodi.split(/\r?\n/)
    if (splitted.length < 1) {
      console.log('No QR code parts')
      return null
    }
    if (splitted[0] !== 'BCD') {
      console.log('Not BCD code')
      return null
    }
    if (splitted.length < 12) {
      console.log('Not enough QR code parts')
      return null
    }
    const lisatiedot = splitted[11].replace('ReqdExctnDt/', '')
    const dueDateStr = lisatiedot.substring(0, 10)
    const dueDate = this._dateService.dateToLocalDate(this._dateService.parsiIso8601(dueDateStr))
    return {
      serviceTag: 'BCD',
      version: splitted[1],
      charset: splitted[2] as LaskunQrKoodinCharacterSet,
      identificationCode: splitted[3],
      bic: splitted[4],
      iban: splitted[6],
      maksunSaajaNimi: splitted[5],
      valuutta: splitted[7].substring(0, 3),
      summa: Number(splitted[7].substring(3)),
      purposeCode: splitted[8],
      viitenumero: this.muotoileViitenumero(splitted[9]),
      erapaiva: dueDate,
      remittanceInfo: splitted[10],
      lisatiedot: lisatiedot.substring(10)
    }
  }

}
