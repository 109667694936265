import { Injectable } from '@angular/core'

import { combineLatest, map } from 'rxjs'

import { LemonTranslationService } from './lemon-translation.service'
import { MaaBaseService, MaaErikoiskoodit } from '../../_jaettu/service/maa.service-base'

import { registerLocale, alpha2ToAlpha3, LocaleData, getNames } from 'i18n-iso-countries'
import { TuettuKieli } from 'app/_shared-core/model/common'

declare function require(uri: string): any

export interface Maa {
  koodi: string
  nimi: string
}

@Injectable()
export class MaaService extends MaaBaseService {

  private _localizationDataLoadCompeleteObservable = Promise.all([
    require('i18n-iso-countries/langs/fi.json'),
    require('i18n-iso-countries/langs/en.json'),
    require('i18n-iso-countries/langs/sv.json')
  ]).then((modules: LocaleData[]) => {
    // Module load is complete, register languages
    const fi = modules[0]
    const en = modules[1]
    const sv = modules[2]
    registerLocale(fi)
    registerLocale(en)
    registerLocale(sv)
    return true
  })

  kaikkiMaatJaVeroalueetObservable = combineLatest([this._localizationDataLoadCompeleteObservable, this.translationService.currentLanguageObservable]).pipe(
    map(([valmis, kieli]) => {
      return kieli ? this._annaMaatJaVeroalueet(kieli) : []
    })
  )

  kaikkiMaatObservable = combineLatest([this._localizationDataLoadCompeleteObservable, this.translationService.currentLanguageObservable]).pipe(
    map(([valmis, kieli]) => {
      return kieli ? this._annaMaat(kieli) : []
    })
  )

  constructor(
    private translationService: LemonTranslationService
  ) {
    super()
  }

  private _annaMaatJaVeroalueet(kieli: TuettuKieli): Maa[] {
    const kaikkiMaat = this._annaMaat(kieli)

    for (const maa of kaikkiMaat) {
      if ('GBR' === maa.koodi) {
        maa.nimi = kieli === 'en' ? 'United Kindom (Not Goods to Northern Ireland)' : 'Yhdistynyt Kuningaskunta (Ei Tavaramyynti Pohjois-Irlantiin)'
      } else if ('ESP' === maa.koodi) {
        maa.nimi = kieli === 'en' ? 'Spain (Not Canary Islands)' : 'Espanja (Ei Kanariansaaret)'
      }
    }

    const kanariansaaretNimi = kieli === 'en' ? 'Canary Islands, Spain' : 'Kanariansaaret, Espanja'
    const kanariansaaret: Maa = { koodi: MaaErikoiskoodit.KANARIANSAARET_KOODI_ALPHA_3, nimi: kanariansaaretNimi }
    kaikkiMaat.push(kanariansaaret)

    const pohjoisIrlantiNimi = kieli === 'en' ? 'Northern Ireland, United Kindom' : 'Pohjois-Irlanti, Yhdistynyt Kuningaskunta'
    const pohjoisIrlanti: Maa = { koodi: MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3, nimi: pohjoisIrlantiNimi }
    kaikkiMaat.push(pohjoisIrlanti)

    kaikkiMaat.sort((a: Maa, b: Maa) => {
      if (a.nimi > b.nimi) {
        return 1
      }
      if (a.nimi < b.nimi) {
        return -1
      }
      return 0
    })

    return kaikkiMaat

  }

  private _annaMaat(kieli: TuettuKieli): Maa[] {

    const kaannokset = getNames(kieli)
    const kaikkiMaat: Maa[] = []
    for (const kaannos in kaannokset) {
      if (kaannokset.hasOwnProperty(kaannos)) {
        const koodi = alpha2ToAlpha3(kaannos)
        const nimet = kaannokset[kaannos]
        if (nimet) {
          if (Array.isArray(nimet) && nimet.length > 0) {
            kaikkiMaat.push({ 'koodi': koodi, 'nimi': nimet[0] })
          } else if (typeof nimet === 'string') {
            kaikkiMaat.push({ 'koodi': koodi, 'nimi': nimet as string })
          } else if ((nimet as any) instanceof String) { // The type says string, but as it's third party lib -> safe guard if they lie.
            kaikkiMaat.push({ 'koodi': koodi, 'nimi': (nimet as any).toString() })
          }
        }
      }
    }

    kaikkiMaat.sort((a: Maa, b: Maa) => {
      if (a.nimi > b.nimi) {
        return 1
      }
      if (a.nimi < b.nimi) {
        return -1
      }
      return 0
    })

    return kaikkiMaat

  }

}
