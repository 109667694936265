import { Injectable, NgZone } from '@angular/core'

import { BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

@Injectable()
export class DragAndDropService {

  private timeout: any = null

  private isDraggingInWindowSubject = new BehaviorSubject<boolean>(false)
  isDraggingInWindowObservable = this.isDraggingInWindowSubject.asObservable().pipe(
    distinctUntilChanged()
  )

  constructor(private _ngZone: NgZone) { }

  setDraggingImmediate(isDragging: boolean) {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    this._ngZone.run(() => {
      this.isDraggingInWindowSubject.next(!!isDragging)
    })
  }

  setDraggingRunInZone(isDragging: boolean) {

    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }

    // console.log('setting value', isDragging)
    if (isDragging) {
      this._ngZone.run(() => {
        this.isDraggingInWindowSubject.next(true)
      })
    } else {
      this.timeout = setTimeout(() => {
        this._ngZone.run(() => {
          this.isDraggingInWindowSubject.next(false)
        })
      }, 250)
    }
  }

  setDragging(isDragging: boolean) {

    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }

    // console.log('setting value', isDragging)
    if (isDragging) {
      this.isDraggingInWindowSubject.next(true)
    } else {
      this.timeout = setTimeout(() => {
        this.isDraggingInWindowSubject.next(false)
      }, 250)
    }
  }

}
