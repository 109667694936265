<div [formGroup]="parent">
  <div>
    <!-- <mat-form-field floatPlaceholder="always">
      <mat-label>{{ 'lasku.laskunro' | translate | async }}</mat-label>
<input type="text" formControlName="laskunro"  matInput />
      <mat-error *ngIf="fieldErrors.laskunro">{{fieldErrors.laskunro}}</mat-error>
    </mat-form-field> -->
    <mat-form-field style="width: 48%; float: left;">
      <mat-label>{{ 'lasku.laskunpvm' | translate | async }}</mat-label>
      <input readonly numberDatePicker aria-readonly="true" type="text" [name]="namename" [matDatepicker]="picker3" formControlName="laskunpvm" matInput data-lpignore="true" />
    </mat-form-field>
    <mat-datepicker #picker3></mat-datepicker>
    <mat-form-field style="width: 48%; float: right;">
      <mat-label>{{ 'lasku.toimituspvm' | translate | async }}</mat-label>
      <input type="text" numberDatePicker [matDatepicker]="picker" [name]="namename" formControlName="toimituspvm" matInput data-lpignore="true" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <div style="clear: both; display: block;"></div>

  <div>
    <mat-form-field style="width: 48%; float: left;">
      <mat-label>{{ 'lasku.maksuaikaa' | translate | async }}</mat-label>
      <input type="number" [name]="namename" step="1" min="1" formControlName="maksuaikaa" matInput required data-lpignore="true" />
      <span matSuffix>pv</span>
      <mat-hint *ngIf="14 > maksuaikaaField?.value">Alle 14 laillinen vain yrityksille</mat-hint>
      <mat-error *ngIf="maksuaikaaField?.errors?.required && (maksuaikaaField?.dirty || maksuaikaaField?.touched)">
        {{ 'lasku.validation.maksuaikaa.required' | translate | async }}
      </mat-error>
      <mat-error *ngIf="maksuaikaaField?.errors?.min && (maksuaikaaField?.dirty || maksuaikaaField?.touched)">
        {{ 'lasku.validation.maksuaikaa.min' | translate | async }}
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 48%; float: right;">
      <mat-label>{{ 'lasku.erapaiva' | translate | async }}</mat-label>
      <input type="text" numberDatePicker [name]="namename" [matDatepicker]="picker2" [min]="minErapaivaDate" formControlName="erapaiva" matInput required data-lpignore="true" />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-error *ngIf="erapaiva?.errors?.min && (erapaiva?.dirty || erapaiva?.touched)">
        {{ 'lasku.validation.erapaiva.required' | translate | async }}
      </mat-error>
      <mat-error *ngIf="erapaiva?.errors?.matDatepickerMin && (erapaiva?.dirty || erapaiva?.touched)">
        {{ 'lasku.validation.erapaiva.matDatepickerMin' | translate | async }}
      </mat-error>
    </mat-form-field>
    <mat-datepicker #picker2></mat-datepicker>
  </div>
  <div style="clear: both; display: block;"></div>

  <mat-form-field *ngIf="naytaViivastuskorko">
    <mat-label>{{ 'lasku.viivastyskorko' | translate | async }}</mat-label>
    <input decimalNumberField type="text" [name]="namename" formControlName="viivastyskorko" matInput required data-lpignore="true" />
    <span matSuffix>%</span>
    <!-- <mat-hint *ngIf="14 > viivastyskorkoField?.value">Alle 14 laillinen vain yrityksille</mat-hint> -->
    <mat-error *ngIf="viivastyskorkoField?.errors?.required && (viivastyskorkoField?.dirty || viivastyskorkoField?.touched)">
      {{ 'lasku.validation.viivastyskorko.required' | translate | async }}
    </mat-error>
    <mat-error *ngIf="viivastyskorkoField?.errors?.min && (viivastyskorkoField?.dirty || viivastyskorkoField?.touched)">
      {{ 'lasku.validation.viivastyskorko.min' | translate | async }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'lasku.viitteenne' | translate | async }}</mat-label>
    <input type="text" formControlName="viitteenne" [name]="namename" autocomplete="kirjanpitomerkkiarkki" matInput data-lpignore="true" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'lasku.lisatiedot' | translate | async }}</mat-label>
    <textarea type="text" formControlName="lisatiedot" [name]="namename" autocomplete="kirjanpitomerkkiarkki" cdkTextareaAutosize matInput data-lpignore="true"></textarea>
  </mat-form-field>
</div>