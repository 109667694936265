
export interface Verkkolaskuoperaattori {
  tunnus: string
  nimi: string
  tyyppi: 'intermediator' | 'operator'
}

// Päivitä täältä: https://tieke.fi/palvelut/liiketoimintapalvelut/verkkolaskuosoitteisto/valittajatunnukset/
export class SahkoisenLaskunValittajaService {

  private kaikki: Verkkolaskuoperaattori[]

  constructor() {

    this.kaikki = []

    // Finvoice välityspalvelussa olevat pankit ja niiden intermediator-tunnukset
    this.kaikki.push({ tunnus: 'HELSFIHH', nimi: 'Aktia', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'DABAFIHH', nimi: 'Danske Bank', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'DNBAFIHX', nimi: 'DNB', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'HANDFIHH', nimi: 'Handelsbanken', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'NDEAFIHH', nimi: 'Nordea Pankki', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'ITELFIHH', nimi: 'Oma Säästöpankki', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'OKOYFIHH', nimi: 'Osuuspankit ja Pohjola Pankki', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'POPFFI22', nimi: 'POP Pankki', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'SBANFIHH', nimi: 'S-Pankki', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'TAPIFI22', nimi: 'S-Pankki (ent. LähiTapiola Pankin tunnus toistaiseksi käytössä)', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'ITELFIHH', nimi: 'Säästöpankit', tyyppi: 'intermediator' })
    this.kaikki.push({ tunnus: 'AABAFI22', nimi: 'Ålandsbanken', tyyppi: 'intermediator' })

    // Operaattoreiden välittäjätunnukset
    this.kaikki.push({ tunnus: 'UTMOST', nimi: '4US Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'APPER', nimi: 'Apper Systems AB', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003723327487', nimi: 'Apix Messaging Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'BAWCFI22', nimi: 'Basware Oyj', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003703575029', nimi: 'CGI', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '5909000716438', nimi: 'Comarch', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'CREDIFLOW', nimi: 'Crediflow AB', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'ROUTTY', nimi: 'Dynatos', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '885790000000418', nimi: 'HighJump AS', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'INEXCHANGE', nimi: 'InExchange Factorum AB', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'EXPSYS', nimi: 'Lexmark Expert Systems AB', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '981012224', nimi: 'LOGIQ AS', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003708599126', nimi: 'Opentext Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003721291126', nimi: 'Maventa', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'MAVENTA', nimi: 'Maventa', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003726044706', nimi: 'Netbox Finland Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'E204503', nimi: 'OpusCapita Solutions Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003723609900', nimi: 'Pagero', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'PAGERO', nimi: 'Pagero', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'PALETTE', nimi: 'Palette Software', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'PEPPOL', nimi: 'Peppol', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: 'FI28768767', nimi: 'Posti Messaging Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003701150617', nimi: 'PostNord Strålfors Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003714377140', nimi: 'Ropo Capital Oy', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003703575029', nimi: 'Telia Finland', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003701011385', nimi: 'TietoEvry Oyj', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '885060259470028', nimi: 'Tradeshift', tyyppi: 'operator' })
    this.kaikki.push({ tunnus: '003722207029', nimi: 'Ålands Post Ab', tyyppi: 'operator' })

    this.kaikki.sort((a, b) => a.nimi.localeCompare(b.nimi))

  }

  public annaKaikkiValittajat(): Verkkolaskuoperaattori[] {
    return this.kaikki
  }

  public annaValittaja(tunnus: string): Verkkolaskuoperaattori | null {
    for (const valittaja of this.kaikki) {
      if (valittaja.tunnus === tunnus) {
        return valittaja
      }
    }
    return null
  }

  public annaValittajanNimi(tunnus: string): string | null {
    for (const valittaja of this.kaikki) {
      if (valittaja.tunnus === tunnus) {
        return valittaja.nimi
      }
    }
    return null
  }

}
