import { KuitinUriRajaytettyna, FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto } from '../../model/tosite'

export class TositeUriService {

  // Realtime database
  annaKuittienFirebaseCollectionUri(asiakasId: string, vuosi: number, kuukausi: number): string {
    return '/r/' + asiakasId + '/' + vuosi + '/' + kuukausi
  }

  annaKuitinFirebaseUri(asiakasId: string, vuosi: number, kuukausi: number, kuitinAvain: string): string {
    return '/r/' + asiakasId + '/' + vuosi + '/' + kuukausi + '/' + kuitinAvain
  }

  annaKuitinSelvitettavaFirebaseUri(asiakasId: string, kuitinAvain: string): string {
    return '/r/' + asiakasId + '/selvitettavat/' + kuitinAvain
  }

  annaKuvanBase64TempUri(kur: KuitinUriRajaytettyna, kuvanAvain: string) {
    return '/r/' + kur.asiakasId + '/w/i/' + kuvanAvain
  }

  annaMaksutapojenFirebaseCollectionUri(kayttajaId: string): string {
    return '/p/' + kayttajaId
  }











  // Firestore!
  annaKuittienFirestoreCollectionUri(asiakasId: string): string {
    return 'kuitit/' + asiakasId + '/kuitit'
  }

  annaKuitinFirestoreUri(asiakasId: string, kuitinAvain: string): string {
    return 'kuitit/' + asiakasId + '/kuitit/' + kuitinAvain
  }

  annaKuitinHistoriaFirestoreCollectionUri(asiakasId: string, kuitinAvain: string): string {
    return 'kuitit/' + asiakasId + '/kuitit/' + kuitinAvain + '/historia'
  }

  annaKuitinHistoriaFirestoreUri(asiakasId: string, kuitinAvain: string, historiaId: string): string {
    return 'kuitit/' + asiakasId + '/kuitit/' + kuitinAvain + '/historia/' + historiaId
  }

  // annaAsiakasId(tyoData: KuitinFirestoreTyojonoMerkinta): string {
  //   const uri = tyoData.uri
  //   return uri.substring(uri.indexOf('kuitit/') + 7, uri.lastIndexOf('/kuitit'))
  // }




  // Cloud storage
  annaCloudStorageKuvaUriRajaytetylle(kur: KuitinUriRajaytettyna, kuvanAvain: string, tyyppi: string): string {
    return kur.asiakasId + '/' + kur.avain + '/' + kuvanAvain + '.' + tyyppi
  }

  annaCloudStorageKuvaUri(asiakasId: string, tositteenAvain: string, kuvanAvain: string, tyyppi: string): string {
    return asiakasId + '/' + tositteenAvain + '/' + kuvanAvain + '.' + tyyppi
  }

  // annaCloudStorageKuvaUriTositteelle(asiakasId: string | number, tosite: Tosite, tositteenSivu: TositteenSivu): string {
  //   return asiakasId + '/' + tosite.firebase_id + '/' + tositteenSivu.firebase_id + '.' + tositteenSivu.tyyppi
  // }

  // annaCloudStoragePdfUri(asiakas: Asiakas, tosite: FirestoreTosite): string {
  //   return asiakas.asiakasId + '/' + tosite.kuvakansio + '/tosite.pdf'
  // }

  // annaCloudStoragePdfUriAsiakasIdlle(asiakasId: string, tosite: FirestoreTosite): string {
  //   return asiakasId + '/' + tosite.kuvakansio + '/tosite.pdf'
  // }

  annaCloudStoragePdfKansioUriAsiakasIdlle(asiakasId: string, tosite: FirestoreTosite): string {
    return asiakasId + '/' + tosite.kuvakansio
  }

  annaCloudStorageAlkuperainenUriAsiakasIdlle(asiakasId: string, alkuperainen: FirestoreTositteenAlkuperainenTiedosto): string {
    return asiakasId + '/' + alkuperainen.kuvakansio + '/' + alkuperainen.avain + '.' + alkuperainen.fileEnding
  }

  annaCloudStorageAlkuperainenUri(asiakasId: string, kuvakansio: string, sivunAvain: string, tiedostopaate: string): string {
    return asiakasId + '/' + kuvakansio + '/' + sivunAvain + '.' + tiedostopaate
  }

  // Firestore work queue
  annaTyojonoWeb1AloitusUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_web_1_pilvi/' + tyoAvain
  }

  annaTyojonoWeb2KuvatUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_web_2_kuvat/' + tyoAvain
  }

  annaTyojonoWeb2KuvatEtenemaCollectionUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_web_2_kuvat/' + tyoAvain + '/kuitti_web_2_kuvat_etenema'
  }

  annaTyojonoWeb3RealtimeDbUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_web_3_realtime-db/' + tyoAvain
  }

  annaTyojonoYhteinen1PdfUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_yhteinen_1_pdf/' + tyoAvain
  }

  annaTyojonoYhteinen2KuukausisummatUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_yhteinen_1japuoli_kk-summat/' + tyoAvain
  }

  annaTyojonoYhteinen3TypeAheadUri(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_yhteinen_3_type-ahead/' + tyoAvain
  }

  annaRealtimeDbToFirestoreAvainMappausUri(asiakasAvain: string, tositeAvain: string): string {
    return 'avaimet/' + asiakasAvain + '/kuitit-realtimedb/' + tositeAvain
  }

  // Realtime db work queue
  annaTyojonoRealtimeDb1AloitusUri(tyoAvain: string): string {
    return '/w/' + tyoAvain
  }

  annaTyojonoRealtimeDb2KuvatUri(tyoAvain: string): string {
    return '/tyot/tositteet/kuvienvienti/' + tyoAvain
  }

  annaTyojonoRealtimeDb3FirestoreUri(tyoAvain: string): string {
    return '/tyot/tositteet/firestorevienti/' + tyoAvain
  }

  annaFirestoreToRealtimeDbAvainMappausUri(asiakasId: string, tositeAvain: string): string {
    return '/avaimet/' + asiakasId + '/kuitit-firestore/' + tositeAvain
  }

  // Summien laskenta Lemonaattorille
  annaTyojonoKuukausisummatLemonator(asiakasId: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasId + '/kuitti_lemonator_kuukausisummat/' + tyoAvain
  }

  // Tositteiden latauskerrat Lemonatorille
  annaLatauskerranUri(asiakasAvain: string, latauskerranAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tositteiden-latauskerrat/' + latauskerranAvain
  }

  annaLatauskertaCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tositteiden-latauskerrat'
  }

}
