<mat-dialog-content>

  <lemon-dialog-header>
    Jaa kulu usealle kuukaudelle
  </lemon-dialog-header>

  <form [formGroup]="form" novalidate style="margin: 0;">
    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>Ensimmäinen kuukausi</mat-label>
        <input type="text" [matDatepicker]="ekaPicker" [name]="namename" monthPicker formControlName="ekaKuukausi" required matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="ekaPicker"></mat-datepicker-toggle>
        <mat-error *ngIf="ekaKuukausi?.errors?.required">Päivämäärä puuttuu</mat-error>
        <mat-error *ngIf="ekaKuukausi.errors?.loppuEnnenAlkua">Viimeinen kuukausi on ennen ensimmäistä</mat-error>
      </mat-form-field>
      <mat-datepicker #ekaPicker></mat-datepicker>

      <mat-form-field class="form-field">
        <mat-label>Viimeinen kuukausi</mat-label>
        <input type="text" [matDatepicker]="vikaPicker" [name]="namename" monthPicker formControlName="vikaKuukausi" required matInput data-lpignore="true" />
        <mat-datepicker-toggle matSuffix [for]="vikaPicker"></mat-datepicker-toggle>
        <mat-error *ngIf="vikaKuukausi?.errors?.required">Päivämäärä puuttuu</mat-error>
        <mat-error *ngIf="vikaKuukausi.errors?.loppuEnnenAlkua">Viimeinen kuukausi on ennen ensimmäistä</mat-error>
      </mat-form-field>
      <mat-datepicker #vikaPicker></mat-datepicker>
    </div>
  </form>

  <div *ngIf="virhe" class="error-text" style="font-size: 120%; padding: 15px;">
    {{virhe}}
  </div>

  <div style="margin-top: 2em;">
    <div style="margin-bottom: 1em;">
      <span class="left-col">Kuukausien lukumäärä:</span>
      <span class="right-col">{{kuukausienLukumaara ?? ' - '}} </span>
    </div>

    <div>
      <span class="left-col">Jaettava kulu yhteensä:</span>
      <span class="right-col">{{kulutYhteensa | lmoneyl: 'EUR' : 'fi' }}</span>
    </div>
    <div>
      <span class="left-col">Kulu per kuukausi:</span>
      <span class="right-col">{{kuluPerKuukausi ? (kuluPerKuukausi | lmoneyl: 'EUR' : 'fi') : ' - '}}</span>
    </div>
  </div>


</mat-dialog-content>

<mat-dialog-actions style="padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button cdkFocusRegionstart mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button [disabled]="!!virhe" (click)="jaaKulu()">Jaa kulu</button>
</mat-dialog-actions>