<div class="tilinpaatos-container">

  <mat-form-field appearance="fill" style="margin-top: 20px;">
    <mat-label>Tilikausi</mat-label>
    <mat-select [value]="selectedTilikausiObservable | async" (selectionChange)="setTilikausiEvent($event)" [compareWith]="compareTilikaudet">
      <mat-option *ngFor="let tilikausi of tilikaudetObservable | async" [value]="tilikausi">{{ tilikausi?.loppuu?.year }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="tilinpaatos-btn-group">
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'aloitus'" (click)="setSelectedTab('aloitus')">
      <div>Aloitus</div>
    </button>
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'tase-erittely'" [disabled]="tilinpaatosNotStarted" (click)="setSelectedTab('tase-erittely')">
      <div>Tase-erittely</div>
    </button>
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'liitetiedot'" [disabled]="tilinpaatosNotStarted" (click)="setSelectedTab('liitetiedot')">
      <div>Liitetiedot</div>
    </button>
    <!-- <button *ngIf="tilintarkastetaanObservable | async" class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'tilintarkastus'" (click)="setSelectedTab('tilintarkastus')">
      <div>Tilintarkastus</div>
    </button> -->
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'lahetys'" [disabled]="tilinpaatosNotStarted" (click)="setSelectedTab('lahetys')">
      <div>Lähetys</div>
    </button>
    <button class="tilinpaatos-btn" mat-button [class.tilinpaatos-btn-selected]="selectedTab === 'rekisterointi'" [disabled]="tilinpaatosNotStarted" (click)="setSelectedTab('rekisterointi')">
      <div>Rekisteröinti</div>
    </button>
  </div>

  <ng-container>
    <div *ngIf="selectedTab === 'aloitus'" app-kirjanpito-tilinpaatos-aloitus [selectedTilikausiObservable]="selectedTilikausiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" [liitetiedotObservable]="liitetiedotObservable" (startDone)="jatkaTilinpaatokseen()"></div>
    <div *ngIf="selectedTab === 'tase-erittely'" app-kirjanpito-tilinpaatos-tase-erittely [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" (kirjaustaKlikattiin)="emitKirjausData($event)"></div>
    <div *ngIf="selectedTab === 'liitetiedot'" app-kirjanpito-tilinpaatos-liitetiedot [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable" [nykyisetLiitetiedotTietokannassaObservable]="liitetiedotObservable"></div>
    <!-- <div *ngIf="selectedTab === 'tilintarkastus'" app-kirjanpito-tilinpaatos-tilintarkastus [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div> -->
    <div *ngIf="selectedTab === 'lahetys'" app-tilinpaatos-lahetys [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
    <div *ngIf="selectedTab === 'rekisterointi'" app-kirjanpito-tilinpaatos-rekisterointi [selectedTilikausiObservable]="selectedTilikausiObservable" [paivitaArvotHiljaisestiObservable]="paivitaArvotHiljaisestiObservable" [tilinpaatosStatusObservable]="tilinpaatosStatusObservable"></div>
  </ng-container>

</div>