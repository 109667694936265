import { AbstractControl, ValidationErrors, ValidatorFn, UntypedFormArray } from '@angular/forms'
import { CodeCheckService } from '../../_shared-core/service/code-check.service'


export class FormValidators {

  private static codeCheckService = new CodeCheckService()

  static phoneNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const nmb = control.value
      if (FormValidators.codeCheckService.isValidPhoneNumber(nmb) === false) {
        return { invalid: true }
      }
      if (FormValidators.codeCheckService.isValidInternationalPhoneNumber(nmb) === false) {
        return { notinternational: true }
      }
    }
    return null
  }

  static finnishHetuYtunnusOrVatNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const hetu = control.value
      if (
        FormValidators.codeCheckService.isValidFinnishPersonalId(hetu) === false &&
        FormValidators.codeCheckService.isValidVatNumber(hetu) === false &&
        FormValidators.codeCheckService.isValidYTunnus(hetu) === false
      ) {
        return { invalid: true }
      }
    }
    return null
  }

  static finnishPersonalIdValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const hetu = control.value
      if (FormValidators.codeCheckService.isValidFinnishPersonalId(hetu) === false) {
        return { invalid: true }
      }
    }
    return null
  }


  static vahintaanYksiArrayssaValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof UntypedFormArray) {
      const array = control as UntypedFormArray
      if (!array.controls || array.controls.length < 1) {
        return { min: true }
      }
      return null
    }
    throw new Error('This validator can only be used with FormArrays.')
  }

  static minDateValidator(min: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value['getTime']) {
        const d = control.value as Date
        if (d.getTime() < min.getTime()) {
          return { 'minDate': true }
        }
      }
      return null
    }
  }

}
