import { Component, ChangeDetectionStrategy, Input, OnInit, ErrorHandler, Output, EventEmitter, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core'
import { Validators, AbstractControl, FormGroup, ValidatorFn, ValidationErrors, FormControl } from '@angular/forms'

import { MatInput } from '@angular/material/input'

import { TypeaheadAsiakas, LaskunAsiakas, LaskunTyypit, LaskunTyyppi, LaskuBase, Lasku } from '../../../_jaettu/model/lasku'

import { combineLatest, Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs'
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators'

import { FormValidationService } from '../../../_jaettu-angular/service/form-validation.service'
import { AlvNumeronTarkistajaService } from '../../../_jaettu-angular/service/vat-number-check.service'
import { AlvNumeronTarkistustulos } from '../../../_jaettu/model/common'
import { LaskunAsiakasTypeaheadBase } from '../../service/lasku/typeahead.service'
import { Maa, MaaService } from '../../../_jaettu-angular/service/maa.service'
import { LemonTranslationService } from '../../../_jaettu-angular/service/lemon-translation.service'
import { LaskuKopioija } from '../../../_jaettu/service/lasku/lasku.kopioija'
import { MaaErikoiskoodit } from 'app/_jaettu/service/maa.service-base'
import { VatNumberValidationService } from 'app/_jaettu/service/vat-number-validation.service'
import { LaskuForm } from './lasku-rivit.component'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'

type VatTaiYtunnuksePakollisuus = 'pakollinen-ytunnus' | 'pakollinen-vat' | 'ei-pakollinen' | 'ei-pakollinen-mutta-tarkista-vat-vai-ytunnus' | 'pakollinen-ulkomaan-vat'

@Component({
  selector: 'app-lasku-asiakkaan-tiedot',
  templateUrl: './lasku-asiakkaan-tiedot.component.html',
  styleUrls: ['./lasku-asiakkaan-tiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
  // ,
  // animations: [
  //   trigger('visibilityChanged', [
  //     state('shown' , style({ opacity: 1 })),
  //     state('hidden', style({ opacity: 0 })),
  //     state('quickhide', style({ opacity: 0 })),
  //     transition('shown => hidden', animate('2100ms')),
  //     transition('hidden => shown', animate('250ms')),
  //     transition('shown => quickhide', animate('1ms'))
  //   ])
  //   ,
  //   trigger('kuvake', [
  //     state('shown' , style({ })),
  //     state('hidden', style({ })),
  //     transition('* => shown', animate('700ms', keyframes([
  //       style({ transform: 'scale(1.3)' }),
  //       style({ transform: 'scale(0.7)' }),
  //       style({ transform: 'scale(1.3)' }),
  //       style({ transform: 'scale(1)' })
  //     ])))
  //   ])
  // ]
})
export class LaskuAsiakkaanTiedotComponent implements OnInit, OnDestroy {

  @ViewChild('asiakassearch', { read: MatInput, static: true }) materialInput: MatInput

  @Input() laskunTyyppiObservable: Observable<LaskunTyyppi>
  @Input() laskuObservable: Observable<{ juurilasku: Lasku, kasiteltava: LaskuBase }>
  @Input() parent: FormGroup<LaskuForm>
  @Input() paivitaValidaatiot: any

  @Output() asiakasMuuttui = new EventEmitter<LaskunAsiakas>()

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public LaskunTyypit = LaskunTyypit

  onkoYtunnusPakollinen: VatTaiYtunnuksePakollisuus = 'ei-pakollinen'
  suodatetutMaat: Observable<Maa[]>
  ytunnusPlaceholderObservable: Observable<string>
  suodatetutAsiakkaat: ReplaySubject<TypeaheadAsiakas[]> = new ReplaySubject<TypeaheadAsiakas[]>(1)
  vatTunnuksenValidointitulos: BehaviorSubject<string> = new BehaviorSubject('')
  asiakashaunVihjetekstiObservable: BehaviorSubject<string> = new BehaviorSubject('')
  // asiakastyyppiObservable: BehaviorSubject<LaskunAsiakastyyppi | null> = new BehaviorSubject(null)
  // asiakastyypinNakyvyysObservable: BehaviorSubject<'shown' | 'hidden' | 'quickhide'> = new BehaviorSubject<'shown' | 'hidden' | 'quickhide'>('shown')
  // asiakastyypinKuvakkeenNakyvyysObservable: BehaviorSubject<'shown' | 'hidden' | 'quickshow'> = new BehaviorSubject<'shown' | 'hidden' | 'quickshow'>('shown')
  namename = 'sdfg' + Math.random()
  valittuAsiakas = false
  naytaCheck = false

  private ngUnsubscribe = new Subject<void>()
  private ngUnsubscribeKomponentti = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _changeDetectorRef: ChangeDetectorRef,
    private _validationService: FormValidationService,
    private _maaService: MaaService,
    private _alvNumeronTarkistajaService: AlvNumeronTarkistajaService,
    private _codeCheckService: CodeCheckService,
    private _laskunAsiakasTypeahead: LaskunAsiakasTypeaheadBase,
    private _laskuKopioija: LaskuKopioija,
    private _translationService: LemonTranslationService,
    private _vatNumberValidationService: VatNumberValidationService
  ) { }

  // naytaAsiakastyyppiValitsin(event: Event) {
  //   if (event.preventDefault) {
  //     event.preventDefault()
  //   }
  //   if (event.stopImmediatePropagation) {
  //     event.stopImmediatePropagation()
  //   }
  //   if (event.stopPropagation) {
  //     event.stopPropagation()
  //   }
  //   this.asiakastyypinNakyvyysObservable.next('shown')
  // }

  focus() {
    setTimeout(() => {
      this.materialInput.focus()
      this._changeDetectorRef.markForCheck()
    }, 50)
  }

  tarkistaPoisto() {
    // console.log('tarkista poisto')
    if (!this.nimi.value) {
      this.poistaValittu()
    }
  }

  poistaValittu() {
    this.tyhjennaAsiakashaunVihjeteksti()
    const s: LaskunAsiakas = {
      avain: null,
      nimi: '',
      ytunnus: '',
      katuosoite: '',
      postinro: '',
      postitmp: '',
      maa: this.parent.get('maa').value,
      date: null,
      laskunVastaanottajat: [],
      laskunKieli: null,
      laskunTyyppi: null,
      laskunValuutta: null,
      sahkoinenosoite: {
        sahkoinenOsoite: null,
        sahkoinenValittaja: null
      },
      asiakastyyppi: null,
      viimeisinLaskuLahetetty: null
    }
    this.ytunnus.reset()
    this.katuosoite.reset()
    this.nimi.reset()
    this.postinro.reset()
    this.postitoimipaikka.reset()
    this.maa.reset()
    // this.asiakastyyppi.reset()
    this.asiakasMuuttui.next(s)
  }

  ngOnDestroy() {
    this.ngUnsubscribeKomponentti.next()
    this.ngUnsubscribeKomponentti.complete()
    this.ngUnsubscribeKomponentti = new Subject<void>()
  }

  ngOnInit() {

    this.laskunTyyppiObservable.pipe(
      takeUntil(this.ngUnsubscribeKomponentti)
    ).subscribe(tyyppi => {
      // Aseta ytunnuksen pakollisuus
      if (tyyppi?.avain === LaskunTyypit.RAKENNUSALA.avain) {
        this.onkoYtunnusPakollinen = 'pakollinen-ytunnus'
      } else if (tyyppi?.avain === LaskunTyypit.EU_PALVELU.avain || tyyppi?.avain === LaskunTyypit.EU_TAVARA.avain) {
        this.onkoYtunnusPakollinen = 'pakollinen-vat'
      } else if (tyyppi?.avain === LaskunTyypit.TAVALLINEN.avain) {
        this.onkoYtunnusPakollinen = 'ei-pakollinen-mutta-tarkista-vat-vai-ytunnus'
      } else if (tyyppi?.avain === LaskunTyypit.TAVALLINEN_ULKOM.avain) {
        this.onkoYtunnusPakollinen = 'pakollinen-ulkomaan-vat'
      } else {
        this.onkoYtunnusPakollinen = 'ei-pakollinen'
      }
      this.paivitaYtunnuksenPakollisuus(this.onkoYtunnusPakollinen)
      // console.log('Tyyppi päivittyi')
    })

    this.suodatetutMaat = combineLatest([this.laskuObservable, this._maaService.kaikkiMaatJaVeroalueetObservable]).pipe(
      map(([lasku, maat]) => {
        // Suodata maat tyypin mukaan
        const tyyppi = LaskunTyypit.annaLaskunTyyppi(lasku.kasiteltava.tyyppi)
        const suodatetut = this._suodataMaat(maat, tyyppi)
        this.asetaMaa(tyyppi, lasku.kasiteltava.asiakas, suodatetut)
        return suodatetut
      })
    )

    this.ytunnusPlaceholderObservable = this.laskunTyyppiObservable.pipe(
      switchMap(tyyppi => {
        return this._translationService.currentLanguageObservable.pipe(
          map(kieli => {
            if (tyyppi.avain === LaskunTyypit.TAVALLINEN.avain || tyyppi.avain === LaskunTyypit.RAKENNUSALA.avain) {
              return this._translationService.lokalisoiKielella('lasku.ytunnus', kieli)
            }
            return this._translationService.lokalisoiKielella('lasku.vat-tunnus', kieli)
          })
        )
      })
    )

    this.laskuObservable.pipe(
      takeUntil(this.ngUnsubscribeKomponentti)
    ).subscribe(lasku => {
      this.ngUnsubscribe.next()
      this.ngUnsubscribe.complete()
      this.ngUnsubscribe = new Subject<void>()
      this.valittuAsiakas = !!lasku.kasiteltava.asiakas.avain
      this._setupForm(lasku.kasiteltava)
      if (this.valittuAsiakas) {
        this.naytaCheck = true
        this.asiakashaunVihjetekstiObservable.next(this._translationService.lokalisoi('lasku.tallennettu-asiakas'))
      } else {
        this.tyhjennaAsiakashaunVihjeteksti()
      }
      // console.log('Käsiteltävä päivittyi')
    })

  }

  private _asetaTypeaheadAsiakas(typeaheadAsiakas: TypeaheadAsiakas) {
    if (!typeaheadAsiakas || !typeaheadAsiakas.avain) {
      return
    }
    // this.parent.get('nimi').setValue(typeaheadAsiakas.nimi)
    this.ytunnus.setValue(typeaheadAsiakas.ytunnus)
    this._laskunAsiakasTypeahead.annaAsiakas(typeaheadAsiakas)
      .then(asiakas => {
        if (asiakas) {
          this.tyhjennaAsiakashaunVihjeteksti()
          this.asiakasMuuttui.next(this._laskuKopioija.copyAsiakas(asiakas))
          this.suodatetutAsiakkaat.next([])
        }
      }).catch(err => { this._errorHandler.handleError(err) })
  }

  private _suodataMaat(maat: Maa[], tyyppi: LaskunTyyppi): Maa[] {
    if (this._onkoKotimaanLasku(tyyppi)) {
      return maat.filter(maa => maa.koodi === 'FIN')
    } else if (this._onkoEuLasku(tyyppi)) {
      if (this._onkoPohjoisIrlantiLasku(tyyppi)) {
        return maat.filter(maa => (maa.koodi === MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3 || this._maaService.euMaakoodit.indexOf(maa.koodi) > -1) && maa.koodi !== 'FIN')
      }
      return maat.filter(maa => this._maaService.euMaakoodit.indexOf(maa.koodi) > -1 && maa.koodi !== 'FIN')
    } else if (this._onkoEunUlkopuolinenLasku(tyyppi)) {
      return maat.filter(maa => this._maaService.euMaakoodit.indexOf(maa.koodi) < 0 && maa.koodi !== MaaErikoiskoodit.POHJOIS_IRLANTI_KOODI_ALPHA_3)
    }
    return maat
  }

  private _onkoKotimaanLasku(tyyppi: LaskunTyyppi): boolean {
    return tyyppi?.avain === LaskunTyypit.TAVALLINEN.avain || tyyppi?.avain === LaskunTyypit.RAKENNUSALA.avain
  }

  /**
   * There is a special agreement that started validity 1.1.2021 and will be in effect for 4 years tha grants
   * Northern Ireland the right to be treated as EU country for VAT taxation purposes. The agreement is valid
   * only for goods. (Not services.)
   * @param tyyppi
   * @returns
   */
  private _onkoPohjoisIrlantiLasku(tyyppi: LaskunTyyppi): boolean {
    return tyyppi.avain === LaskunTyypit.EU_TAVARA.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA_TAVARA.avain
  }

  private _onkoEuLasku(tyyppi: LaskunTyyppi): boolean {
    return tyyppi.avain === LaskunTyypit.EU_TAVARA.avain || tyyppi.avain === LaskunTyypit.EU_PALVELU.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA_TAVARA.avain || tyyppi.avain === LaskunTyypit.EU_KULUTTAJA_PALVELU.avain || tyyppi.avain === LaskunTyypit.TAVALLINEN_ULKOM.avain
  }

  private _onkoEunUlkopuolinenLasku(tyyppi: LaskunTyyppi): boolean {
    return tyyppi.avain === LaskunTyypit.MUU_MAAILMA.avain
  }

  private _setupForm(kasiteltava: LaskuBase) {

    if (kasiteltava && kasiteltava.asiakas) {

      const typeaheadAsiakas: TypeaheadAsiakas = {
        avain: kasiteltava.asiakas.avain,
        nimi: kasiteltava.asiakas.nimi,
        ytunnus: kasiteltava.asiakas.ytunnus
      }

      this.nimi.clearValidators()
      this.nimi.setValidators(this.annaPuuttuvaAsiakasNimiValidator(kasiteltava))

      this.nimi.setValue(typeaheadAsiakas)
      this.ytunnus.setValue(kasiteltava.asiakas.ytunnus)
      this.katuosoite.setValue(kasiteltava.asiakas.katuosoite)
      this.postinro.setValue(kasiteltava.asiakas.postinro)
      this.postitoimipaikka.setValue(kasiteltava.asiakas.postitmp)
      this.maa.setValue(kasiteltava.asiakas.maa)
      // this.asiakastyyppi.setValue(kasiteltava.asiakas.asiakastyyppi)

      // this.asiakastyypinNakyvyysObservable.next(kasiteltava.asiakas.asiakastyyppi ? 'quickhide' : 'shown')
      // this.asiakastyypinKuvakkeenNakyvyysObservable.next(kasiteltava.asiakas.asiakastyyppi ? 'quickshow' : 'hidden')
      // this.asiakastyyppiObservable.next(kasiteltava.asiakas.asiakastyyppi)

      // Aseta kuuntelijat
      this.nimi.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((value: TypeaheadAsiakas) => {

        // // console.log('ASETA NIMI')

        if (typeof value === 'string') {
          kasiteltava.asiakas.nimi = value
          this.asetaAsiakashaunVihjeteksti()
          if (this.valittuAsiakas) {

          } else {
            const kasitelty = this._validationService.processValue(value)
            this._laskunAsiakasTypeahead.haeJaSuodata(kasitelty).then(result => {
              this.suodatetutAsiakkaat.next(result.suodatetut)
            }).catch(err => {
              this._errorHandler.handleError(err)
            })
          }
        } else if (value) {
          if (this.valittuAsiakas) {
            this.asetaAsiakashaunVihjeteksti()
            kasiteltava.asiakas.nimi = value.nimi
          } else {
            this._asetaTypeaheadAsiakas(value)
          }
        }
      })
      this.ytunnus.valueChanges.pipe(
        debounceTime(750),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((value: string) => {
        // console.log('ytunnustarkistus', value)

        if (!value) {
          return
        }

        if (this.onkoYtunnusPakollinen === 'pakollinen-ytunnus') {
          if (this._codeCheckService.isValidYTunnus(value) || this._vatNumberValidationService.isValidEuVatNumberByCountry(value, 'FI')) {
            this._tarkistaAlvNumeroViesista(this._codeCheckService.annaVatTunnus(value))
          } else if (this._vatNumberValidationService.isValidEuVatNumber(value)) {
            this._tarkistaAlvNumeroViesista(value)
          }
        } else if ((this.onkoYtunnusPakollinen === 'pakollinen-vat' || this.onkoYtunnusPakollinen === 'pakollinen-ulkomaan-vat') && this._vatNumberValidationService.isValidEuVatNumber(value)) {
          this._tarkistaAlvNumeroViesista(value)
        }

      })
      this.ytunnus.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        // console.log('ytunnus')
        const processed = this._validationService.processValue(value)
        if (kasiteltava.asiakas.ytunnus !== processed) {
          kasiteltava.asiakas.ytunnus = processed
          this.asetaAsiakashaunVihjeteksti()
        }
      })
      this.katuosoite.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        // console.log('katuosoite')
        const processed = this._validationService.processValue(value)
        if (kasiteltava.asiakas.katuosoite !== processed) {
          kasiteltava.asiakas.katuosoite = processed
          this.asetaAsiakashaunVihjeteksti()
        }
      })
      this.postinro.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        // console.log('postinro')
        const processed = this._validationService.processValue(value)
        if (kasiteltava.asiakas.postinro !== processed) {
          kasiteltava.asiakas.postinro = processed
          this.asetaAsiakashaunVihjeteksti()
        }
      })
      this.postitoimipaikka.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        // console.log('postitoimipaikka')
        const processed = this._validationService.processValue(value)
        if (kasiteltava.asiakas.postitmp !== processed) {
          kasiteltava.asiakas.postitmp = processed
          this.asetaAsiakashaunVihjeteksti()
        }
      })
      this.maa.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        // console.log('maa')
        const processed = this._validationService.processValue(value)
        if (kasiteltava.asiakas.maa !== processed) {
          kasiteltava.asiakas.maa = processed
          this.asetaAsiakashaunVihjeteksti()
          this.ytunnus.updateValueAndValidity()
        }
      })
      // this.asiakastyyppi.valueChanges.pipe(
      //   takeUntil(this.ngUnsubscribe)
      // ).subscribe(value => {
      //   // console.log('asiakastyyppi', value)
      //   this.asetaAsiakashaunVihjeteksti()
      //   kasiteltava.asiakas.asiakastyyppi = this.validationService.processValue(value)
      //   this.asiakastyyppiObservable.next(kasiteltava.asiakas.asiakastyyppi)
      //   this.asiakastyypinNakyvyysObservable.next(kasiteltava.asiakas.asiakastyyppi ? 'hidden' : 'shown')
      //   this.asiakastyypinKuvakkeenNakyvyysObservable.next(kasiteltava.asiakas.asiakastyyppi ? 'shown' : 'hidden')
      // })

      // Aseta ytunnuksen pakollisuus
      this.paivitaYtunnuksenPakollisuus(this.onkoYtunnusPakollinen)

    }

  }

  private _tarkistaAlvNumeroViesista(value: string) {
    const kasitelty = this._validationService.processValue(value)
    this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.tarkistetaan'))
    const maakoodi = kasitelty.substr(0, 2)
    const numero = kasitelty.substr(2)
    this._alvNumeronTarkistajaService.tarkistaVatNumero(maakoodi, numero).then(vastaus => {
      // // console.log(vastaus)
      if (vastaus.tulos === AlvNumeronTarkistustulos.ONNISTUI) {
        this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.oikein', { nimi: vastaus.yrityksenNimi, osoite: vastaus.yrityksenOsoite }))
        // this.vatTunnuksenValidointitulos = 'Numero oikein, tiedot: ' + vastaus.yrityksenNimi + ', ' + vastaus.yrityksenOsoite
      } else if (vastaus.tulos === AlvNumeronTarkistustulos.EU_PALVELIMEN_VIRHE) {
        this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.epaonnistui-eu'))
        // this.vatTunnuksenValidointitulos = 'VAT-numeron tarkistaminen ei onnistunut EU-palvelimen takia.'
      } else if (vastaus.tulos === AlvNumeronTarkistustulos.MAAKOODI_VIRHEELLINEN) {
        this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.virheellinen-maakoodi'))
        // this.vatTunnuksenValidointitulos = 'Virheellinen maakoodi. VAT-numero alkaa kaksikirjaimisella koodilla, esim. FI.'
      } else {
        this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.virheellinen-numero'))
        // this.vatTunnuksenValidointitulos = 'Virheellinen numero. Tarkista numero.'
      }
    }).catch(error => {
      this.vatTunnuksenValidointitulos.next(this._translationService.lokalisoi('lasku.alv-numeron-tarkistaminen.tuntematon-virhe'))
      // this.vatTunnuksenValidointitulos = 'Tarkistuksen aikana tapahtui virhe: ' + error.message
      this._errorHandler.handleError(error)
    })
  }

  private _ytunnusValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (
        !this._codeCheckService.isValidYTunnus(control.value) &&
        !this._vatNumberValidationService.isValidEuVatNumberByCountry(control.value, 'FI')
      ) {
        return { 'invalid_ytunnus': true }
      }
    }
    return null
  }

  private _onlyForeignVatNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const ytunnusControl = this.ytunnus
    const maaControl = this.maa
    if (!maaControl.value || !ytunnusControl.value) {
      return null
    }
    const alpha2Code = this._maaService.getAlpha2Code(maaControl.value)
    if (alpha2Code !== 'FI' && this._vatNumberValidationService.isValidEuVatNumberByCountry(ytunnusControl.value, alpha2Code)) {
      return null
    }
    return { 'invalid_vat_number': true }
  }


  private _vatNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const ytunnusControl = this.ytunnus
    const maaControl = this.maa
    if (!maaControl.value || !ytunnusControl.value) {
      return null
    }
    const alpha2Code = this._maaService.getAlpha2Code(maaControl.value)
    if (this._vatNumberValidationService.isValidEuVatNumberByCountry(ytunnusControl.value, alpha2Code)) {
      return null
    }
    return { 'invalid_vat_number': true }
  }

  /** NB! Only use with LaskunTyyppi.TAVALLINEN */
  private _vatNumberOrYtunnusValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }

    if (this._codeCheckService.isValidYTunnus(control.value) || this._vatNumberValidationService.isValidEuVatNumberByCountry(control.value, 'FI')) {
      return null
    }

    return { 'invalid_vat_number_or_ytunnus': true }
  }

  private tyhjennaAsiakashaunVihjeteksti() {
    this.asiakashaunVihjetekstiObservable.next('')
  }

  private asetaAsiakashaunVihjeteksti() {
    if (this.valittuAsiakas) {
      this.asiakashaunVihjetekstiObservable.next(this._translationService.lokalisoi('lasku.muokkaa-asiakasta'))
    } else {
      this.asiakashaunVihjetekstiObservable.next(this._translationService.lokalisoi('lasku.tallenna-uutena-asiakkaana'))
    }
    this.naytaCheck = false
  }

  private annaPuuttuvaAsiakasNimiValidator(kasiteltava: LaskuBase) {
    return (control: FormControl<TypeaheadAsiakas>) => {
      if (!kasiteltava.asiakas || !kasiteltava.asiakas.nimi || kasiteltava.asiakas.nimi.replace(/\s+/g, '') === '') {
        return { required: true }
      }
      return null
    }
  }

  displayFn(laskunAsiakas: LaskunAsiakas): string {
    if (laskunAsiakas && laskunAsiakas.nimi) {
      return laskunAsiakas.nimi
    }
    return ''
  }

  private paivitaYtunnuksenPakollisuus(pakollinen: VatTaiYtunnuksePakollisuus) {
    const ytunnusKentta = this.ytunnus
    if (pakollinen === 'pakollinen-ytunnus') {
      ytunnusKentta.enable()
      ytunnusKentta.setValidators([Validators.required, this._ytunnusValidator])
    } else if (pakollinen === 'pakollinen-vat') {
      ytunnusKentta.enable()
      ytunnusKentta.setValidators([Validators.required, this._vatNumberValidator])
    } else if (pakollinen === 'ei-pakollinen-mutta-tarkista-vat-vai-ytunnus') {
      ytunnusKentta.enable()
      ytunnusKentta.setValidators([this._vatNumberOrYtunnusValidator])
    } else if (pakollinen === 'pakollinen-ulkomaan-vat') {
      ytunnusKentta.enable()
      ytunnusKentta.setValidators([Validators.required, this._onlyForeignVatNumberValidator])
    } else if (pakollinen === 'ei-pakollinen') {
      ytunnusKentta.clearValidators()
      ytunnusKentta.clearAsyncValidators()
      ytunnusKentta.disable()
    } else {
      throw new Error('Tuntematon pakollisuus ' + pakollinen)
    }
  }

  get katuosoite(): AbstractControl<string> {
    return this.parent?.get('katuosoite') ?? null
  }

  get nimi(): AbstractControl<TypeaheadAsiakas> {
    return this.parent?.get('nimi') ?? null
  }

  get ytunnus(): AbstractControl<string> {
    return this.parent?.get('ytunnus') ?? null
  }

  get postinro(): AbstractControl<string> {
    return this.parent?.get('postinro') ?? null
  }

  get postitoimipaikka(): AbstractControl<string> {
    return this.parent?.get('postitoimipaikka') ?? null
  }

  get maa(): AbstractControl<string> {
    return this.parent?.get('maa') ?? null
  }

  private asetaMaa(tyyppi: LaskunTyyppi, asiakas: LaskunAsiakas, suodatetutMaat: Maa[]) {
    // Aseta maa
    if (this._onkoKotimaanLasku(tyyppi)) {
      const maaControl = this.maa
      maaControl.setValue('FIN')
      maaControl.disable()
      if (asiakas) {
        asiakas.maa = 'FIN'
      }
    } else {
      const maaControl = this.maa
      maaControl.enable()
      if (asiakas) {
        if (suodatetutMaat.findIndex(maa => maa.koodi === asiakas.maa) < 0) {
          if (maaControl) {
            maaControl.setValue(null)
          }
          asiakas.maa = null
        }
      }
    }
  }

}
