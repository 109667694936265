import { Injectable } from '@angular/core'
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms'

import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'

export interface FieldErrors {
  [field: string]: string | string[]
}

@Injectable()
export class FormValidationService {

  constructor(
    private translation: LemonTranslationService
  ) { }

  private getErrorLocalizationKey(view: string, field: string, validator: string): string {
    return view + '.validation.' + field + '.' + validator
  }

  naytaEnsimmainenVirhe() {
    const firstInvalidField = document.querySelector('.mat-form-field-invalid') as HTMLElement
    if (firstInvalidField) {
      // console.log('löytyi', firstInvalidField)
      if ((firstInvalidField as any).scrollIntoView) {
        (firstInvalidField as any).scrollIntoView({ behavior: 'smooth' })
      } else if ((firstInvalidField as any).scrollIntoViewIfNeeded) {
        (firstInvalidField as any).scrollIntoViewIfNeeded()
      }
    }
  }

  onkoLomakkeessaVirheita(form: UntypedFormGroup): boolean {
    if (!form) { return false }

    const avaimet = Object.keys(form.controls)
    for (const fld of avaimet) {
      const ctrl = form.controls[fld]
      if (this.onkoVirheita(ctrl)) {
        return true
      }
    }
    return false
  }

  onkoVirheita(control: AbstractControl): boolean {
    if (control && !control.valid && !control.disabled) {
      return true
    } else if (control instanceof UntypedFormArray) {
      for (const ctrl of control.controls) {
        if (this.onkoVirheita(ctrl)) {
          return true
        }
      }
    } else if (control instanceof UntypedFormGroup) {
      const avaimet = Object.keys(control.controls)
      for (const fld of avaimet) {
        const ctrl = control.controls[fld]
        if (this.onkoVirheita(ctrl)) {
          return true
        }
      }
    }
    return false
  }

  tarkistaVirheetLapsistaJaItsesta(control: AbstractControl) {
    if (control instanceof UntypedFormArray) {
      for (const ctrl of control.controls) {
        this.tarkistaVirheetLapsistaJaItsesta(ctrl)
      }
    } else if (control instanceof UntypedFormGroup) {
      const avaimet = Object.keys(control.controls)
      for (const fld of avaimet) {
        const ctrl = control.controls[fld]
        this.tarkistaVirheetLapsistaJaItsesta(ctrl)
      }
    }
    control.updateValueAndValidity({ emitEvent: false })
  }

  merkitseKokoLomakeKosketuksi(form: UntypedFormGroup): void {
    if (!form) { return }
    form.markAllAsTouched()
    setTimeout(() => {
      this.naytaEnsimmainenVirhe()
    }, 100)
  }

  updateValidationStatus(view: string, form: UntypedFormGroup, produceErrorsAlways?: boolean): FieldErrors {
    if (!form) { return }
    const fldErrors: FieldErrors = {}
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field]
      this.updateValidationStatusForControl(control, view, field, fldErrors, -1, produceErrorsAlways ? true : false)
    })
    return fldErrors
  }

  private updateValidationStatusForControl(control: AbstractControl, view: string, field: string, fldErrors: FieldErrors, index: number, produceErrorsAlways: boolean) {
    if (control instanceof UntypedFormArray) {
      let i = 0
      for (const ctrl of control.controls) {
        this.updateValidationStatusForControl(ctrl, view, field, fldErrors, i, produceErrorsAlways)
        i++
      }
    } else if (control instanceof UntypedFormGroup) {
      Object.keys(control.controls).forEach(fld => {
        const ctrl = control.controls[fld]
        this.updateValidationStatusForControl(ctrl, view, fld, fldErrors, index, produceErrorsAlways)
      })
    } else {
      // if (!control.valid) {
      //   console.log('NOT VALID:', field)
      // }
      for (const key in control.errors) {
        if (control && !control.valid && (control.touched || produceErrorsAlways)) {
          const kokoAvain = this.getErrorLocalizationKey(view, field, key)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const viesti = this.translation.lokalisoi(kokoAvain, { 'rivi_nro': index + 1 })
          if (index > -1) {
            if (fldErrors[field]) {
              const arr = fldErrors[field] as string[]
              if (arr[index]) {
                arr[index] += ' ' + viesti
              }
            } else {
              const arr: string[] = []
              arr[index] = viesti
              fldErrors[field] = arr
            }
          } else {
            if (fldErrors[field]) {
              fldErrors[field] += ' ' + viesti
            } else {
              fldErrors[field] = viesti
            }
          }
        }
      }
    }
  }

  public processValue(value: any) {
    if (value !== undefined && value !== null) {

      if (typeof value === 'string') {
        return value.replace(/\s+/g, ' ').trim()
      }

      return value
    }
    return null
  }

  public sanitizeWhitespace(value: any) {
    if (value !== undefined && value !== null) {

      if (typeof value === 'string') {
        return value.replace(/[ \t]+/g, ' ').trim()
      }

      return value
    }
    return null
  }

}
