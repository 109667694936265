import { Pipe, PipeTransform } from '@angular/core'

import { CurrencyService } from '../../_shared-core/service/currency.service'
import { TuettuKieli } from '../../_shared-core/model/common'

import { LemonTranslationService } from '../service/lemon-translation.service'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Big } from 'big.js'

@Pipe({
  name: 'lmoney'
})
export class CurrencyPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private currencyService: CurrencyService
  ) { }

  transform(value: number, currency?: string): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        // console.log(value + ' - ' + currency + ' - ' + kieli)
        if (value === undefined || value === null) { return '' }

        if (currency) {
          return this.currencyService.formatoiRaha(value, currency.toLowerCase(), kieli)
        }

        return this.currencyService.formatoiRahaIlmanValuuttaSymbolia(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lbigmoney'
})
export class BigCurrencyPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private currencyService: CurrencyService
  ) { }

  transform(value: Big, currency?: string): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        // console.log(value + ' - ' + currency + ' - ' + language)
        if (value === undefined || value === null) { return '' }

        if (currency) {
          return this.currencyService.formatoiBigRaha(value, currency.toLowerCase(), kieli)
        }

        return this.currencyService.formatoiBigRahaIlmanValuuttaSymbolia(value, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lmoneyl'
})
export class CurrencyWithLocalePipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) { }

  transform(value: number, currency: string, kieli: TuettuKieli): string {

    if (value === undefined || value == null) {
      return ''
    }

    if (currency) {
      return this.currencyService.formatoiRaha(value, currency.toLowerCase(), kieli)
    }

    return this.currencyService.formatoiRahaIlmanValuuttaSymbolia(value, kieli)
  }

}

@Pipe({
  name: 'lbigmoneyl'
})
export class BigCurrencyWithLocalePipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) { }

  transform(value: Big, currency: string, kieli: TuettuKieli): string {

    if (value === undefined || value == null) {
      return ''
    }

    if (currency) {
      return this.currencyService.formatoiBigRaha(value, currency.toLowerCase(), kieli)
    }
    return this.currencyService.formatoiBigRahaIlmanValuuttaSymbolia(value, kieli)
  }

}
