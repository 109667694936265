import { VatNumberValidationCountrySettings, VatNumberValidationResult } from '../model/vat-number-validation'
import { austria } from './vat-number-validation/austria'
import { belgium } from './vat-number-validation/belgium'
import { bulgaria } from './vat-number-validation/bulgaria'
import { croatia } from './vat-number-validation/croatia'
import { cyprus } from './vat-number-validation/cyprus'
import { czechRepublic } from './vat-number-validation/czechRepublic'
import { denmark } from './vat-number-validation/denmark'
import { estonia } from './vat-number-validation/estonia'
import { finland } from './vat-number-validation/finland'
import { france } from './vat-number-validation/france'
import { germany } from './vat-number-validation/germany'
import { greece } from './vat-number-validation/greece'
import { hungary } from './vat-number-validation/hungary'
import { ireland } from './vat-number-validation/ireland'
import { italy } from './vat-number-validation/italy'
import { latvia } from './vat-number-validation/latvia'
import { lithuania } from './vat-number-validation/lithuania'
import { luxembourg } from './vat-number-validation/luxembourg'
import { malta } from './vat-number-validation/malta'
import { netherlands } from './vat-number-validation/netherlands'
import { poland } from './vat-number-validation/poland'
import { portugal } from './vat-number-validation/portugal'
import { romania } from './vat-number-validation/romania'
import { slovakiaRepublic } from './vat-number-validation/slovakiaRepublic'
import { slovenia } from './vat-number-validation/slovenia'
import { spain } from './vat-number-validation/spain'
import { sweden } from './vat-number-validation/sweden'

class VatNumberValidationBase {

  private _countries: Map<string, VatNumberValidationCountrySettings> = new Map()

  constructor() {
    this._countries.set(austria.code, austria)
    this._countries.set(belgium.code, belgium)
    this._countries.set(bulgaria.code, bulgaria)
    this._countries.set(croatia.code, croatia)
    this._countries.set(cyprus.code, cyprus)
    this._countries.set(czechRepublic.code, czechRepublic)
    this._countries.set(denmark.code, denmark)
    this._countries.set(estonia.code, estonia)
    this._countries.set(finland.code, finland)
    this._countries.set(france.code, france)
    this._countries.set(germany.code, germany)
    this._countries.set(greece.code, greece)
    this._countries.set(hungary.code, hungary)
    this._countries.set(ireland.code, ireland)
    this._countries.set(italy.code, italy)
    this._countries.set(latvia.code, latvia)
    this._countries.set(lithuania.code, lithuania)
    this._countries.set(luxembourg.code, luxembourg)
    this._countries.set(malta.code, malta)
    this._countries.set(netherlands.code, netherlands)
    this._countries.set(poland.code, poland)
    this._countries.set(portugal.code, portugal)
    this._countries.set(romania.code, romania)
    this._countries.set(slovakiaRepublic.code, slovakiaRepublic)
    this._countries.set(slovenia.code, slovenia)
    this._countries.set(spain.code, spain)
    this._countries.set(sweden.code, sweden)
  }

  private _isVatValidToRegexp(vat: string, regexArr: ReadonlyArray<RegExp>): { isValid: boolean, regex?: RegExp } {
    for (const regex of regexArr) {
      const isValid = regex.test(vat)
      if (isValid) { return { isValid: true, regex: regex } }
    }

    return { isValid: false, regex: undefined }
  }

  public getCountry(vat: string): VatNumberValidationCountrySettings | undefined {
    for (const countryCode of this._countries.keys()) {
      if (vat.startsWith(countryCode)) {
        return this._countries.get(countryCode)
      }
    }

    return undefined
  }

  public isVatValid(vat: string, country: string): boolean {
    const countrySettings = this._countries.get(country)
    if (!countrySettings) { return false }
    const regexpValidRes = this._isVatValidToRegexp(vat, countrySettings.rules.regex)

    if (!regexpValidRes.isValid || !regexpValidRes.regex) {
      return false
    }

    const regexResult = regexpValidRes.regex.exec(vat)
    if (!regexResult) {
      return false
    }

    return countrySettings.calcFn(regexResult[2])
  }

  public checkVAT(vat: string): VatNumberValidationResult {
    if (!vat) {
      return this.makeResult(vat, false)
    }

    const detectedCountry = this.getCountry(vat)
    if (detectedCountry) {
      const isValid = this.isVatValid(vat, detectedCountry.code)
      return this.makeResult(vat, isValid, detectedCountry.code)
    }

    return this.makeResult(vat, false)
  }

  public makeResult(vat: string, isValid?: boolean, country?: string): VatNumberValidationResult {
    let countrySettings: VatNumberValidationCountrySettings | undefined
    if (country) {
      countrySettings = this.getCountry(country)
    }

    return {
      value: vat || undefined,
      isValid: Boolean(isValid),
      isValidFormat: countrySettings ? this._isVatValidToRegexp(vat, countrySettings.rules.regex).isValid : false,
      isSupportedCountry: Boolean(country),
      country: !countrySettings
        ? undefined
        : {
          name: countrySettings.name,
          code: countrySettings.code,
        }
    }
  }

}

export class VatNumberValidationService extends VatNumberValidationBase {

  constructor(

  ) {
    super()
  }

  public isValidEuVatNumber(suspect: string): boolean {
    const res: VatNumberValidationResult = super.checkVAT(suspect)
    return res.isValid
  }

  public isValidEuVatNumberByCountry(suspect: string, country: string): boolean {
    const usedCountry = country === 'GR' ? 'EL' : country
    const isValid = usedCountry ? super.isVatValid(suspect, usedCountry) : false
    const res: VatNumberValidationResult = super.makeResult(suspect, isValid, usedCountry)
    return res.isValid
  }

}
