import { Component, Input, OnChanges, ViewChild, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core'

import * as jsbarcode from 'jsbarcode'

@Component({
  selector: '[lemon-barcode]',
  template: '<div #bcElement [class]="barcodeCssClass"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeComponent implements OnChanges {

  @ViewChild('bcElement', { static: true }) bcElement: ElementRef

  @Input() barcodeElementType: 'svg' | 'img' | 'canvas' = 'img'
  @Input() barcodeCssClass = 'barcode' // this should be done more elegantly

  @Input() barcodeFormat: 'CODE128C' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar' = 'CODE128C'
  @Input() barcodeLineColor = '#000000'
  @Input() barcodeWidth = 1
  @Input() barcodeHeight = 20
  @Input() barcodeDisplayValue = false
  @Input() barcodeFontOptions = ''
  @Input() barcodeFont = 'monospace'
  @Input() barcodeTextAlign = 'center'
  @Input() barcodeTextPosition = 'bottom'
  @Input() barcodeTextMargin = 2
  @Input() barcodeFontSize = 20
  @Input() barcodeBackground = '#ffffff'
  @Input() barcodeMargin = 0
  @Input() barcodeMarginTop = 0
  @Input() barcodeMarginBottom = 0
  @Input() barcodeMarginLeft = 0
  @Input() barcodeMarginRight = 0
  @Input() barcodeValue = ''

  @Input() barCodeValid: () => boolean = () => true


  get options(): jsbarcode.BaseOptions {
    return {
      format: this.barcodeFormat,
      lineColor: this.barcodeLineColor,
      width: this.barcodeWidth,
      height: this.barcodeHeight,
      displayValue: this.barcodeDisplayValue,
      fontOptions: this.barcodeFontOptions,
      font: this.barcodeFont,
      textAlign: this.barcodeTextAlign,
      textPosition: this.barcodeTextPosition,
      textMargin: this.barcodeTextMargin,
      fontSize: this.barcodeFontSize,
      background: this.barcodeBackground,
      margin: this.barcodeMargin,
      marginTop: this.barcodeMarginTop,
      marginBottom: this.barcodeMarginBottom,
      marginLeft: this.barcodeMarginLeft,
      marginRight: this.barcodeMarginRight,
      valid: this.barCodeValid
    }
  }

  constructor(private renderer: Renderer2) { }

  ngOnChanges() {
    this.createBarcode()
  }

  createBarcode() {
    if (!this.barcodeValue) { return }

    let element: Element
    switch (this.barcodeElementType) {
      case 'img':
        element = this.renderer.createElement('img')
        break
      case 'canvas':
        element = this.renderer.createElement('canvas')
        break
      case 'svg':
      default:
        element = this.renderer.createElement('svg', 'svg')
    }

    jsbarcode(element, this.barcodeValue, this.options)

    for (const node of this.bcElement.nativeElement.childNodes) {
      this.renderer.removeChild(this.bcElement.nativeElement, node)
    }

    this.renderer.appendChild(this.bcElement.nativeElement, element)

  }

}
