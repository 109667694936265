import { DateService } from '../../../_shared-core/service/date.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { ReskontraService } from './lasku-shared.service'

import { LaskuBase, Lasku, Laskuasetukset, LaskuReskontra } from '../../model/lasku'

import { Big } from 'big.js'

export class LaskuKorkoService {

  constructor(
    private dateService: DateService,
    private currencyService: CurrencyService,
    private reskontraService: ReskontraService
  ) { }

  annaOletusViivastyskorkoProsentti(): Big {
    // TODO: prosentti on konfiguroitava? Eri prosentti yksityiskauppaan / yritysten väliseen?
    return new Big('11.5')
  }

  annaOletusViivastyskorkoProsenttiNumber(): number {
    return 11.5
    // return Number(this.annaOletusViivastyskorkoProsentti().toFixed(2, Big.roundHalfUp))
  }

  annaViivastyskorkoProsentti(juurilasku: Lasku): Big {
    if (juurilasku.viivastyskorkoprosentti !== null && juurilasku.viivastyskorkoprosentti !== undefined) {
      return new Big(juurilasku.viivastyskorkoprosentti)
    }
    return this.annaOletusViivastyskorkoProsentti()
  }

  laskeKorkoLaskulle(juurilasku: Lasku, kasiteltava: LaskuBase, asetukset: Laskuasetukset, reskontra: LaskuReskontra[]): number {
    const viivastyskorkoprosentti = this.annaViivastyskorkoProsentti(juurilasku)
    const korko = this.laskeKorkoProsentilla(viivastyskorkoprosentti, reskontra, new Big(kasiteltava.summa), juurilasku.erapvm.toDate(), new Date())
    return this.currencyService.muutaBigDecimalRahaksi(korko)
  }

  // laskeKorko(reskontra: LaskuReskontra[], laskunSumma: Big, erapvm: Date, viivastyskorkoprosentti: Big, nyt: Date): Big {
  //   return this.laskeKorkoProsentilla(viivastyskorkoprosentti, reskontra, laskunSumma, erapvm, nyt)
  // }

  laskeKorkoProsentilla(viivastyskorkoprosentti: Big, reskontra: LaskuReskontra[], laskunSumma: Big, erapvm: Date, nyt: Date) {

    if (viivastyskorkoprosentti.eq(0)) {
      return new Big('0')
    }

    const prosentti = viivastyskorkoprosentti.div(new Big('100'))
    const paivia = new Big('365')
    // const paiviaKarkausVuosi = new Big('366') // TODO: jos päivän, jonka korkoa lasketaan, vuosi on karkausvuosi, tulisi jakaa tällä.
    const korkoOnJuossutPaivaa = this.dateService.paiviaValissa(nyt, erapvm)
    // console.log('nyt', this.dateService.dateToLocalDate(nyt), 'erapvm', this.dateService.dateToLocalDate(erapvm), 'paivia valissa', korkoOnJuossutPaivaa)

    // console.log('korkoOnJuossutPaivaa', korkoOnJuossutPaivaa)
    let korko = new Big('0')
    for (let i = 1; i < korkoOnJuossutPaivaa + 1; i++) {
      const kasiteltavaPaiva = this.dateService.lisaaPaivia(erapvm, i)
      const avoinna = laskunSumma.minus(this.reskontraService.annaReskontranSummaPaivalle(reskontra, kasiteltavaPaiva))
      // console.log(kasiteltavaPaiva, 'avoinna', avoinna.toFixed(6))
      const paivanKorko = prosentti.times(avoinna).div(paivia)
      korko = korko.plus(paivanKorko)
    }

    return korko
  }

}
