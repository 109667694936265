import { Injectable } from '@angular/core'
import { TarkistaAlvNumeroVastaus, AlvNumeronTarkistustulos, TarkistaAlvNumeroPyynto } from '../../_jaettu/model/common'
import { SharedFirebaseLemonaid } from 'app/_jaettu-angular/base-firebase.service'

@Injectable()
export class AlvNumeronTarkistajaService {

  constructor(private _firebaseLemonaid: SharedFirebaseLemonaid) { }

  public tarkistaVatNumero(maakoodi: string, numero: string): Promise<TarkistaAlvNumeroVastaus> {

    // Samat tarkistukset kuin WSDL:ssä, eli älä tee turhaan requestia.
    // WSDL is in http://ec.europa.eu/taxation_customs/vies/checkVatService.wsdl
    // The countryCode input parameter must follow the pattern [A-Z]{2}
    if (!maakoodi || !/^[A-Z]{2}$/.test(maakoodi.trim().toUpperCase())) {
      const v1: TarkistaAlvNumeroVastaus = {
        tulos: AlvNumeronTarkistustulos.MAAKOODI_VIRHEELLINEN,
        yrityksenNimi: null,
        yrityksenOsoite: null,
        virhe: 'INVALID_INPUT'
      }
      // console.log('vastaus', v1)
      return Promise.resolve(v1)
    }

    // The vatNumber input parameter must follow the pattern [0-9A-Za-z\+\*\.]{2,12}
    if (!numero || !/^[0-9A-Za-z\+\*\.]{2,12}$/.test(numero.trim().replace('-', ''))) {
      const v2: TarkistaAlvNumeroVastaus = {
        tulos: AlvNumeronTarkistustulos.NUMERO_VIRHEELLINEN,
        yrityksenNimi: null,
        yrityksenOsoite: null,
        virhe: numero ? null : 'INVALID_INPUT'
      }
      // console.log('vastaus', v2)
      return Promise.resolve(v2)
    }

    const pyynto: TarkistaAlvNumeroPyynto = {
      maakoodi: maakoodi,
      numero: numero
    }

    return this._firebaseLemonaid.functionsCall<TarkistaAlvNumeroPyynto, TarkistaAlvNumeroVastaus>('tarkistaVatNumero', pyynto)
  }

}
