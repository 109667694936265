import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { Lasku, LaskuBase } from '../../../_jaettu/model/lasku'

import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { FormValidationService, FieldErrors } from '../../../_jaettu-angular/service/form-validation.service'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'
import { DateService } from '../../../_shared-core/service/date.service'
import { LaskuSharedService } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuForm } from './lasku-rivit.component'

@Component({
  selector: 'app-lasku-muut-tiedot',
  templateUrl: './lasku-muut-tiedot.component.html',
  styleUrls: ['./lasku-muut-tiedot.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuMuutTiedotComponent implements OnInit, OnDestroy {

  @Input() paivitaValidaatiot: any
  @Input() parent: FormGroup<LaskuForm>
  @Input() laskuObservable: Observable<{ juurilasku: Lasku, kasiteltava: LaskuBase }>
  @Input() naytaViivastuskorko: boolean = false

  minErapaivaDate: Date = new Date()
  maksuaikaa = 14
  fieldErrors: FieldErrors = {}
  namename = 'asfasf' + Math.random()

  private ngUnsubscribe = new Subject<void>()
  private ngUnsubscribeKomponentti = new Subject<void>()

  constructor(
    private validationService: FormValidationService,
    private laskuSharedService: LaskuSharedService,
    private timestampService: TimestampService,
    private dateService: DateService
  ) { }

  ngOnInit() {

    this.laskuObservable.pipe(
      takeUntil(this.ngUnsubscribeKomponentti)
    ).subscribe(lasku => {
      this.ngUnsubscribe.complete()
      this.ngUnsubscribe = new Subject<void>()
      this.setupForm(this.parent, lasku.juurilasku, lasku.kasiteltava)
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    this.ngUnsubscribeKomponentti.next()
    this.ngUnsubscribeKomponentti.complete()
  }

  private setupForm(parent: FormGroup<LaskuForm>, juurilasku: Lasku, kasiteltava: LaskuBase) {
    // parent.removeControl('laskunpvm')
    // parent.removeControl('erapaiva')
    // parent.removeControl('maksuaikaa')
    // parent.removeControl('toimituspvm')
    // parent.removeControl('lisatiedot')
    // parent.removeControl('viitteenne')

    if (kasiteltava.pvm) {
      this.minErapaivaDate = this.timestampService.lisaaPaiviaTimestamp(kasiteltava.pvm, 1).toDate()
    } else {
      this.minErapaivaDate = this.timestampService.lisaaPaiviaTimestamp(this.timestampService.now(), 1).toDate()
    }

    if (kasiteltava.erapvm) {
      this.maksuaikaa = this.laskuSharedService.laskeMaksuaikaa(kasiteltava)
    }

    // Luo form controllit
    // parent.addControl('laskunpvm',        new FormControl({value: this.dateService.timestampToDate(kasiteltava.pvm), disabled: true}))
    // parent.addControl('erapaiva',         new FormControl(this.dateService.timestampToDate(kasiteltava.erapvm), [ Validators.required ]))
    // parent.addControl('maksuaikaa',       new FormControl(this.maksuaikaa, [ Validators.required, Validators.min(1) ]))
    // parent.addControl('toimituspvm',      new FormControl(this.dateService.timestampToDate(kasiteltava.toimituspvm)))
    // parent.addControl('lisatiedot',       new FormControl(kasiteltava.lisatiedot))
    // parent.addControl('viitteenne',       new FormControl(kasiteltava.viitteenne))

    parent.get('laskunpvm').setValue(this.dateService.timestampToDate(kasiteltava.pvm))
    parent.get('erapaiva').setValue(this.dateService.timestampToDate(kasiteltava.erapvm))
    parent.get('maksuaikaa').setValue(this.maksuaikaa)
    parent.get('toimituspvm').setValue(this.dateService.timestampToDate(kasiteltava.toimituspvm))
    parent.get('lisatiedot').setValue(kasiteltava.lisatiedot)
    parent.get('viitteenne').setValue(kasiteltava.viitteenne)
    parent.get('viivastyskorko').setValue(juurilasku.viivastyskorkoprosentti)

    // Aseta kuuntelijat
    parent.get('laskunpvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kasiteltava.pvm = this.timestampService.dateToTimestamp(value)
      kasiteltava.pvml = this.dateService.dateToLocalDate(value)
      kasiteltava.p = this.dateService.dateToNumber(value)
    })
    parent.get('erapaiva').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kasiteltava.erapvm = this.timestampService.dateToTimestamp(value)
      kasiteltava.erapvml = this.dateService.dateToLocalDate(value)
      kasiteltava.erap = this.dateService.dateToNumber(value)
      this.maksuaikaa = this.laskuSharedService.laskeMaksuaikaa(kasiteltava)
      parent.get('maksuaikaa').setValue(this.maksuaikaa, { emitEvent: false })
    })
    parent.get('maksuaikaa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      this.maksuaikaa = value
      if (this.maksuaikaa && kasiteltava.pvm) {
        const timestamp = this.timestampService.lisaaPaiviaTimestamp(kasiteltava.pvm, this.maksuaikaa)
        const asLocalDate = this.dateService.timestampToLocalDate(timestamp)
        kasiteltava.erapvm = timestamp
        kasiteltava.erapvml = asLocalDate
        kasiteltava.erap = this.dateService.localDateToNumber(asLocalDate)
        const erapaiva: Date = timestamp ? timestamp.toDate() : null
        parent.get('erapaiva').setValue(erapaiva, { emitEvent: false })
      }
    })
    parent.get('toimituspvm').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      kasiteltava.toimituspvm = this.timestampService.dateToTimestamp(value)
      kasiteltava.toimituspvml = this.dateService.dateToLocalDate(value)
      kasiteltava.toimitusp = this.dateService.dateToNumber(value)
    })
    parent.get('lisatiedot').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log(value, this.validationService.processValue(value))
      kasiteltava.lisatiedot = this.validationService.sanitizeWhitespace(value)
    })
    parent.get('viitteenne').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log(value, this.validationService.processValue(value))
      kasiteltava.viitteenne = this.validationService.processValue(value)
    })

    parent.get('viivastyskorko').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log(value, this.validationService.processValue(value))
      juurilasku.viivastyskorkoprosentti = this.validationService.processValue(value)
    })

  }

  get maksuaikaaField() {
    return this.parent?.get('maksuaikaa') ?? null
  }

  get viivastyskorkoField() {
    return this.parent?.get('viivastyskorko') ?? null
  }

  get erapaiva() {
    return this.parent?.get('erapaiva') ?? null
  }

}
