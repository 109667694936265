<div style="text-align: center; min-width: 300px;">
  <div mat-dialog-content>

    <lemon-dialog-header>Tositteen tuonti käynnistettiin uudelleen</lemon-dialog-header>

    Tuonti käynnistettiin uudelleen luomalla seuraava työjonoentry Lemonaidiin:
    <div><a [href]="uriEncoded" target="_BLANK">{{uri}}</a></div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button mat-dialog-close class="primarybutton" mat-button>OK</button>
  </div>
</div>