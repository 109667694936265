import { Component, Input, OnChanges, ElementRef, ChangeDetectionStrategy } from '@angular/core'
import { getQrCodeAsSvg } from '../../_jaettu/qrcode/svg'
@Component({
  selector: '[lemon-qrcode]',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrcodeComponent implements OnChanges {

  @Input() qrcodeValue: string

  constructor(private _elementRef: ElementRef<HTMLElement>) { }

  ngOnChanges() {
    this.createBarcode()
  }

  async createBarcode() {

    if (!this.qrcodeValue) { return }

    const qrcode = await getQrCodeAsSvg(this.qrcodeValue, {
      bgColor: 'white',
      color: 'black',
      ecLevel: 'M',
      margin: 0
    })

    this._elementRef.nativeElement.innerHTML = qrcode

  }

}
