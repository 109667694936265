import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ErrorHandler } from '@angular/core'

import { Tilikausi } from '../../_jaettu-lemonator/model/asiakas'
import { ResetPoytakirjaHistory, ResetPoytakirjaInLemonaidRequest, ResetPoytakirjaInLemonaidResponse, TilinpaatosLahetys, TilinpaatosLahetysTyojono, TilinpaatosLiitetiedot, TilinpaatosTaseErittely } from '../../_jaettu-lemonator/model/kirjanpito'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampService } from '../../_jaettu-angular/service/timestamp-service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitoUriService } from '../../_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { switchMap, map, startWith } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, firstValueFrom, BehaviorSubject } from 'rxjs'
import { KnowYourCustomer } from 'app/_jaettu/model/kayttaja'
import { lemonShare } from '../../_jaettu-angular/_rxjs/lemon-share.operator'
import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { KycUriService } from 'app/_jaettu/service/kyc-uri.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { TilinpaatosUriService } from 'app/_jaettu/service/tilinpaatos-uri.service'
import { TilinpaatosUsersService, TilinpaatosUsersViewableData } from 'app/_jaettu-lemonator/service/tilinpaatos-users.service'

interface TilinpaatosLahetysHistoria extends TilinpaatosLahetys {
  nimi: string
  luotuDateStr: string
}

interface ResetPoytakirjaHistoryWithKirjanpitaja extends ResetPoytakirjaHistory {
  kirjanpitaja: string
  aika: string
}


@Component({
  selector: '[app-tilinpaatos-lahetys]',
  templateUrl: './tilinpaatos-lahetys.component.html',
  styleUrls: ['./tilinpaatos-lahetys.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTilinpaatosLahetysComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() paivitaArvotHiljaisestiObservable: Observable<number>
  @Input() tilinpaatosStatusObservable: Observable<TilinpaatosStatus>

  sentThisTilikausiObservable: Observable<TilinpaatosLahetysHistoria[]>
  tilinpaatossahkoopostinVoiLahettaaObservable: Observable<boolean>
  tilintarkastetaanObservable: Observable<boolean>
  merkittyAllekirjoitettavaksiMuuallaObservable: Observable<boolean>
  liitetiedotLuomattaObservable: Observable<boolean>
  private _historyLoadingSubject: Subject<boolean> = new Subject()
  historyLoadingObservable: Observable<boolean> = this._historyLoadingSubject.asObservable()
  taseErittelyObservable: Observable<TilinpaatosTaseErittely>
  sendDisabledObservable: Observable<boolean>
  lukittuObservable: Observable<boolean>
  hallitusEiKunnossaObservable: Observable<boolean>
  customTilinpaatosLadattuObservable: Observable<boolean>
  resetHistoryObservable: Observable<ResetPoytakirjaHistoryWithKirjanpitaja[]>
  private _tuntemistiedotObservable: Observable<KnowYourCustomer>
  tilinpaatosUsersObservable: Observable<TilinpaatosUsersViewableData>
  tilinpaatosLahetyksetUriObservable: Observable<string>
  tilinpaatosLahetyksetEncodedUriObservable: Observable<string>
  tilinpaatosStatusUriObservable: Observable<string>
  tilinpaatosStatusEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kayttajaService.kirjanpitajaOnDevaajaObservable

  namename = 'toiughnwro' + Math.random()

  private _ngUnsubscribe = new Subject<void>()

  errorTextSubject: BehaviorSubject<string> = new BehaviorSubject(null)

  eiLemonaidKayttajaaText: string = 'Varmista, että Lemonaid-käyttäjällä on "Tunnistetaan"-rooli ja että hän on vahvasti tunnistautunut. Lemonaid-käyttäjän tiedoissa ja tuntemistiedoissa pitää olla sama henkilötunnus.'

  constructor(
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    private _kayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kycUriService: KycUriService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _firebaseLemonator: FirebaseLemonator,
    private _debugService: DebugService,
    private _tilinpaatosUriService: TilinpaatosUriService,
    private _tilinpaatosUsersService: TilinpaatosUsersService
  ) { }

  ngOnInit() {

    // Debug thingies
    this.tilinpaatosLahetyksetUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(asiakas => {
        if (asiakas) {
          return this._kirjanpitoUriService.annaTilinpaatosLahetysCollectionUri(asiakas.avain)
        }
        return ''
      })
    )
    this.tilinpaatosLahetyksetEncodedUriObservable = this.tilinpaatosLahetyksetUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this._tuntemistiedotObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<KnowYourCustomer>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<KnowYourCustomer>(this._kycUriService.getCustomerKycDocUri(asiakas.avain)).listen()
      })
    )

    this.tilinpaatosStatusUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          return 'asiakkaat/' + asiakas.avain + '/tilinpaatos-status/' + tilikausi.avain
        }
        return ''
      })
    )

    this.tilinpaatosUsersObservable = combineLatest([this._tuntemistiedotObservable, this._asiakasService.nykyisenAsiakkaanKayttajatObservable])
      .pipe(
        map(([tuntemistiedot, kayttajat]) => {
          if (!tuntemistiedot || !kayttajat?.length) {
            return null
          }
          return this._tilinpaatosUsersService.convertKycToViewableData(tuntemistiedot, kayttajat)
        })
      )

    this.tilinpaatosStatusEncodedUriObservable = this.tilinpaatosStatusUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.customTilinpaatosLadattuObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => {
        return !!status?.tilinpaatosUploadedManually
      })
    )

    this.taseErittelyObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (!asiakas || !tilikausi) {
          return observableOf<TilinpaatosTaseErittely>(null)
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
        return this._firebaseLemonator.firestoreDoc<TilinpaatosTaseErittely>(uri).listen()
      })
    )

    // const historyEntry:  = {
    //   avain: historyAvain,
    //   asiakasAvain: asiakas.avain,
    //   tilikausiAvain: tilikausi.avain,
    //   luoja: context.auth.uid,
    //   luotu: FieldValue.serverTimestamp() as any as Timestamp
    // }
    // const historyEntryDoc = lemonatorFirestore.doc(' + historyEntry.avain)

    this.resetHistoryObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      switchMap(([asiakas, tilikausi, kpMap]) => {
        if (!asiakas || !tilikausi || !kpMap) {
          return observableOf<ResetPoytakirjaHistoryWithKirjanpitaja[]>(null as ResetPoytakirjaHistoryWithKirjanpitaja[])
        }
        return this._firebaseLemonator.firestoreCollection<ResetPoytakirjaHistoryWithKirjanpitaja>('asiakkaat/' + asiakas.avain + '/kirjanpito-poytakirja-reset-history')
          .where('tilikausiAvain', '==', tilikausi.avain)
          .listen()
          .pipe(
            map(res => {
              return res.map(a => {
                const kp = kpMap.get(a.luoja)
                a.kirjanpitaja = kp ? kp.etunimi + ' ' + kp.sukunimi : a.luoja
                const aika = this._dateService.timestampToDate(a.luotu)
                a.aika = this._dateService.muotoilePaivaJaAikaDate(aika, 'fi')
                return a
              }).sort((a, b) => {
                return a.luotu.toMillis() - b.luotu.toMillis()
              })
            })
          )
      })
    )

    const liitetiedotObservable: Observable<TilinpaatosLiitetiedot> = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
          // console.log('load current from ' + uri)
          return this._firebaseLemonator.firestoreDoc<TilinpaatosLiitetiedot>(uri).listen()
        }
        return observableOf<TilinpaatosLiitetiedot>(null)
      }),
      lemonShare()
    )

    this.liitetiedotLuomattaObservable = liitetiedotObservable.pipe(
      map(liitetiedot => !liitetiedot?.kokoLomakeTallennettu)
    )

    this.tilintarkastetaanObservable = liitetiedotObservable.pipe(
      map(liitetiedot => !!liitetiedot?.tilintarkastetaan)
    )

    this.merkittyAllekirjoitettavaksiMuuallaObservable = liitetiedotObservable.pipe(
      map(liitetiedot => {
        const value = liitetiedot?.allekirjoitetaanLemonaidissa
        if (value !== null && value !== undefined) {
          return !value
        } else {
          return false
        }
      })
    )

    const hallitusKunnossaObservable: Observable<boolean> = this._tuntemistiedotObservable.pipe(
      map(kyc => {
        if (!kyc?.hallitus?.length) {
          return false
        }
        return true
      }),
      startWith(false),
      lemonShare()
    )

    this.hallitusEiKunnossaObservable = hallitusKunnossaObservable.pipe(map(kunnossa => !kunnossa))

    this.lukittuObservable = this.tilinpaatosStatusObservable.pipe(
      map(status => !!status?.rekisteroity)
    )

    this.sendDisabledObservable = combineLatest([this.taseErittelyObservable, liitetiedotObservable, hallitusKunnossaObservable, this.lukittuObservable, this.kirjanpitajaOnDevaajaObservable]).pipe(
      map(([taseErittely, liitetiedot, hallitusKunnossa, lukittu, devaaja]) => {
        if (lukittu) {
          return !devaaja
        }
        if (!taseErittely || !liitetiedot?.kokoLomakeTallennettu || !hallitusKunnossa || !liitetiedot?.allekirjoitetaanLemonaidissa) {
          return true
        }
        return !!liitetiedot?.tilintarkastetaan
      }),
      startWith(true)
    )

    const historiatTietokannastaObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (!asiakas || !tilikausi) {
          return observableOf<TilinpaatosLahetys[]>([])
        }
        const uri = this._kirjanpitoUriService.annaTilinpaatosLahetysCollectionUri(asiakas.avain)
        return this._firebaseLemonator.firestoreCollection<TilinpaatosLahetys>(uri).whereFree('tilikausi.avain', '==', tilikausi.avain).listen()
      })
    )

    this.sentThisTilikausiObservable = combineLatest([historiatTietokannastaObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([historiatTietokannasta, kirjanpitajatMap]) => {
        return historiatTietokannasta.map(tietokannasta => {
          const modified = tietokannasta as TilinpaatosLahetysHistoria

          const luotuDate = this._dateService.timestampToDate(tietokannasta.luotu)
          modified.luotuDateStr = this._dateService.muotoilePaivaJaAikaDate(luotuDate, 'fi')

          const kirjanpitajaData = kirjanpitajatMap?.get(modified.luoja)
          if (kirjanpitajaData) {
            modified.nimi = kirjanpitajaData.etunimi + ' ' + kirjanpitajaData.sukunimi
          } else {
            modified.nimi = 'Tuntematon kirjanpitäjä'
          }

          return modified
        })
      }),
      map(historia => {
        if (historia?.length) {
          return historia
        }
        return null
      })
    )

    this.tilinpaatossahkoopostinVoiLahettaaObservable = combineLatest([this.sentThisTilikausiObservable, liitetiedotObservable]).pipe(
      map(([aikaisemmat, liitetiedot]) => {
        if (!liitetiedot?.kokoLomakeTallennettu) {
          return false
        }
        return !!(aikaisemmat?.length > 0) || !!liitetiedot?.tilintarkastetaan
      })
    )

  }

  async resetPoytakirja() {

    // Avoid multiple sends
    if (this._sendToBeSignedInprogress) {
      return
    }
    this._sendToBeSignedInprogress = true

    this._ladataanService.aloitaLataaminen()

    try {

      // Obtain required user, etc. data
      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)

      const request: ResetPoytakirjaInLemonaidRequest = {
        asiakasAvain: asiakas.avain,
        tilikausiAvain: tilikausi.avain
      }

      const vastaus = await this._firebaseLemonator.functionsCall<ResetPoytakirjaInLemonaidRequest, ResetPoytakirjaInLemonaidResponse>('yhtiokokouksenPoytakirjaResetInLemonaid', request)

      if (vastaus.e) {
        this.errorTextSubject.next('Tapahtui virhe: ' + vastaus.e)
        return
      }

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._sendToBeSignedInprogress = false
    }

  }

  private _sendToBeSignedInprogress = false
  async lahetaAllekirjoitettavaksi() {

    // Avoid multiple sends
    if (this._sendToBeSignedInprogress) {
      return
    }
    this._sendToBeSignedInprogress = true

    this.errorTextSubject.next(null)

    this._ladataanService.aloitaLataaminen()

    try {
      // Obtain required user, etc. data
      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
      const tallentavaKirjanpitaja = await this._kayttajaService.getKirjanpitajanTiedot()
      const taseErittely = await firstValueFrom(this.taseErittelyObservable)
      const liitetiedotLuomatta = await firstValueFrom(this.liitetiedotLuomattaObservable)
      if (!taseErittely) {
        this.errorTextSubject.next('Tase-erittelyä ei ole luotu. Ole hyvä ja luo tase-erittely ennen lähettämistä.')
        return
      }
      if (liitetiedotLuomatta) {
        this.errorTextSubject.next('Liitetietoja ei ole luotu. Ole hyvä ja luo liitetiedot ennen lähettämistä.')
        return
      }

      const aika = this._timestampService.now()
      const tyojonoId: string = this._firebaseLemonator.firestoreCreateId()
      const tyojonoData: TilinpaatosLahetysTyojono = {
        luotu: this._timestampService.now(),
        asiakasAvain: asiakas.avain,
        tilinpaatosLahetysAvain: tyojonoId
      }
      const lahetysData: TilinpaatosLahetys = {
        avain: tyojonoId,
        tilikausi: tilikausi,
        luoja: tallentavaKirjanpitaja.uid,
        luotu: aika,
        lahetetty: null
      }

      const lahetysUri = this._kirjanpitoUriService.annaTilinpaatosLahetysUri(asiakas.avain, tyojonoId)
      const tyojonoUri = this._kirjanpitoUriService.annaTilinpaatosLahetysTyojonoUri(asiakas.avain, tyojonoId)

      // Add to batch
      const batch = this._firebaseLemonator.firestoreBatch()

      batch.set(tyojonoUri, tyojonoData)
      batch.set(lahetysUri, lahetysData)

      await batch.commit()

    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._sendToBeSignedInprogress = false
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
