import { DataSource } from '@angular/cdk/table'
import { CollectionViewer } from '@angular/cdk/collections'

import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { LaskuSharedService } from '../../_jaettu/service/lasku/lasku-shared.service'

import {
  Lasku,
  LaskunListaustieto,
  LaskunListaustietorivi,
  LaskunListaustyyppi,
  LaskunumeroTyyppi,
  LaskunTila,
  LaskuBase,
  EmailLahetysStatusKoodi,
  LaskuSahkoinenLahetysStatusKoodi
} from '../../_jaettu/model/lasku'

import { DateService } from '../../_shared-core/service/date.service'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { Timestamp, TuettuKieli } from '../../_shared-core/model/common'
import { LemonTranslationService } from '../service/lemon-translation.service'

export class LaskuTransformingWrappingDataSource extends DataSource<LaskunListaustietorivi> {

  constructor(
    private backingDataSource: DataSource<Lasku>,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _laskuSharedService: LaskuSharedService,
    private _lemonTranslationService: LemonTranslationService
  ) {
    super()
  }

  connect(collectionViewer: CollectionViewer): Observable<LaskunListaustietorivi[]> {
    return combineLatest([this.backingDataSource.connect(collectionViewer), this._lemonTranslationService.currentLanguageObservable]).pipe(
      map(([laskut, kieli]) => {
        return this.filterAndMap(laskut as Lasku[], kieli)
      })
    )
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.backingDataSource.disconnect(collectionViewer)
  }

  private filterAndMap(laskut: Lasku[], kieli: TuettuKieli): LaskunListaustietorivi[] {
    const lokalisoituMuistutus: string = this._lemonTranslationService.lokalisoiKielella('laskut.muistutus', kieli)
    const lokalisoituPerinta: string = this._lemonTranslationService.lokalisoiKielella('laskut.perinta', kieli)
    const lokalisoituPerintaPoisto: string = this._lemonTranslationService.lokalisoiKielella('laskut.perinta-poisto', kieli)
    const lokalisoituHyvitys: string = this._lemonTranslationService.lokalisoiKielella('laskut.hyvitys', kieli)
    const lokalisoituHyvitysPerintaAvoinna: string = this._lemonTranslationService.lokalisoiKielella('laskut.hyvitys-perinta-avoinna', kieli)
    const tulos: LaskunListaustietorivi[] = []

    for (const lasku of laskut) {

      if (lasku.nrotyyppi !== LaskunumeroTyyppi.TAVALLINEN && lasku.nrotyyppi !== LaskunumeroTyyppi.HYVITYS) {
        continue
      }

      const listaustiedot = this.muunnaLaskuListatiedoksi(lasku, lokalisoituMuistutus, lokalisoituHyvitys, lokalisoituPerinta, lokalisoituPerintaPoisto, lokalisoituHyvitysPerintaAvoinna)

      tulos.push(listaustiedot)
      for (const korvaava of listaustiedot.korvaavat) {
        tulos.push(korvaava)
      }
    }
    return tulos
  }

  private muunnaLaskuListatiedoksi(juurilasku: Lasku, lokalisoituMuistutus: string, lokalisoituHyvitys: string, lokalisoituPerinta: string, lokalisoituPerintaPoisto: string, lokalisoituHyvitysPerintaAvoinna: string): LaskunListaustieto {

    const viimeisinTavallinen = this._laskuSharedService.annaViimeisinTavallinenLasku(juurilasku)
    const muistutuksetJaHyvitykset = this._laskuSharedService.annaLaskuryppaanMuistutuksetJaHyvitykset(juurilasku)

    let korvaavienLukumaara = muistutuksetJaHyvitykset ? muistutuksetJaHyvitykset.length : 0

    if (juurilasku.perintatiedot?.lahetettyFrontend) {
      korvaavienLukumaara++
    }
    if (juurilasku.perintatiedot?.lahetettyPeruutusFrontend) {
      korvaavienLukumaara++
    }

    const laskunro = this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, viimeisinTavallinen)

    const avoinnaRypas = juurilasku.avoinnaRypas
    const summaRypas = juurilasku.summaRypas

    // [class.blue-text]   = "(row.tyyppi == 't' || row.tyyppi == 'v') && "
    // [class.yellow-text] = "(row.tyyppi == 't' || row.tyyppi == 'v') && (vertaaEkaPienempiKuinToka(row.avoinna, row.summa) && vertaaEkaPienempiKuinToka(0, row.avoinna) && row.tila == 'a')"
    // [class.green-text]  = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'm'"
    // [class.red-text]    = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'e'"
    // [class.purple-text] = "(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila == 'ml'"

    let vari = ''
    if ((avoinnaRypas === summaRypas || summaRypas < avoinnaRypas) && juurilasku.tila === LaskunTila.avoin) {
      vari = 'blue'
    } else if (avoinnaRypas < summaRypas && 0 < avoinnaRypas && juurilasku.tila === LaskunTila.avoin) {
      vari = 'yellow'
    } else if (juurilasku.tila === LaskunTila.maksettu) {
      vari = 'green'
    } else if (juurilasku.tila === LaskunTila.eraantynyt) {
      vari = 'red'
    } else if (juurilasku.tila === LaskunTila.maksettuLiikaa) {
      vari = 'purple'
    } else if (juurilasku.tila === LaskunTila.luottotappio || juurilasku.tila === LaskunTila.hyvitetty) {
      vari = 'mint'
    }

    // Käsittele päälasku
    const tulos: LaskunListaustieto = {
      avain: viimeisinTavallinen.avain,
      tyyppi: korvaavienLukumaara > 0 ? LaskunListaustyyppi.VANHEMPI : LaskunListaustyyppi.TAVALLINEN,
      asiakas: viimeisinTavallinen.asiakas ? viimeisinTavallinen.asiakas.nimi : '',
      nro: laskunro,
      nrotyyppi: viimeisinTavallinen.nrotyyppi,
      pvm: this._dateService.annaPaikallinenPvm(viimeisinTavallinen.pvm, viimeisinTavallinen.pvml),
      erapvm: this._dateService.annaPaikallinenPvm(viimeisinTavallinen.erapvm, viimeisinTavallinen.erapvml),
      summa: juurilasku.summa,
      avoinna: avoinnaRypas,
      tila: juurilasku.tila,
      juuriAvain: juurilasku.avain,
      valuutta: viimeisinTavallinen.valuutta,
      korvaavat: [],
      juurilasku: juurilasku,
      kasiteltava: viimeisinTavallinen,
      lahetysEpaonnistui: this.onkoEmailEpaonnistunut(viimeisinTavallinen) || this.onkoSahkoinenEpaonnistunut(viimeisinTavallinen),
      vari: vari
    }

    const laskuOnLahetettyPerintaan = !!juurilasku.perintatiedot?.lahetettyFrontend
    const laskuOnLahetettyPerintaanPeruutettu = !!juurilasku.perintatiedot?.lahetettyPeruutusFrontend
    let edellisenLaskunAikaleima: Timestamp = juurilasku.date

    let lahetettyPerintaanPitaaLisata: boolean = laskuOnLahetettyPerintaan
    let lahetettyPerintaanPeruutettuPitaaLisata: boolean = laskuOnLahetettyPerintaanPeruutettu
    // tulos['viitenumero'] = juurilasku.viitenumero

    // Käsittele korvaavat
    let index = 1
    let muistutuslaskuri = 1
    let hyvityslaskuri = 1
    for (const muistutusTaiHyvitys of muistutuksetJaHyvitykset) {

      if (
        juurilasku.perintatiedot?.lahetettyFrontend && // On lähetetty perintään
        this._dateService.compareTimestamps(edellisenLaskunAikaleima, '<', juurilasku.perintatiedot.lahetettyFrontend) && // On edellisen laskun jälkeen
        this._dateService.compareTimestamps(juurilasku.perintatiedot.lahetettyFrontend, '<', muistutusTaiHyvitys.date) // On nykyistä laskua ennen
      ) {
        lahetettyPerintaanPitaaLisata = false

        const pvm = juurilasku.perintatiedot.lahetettyFrontend
        const date = this._dateService.timestampToLocalDate(pvm)
        const lahetettyPvm = `${date.day}.${date.month}.${date.year}`

        tulos.korvaavat.push({
          avain: juurilasku.avain,
          tyyppi: index === korvaavienLukumaara ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
          asiakas: lokalisoituPerinta + ' ' + lahetettyPvm,
          nro: '',
          nrotyyppi: '',
          pvm: null,
          erapvm: null,
          summa: null,
          avoinna: null,
          tila: '',
          juuriAvain: juurilasku.avain,
          valuutta: '',
          juurilasku: null,
          kasiteltava: null,
          lahetysEpaonnistui: false,
          vari: vari
        })
        index++
      }

      if (
        juurilasku.perintatiedot?.lahetettyPeruutusFrontend && // On peruutettu perintään lähettäminen
        this._dateService.compareTimestamps(edellisenLaskunAikaleima, '<', juurilasku.perintatiedot.lahetettyPeruutusFrontend) && // On edellisen laskun jälkeen
        this._dateService.compareTimestamps(juurilasku.perintatiedot.lahetettyPeruutusFrontend, '<', muistutusTaiHyvitys.date) // On nykyistä laskua ennen
      ) {
        lahetettyPerintaanPeruutettuPitaaLisata = false

        const pvm = juurilasku.perintatiedot.lahetettyPeruutusFrontend
        const date = this._dateService.timestampToLocalDate(pvm)
        const poistettuPvm = `${date.day}.${date.month}.${date.year}`

        tulos.korvaavat.push({
          avain: juurilasku.avain,
          tyyppi: index === korvaavienLukumaara ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
          asiakas: lokalisoituPerintaPoisto + ' ' + poistettuPvm,
          nro: '',
          nrotyyppi: '',
          pvm: null,
          erapvm: null,
          summa: null,
          avoinna: null,
          tila: '',
          juuriAvain: juurilasku.avain,
          valuutta: '',
          juurilasku: null,
          kasiteltava: null,
          lahetysEpaonnistui: false,
          vari: vari
        })
        index++
      }

      edellisenLaskunAikaleima = muistutusTaiHyvitys.date

      // const tila = !vika ? this._laskuSharedService.annaLaskunKorvaustila(lasku.korvaus[index]) : lasku.tila
      let asiakas = ''
      let summa = muistutusTaiHyvitys.summa
      if (muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        const kulut = this._laskuSharedService.annaMuistutuslaskunKulutTalleLaskulle(juurilasku, muistutusTaiHyvitys)
        summa = this._currencyService.muutaBigDecimalRahaksi(kulut.korot.add(kulut.kulut))
        asiakas = lokalisoituMuistutus + ' ' + muistutuslaskuri

        if (muistutusTaiHyvitys.email && muistutusTaiHyvitys.email.vastaanottajat) {
          for (const status of muistutusTaiHyvitys.email.vastaanottajat) {
            if (status.email === 'info@perintaritari.com' || status.email === 'lemontree@perintaritari.com') {
              asiakas += ' (Perinnässä)'
              break
            }
          }
        }

        muistutuslaskuri++
      } else if (muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
        asiakas = lokalisoituHyvitys + ' ' + hyvityslaskuri
        hyvityslaskuri++
      }

      tulos.korvaavat.push({
        avain: muistutusTaiHyvitys.avain,
        tyyppi: index === korvaavienLukumaara ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
        asiakas: asiakas,
        nro: muistutusTaiHyvitys.nrotyyppi === LaskunumeroTyyppi.HYVITYS ? this._laskuSharedService.annaMuotoiltuLaskunumero(juurilasku, muistutusTaiHyvitys) : '',
        nrotyyppi: muistutusTaiHyvitys.nrotyyppi,
        pvm: this._dateService.annaPaikallinenPvm(muistutusTaiHyvitys.pvm, muistutusTaiHyvitys.pvml),
        erapvm: null, // this._dateService.annaPaikallinenPvm(muistutusTaiHyvitys.erapvm, muistutusTaiHyvitys.erapvml),
        summa: summa,
        avoinna: avoinnaRypas,
        tila: '',
        juuriAvain: juurilasku.avain,
        valuutta: muistutusTaiHyvitys.valuutta,
        juurilasku: juurilasku,
        kasiteltava: muistutusTaiHyvitys,
        lahetysEpaonnistui: this.onkoEmailEpaonnistunut(muistutusTaiHyvitys) || this.onkoSahkoinenEpaonnistunut(muistutusTaiHyvitys),
        vari: vari
      })
      index++
    }

    if (lahetettyPerintaanPitaaLisata) {

      const pvm = juurilasku.perintatiedot.lahetettyFrontend
      const date = this._dateService.timestampToLocalDate(pvm)
      const lahetettyPvm = `${date.day}.${date.month}.${date.year}`

      tulos.korvaavat.push({
        avain: juurilasku.avain,
        tyyppi: index === korvaavienLukumaara ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
        asiakas: lokalisoituPerinta + ' ' + lahetettyPvm,
        nro: '',
        nrotyyppi: '',
        pvm: null,
        erapvm: null,
        summa: null,
        avoinna: null,
        tila: '',
        juuriAvain: juurilasku.avain,
        valuutta: '',
        juurilasku: null,
        kasiteltava: null,
        lahetysEpaonnistui: false,
        vari: vari
      })
      index++
    }

    if (lahetettyPerintaanPeruutettuPitaaLisata) {
      const pvm = juurilasku.perintatiedot.lahetettyPeruutusFrontend
      const date = this._dateService.timestampToLocalDate(pvm)
      const poistettuPvm = `${date.day}.${date.month}.${date.year}`

      tulos.korvaavat.push({
        avain: juurilasku.avain,
        tyyppi: index === korvaavienLukumaara ? LaskunListaustyyppi.LAPSI_VIKA : LaskunListaustyyppi.LAPSI,
        asiakas: lokalisoituPerintaPoisto + ' ' + poistettuPvm,
        nro: '',
        nrotyyppi: '',
        pvm: null,
        erapvm: null,
        summa: null,
        avoinna: null,
        tila: '',
        juuriAvain: juurilasku.avain,
        valuutta: '',
        juurilasku: null,
        kasiteltava: null,
        lahetysEpaonnistui: false,
        vari: vari
      })
      index++
    }

    return tulos

  }

  onkoSahkoinenEpaonnistunut(kasiteltava: LaskuBase): boolean {
    if (kasiteltava && kasiteltava.sahkoinen) {
      if (
        kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.LAHETETTY_VASTAANOTTAJALLE &&
        kasiteltava.sahkoinen.status !== LaskuSahkoinenLahetysStatusKoodi.PROSESSOIDAAN
      ) {
        return true
      }
    }
    return false
  }

  onkoEmailEpaonnistunut(kasiteltava: LaskuBase): boolean {
    if (kasiteltava && kasiteltava.email && kasiteltava.email.vastaanottajat) {
      for (const vastaanottaja of kasiteltava.email.vastaanottajat) {
        if (vastaanottaja.status === EmailLahetysStatusKoodi.LAHETYS_EPAONNISTUI) {
          return true
        }
      }
    }
    return false
  }

}
