import { TypeaheadAsiakas, TypeaheadTuote, LaskunAsiakas, AsiakkaanTuote } from '../../../_jaettu/model/lasku'

export interface AsiakasTypeaheadResult {
  suodatetut: TypeaheadAsiakas[]
}

export abstract class LaskunAsiakasTypeaheadBase {
  public abstract haeJaSuodata(value: string): Promise<AsiakasTypeaheadResult>
  public abstract annaAsiakas(typeaheadAsiakas: TypeaheadAsiakas): Promise<LaskunAsiakas>
}

export interface TuoteTypeaheadResult {
  loytynyt: TypeaheadTuote
  suodatetut: TypeaheadTuote[]
}

export abstract class LaskunTuoteTypeaheadBase {
  public abstract haeJaSuodata(value: string): Promise<TuoteTypeaheadResult>
  public abstract getTuote(avain: string): Promise<AsiakkaanTuote>
}
