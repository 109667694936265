/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, HostListener, Provider, forwardRef, Renderer2, ElementRef, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { LemonNumberOnlyDirective } from './number-only.directive'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { LemonTranslationService } from '../service/lemon-translation.service'

const MODIFIER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeFormatDirective),
  multi: true
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[timeFormat]',
  host: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    // FIXME: see https://angular.io/guide/styleguide#style-06-03
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '(change)': 'onChangeValue($event.target.value)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '(input)': 'onChangeValue($event.target.value)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '(blur)': 'handleOnBlur($event.target.value)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '(focus)': 'handleOnFocus($event.target.value)'
  },
  providers: [MODIFIER_CONTROL_VALUE_ACCESSOR]
})
export class TimeFormatDirective extends LemonNumberOnlyDirective {

  @Input() timeType: 'minutes' | 'hours' = 'hours'

  private _inputEl: HTMLInputElement

  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _curService: CurrencyService,
    private _lemonTranslService: LemonTranslationService
  ) {
    super(
      _elementRef,
      _renderer2,
      _curService,
      _lemonTranslService
    )
    this._inputEl = _elementRef.nativeElement
  }

  handleOnFocus(value: string) {
    if (value?.length > 0) {
      this._inputEl.setSelectionRange(0, value.length)
    }
  }

  handleOnBlur(value: string) {
    if ((value || '').trim().length < 2) {

      // If there is no value, this actually not only pads, but changes the value.
      // This is added to send that change to the underlying form too.
      if (!(value || '').trim()) {
        this.onChangeValue('0')
      }

      const formattedValue = (value || '').trim().padStart(2, '0')
      this._renderer2.setProperty(this._inputEl, 'value', formattedValue)
    }
    if (this.onTouched) { this.onTouched() }
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardDown(event: KeyboardEvent) {

    const key = this.getKeyForKeyCode(event)
    if (key === 'ArrowUp') {
      const asNumber = Number(this._inputEl.value || 0)
      const value = isNaN(asNumber) ? 0 : asNumber
      const finalValue = value + 1
      // console.log('In arrowUp', finalValue)
      if (
        (finalValue < 24 && this.timeType === 'hours') ||
        (finalValue < 60 && this.timeType === 'minutes')
      ) {
        this.onChangeValue(finalValue + '')
        this.renderValueToHtml(finalValue)
      }
      event.preventDefault()
      event.stopImmediatePropagation()
      return
    } else if (key === 'ArrowDown') {
      const asNumber = Number(this._inputEl.value || 0)
      const value = isNaN(asNumber) ? 0 : asNumber
      const finalValue = value - 1
      // console.log('In arrowDown', finalValue)
      if (finalValue > -1) {
        this.onChangeValue(finalValue + '')
        this.renderValueToHtml(finalValue)
      }
      event.preventDefault()
      event.stopImmediatePropagation()
      return
    }

    // Check that after applying this change we don't go over the maximum
    if (
      key === '0' ||
      key === '1' ||
      key === '2' ||
      key === '3' ||
      key === '4' ||
      key === '5' ||
      key === '6' ||
      key === '7' ||
      key === '8' ||
      key === '9'
    ) {

      // console.log(key)

      // This takes care of computing the value after it's been applied
      // Also considers painted (selected) text replace
      // Stops the event if the value goes outside permitted range
      const startIndex = this._inputEl.selectionStart
      const endIndex = this._inputEl.selectionEnd
      const stringValue = this._inputEl.value.substring(0, startIndex) + key + this._inputEl.value.substring(endIndex)
      // console.log(startIndex, endIndex, stringValue)
      const value = Number(stringValue)
      if (
        isNaN(value) ||
        value < 0 ||
        (value > 23 && this.timeType === 'hours') ||
        (value > 59 && this.timeType === 'minutes')
      ) {
        event.preventDefault()
        event.stopImmediatePropagation()
        return
      }
    }

    super.onKeyDown(event)

  }



  writeValue(value: number) {
    this.renderValueToHtml(value)
  }

  private renderValueToHtml(value: number) {

    // Handle null and undefined values
    if (value === null || value === undefined) {
      this._renderer2.setProperty(this._inputEl, 'value', '')
      return
    }

    const formattedValue = ('' + value).length === 1 ? '0' + value : '' + value
    // console.log('In renderValueForUser', formattedValue)

    // store current positions in variables
    const start = this._inputEl.selectionStart
    const end = this._inputEl.selectionEnd

    this._renderer2.setProperty(this._inputEl, 'value', formattedValue)

    // restore from variables...
    this._inputEl.setSelectionRange(start, end)

  }

}
