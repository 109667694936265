import { Pipe, PipeTransform } from '@angular/core'

import { CurrencyService } from '../../_shared-core/service/currency.service'
import { TuettuKieli } from '../../_shared-core/model/common'

import { LemonTranslationService } from '../service/lemon-translation.service'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Big } from 'big.js'

@Pipe({
  name: 'ldecimal'
})
export class DecimalPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private currencyService: CurrencyService
  ) { }

  transform(value: number, desimaalienMaara: number): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (value === undefined || value === null || desimaalienMaara === undefined || desimaalienMaara === null) {
          return ''
        }
        return this.currencyService.formatoiDesimaali(value, desimaalienMaara, kieli)
      })
    )
  }

}

@Pipe({
  name: 'ldecimall'
})
export class DecimalWithLocalePipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) { }

  transform(value: number, desimaalienMaara: number, kieli: TuettuKieli): string {

    if (value === undefined || value == null) {
      return ''
    }

    return this.currencyService.formatoiDesimaali(value, desimaalienMaara, kieli)
  }

}

@Pipe({
  name: 'lbigdecimal'
})
export class BigDecimalPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService,
    private currencyService: CurrencyService
  ) { }

  transform(value: Big, desimaalienMaara: number): Observable<string> {
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (value === undefined || value === null || desimaalienMaara === undefined || desimaalienMaara === null) {
          return ''
        }
        return this.currencyService.formatoiDesimaali(value as any as number, desimaalienMaara, kieli)
      })
    )
  }

}

@Pipe({
  name: 'lbigdecimall'
})
export class BigDecimalWithLocalePipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) { }

  transform(value: number, desimaalienMaara: number, kieli: TuettuKieli): string {

    if (value === undefined || value == null) {
      return ''
    }

    return this.currencyService.formatoiDesimaali(value, desimaalienMaara, kieli)
  }

}
