import { Pipe, PipeTransform } from '@angular/core'

import { LemonTranslationService } from '../service/lemon-translation.service'

import { TuettuKieli } from '../../_shared-core/model/common'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'translate'
})
export class LemonKaannaPipe implements PipeTransform {

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) { }

  transform(avain: string, parametrit?: any): Observable<string> {
    // console.log(parametrit)
    return this.lemonTranslationService.currentLanguageObservable.pipe(
      map(kieli => {
        if (!avain || !kieli) {
          return ''
        }
        // console.log(avain, kieli)
        return this.lemonTranslationService.lokalisoiKielella(avain, kieli, parametrit)
      })
    )
  }

}

@Pipe({
  name: 'translatel'
})
export class LemonKaannaWithLocalePipe implements PipeTransform {

  constructor(private lemonTranslationService: LemonTranslationService) { }

  transform(avain: string, kieli: TuettuKieli, parametrit?: any): string {
    if (!avain || !kieli) {
      return ''
    }
    return this.lemonTranslationService.lokalisoiKielella(avain, kieli, parametrit)
  }

}
