<div class="detail-page">

  <app-asiakas-otsikko otsikko="Asetukset, asiakastiedot" paluuUrl="/asiakkaat" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Asiakas: <a [href]="asiakasEncodedUriObservable | async" target="_BLANK">{{asiakasUriObservable | async}}</a></div>
  </div>

  <form (ngSubmit)="save()" [formGroup]="form" novalidate>

    <div class="cont">

      <div style="max-width: 450px;">
        <div class="light-blue-text otsikko">Yhteystiedot</div>
        <mat-form-field>
          <mat-label>{{ 'asiakas.nimi' | translate | async }}</mat-label>
          <input #nimiInput type="text" formControlName="nimi" matInput required />
          <mat-error *ngIf="nimi?.errors?.required && (nimi?.dirty || nimi?.touched)">
            Nimi puuttuu
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Y-tunnus</mat-label>
          <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="ytunnus" required matInput data-lpignore="true" />
          <mat-hint *ngIf="ytunnusVirhe"><span class="lemon-error">Y-tunnus on virheellinen. Tämä ei estä tallentamista. Tarkista Y-tunnus</span></mat-hint>
          <mat-error *ngIf="ytunnus?.errors?.required && (ytunnus?.dirty || ytunnus?.touched)">
            Y-tunnus puuttuu
          </mat-error>
          <mat-error *ngIf="ytunnus?.errors?.tupla && (ytunnus?.dirty || ytunnus?.touched)">
            Y-tunnus on jo käytössä yrityksellä {{ytunnus?.errors?.tupla.nimi}}.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'lasku.katuosoite' | translate | async }}</mat-label>
          <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="katuosoite" matInput data-lpignore="true" />
          <!-- <mat-error *ngIf="katuosoite?.errors?.required && (katuosoite?.dirty || katuosoite?.touched)">
            {{ 'lasku.validation.katuosoite.required' | translate | async }}
          </mat-error> -->
        </mat-form-field>

        <div>
          <mat-form-field style="width: 48%; float: left;">
            <mat-label>{{ 'lasku.postinro' | translate | async }}</mat-label>
            <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="postinro" matInput data-lpignore="true" />
            <mat-error *ngIf="postinro?.errors?.required && (postinro?.dirty || postinro?.touched)">
              {{ 'lasku.validation.postinro.required' | translate | async }}
            </mat-error>
          </mat-form-field>
          <mat-form-field style="width: 48%; float: right;">
            <mat-label>{{ 'lasku.postitoimipaikka' | translate | async }}</mat-label>
            <input type="text" autocomplete="kirjanpitoaineistoarkki" [name]="inputname" formControlName="postitoimipaikka" matInput data-lpignore="true" />
            <mat-error *ngIf="postitoimipaikka?.errors?.required && (postitoimipaikka?.dirty || postitoimipaikka?.touched)">
              {{ 'lasku.validation.postitoimipaikka.required' | translate | async }}
            </mat-error>
          </mat-form-field>
        </div>
        <div style="clear: both; display: block;"></div>

        <mat-form-field>
          <mat-label>{{ 'lasku.maa' | translate | async }}</mat-label>
          <mat-select formControlName="maa" style="width: 100%;">
            <mat-option *ngFor="let maa of suodatetutMaat | async" [value]="maa.koodi">
              {{ maa.nimi }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="maa?.errors?.required && (maa?.dirty || maa?.touched)">
            {{ 'lasku.validation.maa.required' | translate | async }}
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field *ngIf="naytaAdmin">
          <mat-label>{{ 'Vastuukirjanpitäjä' }}</mat-label>
<mat-select  formControlName="kasittelija">
            <mat-option *ngFor="let kirjanpitaja of kirjanpitajat" [value]="kirjanpitaja.avain">
              {{kirjanpitaja.etunimi}} {{ kirjanpitaja.sukunimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="naytaAdmin">
          <mat-label>{{ 'Väliaikainen tuuraaja' }}</mat-label>
<mat-select  formControlName="kasittelijavara">
            <mat-option [value]="null">
              Ei tuuraajaa
            </mat-option>
            <mat-option *ngFor="let kirjanpitaja of kirjanpitajat" [value]="kirjanpitaja.avain">
              {{kirjanpitaja.etunimi}} {{ kirjanpitaja.sukunimi }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

      </div>

      <div app-asiakas-ytj-tiedot></div>

      <!-- <div style="width: 100%;">
        <div class="light-blue-text otsikko">YTJ-tiedot</div>
        <div class="ytj-data" *ngIf="ytjHtmlObservable | async; let ytjHtml; else ytjLadataan" [innerHtml]="ytjHtml"></div>
        <ng-template #ytjLadataan>
          Ladataan ...
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
      </div> -->

    </div>

    <div style="max-width: 450px;">

      <div class="light-blue-text otsikko">Pääsääntöinen asiointikieli</div>

      <p>
        Pääsääntöisen asiointikielen mukaan valitaan asiakkaalle lähtevien
        kirjanpito- ja laskutussähköpostien ja niiden liitetiedostojen kieli.
      </p>

      <div style="max-width: 450px;">
        <mat-radio-group formControlName="laskunKieli" aria-label="Valitse">
          <mat-radio-button [value]="'fi'">Suomi</mat-radio-button>
          <mat-radio-button [value]="'en'">Englanti</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="light-blue-text otsikko">Muut tiedot</div>
      <div>
        <mat-checkbox formControlName="ossRekisterissa">Asiakas on rekisteröitynyt arvonlisäveron erityisjärjestelmään</mat-checkbox>
      </div>
      <div>
        <mat-checkbox formControlName="estaAlvKasittely">Käsittele palkkaus.fi automaattikirjaukset alvittomina</mat-checkbox>
      </div>
      <div *ngIf="isAdminObservable | async">
        <mat-checkbox formControlName="testiasiakas">Asiakas on testiasiakas</mat-checkbox>
      </div>
      <div>
        <mat-checkbox formControlName="tilinpaatosRekisteroidaanBruttotuloslaskelmalla">Asiakkaan tilinpäätös rekisteröidään bruttotuloslaskelmalla</mat-checkbox>
      </div>
      <mat-form-field *ngIf="!!saleDoneBy.value" style="margin-top: 15px;">
        <mat-label>Myyjä</mat-label>
        <input matInput formControlName="saleDoneBy">
      </mat-form-field>
    </div>

    <ng-container *ngIf="asiakkaanApixTiedotObservable | async let asiakkaanApixTiedot">
      <div class="light-blue-text otsikko">Lemonaid lisäpalvelut</div>
      <div>
        <mat-checkbox [checked]="asiakkaanApixTiedot.paymentReceiveIsActive" [disabled]="true">Laskujen vastaanotto</mat-checkbox>
      </div>
      <div>
        <mat-checkbox [checked]="asiakkaanApixTiedot.paymentIsActive" [disabled]="true">Laskujen maksaminen</mat-checkbox>
      </div>
      <div>
        <mat-checkbox [checked]="maksuperusteinenReskontraVoimassa" [disabled]="true">Maksuperusteinen automaattireskontra
        </mat-checkbox>
      </div>
    </ng-container>

    <div>
      <div style="max-width: 450px;">
        <div class="light-blue-text otsikko">Kuvaus toiminnasta ja tilanteesta</div>
        <mat-form-field>
          <mat-label>Kuvaus</mat-label>
          <textarea matInput formControlName="kuvausKuvaus" cdkTextareaAutosize></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Aloitusajankohta</mat-label>
          <textarea matInput formControlName="kuvausAloitus" cdkTextareaAutosize></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Huomioita</mat-label>
          <textarea class="purple-text" matInput formControlName="kuvausHuomioita" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
      <div style="max-width: 200px;">
        <div class="light-blue-text otsikko">Aloituspalaveri</div>
        <mat-checkbox formControlName="palaveriOnkoPidetty">Aloituspalaveri pidetty</mat-checkbox>
        <div style="font-size: 12.5px; margin: 1em;">
          <mat-error *ngIf="palaveriTyyppi?.errors?.required && (palaveriTyyppi.dirty || palaveriTyyppi.touched)" class="mat-form-field-invalid">Palaverin tyyppi puuttuu.</mat-error>
          <div>Palaverin tyyppi: </div>
          <mat-radio-group formControlName="palaveriTyyppi" aria-label="Valitse">
            <mat-radio-button [value]="'puhelin'">Puhelin</mat-radio-button>
            <mat-radio-button [value]="'Google Meet'">Google Meet</mat-radio-button>
            <mat-radio-button [value]="'toimisto'">Toimisto</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field style="margin: 1em">
          <mat-label>Palaverin ajankohta</mat-label>
          <input matInput formControlName="palaveriPvm" [matDatepicker]="palaveriDatePicker">
          <mat-datepicker-toggle matSuffix [for]="palaveriDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #palaveriDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="light-blue-text otsikko">Arvio tositteiden määrästä</div>
      <div class="cont">
        <mat-form-field>
          <mat-label>Ostolaskut/kuitit</mat-label>
          <input decimalNumberField matInput [numberOfDecimals]="0" formControlName="tositemaaratKuitit">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Myyntilaskut</mat-label>
          <input decimalNumberField matInput [numberOfDecimals]="0" formControlName="tositemaaratMyyntilaskut">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Myyntiraportit</mat-label>
          <input decimalNumberField matInput [numberOfDecimals]="0" formControlName="tositemaaratMyyntiraportit">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Palkkalaskelmat</mat-label>
          <input decimalNumberField matInput [numberOfDecimals]="0" formControlName="tositemaaratPalkkalaskelmat">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Muuta</mat-label>
          <textarea matInput formControlName="tositemaaratMuuta" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="light-blue-text otsikko">Maksutavat</div>
      <div class="cont" style="justify-content: space-between;">
        <div>
          <mat-checkbox formControlName="maksutavatLasku">Lasku</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="maksutavatKorttipaate">Korttipääte</mat-checkbox>
          <mat-form-field>
            <mat-label>Mikä?</mat-label>
            <textarea matInput formControlName="maksutavatKorttipaateLisa" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox formControlName="maksutavatVerkkokauppa">Verkkokauppa</mat-checkbox>
          <mat-form-field>
            <mat-label>Maksutavat</mat-label>
            <textarea matInput formControlName="maksutavatVerkkokauppaMaksutavat" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox formControlName="maksutavatKateinen">Käteinen</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="maksutavatTilitykset">Tilitykset</mat-checkbox>
          <mat-form-field>
            <mat-label>Mitä?</mat-label>
            <textarea matInput formControlName="maksutavatTilityksetMita" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div style="max-width: 600px;">
      <div class="light-blue-text otsikko">Pankkitiedot</div>
      <div class="cont">
        <mat-form-field>
          <mat-label>Yrityksen pankki</mat-label>
          <textarea matInput formControlName="pankkiPankki" cdkTextareaAutosize></textarea>
        </mat-form-field>
        <div>
          <mat-checkbox formControlName="pankkiYhteys">Pankkiyhteys</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="pankkiEiTilia">Ei pankkitiliä kirjanpidossa</mat-checkbox>
        </div>
      </div>
    </div>

    <div style="max-width: 650px;">
      <div class="light-blue-text otsikko">Auto</div>
      <div class="cont">
        <div>
          <mat-checkbox formControlName="auto">Yrityksen toimintaan käytetään autoa</mat-checkbox>
          <div class="cont" style="padding-top: 10px;">
            <mat-radio-group formControlName="autoTyyppi" aria-label="Valitse">
              <mat-radio-button [value]="'henkilo'">Henkilöauto</mat-radio-button>
              <mat-radio-button [value]="'paketti'">Pakettiauto</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group formControlName="autoAjo" aria-label="Valitse">
              <mat-radio-button [value]="'enemmanyksityis'">Enemmän yksityisajoa</mat-radio-button>
              <mat-radio-button [value]="'enemmantyo'">Enemmän työajoa</mat-radio-button>
              <mat-radio-button [value]="'kokonaantyo'">100% työajoa</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-form-field>
          <mat-label>Lisätietoja</mat-label>
          <textarea matInput formControlName="autoLisa" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>

    <div style="max-width: 450px;">
      <div class="light-blue-text otsikko">Sijoitustoiminta</div>
      <div class="cont">
        <div>
          <mat-checkbox formControlName="sijoitus">Yrityksellä on sijoitustoimintaa</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>Mitä?</mat-label>
          <textarea matInput formControlName="sijoitusLisa" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>

    <div style="max-width: 450px;">
      <div class="light-blue-text otsikko">Ohjelmisto</div>
      <div class="cont">
        <mat-radio-group formControlName="ohjelmisto" aria-label="Valitse">
          <mat-radio-button value="1">Lemonaid</mat-radio-button>
          <mat-radio-button value="2">Holvi</mat-radio-button>
          <mat-radio-button value="3">Molemmat</mat-radio-button>
        </mat-radio-group>
        <mat-form-field>
          <mat-label>Lisätietoja</mat-label>
          <textarea matInput formControlName="ohjelmistoLisa" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>

    <div style="max-width: 450px;">
      <div class="light-blue-text otsikko">Palkat</div>
      <div class="cont">
        <mat-form-field>
          <mat-label>Tiedot</mat-label>
          <textarea matInput formControlName="palkatLisa" cdkTextareaAutosize></textarea>
          <mat-hint>Onko työntekijöitä? Miten palkanlaskenta hoidetaan?</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <!-- <div style="max-width: 450px;">
      <div class="light-blue-text otsikko">Riskiarvio</div>
      <div class="cont">
        <mat-radio-group formControlName="riskiarvio" aria-label="Valitse">
          <mat-radio-button value="'vahainen'">Vähäinen riski</mat-radio-button>
          <mat-radio-button value="'kohonnut'">Kohonnut riski</mat-radio-button>
          <mat-radio-button value="'korkea'">Korkea riski</mat-radio-button>
        </mat-radio-group>
        <mat-form-field>
          <textarea matInput formControlName="riskiarvioLisa" cdkTextareaAutosize placeholder="Lisätietoja"></textarea>
        </mat-form-field>
      </div>
    </div> -->

    <div style="display: flex; justify-content: space-evenly; padding-top: 40px; padding-bottom: 2em;">
      <!-- <button class="secondarybutton" mat-button routerLink="/asiakkaat" type="button">{{'yleiset.peruuta' | translate | async}}</button> -->
      <button class="primarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
    </div>

  </form>

</div>