<style>
  .cont {
    display: flex;
  }

  .cont>* {
    margin: 0 1em;
  }
</style>

<div class="list-page">

  <app-asiakas-otsikko [otsikko]="otsikkoObservable | async" [naytaPalaa]="true" [paluuUrl]="'/asiakkaat/' + asiakas?.avain + '/kayttajat'" [asiakas]="asiakas"></app-asiakas-otsikko>

  <div *ngIf="kayttaja?.avain && kirjanpitajaOnDevaajaObservable | async" style="margin-bottom: 20px; margin-left: 20px; padding: 10px; max-width: 1020px; position: relative;" class="mat-elevation-z2">
    <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
    <h3 style="margin: 0 0 1em;">Urit</h3>
    <div style="padding-left: 1em; padding-right: 1em;">
      <div>Käyttäjä (Lemonator): <a [href]="kayttajaEncodedUriObservable | async" target="_BLANK">{{kayttajaUriObservable | async}}</a></div>
      <div *ngIf="kayttaja.kirjanpitajaAvain && naytaTallennaKirjanpitajanaObservable | async">
        <div>Kirjanpitäjä (Lemonator): <a [href]="kirjanpitajaEncodedUriObservable | async" target="_BLANK">{{kirjanpitajaUriObservable | async}}</a></div>
      </div>
      <div>Sopimusten hyväksynnät (Lemonaid): <a [href]="sopimustenHyvaksynnatEncodedUriObservable | async" target="_BLANK">{{sopimustenHyvaksynnatUriObservable | async}}</a></div>
    </div>
  </div>

  <div *ngIf="kayttaja.kirjanpitajaAvain && naytaTallennaKirjanpitajanaObservable | async" style="margin-bottom: 20px; margin-left: 20px; padding: 10px; max-width: 1020px; position: relative;" class="mat-elevation-z2">
    <span style="font-size: 10px; position: absolute; top: 3px; right: 5px;" class="light-gray-text">(Vain devaajat)</span>
    <h3>Linkit</h3>
    <div style="padding-left: 1em; padding-right: 1em;">
      <div>Kirjanpitäjän tiedot Lemonatorissa: <a [routerLink]="'/yllapito/kirjanpitajat/' + kayttaja.kirjanpitajaAvain">https://lemonator.lemontree.fi{{'/yllapito/kirjanpitajat/' + kayttaja.kirjanpitajaAvain}}</a></div>
    </div>
  </div>

  <div style="margin-bottom: 10px; margin-left: 0; margin-right: 0; font-size: 20px; line-height: 40px;">
    <button *ngIf="kayttaja?.avain" mat-button (click)="vaihdaSalasana()" type="button" style="float: right;">
      <!-- <mat-icon>lock</mat-icon><mat-icon>sync</mat-icon> -->
      Vaihda salasana
    </button>
    <button *ngIf="kayttaja?.avain && asiakas?.avain" mat-button (click)="kirjauduKayttajana()" type="button" style="float: right;">
      <!-- <mat-icon>lock</mat-icon><mat-icon>sync</mat-icon> -->
      Kirjaudu käyttäjänä
    </button>
    <button *ngIf="kayttaja?.avain && kirjanpitajaOnDevaajaObservable | async" mat-button (click)="pakotaVahvaTunnistautuminenUudelleen()" type="button" style="float: right;">
      <!-- <mat-icon>lock</mat-icon><mat-icon>sync</mat-icon> -->
      Pakota pankkitunnistautuminen uudelleen
    </button>
  </div>

  <br style="clear: both;" />

  <mat-tab-group>
    <mat-tab label="Käyttäjän tiedot">
      <div style="padding: 20px;">
        <form (ngSubmit)="save()" [formGroup]="form" novalidate>

          <div style="max-width: 400px; margin-left: 20px;">
            <mat-form-field>
              <mat-label>Etunimi</mat-label>
              <input #etunimiInput type="text" formControlName="etunimi" matInput required />
              <mat-error *ngIf="etunimi?.errors?.required && (etunimi?.dirty || etunimi?.touched)">
                Etunimi puuttuu
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sukunimi</mat-label>
              <input type="text" formControlName="sukunimi" matInput required />
              <mat-error *ngIf="sukunimi?.errors?.required && (sukunimi?.dirty || sukunimi?.touched)">
                Sukunimi puuttuu
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sähköpostiosoite</mat-label>
              <input type="text" formControlName="email" matInput required />
              <mat-error *ngIf="email?.errors?.required && (email?.dirty || email?.touched)">
                Sähköpostiosoite puuttuu
              </mat-error>
              <mat-error *ngIf="email?.errors?.email && (email?.dirty || email?.touched)">
                Sähköpostiosoite on virheellinen
              </mat-error>
              <mat-error *ngIf="email?.errors?.emailinuse && (email?.dirty || email?.touched)">
                Sähköpostiosoite on jo käytössä
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Puhelinnumero</mat-label>
              <input type="text" formControlName="puhelin" matInput />
              <mat-error *ngIf="puhelin?.errors?.minlength && (puhelin?.dirty || puhelin?.touched)">
                {{ 'lisaa-puhelin.min' | translate | async }}
              </mat-error>
              <mat-error *ngIf="puhelin?.errors?.invalid && (puhelin?.dirty || puhelin?.touched)">
                Numero on virheellinen
              </mat-error>
              <mat-error *ngIf="puhelin?.errors?.notinternational && (puhelin?.dirty || puhelin?.touched)">
                Numero ei ole kansainvälisessä muodossa ja ala plus-merkillä, esim. +358
              </mat-error>
              <mat-error *ngIf="puhelin?.errors?.leadingzero && (puhelin?.dirty || puhelin?.touched)">
                {{ 'lisaa-puhelin.leadingzero' | translate | async }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Asiointikieli</mat-label>
              <mat-select formControlName="asiointikieli" required>
                <mat-option [value]="'fi'">Suomi</mat-option>
                <mat-option [value]="'en'">Englanti</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox formControlName="aktiivinen">Aktiivinen</mat-checkbox>

          </div>

          <div id="roolit" [class.mat-form-field-invalid]="selvitettavienNakyvyys?.errors?.required && (selvitettavienNakyvyys?.dirty || selvitettavienNakyvyys?.touched)" style="padding-top: 30px; margin-bottom: 10px; margin-left: 0; margin-right: 0;  border-bottom: 1px solid lightgray; font-size: 20px; line-height: 40px;">
            Selvitettävien tositteiden näkyvyys
          </div>

          <div style="margin-left: 20px;">
            <mat-error *ngIf="selvitettavienNakyvyys?.errors?.required && (selvitettavienNakyvyys?.dirty || selvitettavienNakyvyys?.touched)">
              Valinta puuttuu
            </mat-error>
            <mat-radio-group formControlName="selvitettavienNakyvyys" required>
              <ng-container *ngFor="let e of selvitettavienNakyvyyksienArvot">
                <mat-radio-button [value]="e.arvo">{{e.nimi}}</mat-radio-button><br />
              </ng-container>
            </mat-radio-group>
          </div>

          <div id="roolit" style="padding-top: 30px; margin-bottom: 10px; margin-left: 0; margin-right: 0;  border-bottom: 1px solid lightgray; font-size: 20px; line-height: 40px;">
            Käyttäjän roolit
          </div>

          <table style="margin-left: 20px;">
            <tr>
              <td>
                <div style="padding-top: 10px; margin-bottom: 10px; margin-left: 0; margin-right: 0; font-size: 16px; line-height: 16px;">
                  Lemonaid
                </div>
                <table>
                  <tr>
                    <td style="vertical-align: top; padding: 0 20px;">
                      <mat-checkbox formControlName="LASKUTUS">Laskutus</mat-checkbox>
                    </td>
                    <td style="vertical-align: top; padding: 0 20px;">
                      <mat-checkbox formControlName="TOSITTEET">Tositteet</mat-checkbox>
                      <div style="padding-left: 20px; padding-top: 10px;">
                        <mat-checkbox formControlName="TOSITTEET_OSTO">Ostotositteet</mat-checkbox>
                      </div>
                      <div style="padding-left: 20px; padding-top: 5px;">
                        <mat-checkbox formControlName="TOSITTEET_MYYNTI">Myyntitositteet</mat-checkbox>
                      </div>
                      <div style="padding-left: 20px; padding-top: 5px;">
                        <mat-checkbox formControlName="TOSITTEET_TILI">Tiliotteet</mat-checkbox>
                      </div>
                      <div style="padding-left: 20px; padding-top: 5px;">
                        <mat-checkbox formControlName="TOSITTEET_PALKKA">Palkkamateriaali</mat-checkbox>
                      </div>
                      <div style="padding-left: 20px; padding-top: 5px;" *ngIf="kirjanpitajaOnLuottoObservable | async">
                        <mat-checkbox formControlName="SAHKOISTEN_LASKUJEN_VASTAANOTTO">Sähköisten laskujen vastaanotto</mat-checkbox>
                      </div>
                    </td>
                    <td style="vertical-align: top; padding: 0 20px;">
                      <mat-checkbox formControlName="PALKAT">Palkat</mat-checkbox>
                    </td>
                    <td style="vertical-align: top; padding: 0 20px;">
                      <mat-checkbox formControlName="MAKSUT">Ostolaskut</mat-checkbox>
                      <div style="padding-left: 10px; padding-top: 10px;">
                        Vastaanotto
                      </div>
                      <div style="padding-left: 30px; padding-top: 10px;">
                        <mat-checkbox formControlName="MAKSUT_VASTAANOTTAJA">Ostolaskujen käsittelijä</mat-checkbox>
                      </div>
                      <ng-container *ngIf="(kirjanpitajaOnLuottoObservable | async) || (kirjanpitajaOnDevaajaObservable | async)">
                        <div style="padding-left: 10px; padding-top: 10px;">
                          Maksaminen
                        </div>
                        <div style="padding-left: 30px; padding-top: 5px;">
                          <mat-checkbox formControlName="MAKSUT_HYVAKSYJA">Ostolaskujen hyväksyjä</mat-checkbox>
                        </div>
                        <div style="padding-left: 30px; padding-top: 10px;">
                          <mat-checkbox formControlName="MAKSUT_MAKSAJA">Ostolaskujen maksaja</mat-checkbox>
                        </div>
                      </ng-container>
                    </td>
                    <td style="vertical-align: top; padding-right: 40px;">
                      <mat-checkbox formControlName="RAPORTIT">Raportit</mat-checkbox>
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width: 300px;">
                <div style="padding-top: 10px; margin-bottom: 10px; margin-left: 0; margin-right: 0; font-size: 16px; line-height: 16px;">
                  Hallinto
                </div>
                <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="HALLINTO_SOPIMUKSEN_HYVAKSYJA">Sopimuksen hyväksyjä</mat-checkbox>
                </div>
                <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="HALLINTO_YHTEYSHENKILO">Yhteyshenkilö</mat-checkbox>
                </div>
                <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA">Pankkiyhteysvaltuutuksen antaja</mat-checkbox>
                </div>
                <!-- <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA">Suomi.fi valtuutuksen antaja</mat-checkbox>
                </div> -->
                <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="HALLINTO_TUNTEMISTIETOJEN_ANTAJA">Tuntemistietojen antaja</mat-checkbox>
                </div>
                <div style="padding-left: 20px; padding-top: 5px;">
                  <mat-checkbox formControlName="TUNNISTETAAN">Tunnistetaan</mat-checkbox>
                </div>
              </td>
            </tr>
          </table>

          <div *ngIf="atLeastOneLemonaidRoleError" class="lemon-error" style="margin-top: 5px; margin-left: 20px;">{{atLeastOneLemonaidRoleError}}</div>

          <div style="padding-top: 30px; margin-bottom: 10px; margin-left: 0; margin-right: 0;  border-bottom: 1px solid lightgray; font-size: 20px; line-height: 40px;">
            Käyttäjän ominaisuudet
          </div>

          <div style=" margin-left: 20px;">
            <div class="gray-text">Älä laita päälle ominaisuuksia, joita käyttäjä ei ole pyytänyt.</div>
            <mat-checkbox formControlName="CAN_EDIT_PENALTY_INTEREST">Voi muokata laskujen viivästyskorkoa</mat-checkbox>
          </div>

          <div style="padding-top: 30px; margin-bottom: 10px; margin-left: 0; margin-right: 0;  border-bottom: 1px solid lightgray; font-size: 20px; line-height: 40px;">
            Tositteen lisäysprosessi kännykässä
          </div>

          <div style=" margin-left: 20px;">
            <table formArrayName="prosessinOsat" style="width: 100%; padding-top: 10px;">
              <!-- <tr> -->
              <!-- <th colspan="2">Osa</th> -->
              <!-- <th>Järjestys</th> -->
              <!-- </tr> -->
              <ng-container *ngFor="let osa of prosessinOsat; let i = index" [formGroupName]="i">
                <tr style="vertical-align: top;">
                  <td style="width: 40px;">
                    <mat-checkbox formControlName="aktiivinen"></mat-checkbox>
                  </td>
                  <td>
                    <mat-form-field style="margin-top: -20px;">
                      <input type="text" formControlName="otsikko" placeholder="Otsikko" matInput />
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-icon-button (click)="ylos(i)" type="button">
                      <mat-icon>arrow_drop_up</mat-icon>
                    </button>
                    <button mat-icon-button (click)="alas(i)" type="button">
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="naytaMaksutavat && osa.lisaysProsessinOsa.tunniste === 'editpaymentmethod'">
                  <td colspan="3" style="padding-left: 70px; padding-top: 10px; padding-bottom: 15px;" formArrayName="maksutavat">
                    <!-- <pre>{{this.kayttaja.maksutavat | json}}</pre> -->
                    Käytettävät maksutavat
                    <div *ngFor="let maksutapa of asiakkaanMaksutavat; let i = index" [formGroupName]="i" style="padding-top: 5px;">
                      <mat-checkbox formControlName="kaytossa">
                        <div style="display: flex; align-items: center;" [class.dark-gray-text]="!(maksutavatArray?.at(i)?.get('kaytossa')?.value)">
                          <img [src]="'data:image/png;base64,' + maksutapa.base64Kuva" style="margin: 0 10px; width: 40px; border-radius: 40px;" [class.light-blue-background]="maksutavatArray?.at(i)?.get('kaytossa')?.value" [class.light-gray-background]="!(maksutavatArray?.at(i)?.get('kaytossa')?.value)" />
                          <div style="margin: 0 10px; width: 300px;">
                            {{maksutapa.nimi}}
                            <!-- {{maksutapa.tunniste}} -->
                          </div>
                          <div style="display: flex; margin: 0 10px; width: 81px;">
                            <button *ngIf="maksutavatArray?.at(i)?.get('kaytossa')?.value" [disabled]="1 > i" mat-icon-button (click)="maksutapaYlos($event, i)" type="button">
                              <mat-icon>arrow_drop_up</mat-icon>
                            </button>
                            <button *ngIf="maksutavatArray?.at(i)?.get('kaytossa')?.value" [disabled]="i > aktivoitujenMaksutapojenLukumaara - 2" mat-icon-button (click)="maksutapaAlas($event, i)" type="button">
                              <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                          </div>
                        </div>
                      </mat-checkbox>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="naytaAlvPortaat && osa.lisaysProsessinOsa.tunniste === 'editalv'">
                  <td></td>
                  <td colspan="2">
                    <button mat-button (click)="lisaaAlvPorras()" type="button" style="padding-left: 0;">
                      <mat-icon>add</mat-icon> Lisää porras
                    </button>
                    <mat-radio-group formControlName="oletusporras">
                      <table id="alv-portaat-table" formArrayName="alvPortaat">
                        <tr>
                          <th>Arvo</th>
                          <th style="width: 41px;">Oletus</th>
                          <!-- <th style="width: 81px;">Järjestys</th> -->
                          <th style="width: 41px;">Poista</th>
                        </tr>
                        <tr *ngFor="let porras of kayttaja.alvVahennysoikeusPortaat; let i = index" [formGroupName]="i">
                          <td>
                            <mat-form-field>
                              <mat-label>Prosentti</mat-label>
                              <input decimalNumberField matInput floatLabel="never" [numberOfDecimals]="0" formControlName="arvo">
                            </mat-form-field>
                          </td>
                          <td style="text-align: center;">
                            <mat-radio-button [value]="i"></mat-radio-button>
                          </td>
                          <!-- <td style="text-align: center;">
                              <button mat-icon-button (click)="alvPorrasYlos(i)" type="button">
                                <mat-icon>arrow_drop_up</mat-icon>
                              </button>
                              <button mat-icon-button (click)="alvPorrasAlas(i)" type="button">
                                <mat-icon>arrow_drop_down</mat-icon>
                              </button>
                            </td> -->
                          <td>
                            <button mat-icon-button (click)="poistaAlvPorras(i)" type="button">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </mat-radio-group>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>

          <div *ngIf="commonError" class="error-text">
            {{commonError}}
          </div>

          <div *ngIf="naytaTallennaKirjanpitajanaObservable | async" style="margin: 20px; text-align: center; max-width: 900px;">
            <div>JOS KÄYTTÄJÄ ON KIRJANPITÄJÄ, KÄYTÄ TÄTÄ NAPPIA</div>
            <button class="secondarybutton" mat-button (click)="saveKp()" type="button">TALLENNA KIRJANPITÄJÄNÄ</button>
            <div>VAROITUS! LUO KÄYTTÄJÄLLE KIRJANPITÄJÄN OIKEUDET! ÄLÄ KÄYTÄ ESIM. PALKKA-AVULLE TMS. KÄYTTÄJILLE!</div>
          </div>

          <div style="max-width: 900px;">
            <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 1em;">
              <button class="secondarybutton" mat-button (click)="peruuta()" type="button">{{'yleiset.peruuta' | translate | async}}</button>
              <button class="secondarybutton" mat-button (click)="save()" type="button">{{'yleiset.tallenna' | translate | async}}</button>
              <button class="secondarybutton" mat-button (click)="saveTaikalinkki()" type="button">Tallenna ja lähetä taikalinkki</button>
            </div>
            <div *ngIf="lastMagicLinkSendObservable | async" style="display: flex; justify-content: flex-end;">
              <div class="dark-gray-text" style="font-size: 12px; margin-right: 15%">Linkki lähetetty {{ (lastMagicLinkSendObservable | async)?.pvm | ltimestamptimel: 'fi' }}</div>
            </div>
          </div>

        </form>
      </div>
    </mat-tab>
    <mat-tab label="Luvat ja sopimukset" style="padding: 20px;">
      <div style="padding: 20px;">
        <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Yleistiedot</div>
          <div class="cont">
            <div style="width: 300px;">Asiakas luotu</div>
            <div>{{ (luvatJaSopimuksetObservable | async )?.asiakasLuotu }}</div>
          </div>
          <div class="cont">
            <div style="width: 300px;">Käyttäjä luotu</div>
            <div>{{ (luvatJaSopimuksetObservable | async )?.kayttajaLuotu }}</div>
          </div>
        </div>
        <div class="cont">
          <div style="width: 300px;">Viimeisin kirjautuminen</div>
          <div>{{ lastLoginObservable | async }}</div>
          <button mat-button style="cursor: pointer; color: #2dc8fa; line-height: 0;" (click)="naytaLoginHistoriaa()">Historia</button>
        </div>
        <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Tunnistaminen</div>
          <!-- <div class="cont">
            <div style="width: 300px;">Tupas</div>
            <div>N/A</div>
          </div> -->
          <ng-container *ngIf="luvatJaSopimuksetObservable | async; let luvatJaSopimukset">
            <div class="cont" *ngIf="luvatJaSopimukset.tunnistamisStatus === 'ei-tunnistettu'">
              Käyttäjää ei ole vielä tunnistettu
            </div>
            <div class="cont" *ngIf="luvatJaSopimukset.tunnistamisStatus === 'tunnistettu-hetulla'">
              <div style="width: 300px;">Käyttäjän kirjoittama hetu</div>
              <div>{{ luvatJaSopimukset.hetu }}</div>
            </div>
            <div class="cont" *ngIf="luvatJaSopimukset.tunnistamisStatus === 'tunnistettu-nets'">
              <div style="width: 300px;">Tunnistettu verkkopankkikirjautumisella</div>
              <div>{{ luvatJaSopimukset.hetu }}</div>
            </div>
            <button *ngIf="isAdmin" mat-button style="cursor: pointer; color: #2dc8fa;" (click)="naytaVahvanTunnistautumisenHistoriaa()">Historia</button>
          </ng-container>
        </div>
        <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Sopimukset</div>
          <div *ngFor="let hyvaksynta of sopimuksienHyvaksynnatObservable | async" class="cont">
            <div style="width: 300px;">Versio {{ hyvaksynta.sopimusAvain}} </div>
            <div>{{ hyvaksynta.pvm | ltimestamptimel: 'fi' }} </div>
          </div>
        </div>
        <!-- <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Käyttöehdot</div>
          <div class="cont">
            <div style="width: 300px;">Versio xjdhyeogmj</div>
            <div>N/A</div>
          </div>
          <div class="cont">
            <div style="width: 300px;">Versio dkehtciwjs</div>
            <div>Odottaa asiakasta</div>
          </div>
        </div> -->
        <!-- <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Suomi.fi -valtuutus</div>
          <div class="cont">
            <div style="width: 300px;">Valtuutus annettu</div>
            <div>N/A (voimassa N/A asti)</div>
          </div>
        </div>
        <div style="max-width: 900px;">
          <div class="light-blue-text" style="font-size: 22px; margin-bottom: 10px; line-height: 30px; margin-top: 40px;">Pankkiyhteysvaltuutus</div>
          <div class="cont">
            <div style="width: 300px;">Valtuutus 1</div>
            <div>N/A</div>
          </div>
          <div class="cont">
            <div style="width: 300px;">Valtuutus 2</div>
            <div>N/A</div>
          </div>
          <div class="cont">
            <div style="width: 300px;">Valtuutus 3</div>
            <div>N/A (voimassa N/A asti</div>
          </div>
        </div> -->
      </div>
    </mat-tab>
  </mat-tab-group>

</div>

<!-- <pre>{{ kayttaja | json }}</pre> -->