import { NgZone } from '@angular/core'
import { Observable } from 'rxjs'

export const runInZone = (zone: NgZone) => <T>(obs$: Observable<T>): Observable<T> => {
  return new Observable<T>(subscriber => {
    return obs$.subscribe({
      complete: () => zone.run(() => subscriber.complete()),
      error: error => zone.run(() => subscriber.error(error)),
      next: value => zone.run(() => subscriber.next(value))
    })
  })
}
