import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, Input, ViewEncapsulation, ErrorHandler } from '@angular/core'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { VeroilmoituksenAvainluvut, VeroilmoitusDraft, VeroilmoitusFinal, TilikaudenSummat, VeroilmoituksenValueConstants, VeroilmoitusLahetaIlmoitinTyo, TilinpaatosLiitetiedot, TilinpaatosTaseErittely, RaporttiRequest, RaporttiPdfResponse, VeroilmoitusOsakas } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { KirjanpitoMerkitseTilikausiValmiiksiTyojonotiedot } from 'app/_jaettu-lemonator/model/tyojono'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { VeroilmoituksenKaikkiTilisummat, VeroilmoitusLaskentaService, VeroilmoitusValidationError } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-laskenta.service'
import { ErottavanTilinTiedot, VeroilmoitusSummatService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-summat.service'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { LocalMonth } from 'app/_shared-core/model/common'
import { KirjanpidonUlkopuolisetKulut, Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { DateService } from 'app/_shared-core/service/date.service'
import { Observable, Subject, of as observableOf, combineLatest, BehaviorSubject, firstValueFrom, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, takeWhile, tap } from 'rxjs/operators'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { Asiakas, Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { IlmoitusRivi } from 'app/_jaettu-lemonator/service/base-ilmoitin-export.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { lemonShare } from '../../_jaettu-angular/_rxjs/lemon-share.operator'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { Korko, VahvistettuKorkoService } from 'app/_jaettu/service/vahvistettu-korko.service'

export interface VeroilmoituksenMuokkaustiedot {
  perustiedot: VeroilmoituksenPerustiedot
  arvot: IlmoitusRivi[]
  oletukset: VeroilmoituksenAvainluvut
  editedValuesFromDatabase: VeroilmoituksenAvainluvut
  locallyEditedNotPersistedValues: VeroilmoituksenAvainluvut
  punakynaNumbers: { [key: string]: 1 }
  erottavatTilit: ErottavanTilinTiedot[]
  virheet: VeroilmoitusValidationError[]
  kieli: TuettuKieli
  osakkaat: VeroilmoitusOsakas[]
}

export interface VeroilmoituksenPerustiedot {
  asiakas: Asiakas
  kuukausi: LocalMonth
  tilikausi: Tilikausi
  edellinenTilikausi: Tilikausi
  vastuukirjanpitaja: KirjanpitajanNimitiedot
  veroilmoituksenSummatService: VeroilmoitusSummatService
}

export interface PerustiedotJaTilienSummat {
  perustiedot: VeroilmoituksenPerustiedot
  summat: TilikaudenSummat[]
}

interface LahetettyVeroilmoitus {
  lahetetty: string
  lahettaja: string
  ilmo: VeroilmoitusFinal
  dataLink?: string
  dataLinkEncoded?: string
}

export type DoneCallbackHandlerFunction = (outcome: 'no-save-needed' | 'error' | 'success', error?: Error) => void
export interface TallennaArvoBaseEvent {
  numero?: string
  arvo?: string | number
  arvotCheckbox?: { id: string, valittu: boolean }[]
  arvot?: { numero: string, arvo: string | number }[]
  updateOnly?: true
  tyyppi: 'multiple' | 'one' | 'checkbox'
  doneCallback?: DoneCallbackHandlerFunction
  setPunakynaOpen?: true
  removePunakynaOpen?: true
}

export interface TallennaArvoEvent extends TallennaArvoBaseEvent {
  numero: string
  tyyppi: 'one'
  arvo: string | number
}

export interface TallennaArvotEvent extends TallennaArvoBaseEvent {
  tyyppi: 'multiple'
  arvot: { numero: string, arvo: string | number }[]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AVAA_VEROILMOITUKSEN_KENTTA_BROADCAST_CHANNEL = 'avaa-veroilmoituksen-kentta'
export interface AvaaVeroilmoitusKenttaMessage {
  numero: string
}

export interface TallennaCheckboxArvotEvent extends TallennaArvoBaseEvent {
  numero: string
  tyyppi: 'checkbox'
  arvotCheckbox: { id: string, valittu: boolean }[]
}

@Component({
  selector: '[app-kirjanpito-veroilmoitus]',
  templateUrl: './veroilmoitus.component.html',
  styleUrls: ['./veroilmoitus.component.css', './components/veroilmoitus-row.styles.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusComponent implements OnInit, OnDestroy {

  @Input() valittuKuukausiObservable: Observable<LocalMonth>

  onkoYritysmuodolleJaVuodelleLomakkeistoObservable: Observable<boolean>
  onkoYhtiomuotoTuettuObservable: Observable<boolean>
  asiakkaanNimi: string

  private _locallyEditedNotPersistedValues: VeroilmoituksenAvainluvut = {}
  private _muokattavaVeroilmoitusJosSeLahetetaanNytObservable: Observable<VeroilmoitusFinal>

  private _ngUnsubscribe = new Subject<void>()
  perustiedotObservable: Observable<VeroilmoituksenPerustiedot>

  private _reCalcSubject = new BehaviorSubject<boolean>(false)
  veroilmoitusObservable: Observable<VeroilmoituksenMuokkaustiedot>

  // For send component:
  viimeisinLahetettyVeroilmoitusObservable: Observable<VeroilmoitusFinal>
  yhtiomuotoJaVuosiObservable: Observable<{ y: Yritysmuoto, v: number, tilikausiLukittu: boolean }>

  erottavatTilitObservable: Observable<ErottavanTilinTiedot[]>
  lukittuObservable: Observable<boolean>
  veroilmoitusUriObservable: Observable<string>
  veroilmoitusEncodedUriObservable: Observable<string>
  aikaisemmatVeroilmoituksetObservable: Observable<{ devaaja: boolean, lahetetyt: LahetettyVeroilmoitus[] }>
  veroilmoitussahkoopostinVoiLahettaaObservable: Observable<boolean>
  ilmoitinErrorsObservable: Observable<{ fields: string[], message: string }[]>
  account9940DebetMinusKreditSumObservable: Observable<number>

  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
  naytaLataaIlmoitusObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable
  veroilmoitusEditableEntries: [string, any][] = []
  veroilmoitusPunakynaEntries: [string, any][] = []
  veroilmoitusOletuksetEntries: [string, any][] = []
  veroilmoitusEiTallennettuEntries: [string, any][] = []
  veroilmoitusStaticEntries: IlmoitusRivi[] = []
  commonErrorObservable: BehaviorSubject<string> = new BehaviorSubject(null)
  // tilikaudenSummatEntries: Summat[] = []
  // kalenterivuodenSummatEntries: [string, any][] = []

  private _veroilmoService: VeroilmoitusLaskentaService

  private _avaaVeroilmoitusKenttaChannel: BroadcastChannel

  constructor(
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _asiakasService: AsiakasService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _tilikarttaService: TilikarttaService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _timestampService: TimestampService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _codeCheckService: CodeCheckService,
    private _fileSaverService: FileSaverService,
    private _vahvistettuKorkoService: VahvistettuKorkoService
  ) {
    this._veroilmoService = new VeroilmoitusLaskentaService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
  }

  ngOnInit() {

    const asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.avain === b?.avain
      })
    )

    this._avaaVeroilmoitusKenttaChannel = new BroadcastChannel(AVAA_VEROILMOITUKSEN_KENTTA_BROADCAST_CHANNEL)

    const summatServiceObservable: Observable<VeroilmoitusSummatService> = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilikartta => {
        if (tilikartta) {
          return new VeroilmoitusSummatService(this._tilikarttaJaettuService, this._currencyService, tilikartta)
        }
        return null
      })
    )

    this.perustiedotObservable = combineLatest([
      this.valittuKuukausiObservable,
      asiakasObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable,
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
      summatServiceObservable
    ]).pipe(
      map(([kuukausi, asiakas, kirjanpitajat, kirjautunutKirjanpitaja, summatService]) => {
        if (kuukausi && asiakas && kirjanpitajat && summatService) {
          const tilikausi = this._kirjanpitoJaettuService.annaKuukaudenAikanaPaattyvaTilikausi(asiakas.tilikaudet, kuukausi)
          const kirjanpitaja = kirjanpitajat.get(asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2' ? kirjautunutKirjanpitaja.uid : asiakas.kasittelija)
          if (tilikausi && kirjanpitaja) {
            const edellinenTilikausi = this._kirjanpitoJaettuService.annaEdellinenTilikausi(asiakas.tilikaudet, tilikausi)
            const tiedot: VeroilmoituksenPerustiedot = { asiakas: asiakas, kuukausi: kuukausi, tilikausi: tilikausi, edellinenTilikausi: edellinenTilikausi, vastuukirjanpitaja: kirjanpitaja, veroilmoituksenSummatService: summatService }
            return tiedot
          }
        }
        return null
      })
    )

    this.lukittuObservable = this.perustiedotObservable.pipe(
      map(perustiedot => this._onkoLukittu(perustiedot))
    )

    this.yhtiomuotoJaVuosiObservable = this.perustiedotObservable.pipe(
      map(perustiedot => {
        return { y: perustiedot?.asiakas?.yritysmuoto, v: perustiedot?.tilikausi?.loppuu?.year, tilikausiLukittu: perustiedot?.tilikausi?.lukittu }
      })
    )

    this.onkoYhtiomuotoTuettuObservable = this.perustiedotObservable.pipe(
      map(perusdata => {
        if (
          perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
          perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
        ) {
          return true
        }
        return false
      })
    )

    this.onkoYritysmuodolleJaVuodelleLomakkeistoObservable = this.perustiedotObservable.pipe(
      map(perusdata => {
        if (perusdata?.tilikausi?.loppuu?.year === 2020) {
          if (
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
          ) {
            return true
          }
        } else if (perusdata?.tilikausi?.loppuu?.year === 2021) {
          if (
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
          ) {
            return true
          }
        } else if (perusdata?.tilikausi?.loppuu?.year === 2022) {
          if (
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
          ) {
            return true
          }
        } else if (perusdata?.tilikausi?.loppuu?.year === 2023) {
          if (
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
          ) {
            return true
          }
        } else if (perusdata?.tilikausi?.loppuu?.year === 2024) {
          if (
            // perusdata?.asiakas?.yritysmuoto === Yritysmuoto.TOIMINIMI ||
            perusdata?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO
          ) {
            return true
          }
        }
        return false
      })
    )

    this.veroilmoitusUriObservable = this.perustiedotObservable.pipe(
      map(perusdata => {
        if (perusdata != null) {
          return this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(perusdata.asiakas.avain, perusdata.tilikausi)
        }
        return ''
      })
    )
    this.veroilmoitusEncodedUriObservable = this.veroilmoitusUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    const lahetetytVeroilmoitusObservable: Observable<VeroilmoitusFinal[]> = this.perustiedotObservable.pipe(
      switchMap(perusdata => {
        if (!perusdata) {
          return observableOf<VeroilmoitusFinal[]>(null as VeroilmoitusFinal[])
        }
        const uri = this._kirjanpitoUriService.annaVeroilmoituksenFinalCollectionUri(perusdata.asiakas.avain)
        return this._firebase.firestoreCollection<VeroilmoitusFinal>(uri)
          .where('tilikausiAvain', '==', perusdata.tilikausi.avain)
          .listen()
      }),
      map(ilmot => {
        if (ilmot) {
          // NOTE!! IF THIS IS CHANGED, CHANGE viimeisinLahetettyVeroilmoitusObservable ACCORDINGLY!
          return ilmot.sort((a, b) => {
            if (!a.created && !b.created) { return 0 }
            if (a.created && !b.created) { return 1 }
            if (!a.created && b.created) { return -1 }
            if (a.created.seconds !== b.created.seconds) {
              return b.created.seconds - a.created.seconds
            }
            return b.created.nanoseconds - a.created.nanoseconds
          })
        }
        return null
      }),
      lemonShare()
    )

    this.viimeisinLahetettyVeroilmoitusObservable = lahetetytVeroilmoitusObservable.pipe(
      map(aikaisemmat => {
        if (aikaisemmat?.length) {
          return aikaisemmat[0]
        }
        return null
      })
    )

    this.aikaisemmatVeroilmoituksetObservable = combineLatest([
      lahetetytVeroilmoitusObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable,
      this.kirjanpitajaOnDevaajaObservable
    ]).pipe(
      map(([ilmot, nimitiedotMap, devaaja]) => {
        if (!ilmot || !nimitiedotMap) {
          return null
        }
        const listausilmot: LahetettyVeroilmoitus[] = []
        for (const ilmo of ilmot) {
          const sent = this._dateService.timestampToDate(ilmo.created)
          const kirjanpitaja = nimitiedotMap.get(ilmo.creator)
          const lahetetty: LahetettyVeroilmoitus = {
            ilmo: ilmo,
            lahetetty: this._dateService.muotoilePaivaJaAikaDate(sent, 'fi'),
            lahettaja: kirjanpitaja ? kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi : 'Tuntematon lähettäjä'
          }
          if (devaaja) {
            const link = this._kirjanpitoUriService.annaVeroilmoituksenFinalUri(ilmo.asiakasAvain, ilmo.avain)
            lahetetty.dataLink = link
            lahetetty.dataLinkEncoded = this._debugService.createFirestoreLink(link)
          }
          listausilmot.push(lahetetty)
        }
        return { devaaja: devaaja, lahetetyt: listausilmot }
      })
    )

    this.veroilmoitussahkoopostinVoiLahettaaObservable = this.aikaisemmatVeroilmoituksetObservable.pipe(
      map(aikaisemat => !!(aikaisemat?.lahetetyt?.length > 0))
    )

    const veroilmoObservable = this.perustiedotObservable.pipe(
      switchMap(perusdata => {
        if (!perusdata) {
          return observableOf<VeroilmoitusDraft>(null)
        }
        const uri = this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(perusdata.asiakas.avain, perusdata.tilikausi)
        return this._firebase.firestoreDoc<VeroilmoitusDraft>(uri).listen().pipe(
          map(veroilmoitus => {
            if (veroilmoitus) {
              if (!veroilmoitus.arvotEditable) { veroilmoitus.arvotEditable = {} }
              if (!veroilmoitus.punakynaNumbers) { veroilmoitus.punakynaNumbers = {} }
              return veroilmoitus
            }
            if (this._onkoLukittu(perusdata)) { return null }
            const newDraft: VeroilmoitusDraft = {
              arvotEditable: {},
              asiakasAvain: perusdata.asiakas.avain,
              tilikausiAvain: perusdata.tilikausi.avain,
              punakynaNumbers: {},
              osakkaat: null
            }
            return newDraft
          })
        )
      })
    )

    const summatObservable: Observable<PerustiedotJaTilienSummat> = this.perustiedotObservable.pipe(
      switchMap(perusdata => {

        if (!perusdata) {
          return observableOf<PerustiedotJaTilienSummat>(null)
        }

        // TODO: FIXME: ONLY 2 LATEST ARE NEEDED
        const uri = this._kirjanpitoUriService.annaTilikaudenTilisummienCollectionUri(perusdata.asiakas.avain)
        return this._firebase.firestoreCollection<TilikaudenSummat>(uri).listen().pipe(
          map(summat => {
            const kaikki: PerustiedotJaTilienSummat = {
              perustiedot: perusdata,
              summat: summat || []
            }
            return kaikki
          })
        )

      })
    )

    const lajitellutTilienSummat: Observable<VeroilmoituksenKaikkiTilisummat> = summatObservable.pipe(
      map(kaikkiTilisummatJaPerusdata => {
        return this._veroilmoService.lajitteleTilisummat(
          kaikkiTilisummatJaPerusdata?.perustiedot?.asiakas,
          kaikkiTilisummatJaPerusdata?.perustiedot?.tilikausi,
          kaikkiTilisummatJaPerusdata?.perustiedot?.edellinenTilikausi,
          kaikkiTilisummatJaPerusdata?.summat
        )
      })
      // ,
      // tap(test => {
      //   const all: Summat[] = []
      //   const nykyinen: Summat = {
      //     summat: Object.entries(test?.nykyinen?.tilikaudenSummat || {}).sort((a, b) => a[0].localeCompare(b[0])),
      //     tilikausiAvain: test?.nykyinen?.tilikausiAvain,
      //     kuvaus: 'nykyinen'
      //   }
      //   all.push(nykyinen)
      //   const nykyinenKumulatiivinen: Summat = {
      //     summat: Object.entries(test?.nykyinenKumulatiivinen || {}).sort((a, b) => a[0].localeCompare(b[0])),
      //     tilikausiAvain: test?.nykyinen?.tilikausiAvain,
      //     kuvaus: 'nykyinen kumulatiivinen'
      //   }
      //   all.push(nykyinenKumulatiivinen)
      //   const edellinen: Summat = {
      //     summat: Object.entries(test?.edellinen?.tilikaudenSummat || {}).sort((a, b) => a[0].localeCompare(b[0])),
      //     tilikausiAvain: test?.edellinen?.tilikausiAvain,
      //     kuvaus: 'edellinen'
      //   }
      //   all.push(edellinen)
      //   const edellinenKumulatiivinen: Summat = {
      //     summat: Object.entries(test?.edellinenKumulatiivinen || {}).sort((a, b) => a[0].localeCompare(b[0])),
      //     tilikausiAvain: test?.edellinen?.tilikausiAvain,
      //     kuvaus: 'edellinen kumulatiivinen'
      //   }
      //   all.push(edellinenKumulatiivinen)
      //   if (test?.aikaisemmat) {
      //     for (const aikaisempi of test.aikaisemmat) {
      //       const smt: Summat = {
      //         summat: Object.entries(aikaisempi.tilikaudenSummat || {}).sort((a, b) => a[0].localeCompare(b[0])),
      //         tilikausiAvain: aikaisempi.tilikausiAvain,
      //         kuvaus: 'aikaisempi'
      //       }
      //       all.push(smt)
      //     }
      //   }
      //   console.log(all)
      //   this.tilikaudenSummatEntries = all
      // })
    )

    const kirjanpidonUlkopuolisetKulutObservable: Observable<KirjanpidonUlkopuolisetKulut> = this.perustiedotObservable.pipe(
      switchMap(perusdata => {
        if (!perusdata?.tilikausi?.loppuu || !perusdata?.asiakas) {
          return observableOf<KirjanpidonUlkopuolisetKulut>(null)
        }
        // 'customers/' + linkData.asiakasAvain + '/customer-ulkopuoliset-kulut/' + linkData.asiakasAvain + '_' + linkData.year
        const uri = 'customers/' + perusdata.asiakas.avain + '/customer-ulkopuoliset-kulut/' + perusdata.asiakas.avain + '_' + perusdata.tilikausi.loppuu.year
        return this._firebaseLemonaid.firestoreDoc<KirjanpidonUlkopuolisetKulut>(uri).listen().pipe(
          takeWhile(ajopaivakirja => !!ajopaivakirja, true),
          map(kulut => {
            if (kulut) {
              return kulut
            }
            return this._veroilmoService.annaDefaultUlkopuolisetKulutLomake(perusdata.asiakas.avain, perusdata.tilikausi)
          })
        )
      })
    )

    const perusKorkoObservable: Observable<Korko> = this.perustiedotObservable.pipe(
      switchMap(perusdata => {
        if (!perusdata?.tilikausi?.loppuu || perusdata?.asiakas?.yritysmuoto !== Yritysmuoto.TOIMINIMI) {
          return observableOf<Korko>(null)
        }
        return this._vahvistettuKorkoService.annaPeruskorko(perusdata.tilikausi.loppuu)
      }),
    )

    this.account9940DebetMinusKreditSumObservable = combineLatest([
      lajitellutTilienSummat, // .pipe(tap(a => console.log('lajitellutTilienSummat emit')))
      this.perustiedotObservable
    ]).pipe(
      map(([summat, perustiedot]) => {
        if (
          perustiedot?.veroilmoituksenSummatService &&
          summat?.nykyinen?.tilikaudenSummat
        ) {
          return perustiedot.veroilmoituksenSummatService.laskeDebetMinusKredit('9940', summat.nykyinen.tilikaudenSummat, new Set())
        }
        return 0
      })
    )

    this.veroilmoitusObservable = combineLatest([
      this.perustiedotObservable.pipe(tap(a => console.log('perustiedotObservable emit'))),
      veroilmoObservable.pipe(tap(a => console.log('veroilmoObservable emit'))),
      lajitellutTilienSummat.pipe(tap(a => console.log('lajitellutTilienSummat emit'))),
      this._reCalcSubject.pipe(tap(a => console.log('_reCalcSubject emit'))),
      kirjanpidonUlkopuolisetKulutObservable.pipe(tap(a => console.log('kirjanpidonUlkopuolisetKulutObservable emit'))),
      perusKorkoObservable.pipe(tap(a => console.log('perusKorkoObservable emit')))
    ]).pipe(
      map(([perusdata, veroilmo, summat, recalc, kirjanpidonUlkopuolisetKulut, peruskorko]) => {
        if (!perusdata || !veroilmo || perusdata.tilikausi.loppuu.year < 2020) {
          return null
        }

        const sent = this._timestampService.now()

        const result = this._veroilmoService.muodostaVeroilmoituksenLopullisetArvot(perusdata.asiakas, perusdata.vastuukirjanpitaja, perusdata.tilikausi, veroilmo, this._locallyEditedNotPersistedValues, summat, perusdata.veroilmoituksenSummatService, kirjanpidonUlkopuolisetKulut, sent, peruskorko)

        const tiedot: VeroilmoituksenMuokkaustiedot = {
          perustiedot: perusdata,
          editedValuesFromDatabase: veroilmo.arvotEditable,
          punakynaNumbers: veroilmo.punakynaNumbers,
          locallyEditedNotPersistedValues: this._locallyEditedNotPersistedValues,
          arvot: result.arvot,
          oletukset: result.oletukset,
          erottavatTilit: result.erottavatTilit,
          virheet: result.virheet,
          kieli: 'fi',
          osakkaat: veroilmo.osakkaat
        }

        return tiedot
      }),
      tap(test => {
        console.log('Veroilmoitus emit')
        this.veroilmoitusEditableEntries = Object.entries(test?.editedValuesFromDatabase || {}).sort((a, b) => a[0].localeCompare(b[0]))
        this.veroilmoitusPunakynaEntries = Object.entries(test?.punakynaNumbers || {}).sort((a, b) => a[0].localeCompare(b[0]))
        this.veroilmoitusOletuksetEntries = Object.entries(test?.oletukset || {}).sort((a, b) => a[0].localeCompare(b[0]))
        this.veroilmoitusEiTallennettuEntries = Object.entries(test?.locallyEditedNotPersistedValues || {}).sort((a, b) => a[0].localeCompare(b[0]))
        this.veroilmoitusStaticEntries = test?.arvot || []
      }),
      lemonShare()
    )

    this._muokattavaVeroilmoitusJosSeLahetetaanNytObservable = combineLatest([
      this.veroilmoitusObservable,
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable
    ]).pipe(
      map(([tallennettavatTiedot, kirjautunutKirjanpitaja]) => {
        const lahetettavaVeroilmoitus: VeroilmoitusFinal = {
          avain: null,
          asiakasAvain: tallennettavatTiedot.perustiedot.asiakas.avain,
          arvot: tallennettavatTiedot.arvot,
          arvotEditable: tallennettavatTiedot.editedValuesFromDatabase,
          created: this._timestampService.now(),
          creator: kirjautunutKirjanpitaja.uid,
          lahetetty: null,
          tilikausiAvain: tallennettavatTiedot.perustiedot.tilikausi.avain,
          year: tallennettavatTiedot.perustiedot.tilikausi.loppuu.year,
          punakynaNumbers: tallennettavatTiedot.punakynaNumbers,
          osakkaat: tallennettavatTiedot.osakkaat
        }
        return lahetettavaVeroilmoitus
      })
    )

    this.ilmoitinErrorsObservable = this.veroilmoitusObservable.pipe(
      map(result => {
        if (
          result?.perustiedot?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO &&
          !result?.perustiedot?.tilikausi?.veroilmoitusStarted
        ) {
          // Suppress errors if the veroilmoitus has not been started yet.
          return null
        }
        if (!result?.virheet) {
          return null
        }
        return result.virheet.map(virhe => {
          return { fields: virhe.fields.split(','), message: virhe.message }
        })
      })
    )

    this.erottavatTilitObservable = this.veroilmoitusObservable.pipe(
      map(result => {
        if (
          result?.perustiedot?.asiakas?.yritysmuoto === Yritysmuoto.OSAKEYHTIO &&
          !result?.perustiedot?.tilikausi?.veroilmoitusStarted
        ) {
          // Suppress errors if the veroilmoitus has not been started yet.
          return null
        }
        if (result?.erottavatTilit?.length) {
          return result.erottavatTilit
        }
        return null
        // For test:
        // const erottavatTilit: ErottavanTilinTiedot[] = []
        // const tili: Kirjanpitotili = { aktiivinen: true, vanhempi: '401', numero: '4011', nimi: 'Ihmekulut', oletusAlvKasittely: null, alvTyyppi: null }
        // erottavatTilit.push({
        //   debit: 23,
        //   kredit: 44,
        //   tili: tili
        // })
        // erottavatTilit.push({
        //   debit: 55.345,
        //   kredit: 44.28,
        //   tili: tili
        // })
        // return erottavatTilit
      })
    )

  }

  private _onkoLukittu(perustiedot: VeroilmoituksenPerustiedot): boolean {
    if (!perustiedot?.tilikausi || !perustiedot?.asiakas) {
      return true
    } else if (perustiedot.asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI && perustiedot.tilikausi.loppuu.year < 2023) {
      return true
    } else if (perustiedot.asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO && perustiedot.tilikausi.loppuu.year < 2023) {
      return true
    }

    if (perustiedot.tilikausi.lukittu) {
      const plus10Mnths = this._dateService.lisaaKuukausiaPaikallinen(perustiedot.tilikausi.loppuu, 10)
      if (this._dateService.compareLocalMonths(this._dateService.currentLocalMonth(), '>', plus10Mnths)) {
        return true
      }
    }

    return false
  }

  async tallennaArvo(tallennaEvent: TallennaArvoBaseEvent) {
    const perustiedot = await firstValueFrom(this.perustiedotObservable)
    if (perustiedot?.asiakas && perustiedot?.tilikausi) {

      const update: Partial<VeroilmoitusDraft> = {
        arvotEditable: {},
        asiakasAvain: perustiedot.asiakas.avain,
        tilikausiAvain: perustiedot.tilikausi.avain
      }
      if (tallennaEvent.tyyppi === 'one') {
        if (tallennaEvent.arvo === undefined) {
          update.arvotEditable[tallennaEvent.numero] = this._firebase.firestoreDeleteMarker()
        } else {
          update.arvotEditable[tallennaEvent.numero] = tallennaEvent.arvo
        }
      } else if (tallennaEvent.tyyppi === 'checkbox') {
        for (const checkboxArvo of tallennaEvent.arvotCheckbox || []) {
          if (checkboxArvo.valittu === undefined) {
            update.arvotEditable[tallennaEvent.numero + '_' + checkboxArvo.id] = this._firebase.firestoreDeleteMarker()
          } else {
            update.arvotEditable[tallennaEvent.numero + '_' + checkboxArvo.id] = checkboxArvo.valittu ? VeroilmoituksenValueConstants.TRUE : VeroilmoituksenValueConstants.FALSE
          }
        }
      } else if (tallennaEvent.tyyppi === 'multiple') {
        for (const arvo of tallennaEvent.arvot || []) {
          if (arvo.arvo === undefined) {
            update.arvotEditable[arvo.numero] = this._firebase.firestoreDeleteMarker()
          } else {
            update.arvotEditable[arvo.numero] = arvo.arvo
          }
        }
      } else {
        throw new Error('Unknown tyyppi: ' + tallennaEvent.tyyppi)
      }

      if (tallennaEvent.removePunakynaOpen) {
        update.punakynaNumbers = {}
        update.punakynaNumbers[tallennaEvent.numero] = this._firebase.firestoreDeleteMarker()
      } else if (tallennaEvent.setPunakynaOpen) {
        update.punakynaNumbers = {}
        update.punakynaNumbers[tallennaEvent.numero] = 1
      }

      const uri = this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(perustiedot.asiakas.avain, perustiedot.tilikausi)
      this._firebase.firestoreSetData(uri, update, { merge: true }).then(() => {
        if (tallennaEvent.tyyppi === 'one') {
          delete this._locallyEditedNotPersistedValues[tallennaEvent.numero]
        } else if (tallennaEvent.tyyppi === 'checkbox') {
          for (const checkboxArvo of tallennaEvent.arvotCheckbox || []) {
            delete this._locallyEditedNotPersistedValues[tallennaEvent.numero + '_' + checkboxArvo.id]
          }
        } else if (tallennaEvent.tyyppi === 'multiple') {
          for (const arvo of tallennaEvent.arvot || []) {
            delete this._locallyEditedNotPersistedValues[arvo.numero]
          }
        } else {
          throw new Error('Unknown tyyppi: ' + tallennaEvent.tyyppi)
        }
        if (tallennaEvent.doneCallback) { tallennaEvent.doneCallback('success') }
      }).catch(error => {
        this._errorHandler.handleError(error)
        if (tallennaEvent.doneCallback) { tallennaEvent.doneCallback('error', error) }
      })

    }
  }

  laskeUudelleen() {
    this._reCalcSubject.next(true)
  }

  private _saveInflight: boolean = false
  async tallennaVeroilmoitus() {

    if (this._saveInflight) {
      return
    }
    this.commonErrorObservable.next(null)
    this._saveInflight = true

    const tallennettavatTiedot = await firstValueFrom(this.veroilmoitusObservable)
    const kirjautunutKirjanpitaja = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    // tallennettavatTiedot.erottavatTilit.length > 0 ||
    if (!kirjautunutKirjanpitaja || tallennettavatTiedot.virheet?.length > 0) {
      this._saveInflight = false
      return
    }

    if (tallennettavatTiedot.perustiedot.asiakas.yritysmuoto !== Yritysmuoto.TOIMINIMI) {

      // Check that tilinpäätös has been saved at least once
      const liitetiedotObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.valittuKuukausiObservable]).pipe(
        switchMap(([asiakas, kuukausi]) => {
          if (asiakas && kuukausi) {
            const tilikausi = this._kirjanpitoJaettuService.annaKuukaudenAikanaPaattyvaTilikausi(asiakas.tilikaudet, kuukausi)
            const uri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakas.avain, tilikausi)
            // console.log('load current from ' + uri)
            return this._firebase.firestoreDoc<TilinpaatosLiitetiedot>(uri).listen()
          }
          return of(null)
        })
      )
      const liitetiedotPromise = firstValueFrom(liitetiedotObservable)

      // Check that tase-erittely has been saved at least once
      const taseErittelyObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.valittuKuukausiObservable]).pipe(
        switchMap(([asiakas, kuukausi]) => {
          if (asiakas && kuukausi) {
            const tilikausi = this._kirjanpitoJaettuService.annaKuukaudenAikanaPaattyvaTilikausi(asiakas.tilikaudet, kuukausi)
            const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
            // console.log('load current from ' + uri)
            return this._firebase.firestoreDoc<TilinpaatosTaseErittely>(uri).listen()
          }
          return of(null)
        })
      )
      const taseErittelyPromise = firstValueFrom(taseErittelyObservable)

      const [liitetiedot, taseErittely] = await Promise.all([liitetiedotPromise, taseErittelyPromise])
      if (!liitetiedot || !taseErittely) {
        let commonError = ''
        if (!liitetiedot) {
          commonError += 'Veroilmoitusta ei voi lähettää ennen liitetietojen tallentamista. Ole hyvä ja navigoi "Tilinpäätös" -> "Liitetiedot" ja täytä liitetiedot. '
        }
        if (!taseErittely) {
          commonError += 'Veroilmoitusta ei voi lähettää ennen tase-erittelyn tallentamista. Ole hyvä ja navigoi "Tilinpäätös" -> "Tase-erittely" ja tee erittely. '
        }
        this.commonErrorObservable.next(commonError)
        this._saveInflight = false
        return
      }

    }

    const tyojonoAvain = this._firebase.firestoreCreateId()

    const batch = this._firebase.firestoreBatch()

    // Remove possible notification clause from draft
    const veroilmoitusDraftUri = this._kirjanpitoUriService.annaVeroilmoituksenDraftUri(tallennettavatTiedot.perustiedot.asiakas.avain, tallennettavatTiedot.perustiedot.tilikausi)
    const veroilmoitusDraftUpdate: Partial<VeroilmoitusDraft> = {
      osingonjakoNotification: this._firebase.firestoreDeleteMarker()
    }
    batch.set(veroilmoitusDraftUri, veroilmoitusDraftUpdate, { merge: true })

    // Tallenna veroilmoitus
    const veroilmoitusFinalUri = this._kirjanpitoUriService.annaVeroilmoituksenFinalUri(tallennettavatTiedot.perustiedot.asiakas.avain, tyojonoAvain)
    const lahetettavaVeroilmoitus: VeroilmoitusFinal = {
      avain: tyojonoAvain,
      asiakasAvain: tallennettavatTiedot.perustiedot.asiakas.avain,
      arvot: tallennettavatTiedot.arvot,
      arvotEditable: tallennettavatTiedot.editedValuesFromDatabase,
      punakynaNumbers: tallennettavatTiedot.punakynaNumbers || {},
      created: this._firebase.firestoreServerTimestamp(),
      creator: kirjautunutKirjanpitaja.uid,
      lahetetty: null,
      tilikausiAvain: tallennettavatTiedot.perustiedot.tilikausi.avain,
      year: tallennettavatTiedot.perustiedot.tilikausi.loppuu.year,
      osakkaat: tallennettavatTiedot.osakkaat ?? null
    }
    batch.set(veroilmoitusFinalUri, lahetettavaVeroilmoitus)

    // Tallenna työjono ilmoittimeen
    const ilmoitinTyoUri = this._kirjanpitoUriService.annaTyojonoVeroilmoituksenIlmoitinLahettaminenUri(tallennettavatTiedot.perustiedot.asiakas.avain)
    const ilmoitinTyoData: VeroilmoitusLahetaIlmoitinTyo = {
      luotu: this._timestampService.now(),
      asiakasAvain: tallennettavatTiedot.perustiedot.asiakas.avain
    }
    batch.set(ilmoitinTyoUri, ilmoitinTyoData)

    // Work queue entry to mark tilikausi as done.
    const lockWorkUri = this._kirjanpitoUriService.annaTyojonoKirjanpitoTilikausiValmiiksiUri(tallennettavatTiedot.perustiedot.asiakas.avain, tyojonoAvain)
    const lockWorkData: KirjanpitoMerkitseTilikausiValmiiksiTyojonotiedot = {
      asiakasAvain: tallennettavatTiedot.perustiedot.asiakas.avain,
      tilikausiAvain: tallennettavatTiedot.perustiedot.tilikausi.avain,
      toiminto: 'veroilmoitus-lahetetty'
    }
    batch.set(lockWorkUri, lockWorkData)

    await batch.commit()

  }

  async lataaTyviTiedosto(veroilmoitus: LahetettyVeroilmoitus) {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    this._lataaTyviTiedostoInternal(asiakas, veroilmoitus.ilmo)
  }

  async lataaDraftVeroilmoitus() {
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const lahetettavaVeroilmoitus = await firstValueFrom(this._muokattavaVeroilmoitusJosSeLahetetaanNytObservable)
    lahetettavaVeroilmoitus.avain = this._firebase.firestoreCreateId()
    this._lataaTyviTiedostoInternal(asiakas, lahetettavaVeroilmoitus)
  }

  private _lataaTyviTiedostoInternal(asiakas: Asiakas, ilmoitus: VeroilmoitusFinal) {
    const tiedosto = this._ilmoitusRiviArrToString(ilmoitus.arvot || [])

    // TODO: This is copied from backend integration handler. Really should call backend here for the final file.
    const lahetyskanava = 'V'

    const lahetetty = this._dateService.timestampToDate(ilmoitus.created)
    const lahetettyPvm = this._dateService.muotoileVeroFormaattiPaivaDate(lahetetty)
    const lahetettyAika = this._dateService.muotoileVeroFormaattiAikaDate(lahetetty)

    // 8 chars from the end of the avain. Might be enough to identify or not.
    const tarkenne = ilmoitus.avain.substring(ilmoitus.avain.length - 8)

    const tiedostonNimi = `${lahetyskanava}${asiakas.ytunnus}_${lahetettyPvm}_${lahetettyAika}_${ilmoitus.year}_${tarkenne}.TXT`

    this._fileSaverService.saveStringAs(tiedosto, tiedostonNimi, 'txt')
  }

  private _ilmoitusRiviArrToString(rivit: IlmoitusRivi[]): string {
    let finalString = ''
    for (const rivi of rivit) {
      finalString += rivi.key + ':' + rivi.value + '\n'
    }
    return finalString.slice(0, -1) // chop off the last new line.
  }

  async downloadPdf(veroilmoitus: LahetettyVeroilmoitus) {
    this._downloadPdf(veroilmoitus.ilmo.avain)
  }

  downloadUnsavedPdf() {
    this._downloadPdf()
  }

  private async _downloadPdf(veroilmoituksenAvain?: string) {
    this._ladataanService.aloitaLataaminen()

    const perustiedot = await firstValueFrom(this.perustiedotObservable)

    const pyynto: RaporttiRequest = {
      a: perustiedot.asiakas.avain,
      k: 'fi',
      w: RaporttiType.VEROILMOITUS,
      s: this._dateService.localDateToNumber(perustiedot.tilikausi.alkaa),
      e: this._dateService.localDateToNumber(perustiedot.tilikausi.loppuu)
    }

    if (veroilmoituksenAvain) {
      pyynto.va = veroilmoituksenAvain
    } else {
      const muokattavaVeroilmoitusJosSeLahetetaanNyt = await firstValueFrom(this._muokattavaVeroilmoitusJosSeLahetetaanNytObservable)
      pyynto.vi = muokattavaVeroilmoitusJosSeLahetetaanNyt
    }

    try {
      return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', pyynto).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          this._ladataanService.lopetaLataaminen()
          if (data.base64File) {
            const fileName = data.fileName || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:n lataaminen epäonnistui.')
          }
        }
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }

  }

  avaaKentta(numero: string) {
    const message: AvaaVeroilmoitusKenttaMessage = { numero }
    this._avaaVeroilmoitusKenttaChannel.postMessage(message)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
