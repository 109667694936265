import { Component, Input, EventEmitter, Output, OnInit, ViewChildren, QueryList, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ErrorHandler } from '@angular/core'
import { Validators, FormGroup, FormControl, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms'

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'

import { LaskunAlv, LaskunTuote, AsiakkaanTuote, AsiakkaanTuoteLaskussa, LaskunTyypit, LaskunTyyppi, LaskunLiitetiedosto, TypeaheadAsiakas } from '../../../_jaettu/model/lasku'
import { AlvService } from 'app/_jaettu-angular/service/alv.service'

import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { LaskuSharedService } from '../../../_jaettu/service/lasku/lasku-shared.service'
import { LaskuKopioija } from '../../../_jaettu/service/lasku/lasku.kopioija'
import { DateService } from '../../../_shared-core/service/date.service'

import { SimplifiedUploadData, TiedostojenLataamisService } from '../../service/tiedostojen-lataamis.service'
import { LemonTranslationService } from '../../service/lemon-translation.service'
import { LaskuttavaAsiakasProvider } from '../../service/lasku/laskuttava-asiakas.service'
import { TimestampService } from '../../service/timestamp-service'

import { LaskuRiviComponent, FieldError } from './lasku-rivi.component'

import { Observable, BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs'
import { map, switchMap, take, takeUntil } from 'rxjs/operators'

import { Big } from 'big.js'

import { SharedFirebaseLemonaid } from 'app/_jaettu-angular/base-firebase.service'
import { TuettuKieli } from 'app/_shared-core/model/common'

export interface LaskuForm {
  // Yläosan dropparit
  tyyppi: FormControl<LaskunTyyppi>
  kieli: FormControl<TuettuKieli>
  valuutta: FormControl<string>

  // Asiakkaan tiedot
  // asiakastyyppi: FormControl<?>
  nimi: FormControl<TypeaheadAsiakas>
  ytunnus: FormControl<string>
  katuosoite: FormControl<string>
  maa: FormControl<string>
  postinro: FormControl<string>
  postitoimipaikka: FormControl<string>

  // Laskun muut tiedot
  laskunpvm: FormControl<Date>
  erapaiva: FormControl<Date>
  maksuaikaa: FormControl<number>
  toimituspvm: FormControl<Date>
  lisatiedot: FormControl<string>
  viitteenne: FormControl<string>
  viivastyskorko: FormControl<number>

  // Tuotteet
  tuotteet: FormArray<FormGroup<LaskunRiviForm>>
  liitetiedostot: FormArray<FormControl<LadattavaLiitetiedosto>>
}

export interface LaskunRiviForm {
  tuote: FormControl<AsiakkaanTuote>
  ahinta: FormControl<number>
  maara: FormControl<number>
  alv: FormControl<LaskunAlv>
  ale: FormControl<number>
  yhteensa: FormControl<number>
}

export interface RivinVirheet {
  virheet: FieldError[]
}

export interface LadattavaLiitetiedosto extends LaskunLiitetiedosto {
  uploadDone?: boolean
  uploadDonePercentage?: number
  uploadError?: string
  uploadData?: SimplifiedUploadData
}

@Component({
  selector: 'app-lasku-rivit',
  templateUrl: './lasku-rivit.component.html',
  styleUrls: ['./lasku-rivit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaskuRivitComponent implements OnInit, OnDestroy {

  @ViewChildren(LaskuRiviComponent) riviKomponentit: QueryList<LaskuRiviComponent>
  @ViewChild('fileInput', { static: true }) fileInput

  @Output() summatMuuttuivat: EventEmitter<void> = new EventEmitter()

  private _paivitaValidaatiot: any
  private poistaFunktio: any = null
  private ngUnsubscribe = new Subject<void>()
  private virheet: RivinVirheet[] = []

  @Input() parent: FormGroup<LaskuForm>
  @Input() laskunTuotteetObservable: Observable<LaskunTuote[]>
  @Input() laskunTyyppiObservable: Observable<LaskunTyyppi>
  @Input() laskunVastaanottajanMaaObservable: Observable<string>
  @Input() laskunLiitetiedostotObservable: ReplaySubject<LadattavaLiitetiedosto[]>

  get paivitaValidaatiot(): any {
    return this._paivitaValidaatiot
  }
  @Input()
  set paivitaValidaatiot(p: any) {
    if (this.riviKomponentit) {
      for (const komponentti of this.riviKomponentit.toArray()) {
        // console.log('check comp')
        komponentti.markAsTouched()
        komponentti.paivitaVirheet()
      }
    }
    this._paivitaValidaatiot = p
  }

  kuvavirheAvain: string = null
  kuvavirheParametrit: any = null
  tuoteControllit: BehaviorSubject<FormGroup<LaskunRiviForm>[]> = new BehaviorSubject([])
  liitetiedostoControllit: BehaviorSubject<FormControl<LadattavaLiitetiedosto>[]> = new BehaviorSubject([])
  virheetObservable: BehaviorSubject<RivinVirheet[]> = new BehaviorSubject([])
  alvtObservable: Observable<LaskunAlv[]>
  // isDraggingObservable: Observable<boolean> // = new BehaviorSubject(true)

  private tyyppiJaAlvtObservable: Observable<{ laskunTyyppi: LaskunTyyppi, alvt: LaskunAlv[] }>

  constructor(
    private _errorHandler: ErrorHandler,
    private laskuSharedService: LaskuSharedService,
    private laskuKopioija: LaskuKopioija,
    private currencyService: CurrencyService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private translationService: LemonTranslationService,
    private _firebase: SharedFirebaseLemonaid,
    private laskuttavaAsiakasProvider: LaskuttavaAsiakasProvider,
    // private dndService: DragAndDropService,
    private timestampProvider: TimestampService,
    private dateService: DateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _alvService: AlvService
  ) {
    // this.isDraggingObservable = this.dndService.isDraggingInWindowObservable
  }

  ngOnInit() {

    this.laskunLiitetiedostotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(liitetiedostot => {

      const formArray = this.annaLiitetiedostotFormArray()
      formArray.clear()
      if (liitetiedostot) {
        for (const liitetiedosto of liitetiedostot) {
          formArray.push(new FormControl<LadattavaLiitetiedosto>(liitetiedosto, this.liitetiedostoValidator))
        }
      }

      this.liitetiedostoControllit.next(formArray.controls)

    })

    this.tyyppiJaAlvtObservable = combineLatest([this.laskunTyyppiObservable, this.laskunVastaanottajanMaaObservable]).pipe(
      switchMap(async ([laskunTyyppi, maa]) => {
        if (laskunTyyppi && maa) {
          return { laskunTyyppi: laskunTyyppi, alvt: await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, maa) || [] }
        } else if (laskunTyyppi) {
          return { laskunTyyppi: laskunTyyppi, alvt: await this._alvService.annaLaskutyypinAlvt(laskunTyyppi, null) || [] }
        }
        return null
      })
    )

    this.alvtObservable = this.tyyppiJaAlvtObservable.pipe(
      map(alvtJaTyyppi => alvtJaTyyppi?.alvt || [])
    )

    combineLatest([this.laskunTuotteetObservable, this.tyyppiJaAlvtObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([tuotteet, tyyppiJaAlvt]) => {

      if (!tyyppiJaAlvt) {
        return
      }

      const formArray = this.annaTuotteetFormArray()
      formArray.clear()

      if (tuotteet.length < 1) {
        const uusi = this.annaUusiTuote(tyyppiJaAlvt.alvt)
        tuotteet.push(uusi)
      }

      for (const tuote of tuotteet) {
        const formGroup = this.annaFormGroupLaskunTuotteelle(tuote, false, tyyppiJaAlvt.laskunTyyppi, tyyppiJaAlvt.alvt)
        // this.virheet.push({})
        formArray.push(formGroup)
      }

      // Aseta ALV:t
      const controllit = formArray.controls as FormGroup<LaskunRiviForm>[]
      let i = 0
      for (const control of controllit) {
        const alvField = control.get('alv')
        const tuote = tuotteet[i]
        let alvi: LaskunAlv = null

        if (tuote.alv && tyyppiJaAlvt.alvt) {
          for (const alv of tyyppiJaAlvt.alvt) {
            if (tuote.alv.tunniste === alv.tunniste) {
              alvi = alv
            }
          }
        }

        if (!alvi) {
          alvi = this._annaLaskunAlvTuotteelle(tuote.tuote, tyyppiJaAlvt.laskunTyyppi, tyyppiJaAlvt.alvt)
        }

        if (this._onkoAlvKenttaMuokattavissa(tyyppiJaAlvt.laskunTyyppi)) {
          alvField.enable()
        } else {
          alvField.disable()
        }

        if (!alvi) {
          for (const alv of tyyppiJaAlvt.alvt) {
            if (alv.d) {
              alvi = alv
              break
            }
          }
        }

        alvField.setValue(alvi ? alvi : tyyppiJaAlvt.alvt[0])

        i++
      }

      this.paivitaTuoteControllit(formArray)
      this.summatMuuttuivat.next()
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  validaatioMuuttuiTapahtumakuuntelija(errors: FieldError[], index: number) {
    if (errors && errors.length > 0) {
      this.virheet[index] = {
        virheet: errors
      }
    } else {
      delete this.virheet[index]
    }
    this.virheetObservable.next(this.virheet)
  }

  rivinPoistamisenKuuntelija(poistetunFormGroup: FormGroup<LaskunRiviForm>) {
    this.laskunTuotteetObservable.pipe(
      take(1)
    ).subscribe(tuotteet => {
      const tuotteetArray = this.annaTuotteetFormArray()
      const index = tuotteetArray.controls.indexOf(poistetunFormGroup)
      if (index > -1) {
        tuotteetArray.removeAt(index)
        tuotteet.splice(index, 1)
        this.virheet.splice(index, 1)
        this.summatMuuttuivat.next()
        this.annaTuotteetFormArray().updateValueAndValidity()
      }
    })
  }

  private paivitaTuoteControllit(formArray: FormArray<FormGroup<LaskunRiviForm>>) {
    this.tuoteControllit.next(formArray.controls)
  }

  annaTuotteetFormArray(): FormArray<FormGroup<LaskunRiviForm>> {
    return this.parent.get('tuotteet') as FormArray<FormGroup<LaskunRiviForm>>
  }

  private annaLiitetiedostotFormArray(): FormArray<FormControl<LadattavaLiitetiedosto>> {
    return this.parent.get('liitetiedostot') as FormArray<FormControl<LadattavaLiitetiedosto>>
  }

  lisaaLiite() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event: Event) {

    const list: FileList = (event.target as HTMLInputElement).files
    const tiedostot: NgxFileDropEntry[] = this.tiedostojenLataamisService.fileListToNgxFileDropEntries(list)

    this.laskunLiitetiedostotObservable.pipe(
      take(1)
    ).subscribe(async liitteet => {

      const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot, liitteet)
      if (!voidaanLadata) {
        return
      }

      this.lisaaTiedostot(tiedostot, liitteet, this.annaLiitetiedostotFormArray())
    })

  }

  private async lisaaTiedostot(tiedostot: NgxFileDropEntry[], liitteet: LadattavaLiitetiedosto[], formArray: FormArray<FormControl<LadattavaLiitetiedosto>>) {
    const asiakasAvain = await this.laskuttavaAsiakasProvider.annaLaskuttavaAsiakasAvain()
    if (!asiakasAvain) {
      return // TODO: VIRHEEN KÄSITTELY!!!
    }
    for (const tiedosto of tiedostot) {
      const fileEntry = tiedosto.fileEntry as FileSystemFileEntry
      const fileSize = await this.tiedostojenLataamisService.getFileSize(fileEntry)
      const file = await this.tiedostojenLataamisService.getFile(fileEntry)
      const fileEnding = await this.tiedostojenLataamisService.getFileEndingFromFile(file)

      const nyt = this.timestampProvider.now()
      const liitetiedosto: LadattavaLiitetiedosto = {
        avain: this._firebase.firestoreCreateId(),
        fileEnding: fileEnding,
        nimi: tiedosto.relativePath,
        koko: fileSize,
        lisatty: nyt,
        lisattyl: this.dateService.timestampToLocalDate(nyt),
        uploadDone: false,
        uploadDonePercentage: 0
      }

      liitteet.push(liitetiedosto)
      const control = new FormControl<LadattavaLiitetiedosto>(liitetiedosto, this.liitetiedostoValidator)

      liitetiedosto.uploadData = this.tiedostojenLataamisService.tallennaLaskunliitetiedosto(this._firebase, asiakasAvain, liitetiedosto, file)
      liitetiedosto.uploadData.statusObservable.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        error: (err) => {
          this._errorHandler.handleError(err)
          control.updateValueAndValidity()
          this.liitetiedostoControllit.next(formArray.controls)
        },
        next: (status) => {
          const currentValue = control.value
          currentValue.uploadDone = status.done
          currentValue.uploadDonePercentage = status.donePercentage
          if (status.error) {
            currentValue.uploadError = this.annaVirheteksti(status.error)
          }
          control.setValue(currentValue)
          control.updateValueAndValidity()
          this.liitetiedostoControllit.next(formArray.controls)
        },
        complete: () => {
          // console.log('Complete')
        }
      })
      formArray.push(control)
    }
    this.liitetiedostoControllit.next(formArray.controls)
    // this.laskunLiitetiedostotObservable.next(liitteet)
  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[], liitetiedostot: LadattavaLiitetiedosto[]): Promise<boolean> {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}
    const maxTiedostokoko = 5 * 1024 * 1024
    const maxYhteiskoko = 8 * 1024 * 1024
    const maxTiedostomaara = 8

    if (tiedostot.length < 1) {
      // this.lisaaLatausvirheviesti('kuitit.lataaminen.virheet.ei-tiedosto', {})
      return false
    }

    let kokonaiskoko = 0
    let lukumaara = 0
    if (liitetiedostot) {
      for (const liitetiedosto of liitetiedostot) {
        kokonaiskoko += liitetiedosto.koko
        lukumaara++
      }
    }

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'pdf', 'heic', 'heif']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this.tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this.tiedostojenLataamisService.getFileSize(file) : -1

      kokonaiskoko += fileSize
      lukumaara++

      // console.log(fileSize, file)

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        this.lisaaLatausvirheviesti('lasku.vaara-muoto', {
          tuetutMuodot: supportedImageTypes.join(', ')
        })
        return false
      }

      if (fileSize > maxTiedostokoko) {
        const kokoMegatavuissaLokalisoitu = this.currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        const maxKokoLokalisoitu = this.currencyService.formatoiDesimaali((maxTiedostokoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
        this.lisaaLatausvirheviesti('lasku.liian-suuri', {
          kokoMax: maxKokoLokalisoitu,
          kokoNyt: kokoMegatavuissaLokalisoitu
        })
        return false
      }

    }

    if (kokonaiskoko > maxYhteiskoko) {
      const kokoMegatavuissaLokalisoitu = this.currencyService.formatoiDesimaali((kokonaiskoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
      const maxKokoLokalisoitu = this.currencyService.formatoiDesimaali((maxYhteiskoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
      this.lisaaLatausvirheviesti('lasku.liian-suuri', {
        kokoMax: maxKokoLokalisoitu,
        kokoNyt: kokoMegatavuissaLokalisoitu
      })
      return false
    }

    if (lukumaara > maxTiedostomaara) {
      this.lisaaLatausvirheviesti('lasku.liian-monta', {
        lukumaaraMax: maxTiedostomaara,
        lukumaaraNyt: lukumaara
      })
      return false
    }

    return true

  }

  private lisaaLatausvirheviesti(kuvavirheAvain: string, kuvavirheParametrit: any) {
    if (this.poistaFunktio) {
      clearTimeout(this.poistaFunktio)
    }
    this.kuvavirheParametrit = kuvavirheParametrit
    this.kuvavirheAvain = kuvavirheAvain
    this.poistaFunktio = setTimeout(() => {
      this.kuvavirheAvain = ''
      this.kuvavirheParametrit = {}
      this._changeDetectorRef.markForCheck()
    }, 15000)
  }

  poistaLiitetiedosto(liitetiedosto: LadattavaLiitetiedosto, index: number) {
    this.laskunLiitetiedostotObservable.pipe(
      take(1)
    ).subscribe(liitteet => {
      if (liitteet) {
        liitteet.splice(index, 1)
        this.laskunLiitetiedostotObservable.next(liitteet)
      }
    })
  }

  annaVirheteksti(error: any): string {
    if (error && error.message) {
      return error.message
    }
    return JSON.stringify(error)
  }

  // fileOver(event) {
  //   this.dndService.setDragging(true)
  // }

  // fileLeave(event) {
  //   this.dndService.setDragging(true)
  // }

  // fileDrop(entries: NgxFileDropEntry[]) {

  //   this.dndService.setDragging(false)

  //   this.laskunLiitetiedostotObservable.pipe(
  //     take(1)
  //   ).subscribe(async liitteet => {

  //     const voidaanLadata = await this.tiedostoVoidaanLadata(entries, liitteet)
  //     if (!voidaanLadata) {
  //       return
  //     }

  //     this.lisaaTiedostot(entries, liitteet, this.annaLiitetiedostotFormArray())

  //   })

  // }

  private liitetiedostoValidator: ValidatorFn = (control: FormControl<LadattavaLiitetiedosto>): ValidationErrors => {
    // console.log('TÄSÄ VALIDOIDAAN: ', control.value)
    if (!control.value) {
      // console.log('EI ARVOA')
      return { 'upload-file-required': true }
    }
    const liitetiedosto = control.value
    if (liitetiedosto.uploadError) {
      return { 'upload-file-error': true, errorData: liitetiedosto.uploadError }
    }
    if (!liitetiedosto.uploadDone) {
      return { 'upload-file-loading': true }
    }
    // console.log('Return OK')
    return null
  }

  lisaaLaskunRivi() {
    combineLatest([this.laskunTuotteetObservable, this.laskunTyyppiObservable, this.alvtObservable]).pipe(
      take(1)
    ).subscribe(([tuotteet, tyyppi, alvt]) => {
      const formArray = this.annaTuotteetFormArray()

      const tuote = this.annaUusiTuote(alvt)
      const formGroup = this.annaFormGroupLaskunTuotteelle(tuote, true, tyyppi, alvt)

      tuotteet.push(tuote)
      formArray.push(formGroup)

      this.paivitaTuoteControllit(formArray)
      this.summatMuuttuivat.next()
    })
  }

  private annaUusiTuote(alvt: LaskunAlv[]): LaskunTuote {

    const tuote: LaskunTuote = {
      tuote: null,
      hinta: null,
      alv: null,
      ale: null,
      maara: 1
    }

    // Set default alv
    tuote.alv = this._alvService.annaDefaultAlv(alvt, this.dateService.currentNumberDate())
    if (!tuote.alv) {
      tuote.alv = alvt[0]
    }

    return tuote
  }

  private puuttuvaTuoteNimiValidator = (control: FormControl<AsiakkaanTuoteLaskussa>) => {
    // console.log('TÄSÄ VALIDOIDAAN: ', control.value)
    if (!control.value) {
      // console.log('EI ARVOA')
      return { required: true }
    }
    if (typeof control.value === 'string') {
      // console.log('String')
      if ((control.value as string).replace(' ', '') === '') {
        // console.log('TYHJÄ')
        return { required: true }
      }
    } else if (control.value.hasOwnProperty('nimi')) {
      // console.log('on nimi')
      if (!control.value.nimi || control.value.nimi.replace(' ', '') === '') {
        // console.log('TYHJÄ')
        return { required: true }
      }
    }
    // console.log('Return OK')
    return null
  }

  private paivitaYhteensa(tuoteGroup: FormGroup<LaskunRiviForm>, tuote: LaskunTuote) {
    const uusiYhteensa = this.laskuSharedService.annaLaskurivinYhteensaLaskunTuotteelle(tuote)
    const yhteensaKentta = tuoteGroup.get('yhteensa')
    if (this.paivitaNumero(yhteensaKentta.value, uusiYhteensa)) {
      const formatoitu = this.currencyService.formatoiDesimaali(uusiYhteensa, 2, 'fi')
      const takaisinNumero = this.currencyService.muutaMerkkijonoNumeroksi(formatoitu, 2)
      yhteensaKentta.setValue(takaisinNumero, { emitEvent: false, onlySelf: true, emitModelToViewChange: true, emitViewToModelChange: false })
    }
  }

  private asetaKappalehinta(tuote: LaskunTuote) {
    delete tuote.hinnanTyyppi
    delete tuote.yhteensa
  }

  private _onkoAlvKenttaMuokattavissa(tyyppi: LaskunTyyppi): boolean {
    return (tyyppi?.avain === LaskunTyypit.TAVALLINEN.avain ||
      tyyppi?.avain === LaskunTyypit.TAVALLINEN_ULKOM.avain ||
      tyyppi?.avain === LaskunTyypit.EU_KULUTTAJA.avain ||
      tyyppi?.avain === LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain ||
      tyyppi?.avain === LaskunTyypit.EU_KULUTTAJA_PALVELU.avain ||
      tyyppi?.avain === LaskunTyypit.EU_KULUTTAJA_TAVARA.avain)
  }

  private annaFormGroupLaskunTuotteelle(tuote: LaskunTuote, focus: boolean, tyyppi: LaskunTyyppi, alvt: LaskunAlv[]): FormGroup<LaskunRiviForm> {

    const disableAlv = !this._onkoAlvKenttaMuokattavissa(tyyppi)
    const yhteensa = this.laskuSharedService.annaLaskurivinYhteensaLaskunTuotteelle(tuote)
    const tuoteGroup = new FormGroup<LaskunRiviForm>({
      tuote: new FormControl<AsiakkaanTuote>(this.laskuKopioija.copyTuoteLaskusta(tuote.tuote), [this.puuttuvaTuoteNimiValidator]),
      ahinta: new FormControl<number>(tuote.hinta, [Validators.required, Validators.min(-99999999999.99), Validators.max(99999999999.99)]),
      maara: new FormControl<number>(tuote.maara, [Validators.required, Validators.min(-99999999999), Validators.max(99999999999)]),
      alv: new FormControl<LaskunAlv>({ value: tuote.alv, disabled: disableAlv }, [Validators.required]),
      ale: new FormControl<number>(tuote.ale, [Validators.min(0), Validators.max(100.01)]),
      yhteensa: new FormControl<number>(yhteensa, [Validators.required, Validators.min(-99999999999.99), Validators.max(99999999999.99)])
    })

    // tuoteGroup.valueChanges.subscribe(value => {
    //   console.log('ALL', value)
    // })

    tuoteGroup.get('tuote').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: AsiakkaanTuote) => {
      tuote.tuote = this.laskuKopioija.copyTuoteLaskuun(value)
      this.asetaKappalehinta(tuote)
      if (value) {
        if (value.hinta && !tuoteGroup.get('ahinta').value) {
          tuoteGroup.get('ahinta').setValue(value.hinta)
        }
        const alv = this._annaLaskunAlvTuotteelle(value, tyyppi, alvt)
        if (alv) {
          tuoteGroup.get('alv').setValue(alv)
        }
      }
    })
    tuoteGroup.get('ahinta').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('ahinta', value)
      tuote.hinta = value
      this.asetaKappalehinta(tuote)
      this.paivitaYhteensa(tuoteGroup, tuote)
    })
    tuoteGroup.get('maara').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('maara', value)
      tuote.maara = value
      this.asetaKappalehinta(tuote)
      this.paivitaYhteensa(tuoteGroup, tuote)
    })
    tuoteGroup.get('ale').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('ale ', value)
      tuote.ale = value === 0 ? null : value
      this.asetaKappalehinta(tuote)
      this.paivitaYhteensa(tuoteGroup, tuote)
    })
    tuoteGroup.get('alv').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('alv ', value)
      tuote.alv = value
      this.asetaKappalehinta(tuote)
      this.paivitaYhteensa(tuoteGroup, tuote)
    })
    tuoteGroup.get('yhteensa').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(value => {
      // console.log('yhteensa', value)

      tuote.hinnanTyyppi = 'yhteensa'
      tuote.yhteensa = value

      const alvKerroin = this.laskuSharedService.annaAlvKerroin(tuote)
      const aleKerroin = this.laskuSharedService.annaAleKerroin(tuote)
      const rivinYhteensa = new Big(tuote.yhteensa || 0).round(2, Big.roundHalfUp)
      const alentamatonHinta = rivinYhteensa.div(aleKerroin).round(2, Big.roundHalfUp)
      const yhteensaIlmanAlv = alentamatonHinta.div(alvKerroin).round(2, Big.roundHalfUp)
      const maara = new Big(tuote.maara || 1)
      const tuotteenVerotonYksikkohinta = yhteensaIlmanAlv.div(maara)

      // console.log('Ale', aleKerroin.toString(), 'ALV', alvKerroin.toString(), 'Yhteensä', rivinYhteensa.toString())

      const takaisinNumeroYksikkoHinta = this.currencyService.muutaBigDecimalRahaksi(tuotteenVerotonYksikkohinta)
      tuote.hinta = takaisinNumeroYksikkoHinta
      tuote.maara = Number(this.currencyService.muutaBigDecimalRahamerkkijonoksiDesimaalit(maara, 4))

      const hintaControl = tuoteGroup.get('ahinta')
      if (this.paivitaNumero(hintaControl.value, tuote.hinta)) {
        const formatoitu = this.currencyService.formatoiDesimaali(tuote.hinta, 2, 'fi')
        const takaisinNumero = this.currencyService.muutaMerkkijonoNumeroksi(formatoitu, 2)
        // console.log('ahintacontrol', hintaControl.value, '!==', takaisinNumero)
        hintaControl.setValue(takaisinNumero, { emitEvent: false, onlySelf: true })
      }

      const maaraControl = tuoteGroup.get('maara')
      if (this.paivitaNumero(maaraControl.value, tuote.maara)) {
        const formatoitu = this.currencyService.formatoiDesimaali(tuote.maara, 4, 'fi')
        const takaisinNumero = this.currencyService.muutaMerkkijonoNumeroksi(formatoitu, 4)
        // console.log('maaracontrol', maaraControl.value, '!==', takaisinNumero)
        maaraControl.setValue(takaisinNumero, { emitEvent: false, onlySelf: true, emitModelToViewChange: true, emitViewToModelChange: false })
      }

    })

    if (focus) {
      setTimeout(() => {
        this.riviKomponentit.last.focus()
        this._changeDetectorRef.markForCheck()
      }, 10)
    }

    return tuoteGroup

  }

  private _annaLaskunAlvTuotteelle(tuote: AsiakkaanTuote | AsiakkaanTuoteLaskussa, tyyppi: LaskunTyyppi, alvt: LaskunAlv[]): LaskunAlv {
    if (tuote && tuote.alv && tuote.alv[tyyppi.avain] && alvt) {
      const alvTunniste = tuote.alv[tyyppi.avain]
      const laskunTyyppi = LaskunTyypit.annaLaskunTyyppi(tyyppi.avain)
      if (alvTunniste && laskunTyyppi) {
        for (const alv of alvt) {
          if (alv.tunniste === alvTunniste) {
            return alv
          }
        }
      }
    }
    return null
  }

  private paivitaNumero(uusi: number, vanha: number): boolean {
    if ((uusi === null || vanha === null) && vanha !== uusi) {
      return true
    }
    if (this.currencyService.onkoNumero(uusi)) {
      if (this.currencyService.onkoNumero(vanha)) {
        const v = new Big(vanha).round(4, Big.roundHalfUp)
        const u = new Big(uusi).round(4, Big.roundHalfUp)
        if (!v.eq(u)) {
          return true
        }
      } else {
        return true
      }
    }
    return false
  }

}
