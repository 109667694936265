<div class="blue-background" style="min-height: 200px;">
  <div class="lemon-table-container">

    <div class="lemon-table" id="tuotteet" style="width: 100%; font-size: .8rem;">

      <div class="valkoinen lemon-header-row">
        <div class="tuotteet_nimi lemon-header-cell">{{'lasku.tuote-kuvaus' | translate | async}}</div>
        <div class="tuotteet_maara lemon-header-cell">{{'lasku.maara' | translate | async}}</div>
        <div class="tuotteet_ahinta lemon-header-cell">{{'lasku.a-hinta-veroton' | translate | async}}</div>
        <div class="tuotteet_alv lemon-header-cell">{{'lasku.alv-kanta' | translate | async}} </div>
        <div class="tuotteet_ale lemon-header-cell">{{'lasku.ale-pros' | translate | async}} </div>
        <div class="tuotteet_yhteensa lemon-header-cell">{{'lasku.tuote-yhteensa-veroton' | translate | async}}</div>
        <div class="tuotteet_poista lemon-header-cell"></div>
      </div>

      <app-lasku-rivi *ngFor="let tuote of tuoteControllit | async; let i = index;" [parent]="tuote" [alvt]="alvtObservable | async" [paivitaValidaatiot]="paivitaValidaatiot" (validaatioMuuttuiTapahtuma)="validaatioMuuttuiTapahtumakuuntelija($event, i)" (riviPoistettuTapahtuma)="rivinPoistamisenKuuntelija($event)">
      </app-lasku-rivi>

    </div>

    <div *ngIf="annaTuotteetFormArray()?.errors?.min" class="lemon-error" style="margin-top: 10px;">
      {{'lasku.validation.tuotteet.min' | translate | async}}
    </div>

    <button class="primarybutton" mat-button (click)="lisaaLaskunRivi()" type="button" style="padding: 0 1rem 0 .5rem; border: 1px rgb(255,255,255) solid; margin-top:20px; margin-bottom:20px;">
      <mat-icon>add</mat-icon>
      {{'lasku.lisaa-rivi' | translate | async}}
    </button>

    <div *ngIf="(virheetObservable | async)?.length > 0" class="lemon-error" style="margin-bottom: 30px;">
      <div *ngFor="let virhe of virheetObservable | async; let i = index;">
        <div *ngIf="virhe?.virheet">
          {{ 'lasku.validation.rivin-alku' | translate: { rivi_nro: i + 1 } | async }}
          <span *ngFor="let err of virhe?.virheet">
            {{ 'lasku.validation.' + err.field + '.' + err.error | translate | async }}
          </span>
        </div>
      </div>
    </div>

    <div>

      <button class="primarybutton" mat-button (click)="lisaaLiite()" type="button" style="border: 1px rgb(255,255,255) solid; color: white; padding: 0 1rem 0 .5rem; margin: 0;">
        <mat-icon>add</mat-icon>
        {{'lasku.lisaa-liite' | translate | async}}
      </button>

      <div *ngIf="kuvavirheAvain" class="red-text" style="padding: 2vh 3vh 2em 2em;">{{kuvavirheAvain | translate: kuvavirheParametrit | async}}</div>

      <div *ngIf="(liitetiedostoControllit | async)?.length > 0" style="padding-top: 20px;">
        <mat-chip-listbox>
          <ng-container *ngFor="let control of liitetiedostoControllit | async; let i = index">
            <mat-chip-option style="height: unset;" [removable]="true" (removed)="poistaLiitetiedosto(control.value, i)">

              <div style="display: flex; flex-flow: wrap; width: 100%;">
                <div style="display: flex; flex-flow: nowrap; width: 100%; justify-content: space-between;">
                  <div *ngIf="control.value.uploadDone">{{control.value.nimi}}</div>
                  <div *ngIf="!control.value.uploadDone">{{ 'lasku.ladataan' | translate | async }} {{control.value.nimi}}...</div>
                  <mat-icon matChipRemove>cancel</mat-icon>
                </div>
                <div style="width: 100%; padding: 10px 0;" *ngIf="!control.value.uploadDone">
                  <mat-progress-bar mode="determinate" [value]="control.value.uploadDonePercentage" style="width: calc(100% - 25px);"></mat-progress-bar>
                </div>
                <div class="error-text" style="width: calc(100% - 25px); padding: 10px 0;" *ngIf="control.value.uploadError">
                  {{ 'lasku.tiedoston-lahettaminen-epaonnistui-alku' | translate | async }} "{{ annaVirheteksti(control.value.uploadError) }}". {{ 'lasku.tiedoston-lahettaminen-epaonnistui-loppu' | translate | async }}
                </div>
              </div>

            </mat-chip-option>
          </ng-container>
        </mat-chip-listbox>
      </div>

    </div>

  </div>

  <!-- <div *ngIf="isDraggingObservable | async">
    <ngx-file-drop #droppi dropZoneClassName="laskun-rivit-liitetiedosto-drop-container"
      (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)"
      (onFileDrop)="fileDrop($event)"
      class="laskun-liitetiedostot-droppi"
    >
      <ng-template ngx-file-drop-content-tmp>
        <div class="laskun-rivit-liitetiedosto-drop-laatikko">
          <div style="text-align: center;">
            <div>{{'lasku.raahaa-tanne' | translate | async}}</div>
            <div style="font-size: .9rem; padding-top: 1.5em;">{{'lasku.tuetut-tiedostot' | translate | async}}</div>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div> -->

</div>

<input style="visibility:hidden; height: 1px;" (change)="fileChanged($event)" type="file" accept="image/*,application/pdf" #fileInput multiple="multiple" />