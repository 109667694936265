import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild, ChangeDetectorRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormArray } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { FormValidationService, FieldErrors } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { Subject, combineLatest, Observable, of } from 'rxjs'
import { takeUntil, switchMap, map } from 'rxjs/operators'

import { Asiakas, AsiakkaanMaksutapa, Kayttaja, TallennaTaikalinkkiType, TaikalinkkiLahetysHistoria } from '../_jaettu-lemonator/model/asiakas'
import { KayttajanTunnistamisStatus, SelvitettavienNakyvyys, UserFeature } from '../_jaettu/model/kayttaja'
import { LoginInformation } from '../_jaettu/model/login'
import { AloitaKayttajanaKirjautuminenPyynto } from '../_jaettu-lemonator/model/kirjanpitaja'
import { TallennaKayttajaPyynto } from '../_jaettu-lemonator/model/asiakas'
import { TallennaKayttajaVastaus } from '../_jaettu-lemonator/model/asiakas'

import { TositteenLisaysprosessinOsaMobiilissa, TositteenLisaysprosessinAlvVahennysoikeusPorras, TositteenLisaysprosessinOsanTunniste, Roolit } from '../_jaettu/model/kayttaja'
import { DateService } from '../_shared-core/service/date.service'

import { KayttajaComponentData } from '../_angular/_resolvers/asiakas.resolve'
import { KayttajaKopioija } from '../_angular/service/asiakas/kayttaja.kopioija'
import { KayttajaVaihdaSalasanaDialog } from './kayttaja.vaihda-salasana.dialog'
import { AsiakasService, AsiakkaanAvainTiedot } from '../_angular/service/asiakas/asiakas.service'
import { KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog } from './kayttaja.haluatko-varmasti-lahettaa-taikalinkin.dialog'
import { SopimuksenHyvaksynnanHistoria } from '../_jaettu/model/sopimus'
import { KayttajaLoginHistoryDialog } from './kayttaja.login-history.dialog'
import { FormValidators } from '../_jaettu-angular/_validators/FormValidators'
import { StringService } from 'app/_shared-core/service/string.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { StrongAuthenticationSessionHistory } from 'app/_jaettu/model/tunnistaminen'
import { KayttajaStrongAuthHistoryDialog } from './kayttaja.strong-auth-history.dialog'
import { DebugService } from 'app/_angular/service/debug.service'

export interface MuokkausprosessinOsa {
  lisaysProsessinOsa: TositteenLisaysprosessinOsaMobiilissa
  valittu: boolean
}
interface LuvatJaSopimukset {
  asiakasLuotu: string
  kayttajaLuotu: string
  // lastLogin: string
  // tupas: string
  hetu: string
  tunnistamisStatus: KayttajanTunnistamisStatus
  // sopimukset: {[key: string]: string }
  // kayttoehdot: {[key: string]: string }
}


type KaikkiRoolitInterface = { [key in Roolit]?: FormControl<boolean> }
type KaikkiFeaturetInterface = { [key in UserFeature]?: FormControl<boolean> }

interface MainFormInterface extends KaikkiRoolitInterface, KaikkiFeaturetInterface {
  etunimi: FormControl<string>
  sukunimi: FormControl<string>
  asiointikieli: FormControl<string>
  email: FormControl<string>
  puhelin: FormControl<string>
  aktiivinen: FormControl<boolean>
  selvitettavienNakyvyys: FormControl<SelvitettavienNakyvyys>
  prosessinOsat: FormArray<FormGroup<PortaatOsaFormInterface>>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'TOSITTEET'?: FormControl<boolean>
}

interface PortaatOsaFormInterface {
  aktiivinen: FormControl<boolean>
  otsikko: FormControl<string>
  alvPortaat?: FormArray<FormGroup<AlvPorrasFormGroupInterface>>
  oletusporras?: FormControl<number>
  maksutavat?: FormArray<FormGroup<MaksutaFormGroupInterface>>
}

interface AlvPorrasFormGroupInterface {
  arvo: FormControl<number>
  oletus: FormControl<boolean>
}

interface MaksutaFormGroupInterface {
  kaytossa: FormControl<boolean>
  tunniste: FormControl<number>
}

@Component({
  templateUrl: './kayttaja.component.html'
})
export class KayttajaComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput) etunimiInput: MatInput

  form: FormGroup<MainFormInterface>
  fieldErrors: FieldErrors = {}
  atLeastOneLemonaidRoleError: string
  selvitettavienNakyvyyksienArvot: { nimi: string, arvo: SelvitettavienNakyvyys }[] = [
    { nimi: 'Käyttäjä näkee vain omat selvitettävät (kaikki selvitettävät, jotka ovat hänellä käytössä olevissa maksutavoissa)', arvo: 'omat' },
    { nimi: 'Käyttäjä näkee kaikki selvitettävät', arvo: 'kaikki' }
  ]

  asiakas: Asiakas = null
  asiakkaanMaksutavat: AsiakkaanMaksutapa[] = null
  aktivoitujenMaksutapojenLukumaara = 0
  prosessinOsat: MuokkausprosessinOsa[] = null
  kayttaja: Kayttaja = null
  naytaAlvPortaat: boolean = false
  naytaMaksutavat: boolean = false
  commonError: string = null
  luvatJaSopimuksetObservable: Observable<LuvatJaSopimukset>
  lastLoginObservable: Observable<string>
  otsikkoObservable: Observable<string>
  sopimuksienHyvaksynnatObservable: Observable<SopimuksenHyvaksynnanHistoria[]>
  lastMagicLinkSendObservable: Observable<TaikalinkkiLahetysHistoria>
  isAdmin: boolean

  kayttajaUriObservable: Observable<string>
  kayttajaEncodedUriObservable: Observable<string>
  kirjanpitajaUriObservable: Observable<string>
  kirjanpitajaEncodedUriObservable: Observable<string>
  sopimustenHyvaksynnatUriObservable: Observable<string>
  sopimustenHyvaksynnatEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  kirjanpitajaOnLuottoObservable: Observable<boolean>
  naytaTallennaKirjanpitajanaObservable: Observable<boolean>

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  private portaatArray: FormArray<FormGroup<AlvPorrasFormGroupInterface>> = null
  private portaatOsaForm: FormGroup<PortaatOsaFormInterface> = null
  maksutavatArray: FormArray<FormGroup<MaksutaFormGroupInterface>> = null
  private _muidenKayttajienEmailit: string[]

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _kayttajaKopioija: KayttajaKopioija,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _stringService: StringService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) {
    this.kayttaja = this._kayttajaKopioija.annaUusiKayttaja(null)
  }


  peruuta() {
    this._router.navigate(['asiakkaat', this.asiakas.avain, 'kayttajat'])
  }

  save() {
    this.saveInternal('ala-tallenna-taikalinkkia')
  }

  saveTaikalinkki() {
    this.saveInternal('tallenna-taikalinkki')
  }

  saveKp() {
    this.saveInternal('ala-tallenna-taikalinkkia', 'tallennaKirjanpitajana')
  }

  saveInternal(tallennaTaikalinkki: TallennaTaikalinkkiType, kp?: 'tallennaKirjanpitajana') {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (this.kayttaja.aktiivinen && !this._kayttajaHasAtLeastOneLemonaidRole(this.kayttaja)) {
      const err = document.querySelector('#roolit')
      err.scrollIntoView({ behavior: 'smooth' })

      this.atLeastOneLemonaidRoleError = 'Lisää vähintään yksi Lemonaid-rooli'

      return
    }

    let alvPortaatValittu = false
    this.kayttaja.prosessinOsat = []
    for (const osa of this.prosessinOsat) {
      if (osa.valittu) {
        this.kayttaja.prosessinOsat.push(osa.lisaysProsessinOsa)
        if (osa.lisaysProsessinOsa.tunniste === TositteenLisaysprosessinOsanTunniste.ALV_VAHENNYSOIKEUS) {
          alvPortaatValittu = true
        }
      }
    }

    // Suodata pois portaat, jos osa ei valittu
    if (!alvPortaatValittu) {
      this.kayttaja.alvVahennysoikeusPortaat = null
    }

    const tallennaData: TallennaKayttajaPyynto = { asiakasAvain: this.asiakas.avain, kayttaja: this.kayttaja, tallennaTaikalinkki: tallennaTaikalinkki }

    if (kp === 'tallennaKirjanpitajana') {
      tallennaData.paivitaKirjanpitajanTiedot = true
    }

    if (tallennaTaikalinkki === 'tallenna-taikalinkki' && this.kayttaja.tervetuloaEmailStatus) {
      this._dialog.open(KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog).afterClosed().subscribe(result => {
        if (result === 'yes-please-send') {
          console.log('Tallenna dialogista')
          this._saveIt(tallennaData)
        }
      })
      return
    }

    console.log('Tallenna ilman dialogia')
    this._saveIt(tallennaData)

  }

  private _kayttajaHasAtLeastOneLemonaidRole(kayttaja: Kayttaja) {
    return kayttaja.roolit.LASKUTUS ||
      kayttaja.roolit.TOSITTEET_MYYNTI ||
      kayttaja.roolit.TOSITTEET_OSTO ||
      kayttaja.roolit.TOSITTEET_PALKKA ||
      kayttaja.roolit.TOSITTEET_TILI ||
      kayttaja.roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO ||
      kayttaja.roolit.PALKAT ||
      kayttaja.roolit.MAKSUT ||
      kayttaja.roolit.MAKSUT_VASTAANOTTAJA ||
      kayttaja.roolit.MAKSUT_MAKSAJA ||
      kayttaja.roolit.MAKSUT_HYVAKSYJA ||
      kayttaja.roolit.TUNNISTETAAN // TODO! Consider using RAPORTIT instead when the tase, pääkirja tc reports are live in Lemonaid
  }

  private _saveIt(tallennaData: TallennaKayttajaPyynto) {

    this.commonError = null

    if (tallennaData.kayttaja.aktiivinen) {
      const rooleja = Object.entries(tallennaData.kayttaja?.roolit ?? {}).filter(([rooli, aktiivinen]) => aktiivinen).length
      if (rooleja < 1) {
        this.commonError = 'Aktiivisella käyttäjällä pitää olla vähintään yksi rooli. (Jollei rooleja ole, ei käyttäjä näe mitään Lemonidissa, vaikka pystyykin kirjautumaan sinne.'
        return
      }
    }

    this._ladataanService.aloitaLataaminen()

    this._firebase.functionsCall<TallennaKayttajaPyynto, TallennaKayttajaVastaus>('asiakasKayttajaTallenna', tallennaData).then(result => {
      this._ladataanService.lopetaLataaminen()
      if (!result || result.e) {
        if (result && result.e === 'kayttaja-useassa-yrityksessa') {
          let error = 'Käyttäjä ' + this.kayttaja.etunimi + ' ' + this.kayttaja.sukunimi + ' (' + this.kayttaja.email + ', uid: ' + this.kayttaja.avain + ') on jo lisätty asiakkaisiin:'
          for (const yritys of result.missaYrityksissaKiinni) {
            error += ' ' + yritys.asiakasNimi + ' (' + yritys.asiakasYtunnus + ', id: ' + yritys.asiakasAvain + '),'
          }
          error += ' Sama käyttäjä ei voi olla useammassa asiakkaassa.'
          this.commonError = error
        } else {
          const error = result ? result.e : 'Ei resulttia'
          this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpidolle. Tekninen virhe: "' + error + '".'
        }
      } else {
        this.navigoiTakaisinListaan()
      }
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpidolle. Tekninen virhe: "' + err.message + '".'
    })
  }

  maksutapaAlas($event: MouseEvent, index: number) {
    const ctrls = this.maksutavatArray.controls
    if (ctrls.length > index + 1) {
      const b = ctrls[index]
      ctrls[index] = ctrls[index + 1]
      ctrls[index + 1] = b

      const b2 = this.kayttaja.maksutavat[index]
      this.kayttaja.maksutavat[index] = this.kayttaja.maksutavat[index + 1]
      this.kayttaja.maksutavat[index + 1] = b2

      this.paivitaMaksutapojenJarjestys(this.maksutavatArray, this.asiakkaanMaksutavat, this.kayttaja)
    }
    $event.stopPropagation()
    $event.preventDefault()
  }

  public maksutapaYlos($event: MouseEvent, index: number) {
    const ctrls = this.maksutavatArray.controls
    if (index - 1 > -1) {
      const b = ctrls[index]
      ctrls[index] = ctrls[index - 1]
      ctrls[index - 1] = b

      const b2 = this.kayttaja.maksutavat[index]
      this.kayttaja.maksutavat[index] = this.kayttaja.maksutavat[index - 1]
      this.kayttaja.maksutavat[index - 1] = b2

      this.paivitaMaksutapojenJarjestys(this.maksutavatArray, this.asiakkaanMaksutavat, this.kayttaja)
    }
    $event.stopPropagation()
    $event.preventDefault()
  }

  public poistaAlvPorras(index: number) {
    this.portaatArray.removeAt(index)
    this.kayttaja.alvVahennysoikeusPortaat.splice(index, 1)
  }

  public lisaaAlvPorras(): FormGroup<AlvPorrasFormGroupInterface> {
    const onkoOletusJo = this.onkoKayttajallaOletusAlvPorras(this.kayttaja)
    const lisattava: TositteenLisaysprosessinAlvVahennysoikeusPorras = {
      arvo: null,
      oletus: !onkoOletusJo
    }
    if (!this.kayttaja.alvVahennysoikeusPortaat) {
      this.kayttaja.alvVahennysoikeusPortaat = []
    }

    const group = this.annaAlvPorrasFormGroup(lisattava)
    this.portaatArray.push(group)
    this.kayttaja.alvVahennysoikeusPortaat.push(lisattava)

    if (lisattava.oletus && this.portaatOsaForm?.get('oletusporras')) {
      const oletusIndex = this.kayttaja.alvVahennysoikeusPortaat.length - 1
      this.portaatOsaForm.get('oletusporras').setValue(oletusIndex)
    }
    return group
  }

  ylos(index: number) {
    const prosessinOsatArray = this.form.get('prosessinOsat') as FormArray<FormGroup<PortaatOsaFormInterface>>
    const ctrls = prosessinOsatArray.controls
    if (index - 1 > -1) {
      const b = ctrls[index]
      ctrls[index] = ctrls[index - 1]
      ctrls[index - 1] = b

      const b2 = this.prosessinOsat[index]
      this.prosessinOsat[index] = this.prosessinOsat[index - 1]
      this.prosessinOsat[index - 1] = b2
    }
  }

  alas(index: number) {
    const prosessinOsatArray = this.form.get('prosessinOsat') as FormArray<FormGroup<PortaatOsaFormInterface>>
    const ctrls = prosessinOsatArray.controls
    if (ctrls.length > index + 1) {
      const b = ctrls[index]
      ctrls[index] = ctrls[index + 1]
      ctrls[index + 1] = b

      const b2 = this.prosessinOsat[index]
      this.prosessinOsat[index] = this.prosessinOsat[index + 1]
      this.prosessinOsat[index + 1] = b2
    }
  }

  pakotaVahvaTunnistautuminenUudelleen() {
    if (this.kayttaja?.avain) {
      this.asetaRooli(this.kayttaja, Roolit.TUNNISTETAAN, true)
      this.kayttaja.hetu = null
      this.kayttaja.kayttajaTunnistettu = 'ei-tunnistettu'
      this.saveInternal('ala-tallenna-taikalinkkia')
    } else {
      this._errorHandler.handleError(new Error('Käyttäjä puuttuu!!'))
    }
  }

  vaihdaSalasana() {
    if (this.kayttaja?.avain) {
      this._dialog.open(KayttajaVaihdaSalasanaDialog, { data: this.kayttaja })
    } else {
      this._errorHandler.handleError(new Error('Käyttäjä puuttuu!!'))
    }
  }

  kirjauduKayttajana() {
    if (this.kayttaja && this.kayttaja.avain && this.asiakas && this.asiakas.avain) {
      this._ladataanService.aloitaLataaminen()
      const pyynto: AloitaKayttajanaKirjautuminenPyynto = {
        asiakasAvain: this.asiakas.avain,
        kayttajaAvain: this.kayttaja.avain
      }
      this._firebase.functionsCall<AloitaKayttajanaKirjautuminenPyynto, void>('kirjautuminenKayttajanaAloita', pyynto).then(() => {
        this._ladataanService.lopetaLataaminen()
      }).catch(error => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(error)
      })
    } else {
      this._errorHandler.handleError(new Error('Asiakas tai käyttäjä puuttuu!! ' + this.asiakas + ' | ' + this.kayttaja))
    }
  }
  async naytaLoginHistoriaa() {
    let loginHistoria: LoginInformation[] = []
    if (this.kayttaja?.avain) {
      loginHistoria = await this._firebaseLemonaid.firestoreCollection<LoginInformation>('customers/' + this.asiakas.avain + '/customers-users/' + this.kayttaja.avain + '/user-logins/').orderBy('timestamp', 'desc').limit(100).get().then(snapshots => {
        const output: LoginInformation[] = []
        for (const snap of snapshots) {
          output.push(snap)
        }
        return output
      })
    }
    this._dialog.open(KayttajaLoginHistoryDialog, { data: loginHistoria })
  }

  ngAfterContentChecked() {
    this.fieldErrors = this._validationService.updateValidationStatus('asiakas', this.form)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.kirjanpitajaOnLuottoObservable = this._kirjautunutKayttajaService.kirjanpitajaOnLuottoObservable
    this.naytaTallennaKirjanpitajanaObservable = combineLatest([this._kirjautunutKayttajaService.kirjanpitajaOnLuottoObservable, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      map(([luotto, asiakas]) => luotto && asiakas?.avain === 'jzATU474P4BRCkEANZ1H')
    )
    this.kirjanpitajaUriObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      map(([route, asiakas]: [{ data: KayttajaComponentData }, AsiakkaanAvainTiedot]) => {
        if (asiakas?.avain === 'jzATU474P4BRCkEANZ1H' && route?.data?.kayttaja?.avain && route?.data?.kayttaja?.kirjanpitajaAvain) {
          return 'kirjanpitajat/' + route.data.kayttaja.kirjanpitajaAvain
        }
        return ''
      })
    )
    this.kirjanpitajaEncodedUriObservable = this.kirjanpitajaUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )
    this.kayttajaUriObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      map(([route, avainTiedot]: [{ data: KayttajaComponentData }, AsiakkaanAvainTiedot]) => {
        if (avainTiedot?.avain && route?.data?.kayttaja?.avain) {
          return 'asiakkaat/' + avainTiedot.avain + '/kayttajat/' + route.data.kayttaja.avain
        }
        return ''
      })
    )
    this.kayttajaEncodedUriObservable = this.kayttajaUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )
    this.sopimustenHyvaksynnatUriObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasAvainObservable]).pipe(
      map(([route, avainTiedot]: [{ data: KayttajaComponentData }, AsiakkaanAvainTiedot]) => {
        if (avainTiedot?.avain && route?.data?.kayttaja?.avain) {
          return 'customers/' + avainTiedot.avain + '/customers-users/' + route.data.kayttaja.avain + '/sopimus-hyvaksynnat/'
        }
        return ''
      })
    )
    this.sopimustenHyvaksynnatEncodedUriObservable = this.sopimustenHyvaksynnatUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )

    this._kirjautunutKayttajaService.kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(isAdmin => {
      this.isAdmin = isAdmin
    })

    // Create form
    this.form = new FormGroup({
      'etunimi': new FormControl<string>('', [Validators.required]),
      'sukunimi': new FormControl<string>('', [Validators.required]),
      'asiointikieli': new FormControl<string>('', [Validators.required]),
      'email': new FormControl<string>('', [Validators.required, this.validateEmail, this.duplicateEmailsValidator]),
      'puhelin': new FormControl<string>('', [FormValidators.phoneNumberValidator]),
      'aktiivinen': new FormControl<boolean>(null, []),
      'selvitettavienNakyvyys': new FormControl<SelvitettavienNakyvyys>(null, [Validators.required]),
      'prosessinOsat': new FormArray([])
    })

    // Lisää roolit
    this.form.addControl(Roolit.LASKUTUS, new FormControl())

    this.form.addControl('TOSITTEET', new FormControl({ value: false, disabled: true }))
    this.form.addControl(Roolit.TOSITTEET_MYYNTI, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.TOSITTEET_OSTO, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.TOSITTEET_PALKKA, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.TOSITTEET_TILI, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO, new FormControl<boolean>({ value: false, disabled: false }))

    this.form.addControl(Roolit.PALKAT, new FormControl<boolean>({ value: false, disabled: false }))

    this.form.addControl(Roolit.MAKSUT, new FormControl<boolean>({ value: false, disabled: true }))
    this.form.addControl(Roolit.MAKSUT_VASTAANOTTAJA, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.MAKSUT_MAKSAJA, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.MAKSUT_HYVAKSYJA, new FormControl<boolean>({ value: false, disabled: false }))

    this.form.addControl(Roolit.RAPORTIT, new FormControl<boolean>({ value: false, disabled: false }))

    this.form.addControl(Roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA, new FormControl<boolean>({ value: false, disabled: false }))
    // this.form.addControl(Roolit.HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA, new FormControl({ value: false, disabled: true }))
    this.form.addControl(Roolit.HALLINTO_YHTEYSHENKILO, new FormControl<boolean>({ value: false, disabled: false }))
    this.form.addControl(Roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA, new FormControl<boolean>({ value: false, disabled: false }))

    this.form.addControl(Roolit.TUNNISTETAAN, new FormControl<boolean>({ value: false, disabled: false }))

    // Ominaisuudet
    this.form.addControl(UserFeature.CAN_EDIT_PENALTY_INTEREST, new FormControl<boolean>({ value: false, disabled: false }))

    // Bind to changes
    this.form.get('etunimi').valueChanges.subscribe(value => { this.kayttaja.etunimi = this._validationService.processValue(value) })
    this.form.get('sukunimi').valueChanges.subscribe(value => { this.kayttaja.sukunimi = this._validationService.processValue(value) })
    this.form.get('email').valueChanges.subscribe(value => { this.kayttaja.email = this._validationService.processValue(value) })
    this.form.get('puhelin').valueChanges.subscribe(value => { this.kayttaja.puhelinnumero = this._validationService.processValue(value) })
    this.form.get('aktiivinen').valueChanges.subscribe(value => { this.kayttaja.aktiivinen = this._validationService.processValue(value) })
    this.form.get('selvitettavienNakyvyys').valueChanges.subscribe(value => { this.kayttaja.selvitettavienNakyvyys = this._validationService.processValue(value) })
    this.form.get('asiointikieli').valueChanges.subscribe(value => { this.kayttaja.asiointikieli = this._validationService.processValue(value) })

    this.otsikkoObservable = combineLatest([this.form.get('etunimi').valueChanges, this.form.get('sukunimi').valueChanges]).pipe(
      map(([etunimi, sukunimi]) => {
        const e = this._validationService.processValue(etunimi)
        const s = this._validationService.processValue(sukunimi)
        if (e || s) {
          return this._stringService.normalizeWhitespace((s || '') + ' ' + (e || '')).trim()
        }
        return ''
      })
    )

    // Bind roolit begins
    this.form.get(Roolit.LASKUTUS).valueChanges.subscribe(value => {
      if (value) {
        this.atLeastOneLemonaidRoleError = null
      }
      this.asetaRooli(this.kayttaja, Roolit.LASKUTUS, value)
    })

    this.form.get('TOSITTEET').valueChanges.subscribe(value => {
      if (value) {
        this.atLeastOneLemonaidRoleError = null
      }
    })
    this.form.get(Roolit.TOSITTEET_MYYNTI).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.TOSITTEET_MYYNTI, value); this.asetaTositteetRooli(this.kayttaja) })
    this.form.get(Roolit.TOSITTEET_OSTO).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.TOSITTEET_OSTO, value); this.asetaTositteetRooli(this.kayttaja) })
    this.form.get(Roolit.TOSITTEET_PALKKA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.TOSITTEET_PALKKA, value); this.asetaTositteetRooli(this.kayttaja) })
    this.form.get(Roolit.TOSITTEET_TILI).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.TOSITTEET_TILI, value); this.asetaTositteetRooli(this.kayttaja) })
    this.form.get(Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO, value); this.asetaTositteetRooli(this.kayttaja) })

    this.form.get(Roolit.PALKAT).valueChanges.subscribe(value => {
      if (value) {
        this.atLeastOneLemonaidRoleError = null
      }
      this.asetaRooli(this.kayttaja, Roolit.PALKAT, value)
    })

    this.form.get(Roolit.MAKSUT).valueChanges.subscribe(value => {
      if (value) {
        this.atLeastOneLemonaidRoleError = null
      }
      this.asetaRooli(this.kayttaja, Roolit.MAKSUT, value)
    })

    this.form.get(Roolit.MAKSUT_VASTAANOTTAJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.MAKSUT_VASTAANOTTAJA, value); this.asetaMaksutRooli(this.kayttaja) })
    this.form.get(Roolit.MAKSUT_MAKSAJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.MAKSUT_MAKSAJA, value); this.asetaMaksutRooli(this.kayttaja) })
    this.form.get(Roolit.MAKSUT_HYVAKSYJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.MAKSUT_HYVAKSYJA, value); this.asetaMaksutRooli(this.kayttaja) })

    this.form.get(Roolit.RAPORTIT).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.RAPORTIT, value) })

    this.form.get(Roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA, value) })
    this.form.get(Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA, value) })
    // this.form.get(Roolit.HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA, value) })
    this.form.get(Roolit.HALLINTO_YHTEYSHENKILO).valueChanges.subscribe(value => {
      this.asetaRooli(this.kayttaja, Roolit.HALLINTO_YHTEYSHENKILO, value)
      this.form.get(Roolit.RAPORTIT).setValue(value)
    })
    this.form.get(Roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA, value) })

    this.form.get(Roolit.TUNNISTETAAN).valueChanges.subscribe(value => { this.asetaRooli(this.kayttaja, Roolit.TUNNISTETAAN, value) })

    this.form.get(UserFeature.CAN_EDIT_PENALTY_INTEREST).valueChanges.subscribe(value => { this.asetaFeature(this.kayttaja, UserFeature.CAN_EDIT_PENALTY_INTEREST, value) })

    combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable, this._asiakasService.nykyisenAsiakkaanMaksutavatObservable]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(results => {
      const all = results[0] as { data: KayttajaComponentData }
      this.asiakas = results[1]
      this.asiakkaanMaksutavat = results[2] ? [].concat(results[2]) : []

      this.nollaaLomake()

      if (!all.data.kayttaja) {
        return
      }

      this.kayttaja = this._kayttajaKopioija.kopioiKayttaja(all.data.kayttaja)
      if (!this.kayttaja.roolit) {
        this.kayttaja.roolit = {}
      }
      this._muidenKayttajienEmailit = all.data.muidenKayttajienEmailit

      this.alustaLomakkeenTiedot(this.kayttaja, this.asiakkaanMaksutavat)
      setTimeout(() => {
        if (this.etunimiInput) {
          this.etunimiInput.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 25)
    })

    const lastLoginInformationObservable: Observable<LoginInformation> = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      switchMap(([routeData, asiakas]) => {
        const all = routeData as { data: KayttajaComponentData }
        const kayttaja: Kayttaja = all.data.kayttaja
        if (kayttaja && asiakas && kayttaja.avain) {
          return this._firebaseLemonaid.firestoreCollection<LoginInformation>('customers/' + asiakas.avain + '/customers-users/' + kayttaja.avain + '/user-logins/').orderBy('timestamp', 'desc').limit(1).listen().pipe(
            map(list => {
              return list && list.length > 0 ? list[0] : null
            })
          )
        }
        return of<LoginInformation>(null)
      })
    )
    this.lastMagicLinkSendObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      switchMap(([routeData, asiakas]) => {
        const all = routeData as { data: KayttajaComponentData }
        const kayttaja: Kayttaja = all.data.kayttaja
        if (kayttaja && asiakas && kayttaja.avain) {
          return this._firebase.firestoreCollection<TaikalinkkiLahetysHistoria>('asiakkaat/' + asiakas.avain + '/kayttajat/' + kayttaja.avain + '/taikalinkki-lahetys-historia/').orderBy('pvm', 'desc').limit(1).listen().pipe(
            map(list => {
              return list && list.length > 0 ? list[0] : null
            })
          )
        }
        return of<TaikalinkkiLahetysHistoria>(null)
      })
    )

    this.lastLoginObservable = lastLoginInformationObservable.pipe(
      map(info => {
        if (info) {

          // Aika
          let retVal = this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(info.timestamp), 'fi')

          // Ympäristö
          if (info.ymparisto === 'a') {
            retVal += ' Android'
          } else if (info.ymparisto === 'i') {
            retVal += ' iOS'
          } else if (info.ymparisto === 'w') {
            retVal += ' Web'
          } else {
            retVal += ' tuntematon ympäristö'
          }

          // Ympäristön softaversio
          retVal += ' ' + info.version

          // Laite jolla käytettiin
          // retVal += ' ' + info.device
          // retVal += ' ' + info.os

          return retVal
        }
        return 'Ei vielä kirjautunut'
      })
    )

    this.luvatJaSopimuksetObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      switchMap(([routeData, asiakas]) => {
        const all = routeData as { data: KayttajaComponentData }
        const kayttaja: Kayttaja = all.data.kayttaja
        if (kayttaja && asiakas) {
          const output: LuvatJaSopimukset = {
            asiakasLuotu: this._dateService.muotoilePaivaJaAikaDate(asiakas.luotu.toDate(), 'fi'),
            kayttajaLuotu: this._dateService.muotoilePaivaJaAikaDate(kayttaja.luotu.toDate(), 'fi'),
            hetu: kayttaja.hetu,
            tunnistamisStatus: kayttaja.kayttajaTunnistettu
          }
          return of<LuvatJaSopimukset>(output)
        }
        return of<LuvatJaSopimukset>(null)
      })
    )
    this.sopimuksienHyvaksynnatObservable = combineLatest([this._route.data, this._asiakasService.nykyinenAsiakasObservable]).pipe(
      switchMap(([routeData, asiakas]) => {
        const all = routeData as { data: KayttajaComponentData }
        const kayttaja: Kayttaja = all.data.kayttaja
        if (kayttaja && kayttaja.avain && asiakas) {
          return this._firebaseLemonaid.firestoreCollection<SopimuksenHyvaksynnanHistoria>('customers/' + asiakas.avain + '/customers-users/' + kayttaja.avain + '/sopimus-hyvaksynnat/').listen().pipe(
            map(docs => {
              if (docs || docs.length) {
                return docs
              }
              return null
            })
          )
        }
        return of(null)
      })
    )
  }

  private asetaRooli(kayttaja: Kayttaja, rooli: Roolit, value: boolean) {
    if (value) {
      kayttaja.roolit[rooli] = true
    } else {
      kayttaja.roolit[rooli] = false // firebase.firestore.FieldValue.delete() as any
    }
  }

  private asetaFeature(kayttaja: Kayttaja, feature: UserFeature, value: boolean) {
    if (value) {
      if (!kayttaja.features) { kayttaja.features = {} }
      kayttaja.features[feature] = true
    } else {
      if (kayttaja.features) {
        kayttaja.features[feature] = false
      }
    }
  }

  private validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  private duplicateEmailsValidator: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (this._muidenKayttajienEmailit.includes(ctrl.value?.trim())) {
      return { emailinuse: true }
    }
    return null
  }

  private nollaaLomake() {
    const prosessinOsatArray = this.form.get('prosessinOsat') as FormArray<FormGroup<PortaatOsaFormInterface>>
    prosessinOsatArray.clear()
    this.form.reset()
  }

  private asetaTositteetRooli(kayttaja: Kayttaja) {
    this.form.get('TOSITTEET').setValue(
      RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_MYYNTI) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_OSTO) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_PALKKA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_TILI) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO)
    )
  }

  private asetaMaksutRooli(kayttaja: Kayttaja) {
    this.form.get(Roolit.MAKSUT).setValue(
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_MAKSAJA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_HYVAKSYJA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_VASTAANOTTAJA)
    )
  }

  private alustaLomakkeenTiedot(kayttaja: Kayttaja, maksutavat: AsiakkaanMaksutapa[]) {

    // Basic data
    this.form.get('etunimi').setValue(kayttaja.etunimi)
    this.form.get('sukunimi').setValue(kayttaja.sukunimi)
    this.form.get('email').setValue(kayttaja.email)
    this.form.get('puhelin').setValue(kayttaja.puhelinnumero)
    this.form.get('aktiivinen').setValue(kayttaja.aktiivinen)
    this.form.get('selvitettavienNakyvyys').setValue(kayttaja.selvitettavienNakyvyys)
    this.form.get('asiointikieli').setValue(kayttaja.asiointikieli ? kayttaja.asiointikieli : 'fi')

    // Roles
    this.form.get(Roolit.LASKUTUS).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.LASKUTUS))

    this.asetaTositteetRooli(kayttaja)
    // this.form.get('TOSITTEET').setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET))
    this.form.get(Roolit.TOSITTEET_MYYNTI).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_MYYNTI))
    this.form.get(Roolit.TOSITTEET_OSTO).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_OSTO))
    this.form.get(Roolit.TOSITTEET_PALKKA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_PALKKA))
    this.form.get(Roolit.TOSITTEET_TILI).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TOSITTEET_TILI))
    this.form.get(Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO))

    this.form.get(Roolit.PALKAT).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.PALKAT))

    this.form.get(Roolit.MAKSUT).setValue(
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_VASTAANOTTAJA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_MAKSAJA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_HYVAKSYJA) ||
      RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT)
    )
    this.form.get(Roolit.MAKSUT_VASTAANOTTAJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_VASTAANOTTAJA))
    this.form.get(Roolit.MAKSUT_MAKSAJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_MAKSAJA))
    this.form.get(Roolit.MAKSUT_HYVAKSYJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.MAKSUT_HYVAKSYJA))

    this.form.get(Roolit.RAPORTIT).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.RAPORTIT))

    this.form.get(Roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA))
    this.form.get(Roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA))
    // this.form.get(Roolit.HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.HALLINTO_SUOMI_FI_VALTUUTUKSEN_ANTAJA))
    this.form.get(Roolit.HALLINTO_YHTEYSHENKILO).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.HALLINTO_YHTEYSHENKILO))
    this.form.get(Roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA))

    this.form.get(Roolit.TUNNISTETAAN).setValue(RooliMapper.annaBooleanArvo(kayttaja.roolit.TUNNISTETAAN))

    this.form.get(UserFeature.CAN_EDIT_PENALTY_INTEREST).setValue(!!kayttaja?.features?.CAN_EDIT_PENALTY_INTEREST)

    // Lemonaid cell phone process
    this.prosessinOsat = this.annaProsessienOsat(this.kayttaja)
    this.lisaaProsessinOsatFormiin(this.prosessinOsat, kayttaja, maksutavat)

  }

  private lisaaMaksutavat(osaForm: FormGroup<PortaatOsaFormInterface>, kayttaja: Kayttaja, maksutavat: AsiakkaanMaksutapa[]) {
    this.maksutavatArray = new FormArray<FormGroup<MaksutaFormGroupInterface>>([])
    osaForm.addControl('maksutavat', this.maksutavatArray)
    if (maksutavat) {
      for (const maksutapa of maksutavat) {
        const group = this.annaMaksutapaFormGroup(kayttaja, maksutapa, maksutavat)
        this.maksutavatArray.push(group)
      }
      this.paivitaMaksutapojenJarjestys(this.maksutavatArray, maksutavat, kayttaja)
    }
  }

  private paivitaMaksutapojenJarjestys(maksutavatArray: FormArray, maksutavat: AsiakkaanMaksutapa[], kayttaja: Kayttaja) {
    maksutavat.sort((a, b) => {
      if (kayttaja.maksutavat) {
        const indexOfA = kayttaja.maksutavat.indexOf(a.tunniste)
        const indexOfB = kayttaja.maksutavat.indexOf(b.tunniste)
        if (indexOfA > -1 && indexOfB > -1) {
          return indexOfA - indexOfB
        }
        if (indexOfA > -1) {
          return -1
        }
        if (indexOfB > -1) {
          return 1
        }
      }
      return b.tunniste - a.tunniste
    })
    maksutavatArray.controls.sort((a, b) => {
      const tunnisteA = a.get('tunniste').value
      const tunnisteB = b.get('tunniste').value
      if (kayttaja.maksutavat) {
        const indexOfA = kayttaja.maksutavat.indexOf(tunnisteA)
        const indexOfB = kayttaja.maksutavat.indexOf(tunnisteB)
        if (indexOfA > -1 && indexOfB > -1) {
          return indexOfA - indexOfB
        }
        if (indexOfA > -1) {
          return -1
        }
        if (indexOfB > -1) {
          return 1
        }
      }
      return tunnisteB - tunnisteA
    })
    this.aktivoitujenMaksutapojenLukumaara = kayttaja.maksutavat ? kayttaja.maksutavat.length : 0
  }

  private annaMaksutapaFormGroup(kayttaja: Kayttaja, maksutapa: AsiakkaanMaksutapa, maksutavat: AsiakkaanMaksutapa[]): FormGroup<MaksutaFormGroupInterface> {
    const kaytossa = kayttaja.maksutavat ? kayttaja.maksutavat.reduce<boolean>((previousValue, currentValue, currentIndex, array) => {
      return previousValue || currentValue === maksutapa.tunniste
    }, false) : false
    const maksutapaForm = new FormGroup<MaksutaFormGroupInterface>({
      'kaytossa': new FormControl<boolean>(kaytossa, []),
      'tunniste': new FormControl(maksutapa.tunniste, [])
    })
    maksutapaForm.get('kaytossa').valueChanges.subscribe(value => {
      if (kayttaja.maksutavat) {
        const index = kayttaja.maksutavat.indexOf(maksutapa.tunniste)
        if (value && index < 0) {
          kayttaja.maksutavat.push(maksutapa.tunniste)
        } else if (!value && index > -1) {
          kayttaja.maksutavat.splice(index, 1)
        }
      } else if (value) {
        kayttaja.maksutavat = []
        kayttaja.maksutavat.push(maksutapa.tunniste)
      }
      this.paivitaMaksutapojenJarjestys(this.maksutavatArray, maksutavat, kayttaja)
    })
    return maksutapaForm
  }

  private onkoKayttajallaOletusAlvPorras(kayttaja: Kayttaja): boolean {
    if (kayttaja && kayttaja.alvVahennysoikeusPortaat) {
      for (const porras of kayttaja.alvVahennysoikeusPortaat) {
        if (porras.oletus) {
          return true
        }
      }
    }
    return false
  }

  private lisaaProsessinOsatFormiin(prosessinOsat: MuokkausprosessinOsa[], kayttaja: Kayttaja, maksutavat: AsiakkaanMaksutapa[]) {
    const prosessinOsatArray = this.form.get('prosessinOsat') as FormArray
    for (const osa of prosessinOsat) {
      const group = this.annaProsessinOsaFormGroup(osa, kayttaja, maksutavat)
      prosessinOsatArray.push(group)
    }
  }

  private annaAlvPorrasFormGroup(porras: TositteenLisaysprosessinAlvVahennysoikeusPorras): FormGroup<AlvPorrasFormGroupInterface> {
    const porrasForm = new FormGroup<AlvPorrasFormGroupInterface>({
      arvo: new FormControl<number>(porras.arvo, [Validators.required]),
      oletus: new FormControl<boolean>(porras.oletus, [])
    })
    porrasForm.get('arvo').valueChanges.subscribe(value => { porras.arvo = this._validationService.processValue(value) })
    porrasForm.get('oletus').valueChanges.subscribe(value => { porras.oletus = this._validationService.processValue(value) })
    return porrasForm
  }

  // 'alvPortaat': new FormControl('', [ Validators.required ] ),
  private lisaaAlvPortaat(kayttaja: Kayttaja, parent: FormArray<FormGroup<AlvPorrasFormGroupInterface>>): number {
    if (!kayttaja?.alvVahennysoikeusPortaat?.length) {
      const firstGroup = this.lisaaAlvPorras()
      const secondGroup = this.lisaaAlvPorras()
      const thirdGroup = this.lisaaAlvPorras()
      firstGroup.get('arvo').setValue(0)
      firstGroup.get('oletus').setValue(false)
      secondGroup.get('arvo').setValue(50)
      secondGroup.get('oletus').setValue(true)
      thirdGroup.get('arvo').setValue(100)
      thirdGroup.get('oletus').setValue(false)
    } else {
      for (const porras of kayttaja.alvVahennysoikeusPortaat) {
        const group = this.annaAlvPorrasFormGroup(porras)
        parent.push(group)
      }
    }
    let oletus = 0, index = 0
    for (const porras of kayttaja.alvVahennysoikeusPortaat) {
      if (porras.oletus) {
        oletus = index
      }
      index++
    }
    return oletus
  }

  private lisaaAlvPortaatJaOletus(osaForm: FormGroup<PortaatOsaFormInterface>, kayttaja: Kayttaja) {
    this.portaatArray = new FormArray<FormGroup<AlvPorrasFormGroupInterface>>([])
    osaForm.addControl('alvPortaat', this.portaatArray)
    const oletuksenIndeksi = this.lisaaAlvPortaat(kayttaja, this.portaatArray)

    const oletusporras = new FormControl<number>(oletuksenIndeksi, [Validators.required])
    osaForm.addControl('oletusporras', oletusporras)
    oletusporras.valueChanges.subscribe(value => {
      let i = 0
      for (const ctrl of this.portaatArray.controls) {
        ctrl.get('oletus').setValue(i === value)
        i++
      }
    })
  }

  private annaProsessinOsaFormGroup(osa: MuokkausprosessinOsa, kayttaja: Kayttaja, maksutavat: AsiakkaanMaksutapa[]): FormGroup<PortaatOsaFormInterface> {

    const aktiivinenControl = new FormControl<boolean>(osa.valittu, [])
    const otsikkoControl = new FormControl<string>(osa.lisaysProsessinOsa.otsikko, [Validators.required])
    const osaForm = new FormGroup<PortaatOsaFormInterface>({
      'aktiivinen': aktiivinenControl,
      'otsikko': otsikkoControl
    })

    if (osa.lisaysProsessinOsa.tunniste === 'editalv') {
      this.naytaAlvPortaat = osa.valittu
      this.portaatOsaForm = osaForm
      if (osa.valittu) {
        this.lisaaAlvPortaatJaOletus(osaForm, kayttaja)
      }
    } else if (osa.lisaysProsessinOsa.tunniste === 'editpaymentmethod') {
      if (osa.valittu) {
        this.lisaaMaksutavat(osaForm, kayttaja, maksutavat)
      }
      this.naytaMaksutavat = osa.valittu
    }

    aktiivinenControl.valueChanges.subscribe(value => {
      osa.valittu = value
      if (osa.lisaysProsessinOsa.tunniste === 'editalv') {
        if (osa.valittu) {
          this.lisaaAlvPortaatJaOletus(osaForm, kayttaja)
        } else {
          osaForm.removeControl('alvPortaat')
          this.portaatArray = null
        }
        this.naytaAlvPortaat = osa.valittu
      } else if (osa.lisaysProsessinOsa.tunniste === 'editpaymentmethod') {
        if (osa.valittu) {
          this.lisaaMaksutavat(osaForm, kayttaja, maksutavat)
        } else {
          osaForm.removeControl('maksutavat')
        }
        this.naytaMaksutavat = osa.valittu
      }
    })
    otsikkoControl.valueChanges.subscribe(value => { osa.lisaysProsessinOsa.otsikko = this._validationService.processValue(value) })

    return osaForm
  }

  private navigoiTakaisinListaan() {
    this._router.navigate(['asiakkaat', this.asiakas.avain, 'kayttajat'])
  }

  private annaProsessinOsa(tunniste: string, kayttaja: Kayttaja): TositteenLisaysprosessinOsaMobiilissa | null {
    if (kayttaja && tunniste && kayttaja.prosessinOsat) {
      for (const osa of kayttaja.prosessinOsat) {
        if (osa.tunniste === tunniste) {
          return osa
        }
      }
    }
    return null
  }

  private annaProsessienOsat(kayttaja: Kayttaja): MuokkausprosessinOsa[] {
    const osat: MuokkausprosessinOsa[] = []

    // CAMERA("cameraview", "Kamera", 1),
    const kameraOsa = this.annaProsessinOsa('cameraview', kayttaja)
    const kameraValittu = (!!kameraOsa && !!kayttaja.avain) || !kayttaja.avain
    osat.push({
      lisaysProsessinOsa: kameraOsa ? kameraOsa : {
        otsikko: 'Kamera',
        tunniste: TositteenLisaysprosessinOsanTunniste.KAMERA
      },
      valittu: kameraValittu
    })

    // PAYMENT_METHOD("editpaymentmethod", "Tosite on maksettu", 2),
    const maksutapaOsa = this.annaProsessinOsa('editpaymentmethod', kayttaja)
    const maksutapaValittu = (!!maksutapaOsa && !!kayttaja.avain) || !kayttaja.avain
    osat.push({
      lisaysProsessinOsa: maksutapaOsa ? maksutapaOsa : {
        otsikko: 'Tosite on maksettu',
        tunniste: TositteenLisaysprosessinOsanTunniste.MAKSUTAPA
      },
      valittu: maksutapaValittu
    })

    // AMOUNT("editamount", "Maksun määrä", 3),
    const maaraOsa = this.annaProsessinOsa('editamount', kayttaja)
    const maaraValittu = (!!maaraOsa && !!kayttaja.avain) || !kayttaja.avain
    osat.push({
      lisaysProsessinOsa: maaraOsa ? maaraOsa : {
        otsikko: 'Maksun määrä',
        tunniste: TositteenLisaysprosessinOsanTunniste.MAKSUN_MAARA
      },
      valittu: maaraValittu
    })

    // DATE_AND_DESCRIPTION("editdatedesc", "Lisätiedot", 4),
    const pvmSeliteOsa = this.annaProsessinOsa('editdatedesc', kayttaja)
    const pvmSeliteValittu = (!!pvmSeliteOsa && !!kayttaja.avain) || !kayttaja.avain
    osat.push({
      lisaysProsessinOsa: pvmSeliteOsa ? pvmSeliteOsa : {
        otsikko: 'Lisätiedot',
        tunniste: TositteenLisaysprosessinOsanTunniste.PVM_SELITE
      },
      valittu: pvmSeliteValittu
    })

    // ALV("editalv", "Alv:n vähennysoikeus", 7);
    const alvOsa = this.annaProsessinOsa('editalv', kayttaja)
    const alvValittu = (!!alvOsa && !!kayttaja.avain)
    osat.push({
      lisaysProsessinOsa: alvOsa ? alvOsa : {
        otsikko: 'Alv:n vähennysoikeus',
        tunniste: TositteenLisaysprosessinOsanTunniste.ALV_VAHENNYSOIKEUS
      },
      valittu: alvValittu
    })

    if (kayttaja && kayttaja.prosessinOsat) {
      let indeksi = 0
      for (const osa of kayttaja.prosessinOsat) {
        let muokkausIndeksi = 0
        for (const muokkausOsa of osat) {
          if (muokkausOsa.lisaysProsessinOsa.tunniste === osa.tunniste) {
            if (indeksi !== muokkausIndeksi) {
              const temp = osat[muokkausIndeksi]
              osat[muokkausIndeksi] = osat[indeksi]
              osat[indeksi] = temp
            }
            break
          }
          muokkausIndeksi++
        }
        indeksi++
      }
    }

    return osat
  }

  async naytaVahvanTunnistautumisenHistoriaa() {
    const historia: StrongAuthenticationSessionHistory[] = await this._firebaseLemonaid.firestoreCollection<StrongAuthenticationSessionHistory>('customers/' + this.asiakas.avain + '/customers-users/' + this.kayttaja.avain + '/user-auth-history/').orderBy('logTime', 'desc').limit(100).get().then(
      snapshots => { return snapshots }
    )

    console.log(historia)
    this._dialog.open(KayttajaStrongAuthHistoryDialog, { data: historia })
  }

  get etunimi() {
    return this.get('etunimi')
  }

  get sukunimi() {
    return this.get('sukunimi')
  }

  get email() {
    return this.get('email')
  }

  get puhelin() {
    return this.get('puhelin')
  }

  get selvitettavienNakyvyys() {
    return this.get('selvitettavienNakyvyys')
  }

  private get(name: keyof MainFormInterface): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }
}

export class RooliMapper {

  static annaBooleanArvo(arvo: any): boolean {
    if (!arvo) {
      return false
    }
    if (typeof arvo === 'boolean') {
      return arvo as boolean
    }
    if (arvo instanceof Boolean) {
      return (arvo as Boolean).valueOf()
    }
    return false
  }

}
