import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { take } from 'rxjs/operators'

export interface VirheDialogData {
  error: Error | null
}

@Component({
  templateUrl: './virhe.dialog.html'
})
export class VirheDialog {

  virhe: Error | null

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: VirheDialogData,
    private dialogRef: MatDialogRef<VirheDialog>
  ) {
    this.virhe = this.data ? this.data.error : null
    this.dialogRef.beforeClosed().pipe(
      take(1)
    ).subscribe(() => {
      location.reload()
    })
  }

}
