import { VatNumberValidationCountrySettings } from '../../model/vat-number-validation'

export const belgium: VatNumberValidationCountrySettings = {
  name: 'Belgium',
  code: 'BE',
  calcFn: (vat: string): boolean => {
    const newVat = vat.length === 9 ? '0' + vat : vat

    const check = 97 - (Number(newVat.slice(0, 8)) % 97)
    return check === Number(newVat.slice(8, 10))
  },
  rules: {
    multipliers: {},
    regex: [/^(BE)(0?\d{9}|1\d{9})$/]
  }
}
