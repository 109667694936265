<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <h1 class="light-blue-text">Verohallintointegraatiot</h1>

  <form [formGroup]="form">
    <mat-tab-group>
      <!-- <mat-tab label="Omavero - saldot">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">Omaverosaldojen päivittäminen</h2>
            <div style="padding-bottom: 20px;">Saldot viimeksi päivitetty: {{ saldotPaivitettyObservable | async }}.</div>
            <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="paivitaSaldotOmaverosta()">
              {{ (kertisNroObservable | async) > 0 ? 'Päivitä' : 'Jatka' }}
            </button>
          </div>
          <div style="margin: 2em;">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of omaveroSaldotLogRivitObservable | async">{{rivi}}</ng-container></pre>
          </div>
        </div>
      </mat-tab> -->
      <!-- <mat-tab label="Omavero - maksuyhteystiedot">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">Omaveron maksuyhteystietojen päivittäminen</h2>
            <div style="padding-bottom: 20px;">Ajo päivittää maksuyhteystiedot niille asiakkaille, joilta ne puuttuvat.</div>
            <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="paivitaMaksuyhteystiedotOmaverosta()">
              {{ (kertisNroObservable | async) > 0 ? 'Päivitä' : 'Jatka' }}
            </button>
          </div>
          <div style="margin: 2em;">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of omaveroMaksuyhteystiedotLogRivitObservable | async">{{rivi}}</ng-container></pre>
          </div>
        </div>
      </mat-tab> -->
      <!-- <mat-tab label="Omavero - maksuohjelmat">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">Omaveron ennakkoverojen maksuohjelmien päivittäminen</h2>
            <div style="padding-bottom: 20px;">Ajo päivittää maksuohjelmat holviasiakkaille.</div>
            <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="paivitaMaksuyhteystiedotOmaverosta()">
              {{ (kertisNroObservable | async) > 0 ? 'Päivitä' : 'Jatka' }}
            </button>
          </div>
          <div style="margin: 2em;">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of omaveroMaksuohjelmatLogRivitObservable | async">{{rivi}}</ng-container></pre>
          </div>
        </div>
      </mat-tab> -->
      <mat-tab label="Ilmoitin - ALV-ilmoitukset">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">ALV-ilmoitusten lähetystiedot</h2>
            <div style="padding-bottom: 20px;">Lähettämättömiä ALV-ilmoituksia: {{ lahettamattomatIlmoituksetMaaraObservable | async }}.</div>
            <!-- <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="vieVeroilmot()">
              {{ (kertisNroObservable | async) > 0 ? 'Lähetä' : 'Jatka' }}
            </button> -->
          </div>
          <!-- <div style="margin: 2em; overflow: auto; max-height: 250px">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of ilmoitinAlvIlmoituksetObservable | async">{{rivi}}</ng-container></pre>
          </div> -->
        </div>

        <div style="margin: 2em;">
          <ng-container *ngIf="lahettamattomatVirheellisetObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät VIRHEELLISET ALV-ilmoitukset</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                  </mat-expansion-panel-header>

                  <div>
                    Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                    Data: <a [href]="ilmoitus.ilmoitus.url" target="_BLANK">{{ilmoitus.ilmoitus.uri}}</a>
                  </div>

                  <span style="color: red;">
                    Yritetty {{ ilmoitus.viimeisinVirhe?.tapahtunut | ltimestamptime | async }}:
                    <div>{{ ilmoitus.viimeisinVirhe?.virhe }}</div>
                  </span>
                  <!-- <div style="color: red;">
                    Virheet:
                    <div *ngFor="let virhe of ilmoitus.ilmoitus.lahetysvirheet">
                      {{ virhe?.tapahtunut | ltimestamptime | async }}: {{ virhe?.virhe }}
                    </div>
                  </div> -->

                  <div>
                    <div style="text-align: right; float: right;">
                      {{ ilmoitus.ilmoitus.tunnistetiedotString }}
                    </div>

                    <h2 class="alv-otsikko" style="padding: 1em 0 0 0; margin: 0;">Vero kotimaan myynneistä verokannoittain</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>25,5% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv255 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>24% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv24 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>14% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv14 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>10% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv10 | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Vero ostoista ja maahantuonneista</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Vero tavaraostoista muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.taveu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero palveluostoista muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.palveu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero tavaroiden maahantuonneista EU:n ulkopuolelta</td>
                            <td>{{ilmoitus.ilmoitus?.taveieu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero rakentamispalvelun ja metalliromun ostoista</td>
                            <td>{{ilmoitus.ilmoitus?.rak | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Vähennettävä vero</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Verokauden vähennettävä vero</td>
                            <td>{{ilmoitus.ilmoitus?.vahennettava | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div *ngIf="ilmoitus.ilmoitus?.arhSyotetty" style="background: rgb(229,244,250); padding: 1em;">
                      <div style="font-size: 18px; line-height: 30px;">Alarajahuojennus</div>
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Alarajahuojennukseen oikeuttava liikevaihto</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.suhteutettuLiikevaihto | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Alarajahuojennukseen oikeuttava vero</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.vero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Alarajahuojennuksen määrä</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.huojennus | lmoney: 'EUR' | async }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style="padding: 1em; background-color: gray; margin-top: 1em;">
                      <table class="alv-table" style="color: white; font-size: 18px; line-height: 30px;">
                        <tbody>
                          <tr>
                            <td>Maksettava vero / palautukseen oikeuttava vero</td>
                            <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Aiemman ilmoituksen mukainen vero</td>
                            <td>{{ ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Muutos veron määrässä</td>
                            <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero - ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Myynnit, ostot ja maahantuonnit</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>0-verokannan alainen liikevaihto</td>
                            <td>{{ilmoitus.ilmoitus?.nolla | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaroiden myynnit muihin EU-maihin</td>
                            <td>{{ilmoitus.ilmoitus?.tavmyynniteu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Palveluiden myynnit muihin EU-maihin</td>
                            <td>{{ilmoitus.ilmoitus?.palvmyynniteu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaraostot muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.tavostoeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Palveluostot muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.palvostoeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaroiden maahantuonnit EU:n ulkopuolelta</td>
                            <td>{{ilmoitus.ilmoitus?.tavulkeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)</td>
                            <td>{{ilmoitus.ilmoitus?.rakmetmyynti | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Rakentamispalvelun ja metalliromun ostot</td>
                            <td>{{ilmoitus.ilmoitus?.rakmetosto | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                      {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }})
                      <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedosto(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahettamattomatIlmoituksetObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät ALV-ilmoitukset</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                    <mat-panel-description>
                      <!-- Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }} -->
                      <!-- <mat-icon>account_circle</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div>

                    <div>
                      Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                      Data: <a [href]="ilmoitus.ilmoitus.url" target="_BLANK">{{ilmoitus.ilmoitus.uri}}</a>
                    </div>

                    <div style="text-align: right; float: right;">
                      {{ ilmoitus.ilmoitus.tunnistetiedotString }}
                    </div>

                    <h2 class="alv-otsikko" style="padding: 1em 0 0 0; margin: 0;">Vero kotimaan myynneistä verokannoittain</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>25,5% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv255 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>24% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv24 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>14% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv14 | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>10% vero</td>
                            <td>{{ilmoitus.ilmoitus?.alv10 | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Vero ostoista ja maahantuonneista</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Vero tavaraostoista muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.taveu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero palveluostoista muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.palveu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero tavaroiden maahantuonneista EU:n ulkopuolelta</td>
                            <td>{{ilmoitus.ilmoitus?.taveieu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Vero rakentamispalvelun ja metalliromun ostoista</td>
                            <td>{{ilmoitus.ilmoitus?.rak | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Vähennettävä vero</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Verokauden vähennettävä vero</td>
                            <td>{{ilmoitus.ilmoitus?.vahennettava | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div *ngIf="ilmoitus.ilmoitus?.arhSyotetty" style="background: rgb(229,244,250); padding: 1em;">
                      <div style="font-size: 18px; line-height: 30px;">Alarajahuojennus</div>
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>Alarajahuojennukseen oikeuttava liikevaihto</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.suhteutettuLiikevaihto | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Alarajahuojennukseen oikeuttava vero</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.vero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Alarajahuojennuksen määrä</td>
                            <td>{{ ilmoitus.ilmoitus.arhSyotetty.huojennus | lmoney: 'EUR' | async }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style="padding: 1em; background-color: gray; margin-top: 1em;">
                      <table class="alv-table" style="color: white; font-size: 18px; line-height: 30px;">
                        <tbody>
                          <tr>
                            <td>Maksettava vero / palautukseen oikeuttava vero</td>
                            <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Aiemman ilmoituksen mukainen vero</td>
                            <td>{{ ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                          <tr>
                            <td>Muutos veron määrässä</td>
                            <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero - ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 class="alv-otsikko">Myynnit, ostot ja maahantuonnit</h2>
                    <div style="padding: 0 1em;">
                      <table class="alv-table">
                        <tbody>
                          <tr>
                            <td>0-verokannan alainen liikevaihto</td>
                            <td>{{ilmoitus.ilmoitus?.nolla | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaroiden myynnit muihin EU-maihin</td>
                            <td>{{ilmoitus.ilmoitus?.tavmyynniteu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Palveluiden myynnit muihin EU-maihin</td>
                            <td>{{ilmoitus.ilmoitus?.palvmyynniteu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaraostot muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.tavostoeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Palveluostot muista EU-maista</td>
                            <td>{{ilmoitus.ilmoitus?.palvostoeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Tavaroiden maahantuonnit EU:n ulkopuolelta</td>
                            <td>{{ilmoitus.ilmoitus?.tavulkeu | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)</td>
                            <td>{{ilmoitus.ilmoitus?.rakmetmyynti | lmoney: 'EUR' | async}}</td>
                          </tr>
                          <tr>
                            <td>Rakentamispalvelun ja metalliromun ostot</td>
                            <td>{{ilmoitus.ilmoitus?.rakmetosto | lmoney: 'EUR' | async}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                      {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }})
                      <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedosto(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahetetytIlmoituksetObservable | async as lahetetytIlmoitukset">
            <div *ngIf="lahetetytIlmoitukset.length > 0" style="display: flex; justify-content: space-between; margin-top: 4em; margin-bottom: 2em;">
              <h3 style="margin: 0;" class="light-blue-text">
                Lähetyshistoria
              </h3>
              <mat-form-field style="width: 150px;">
                <mat-label>Näytä kerralla</mat-label>
                <mat-select [(value)]="historiaEntryjenMaara">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="25">25</mat-option>
                  <mat-option [value]="100">100</mat-option>
                  <mat-option [value]="1000">1000</mat-option>
                  <mat-option [value]="10000">10000</mat-option>
                  <!-- <mat-option [value]="100000">100000</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let ilmoitus of lahetetytIlmoitukset">
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                  <mat-panel-description>
                    Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }}
                    <!-- <mat-icon>account_circle</mat-icon> -->
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div>

                  <div>
                    Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                    Data: <a [href]="ilmoitus.ilmoitus.url" target="_BLANK">{{ilmoitus.ilmoitus.uri}}</a>
                  </div>

                  <div style="text-align: right; float: right;">
                    {{ ilmoitus.ilmoitus.tunnistetiedotString }}
                  </div>

                  <h2 class="alv-otsikko" style="padding: 1em 0 0 0; margin: 0;">Vero kotimaan myynneistä verokannoittain</h2>
                  <div style="padding: 0 1em;">
                    <table class="alv-table">
                      <tbody>
                        <tr>
                          <td>25,5% vero</td>
                          <td>{{ilmoitus.ilmoitus?.alv255 | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>24% vero</td>
                          <td>{{ilmoitus.ilmoitus?.alv24 | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>14% vero</td>
                          <td>{{ilmoitus.ilmoitus?.alv14 | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>10% vero</td>
                          <td>{{ilmoitus.ilmoitus?.alv10 | lmoney: 'EUR' | async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h2 class="alv-otsikko">Vero ostoista ja maahantuonneista</h2>
                  <div style="padding: 0 1em;">
                    <table class="alv-table">
                      <tbody>
                        <tr>
                          <td>Vero tavaraostoista muista EU-maista</td>
                          <td>{{ilmoitus.ilmoitus?.taveu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Vero palveluostoista muista EU-maista</td>
                          <td>{{ilmoitus.ilmoitus?.palveu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Vero tavaroiden maahantuonneista EU:n ulkopuolelta</td>
                          <td>{{ilmoitus.ilmoitus?.taveieu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Vero rakentamispalvelun ja metalliromun ostoista</td>
                          <td>{{ilmoitus.ilmoitus?.rak | lmoney: 'EUR' | async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h2 class="alv-otsikko">Vähennettävä vero</h2>
                  <div style="padding: 0 1em;">
                    <table class="alv-table">
                      <tbody>
                        <tr>
                          <td>Verokauden vähennettävä vero</td>
                          <td>{{ilmoitus.ilmoitus?.vahennettava | lmoney: 'EUR' | async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div *ngIf="ilmoitus.ilmoitus?.arhSyotetty" style="background: rgb(229,244,250); padding: 1em;">
                    <div style="font-size: 18px; line-height: 30px;">Alarajahuojennus</div>
                    <table class="alv-table">
                      <tbody>
                        <tr>
                          <td>Alarajahuojennukseen oikeuttava liikevaihto</td>
                          <td>{{ ilmoitus.ilmoitus.arhSyotetty.suhteutettuLiikevaihto | lmoney: 'EUR' | async }}</td>
                        </tr>
                        <tr>
                          <td>Alarajahuojennukseen oikeuttava vero</td>
                          <td>{{ ilmoitus.ilmoitus.arhSyotetty.vero | lmoney: 'EUR' | async }}</td>
                        </tr>
                        <tr>
                          <td>Alarajahuojennuksen määrä</td>
                          <td>{{ ilmoitus.ilmoitus.arhSyotetty.huojennus | lmoney: 'EUR' | async }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style="padding: 1em; background-color: gray; margin-top: 1em;">
                    <table class="alv-table" style="color: white; font-size: 18px; line-height: 30px;">
                      <tbody>
                        <tr>
                          <td>Maksettava vero / palautukseen oikeuttava vero</td>
                          <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                        </tr>
                        <tr>
                          <td>Aiemman ilmoituksen mukainen vero</td>
                          <td>{{ ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                        </tr>
                        <tr>
                          <td>Muutos veron määrässä</td>
                          <td>{{ ilmoitus.ilmoitus?.maksettavaTaiPalautettavaVero - ilmoitus.ilmoitus?.edellinenMaksettavaTaiPalautettavaVero | lmoney: 'EUR' | async }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h2 class="alv-otsikko">Myynnit, ostot ja maahantuonnit</h2>
                  <div style="padding: 0 1em;">
                    <table class="alv-table">
                      <tbody>
                        <tr>
                          <td>0-verokannan alainen liikevaihto</td>
                          <td>{{ilmoitus.ilmoitus?.nolla | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Tavaroiden myynnit muihin EU-maihin</td>
                          <td>{{ilmoitus.ilmoitus?.tavmyynniteu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Palveluiden myynnit muihin EU-maihin</td>
                          <td>{{ilmoitus.ilmoitus?.palvmyynniteu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Tavaraostot muista EU-maista</td>
                          <td>{{ilmoitus.ilmoitus?.tavostoeu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Palveluostot muista EU-maista</td>
                          <td>{{ilmoitus.ilmoitus?.palvostoeu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Tavaroiden maahantuonnit EU:n ulkopuolelta</td>
                          <td>{{ilmoitus.ilmoitus?.tavulkeu | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Rakentamispalvelun ja metalliromun myynnit (käännetty verovelvollisuus)</td>
                          <td>{{ilmoitus.ilmoitus?.rakmetmyynti | lmoney: 'EUR' | async}}</td>
                        </tr>
                        <tr>
                          <td>Rakentamispalvelun ja metalliromun ostot</td>
                          <td>{{ilmoitus.ilmoitus?.rakmetosto | lmoney: 'EUR' | async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div style="position: relative; height: 20px;">
                  <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                    {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }})
                    <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedosto(ilmoitus)">
                      <mat-icon>save</mat-icon>
                    </button>
                  </div>
                </div>

                <pre [innerHtml]="ilmoitus?.ilmoitus?.successData"></pre>

                <!-- <pre>{{ ilmoitus.ilmoitus | json }}</pre> -->
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Ilmoitin - Veroilmoitukset">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">Veroilmoitusten lähetystiedot</h2>
            <div style="padding-bottom: 20px;">Lähettämättömiä Veroilmoituksia: {{ lahettamattomatVeroilmoituksetMaaraObservable | async }}.</div>
            <!-- <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="vieVeroilmot()">
              {{ (kertisNroObservable | async) > 0 ? 'Lähetä' : 'Jatka' }}
            </button> -->
          </div>
          <!-- <div style="margin: 2em; overflow: auto; max-height: 250px">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of ilmoitinAlvIlmoituksetObservable | async">{{rivi}}</ng-container></pre>
          </div> -->
        </div>

        <div style="margin: 2em;">
          <ng-container *ngIf="lahettamattomatVirheellisetVeroilmoituksetObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät VIRHEELLISET Veroilmoitukset</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                    <!-- <mat-panel-description>
                      <span style="color: red;">
                        Yritetty {{ ilmoitus.viimeisinVirhe?.tapahtunut | ltimestamptime | async }}: {{ ilmoitus.viimeisinVirhe?.virhe }}
                      </span> -->
                    <!-- <mat-icon>account_circle</mat-icon> -->
                    <!-- </mat-panel-description> -->
                  </mat-expansion-panel-header>

                  <div>

                    <div>
                      Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                      Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                    </div>

                    <span style="color: red;">
                      Yritetty {{ ilmoitus.viimeisinVirhe?.tapahtunut | ltimestamptime | async }}:
                      <div>{{ ilmoitus.viimeisinVirhe?.virhe }}</div>
                    </span>
                    <!-- <div style="color: red;">
                      Virheet:
                      <div *ngFor="let virhe of ilmoitus.ilmoitus.lahetysvirheet">
                        {{ virhe?.tapahtunut | ltimestamptime | async }}: {{ virhe?.virhe }}
                      </div>
                    </div> -->

                    <!-- <div style="text-align: right; float: right;">

                    </div> -->

                    <pre>{{ilmoitus.content}}</pre>

                  </div>

                  <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                      <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                      <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahettamattomatVeroilmoituksetObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät veroilmoitukset</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                    <!-- <mat-panel-description> -->
                    <!-- Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }} -->
                    <!-- <mat-icon>account_circle</mat-icon> -->
                    <!-- </mat-panel-description> -->
                  </mat-expansion-panel-header>

                  <div>

                    <div>
                      Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                      Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                    </div>

                    <!-- <div style="text-align: right; float: right;">
                      MITÄ TÄHÄN?
                    </div> -->

                    <pre>{{ilmoitus.content}}</pre>

                  </div>

                  <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                      <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                      <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahetetytVeroilmoituksetObservable | async as lahetetytIlmoitukset">
            <div *ngIf="lahetetytIlmoitukset.length > 0" style="display: flex; justify-content: space-between; margin-top: 4em; margin-bottom: 2em;">
              <h3 style="margin: 0;" class="light-blue-text">
                Lähetyshistoria
              </h3>
              <mat-form-field style="width: 150px;">
                <mat-label>Näytä kerralla</mat-label>
                <mat-select [(value)]="historiaEntryjenMaaraVeroilmo">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="25">25</mat-option>
                  <mat-option [value]="100">100</mat-option>
                  <mat-option [value]="1000">1000</mat-option>
                  <mat-option [value]="10000">10000</mat-option>
                  <!-- <mat-option [value]="100000">100000</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let ilmoitus of lahetetytIlmoitukset">
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                  <mat-panel-description>
                    Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }}
                    <!-- <mat-icon>account_circle</mat-icon> -->
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div>

                  <div>
                    Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                    Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                  </div>

                  <!-- <div style="text-align: right; float: right;">
                    MITÄ TÄHÄN?
                  </div> -->

                  <pre>{{ilmoitus.content}}</pre>
                  <pre [innerHtml]="ilmoitus?.ilmoitus?.successData"></pre>

                </div>

                <div style="position: relative; height: 20px;">
                  <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;">
                    <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                    <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                      <mat-icon>save</mat-icon>
                    </button>
                  </div>
                </div>

                <!-- <pre>{{ ilmoitus.ilmoitus | json }}</pre> -->
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Ilmoitin - PRH rekisteröinnit">
        <div style="display: flex;">
          <div style="margin: 2em;">
            <h2 class="light-blue-text">PRH rekisteröintien lähetystiedot</h2>
            <div style="padding-bottom: 20px;">Lähettämättömiä PRH rekisteröintejä: {{ lahettamattomatPrhRekisteroinnitMaaraObservable | async }}.</div>
            <!-- <mat-form-field style="max-width: 200px;">
              <input type="text" name="username" autocomplete="username" formControlName="otpKayttajatunnus" placeholder="Käyttäjätunnus" matInput required />
              <mat-error *ngIf="otpKayttajatunnus?.errors?.required && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus puuttuu</mat-error>
              <mat-error *ngIf="otpKayttajatunnus?.errors?.username && (otpKayttajatunnus?.dirty || otpKayttajatunnus?.touched)">Käyttäjätunnus on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;">
              <input type="password" name="password" autocomplete="current-password" formControlName="otpSalasana" placeholder="Salasana" matInput required />
              <mat-error *ngIf="otpSalasana?.errors?.required && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana puuttuu</mat-error>
              <mat-error *ngIf="otpSalasana?.errors?.pwd && (otpSalasana?.dirty || otpSalasana?.touched)">Salasana on väärä</mat-error>
            </mat-form-field><br />
            <mat-form-field style="max-width: 200px;" *ngIf="(kertisNroObservable | async) > 0">
              <mat-label>{{ 'Kertasalasana nro ' + (kertisNroObservable | async) }}</mat-label>
<input #kertisTextfield type="text" [name]="name" formControlName="otpKertis"  data-lpignore="true" matInput required />
              <mat-error *ngIf="otpKertis?.errors?.required && (otpKertis?.dirty || otpKertis?.touched)">Kertasalasana puuttuu</mat-error>
            </mat-form-field><br />
            <button mat-button class="primarybutton" (click)="vieVeroilmot()">
              {{ (kertisNroObservable | async) > 0 ? 'Lähetä' : 'Jatka' }}
            </button> -->
          </div>
          <!-- <div style="margin: 2em; overflow: auto; max-height: 250px">
            Viimeisimmän ajon logit
            <pre><ng-container *ngFor="let rivi of ilmoitinAlvIlmoituksetObservable | async">{{rivi}}</ng-container></pre>
          </div> -->
        </div>

        <div style="margin: 2em;">
          <ng-container *ngIf="lahettamattomatVirheellisetPrhRekisteroinnitObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät VIRHEELLISET rekisteröinnit</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                    <!-- <mat-panel-description>
                      <span style="color: red;">
                        Yritetty {{ ilmoitus.viimeisinVirhe?.tapahtunut | ltimestamptime | async }}: {{ ilmoitus.viimeisinVirhe?.virhe }}
                      </span> -->
                    <!-- <mat-icon>account_circle</mat-icon> -->
                    <!-- </mat-panel-description> -->
                  </mat-expansion-panel-header>

                  <div>

                    <div>
                      Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                      Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                    </div>

                    <span style="color: red;">
                      Yritetty {{ ilmoitus.viimeisinVirhe?.tapahtunut | ltimestamptime | async }}:
                      <div>{{ ilmoitus.viimeisinVirhe?.virhe }}</div>
                    </span>
                    <!-- <div style="color: red;">
                      Virheet:
                      <div *ngFor="let virhe of ilmoitus.ilmoitus.lahetysvirheet">
                        {{ virhe?.tapahtunut | ltimestamptime | async }}: {{ virhe?.virhe }}
                      </div>
                    </div> -->

                    <!-- <div style="text-align: right; float: right;">

                    </div> -->

                    <!-- <pre>{{ilmoitus.content}}</pre> -->

                  </div>

                  <!-- <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;"> -->
                  <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                  <!-- <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div> -->

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahettamattomatPrhRekisteroinnitObservable | async as lahettamattomat">
            <div *ngIf="lahettamattomat.length > 0">
              <h3 style="margin-top: 4em;" class="light-blue-text">Lähettämättömät rekisteröinnit</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let ilmoitus of lahettamattomat">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                    <mat-panel-description>
                      <!-- Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }} -->
                      <!-- <mat-icon>account_circle</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div>

                    <div>
                      Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                      Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                    </div>

                    <!-- <div style="text-align: right; float: right;">
                      MITÄ TÄHÄN?
                    </div> -->

                    <!-- <pre>{{ilmoitus.content}}</pre> -->

                  </div>

                  <!-- <div style="position: relative; height: 20px;">
                    <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;"> -->
                  <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                  <!-- <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div> -->

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-container *ngIf="lahetetytPrhRekisteroinnitObservable | async as lahetetytIlmoitukset">
            <div *ngIf="lahetetytIlmoitukset.length > 0" style="display: flex; justify-content: space-between; margin-top: 4em; margin-bottom: 2em;">
              <h3 style="margin: 0;" class="light-blue-text">
                Lähetyshistoria
              </h3>
              <mat-form-field style="width: 150px;">
                <mat-label>Näytä kerralla</mat-label>
                <mat-select [(value)]="historiaEntryjenMaaraPrh">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="25">25</mat-option>
                  <mat-option [value]="100">100</mat-option>
                  <mat-option [value]="1000">1000</mat-option>
                  <mat-option [value]="10000">10000</mat-option>
                  <!-- <mat-option [value]="100000">100000</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let ilmoitus of lahetetytIlmoitukset">
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ ilmoitus?.asiakas?.nimi }} ({{ ilmoitus?.asiakas?.ytunnus }})</mat-panel-title>
                  <mat-panel-description>
                    Lähetetty {{ ilmoitus?.ilmoitus?.lahetetty | ltimestamptime | async }}
                    <!-- <mat-icon>account_circle</mat-icon> -->
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div>

                  <div>
                    Asiakas: <a [href]="'/asiakkaat/' + ilmoitus.asiakas.avain + '/asiakastiedot'" target="_BLANK">Asiakkaan tiedot</a><br />
                    Data: <a [href]="ilmoitus.url" target="_BLANK">{{ilmoitus.uri}}</a>
                  </div>
                  <pre [innerHtml]="ilmoitus?.ilmoitus?.successData"></pre>
                  <!-- <div style="text-align: right; float: right;">
                    MITÄ TÄHÄN?
                  </div> -->

                  <!-- <pre>{{ilmoitus.content}}</pre> -->

                </div>

                <!-- <div style="position: relative; height: 20px;">
                  <div style="text-align: right; position: absolute; right: -10px; bottom: -10px; color: darkgray; font-size: 80%; width: 600px;"> -->
                <!-- {{ilmoitus.ilmoitus?.tallennustiedot}} (Lähetys #{{ ilmoitus.ilmoitus?.jarjestysnumero }}) -->
                <!-- <button class="light-blue-text" mat-icon-button (click)="lataaTyviTiedostoVeroIlmo(ilmoitus)">
                      <mat-icon>save</mat-icon>
                    </button>
                  </div>
                </div> -->

                <!-- <pre>{{ ilmoitus.ilmoitus | json }}</pre> -->
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>

</div>