import { Environment, EnvironmentType } from '../app/app.environment'
import { LemonaidFirebaseConfig } from '../app/_jaettu-angular/base-firebase.service'

export const lemonatorFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyBeuGLthrMYpBgH7eZB59qC3Alg3nBfM0Q',
  authDomain: 'kirjaudu.dev.lemonator.lemontree.fi',
  databaseURL: 'https://dev-lemonator-web-default-rtdb.firebaseio.com',
  projectId: 'dev-lemonator-web',
  storageBucket: 'dev-lemonator-web.appspot.com',
  messagingSenderId: '426230395127',
  appId: '1:426230395127:web:287fd55865aafd454023f2',
  functionsRegion: 'europe-west1'
}

export const lemonaidFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyAQ6eSAtBdxY4kWpbM-j3T7wO7uTpbGlGs',
  authDomain: 'dev-lemonaid.firebaseapp.com',
  databaseURL: 'https://dev-lemonaid-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dev-lemonaid',
  storageBucket: 'dev-lemonaid.appspot.com',
  messagingSenderId: '113515017142',
  appId: '1:113515017142:web:3b78992265c8cb8de10717',
  functionsRegion: 'europe-west1'
}

export const environment: Environment = {
  environment: EnvironmentType.DEV,
  airbrakeEnabled: false,
  enableVersionCheck: false,
  lemonatorFirebaseConfig: lemonatorFirebaseConfig,
  lemonaidFirebaseConfig: lemonaidFirebaseConfig,
  recaptchaSiteId: '6LfjAEgfAAAAABrF1ZfcQ2nefFRKTV8f1mC771WS',
  lemonaidRecaptchaSiteId: '6Ley3kcfAAAAACIe0hoWwsgPXl0yZxO3cFTGoMxA',
  version: 'DEV',
  host: 'https://dev.lemonator.lemontree.fi'
}
